


import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';


import moment from 'moment';
import './documentlisting.css';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import Button from '@material-ui/core/Button';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


import jwt from 'jwt-decode'
import { PDFReader } from 'reactjs-pdf-reader';


import MyModal from './modal.js';
import { Tooltip } from '@mui/material';


import DatePicker from "react-datepicker";
import "./react-datepicker.css";
import "./react-datepicker-cssmodules.css";
import { Typography } from '@material-ui/core';




//let server_url = 'https://automate.isnet.co.za';
//let server_url = 'http://127.0.0.1:3002';
let server_url = process.env.REACT_APP_SERVER_URL;

var date;




const open_file = (doc_link) => {

  console.log('opening file: ' + doc_link);

  return new Promise(function (resolve,reject){

    try{
                      const token = localStorage.getItem('AccessToken'); 
                      let options = {
                      headers: {
                          'Authorization': 'Bearer ' + token,
                          Accept: 'application/pdf'
                      },
                      insecure: true,
                      rejectUnauthorized: false


                      }



                      const requestOptions = {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/pdf',
                        'Authorization': 'Bearer ' + token
                         }
                        };
                        
                        //fetch(server_url + "/get_pdf_file?file_name=" + doc_link , requestOptions)
                        fetch(doc_link, requestOptions)
                        .then((res) => {
                            return res.blob();
                        })
                        .then((blob) => {
                            const href = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', 'file.pdf'); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch((err) => {
                            return Promise.reject({ Error: 'Something Went Wrong', err });
                        })
                        

                    /*
                    fetch(server_url + "/get_pdf_file?file_name=" + doc_link , options)
                    .then(response => response.text())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);

                            //const file = new Blob(
                            //  [data], 
                            //  {type: 'application/pdf'})

                              //Build a URL from the file
                              //const fileURL = URL.createObjectURL(file);
                              //console.log(fileURL);

                            //Build a URL from the file
                            //window.open(fileURL);

                            console.log('xxx2');
                            //resolve(data.toString('base64'));

                            resolve(data);
                            //resolve(fileURL);
                            //resolve(file);

                           
                            
                    });
                    */


                    
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}




async function load_pdf(doc_link){


    return new Promise(function (resolve,reject){
  
        try{
                          const token = localStorage.getItem('AccessToken'); 



                          let options = {
                          headers: {
                              'Authorization': 'Bearer ' + token
                          },
                          insecure: true
                          //rejectUnauthorized: false
                          }


  
  
                        fetch(server_url + "/", options)
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
  
                               
                                
                        });
  
                        
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })



}

async function get_documents(state_date, end_date){





    return new Promise(function (resolve,reject){
  
      try{
                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                        insecure: true,
                        rejectUnauthorized: false
                        }


                      fetch(server_url + "/transactions?start_date=" + state_date + "&end_date=" + end_date, options)
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);

                             
                              
                      });

                      
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
    }
  
  
    async function get_pdf_data(filename, company){
  
  
        return new Promise(function (resolve,reject){
      
          try{
      
      
            const token = localStorage.getItem('AccessToken'); 
      
          
          
      
      
                      
                      fetch(server_url + "/get_queue_pdf_file?company=" + company + "&filename=" + filename,{
                        method: 'GET',
                        headers: { 
                                    'Content-Type': 'application/pdf'
                        },
                        })
                        .then((res) => {
                           // return res.blob();
                           //resolve(res.blob());]
                           const file = new Blob([res.data], { type: 'application/pdf' });
                           const fileURL = URL.createObjectURL(file);
                           resolve( fileURL);
                           //resolve(res.data);

                        })
                    
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
      
      
      
      
      }
  
function DocumentList(props){

   
    let listItems = props.RepoData;

    console.log(listItems.length);
    console.log(listItems);
   
    

    if(listItems.length > 0){
    return(
        <div>
       {
            listItems.map((item)=> {

               

                return(
                    
                    <div>
                     <li key={item.id}>
                          {item.doc_name}
                    </li> 
                  </div>
                )

            }  )  
        }
        </div>
    )
    }
    else{
        return(null);
    }

}


async function delete_from_processing_queue(dataObject){

  
    return new Promise(function (resolve,reject){

      try{

                const token = localStorage.getItem('AccessToken'); 
                let options = {
                  headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  },
                  insecure: true,
                  rejectUnauthorized: false
                }

                let myheaders = {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                  
        
                  fetch(server_url + "/delete_from_processing_queue",{
                    method: 'POST',
                    //headers: { 'Content-Type': 'application/json' },
                    headers: myheaders,
                    body: JSON.stringify(dataObject)
                    })
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              //resolve(data);
                              window.location.reload();
                      
                      });
      }
      catch(err){
              console.log(err);
              reject(err);
      }

      })




  }



async function process_queue_file(filename){

    //https://automate.isnet.co.za/process_file2?filename=mustek&trained=true

  
        return new Promise(function (resolve,reject){
      
          try{
      
      
            const token = localStorage.getItem('AccessToken'); 
      
          

      
      
                      
                      fetch(server_url + "/process_file2?filename=" + filename + "&queue=true",{
                        method: 'GET',
                        headers: { 
                                    'Content-Type': 'application/json',
                                    Authorization: 'Bearer ' + token
                        }
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
      
      
      
      
      
      


}


async function get_invoice_data(filename){

  //https://automate.isnet.co.za/process_file2?filename=mustek&trained=true


      return new Promise(function (resolve,reject){
    
        try{
    
    
          const token = localStorage.getItem('AccessToken'); 
    
        

    
    
                    
                    fetch(server_url + "/get_invoice_data?filename=" + filename + "&queue=true",{
                      method: 'GET',
                      headers: { 
                                  'Content-Type': 'application/json',
                                  Authorization: 'Bearer ' + token
                      }
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    
    


}



function DocumentList2(props){

   
    let listItems = props.RepoData;

    console.log(listItems.length);
    console.log(listItems);
  
   
    const [Company,setCompany] = useState();
    const [PDFData,setPDFData] = useState();
    const [ModalShow,setModalShow] = useState();
    


    const history = useHistory();


    useEffect(()=>{

    let token = localStorage.getItem('AccessToken');
    let decoded_token = jwt(token);
    //console.log('decoded:');
    //console.log(decoded_token);
    setCompany(decoded_token.company);

    },[]);

    

    

    if(listItems.length > 0){
    return(
        
        <div>

        <Typography>
          Total Transactions: {listItems.length}
        </Typography>
        {/*
        <MyModal show={ModalShow} pdf_url={PDFData}>
          <p>Modal</p>
        </MyModal>
        */}

      
        <table id="myTable">

          
        
        <thead>
                            <tr>
                            <th>timestamp</th>
                            <th>method</th>
                            <th>route</th>
                            <th>statusCode</th>
                            <th width='20px'>company</th>
                            {/*<th>Detect</th>*/}
                            <th>user</th>
                            </tr>
                        </thead>
                        <tbody>
                       

        
       {
            listItems.map((item, index)=> {
               

                let item_url = '';

         
           
                
                date = moment.unix(item.updated).format('YYYY-MM-DD HH:mm:ss');
                //var sender= item.doc_sender;
               // var docStatus= item.status;

               item_url = "/openfile?company=" + Company + "&filename=" + item.filename + "&from='" + item.from+ "'&to='" + item.to + "&subject='" + item.subject + "'&date=" +item.date + "&doclink=" + item.filename;
               let myurl = server_url + "/get_queue_pdf_file?filename=" + item.filename + "&company=" + Company;
                
                return(
                   
                            <tr key={index}>
                           {/* <td><Link to={item_url}>{item.doc_name}</Link></td> */}
                      
                            <td>{moment.unix(item.timestamp).format('YYYYMMDD HH:mm:ss')}</td>
                            <td>{item.method}</td>
                            <td>{item.route}</td>
                            <td>{item.statusCode}</td>
                            <td width="20px">
                              {item.company}
                            </td>
                            <td>
                              {item.user}
                            </td>
                           
                            </tr>
                
                     
                )

            }  )  
        }
        </tbody>
        </table>


       

        <div >

       


   
        {PDFData &&
         <PDFReader  url={PDFData}></PDFReader> 
            
        }
        
        </div>

        </div>
      
    )
    }
    else{
        return(null);
    }

}



const MyDate = (props) => {
  const [startDate, setStartDate] = useState(new Date().setHours(0,0,0,0));
  return (
    <DatePicker selected={startDate} onChange={(date:Date)=>{

      //(date:Date) => setStartDate(date);
      setStartDate(date);
      props.parentCallback(date);
      console.log(date);

    }


    } className="red-border" />
  );
};


export default function Transactions(){


    const [RepoData,setRepoData] = useState({});
    const [TotalTransactions, setTotalTransactions] = useState();
    const history = useHistory();

    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());


    async function reload_transactions(start_date,end_date){
      try{
        let doc_data = await get_documents(start_date, end_date);
        console.log('DD: ' + JSON.stringify(doc_data));
        setRepoData(doc_data.reverse());
        setTotalTransactions(doc_data.length);
        console.log(RepoData.length)
      }
      catch(err){
        console.log(err);
      }
    }


    useEffect(()=>{


            //Get All Documents from database
            async function get_docs(){

              try{
                let doc_data = await get_documents();
                console.log('DD: ' + JSON.stringify(doc_data[0].New));
                setRepoData(doc_data[0].New.reverse());
                console.log(RepoData.length)
              }
              catch(err){
                console.log(err);
              }
            }
            get_docs();
            
            



    },[])


    function handleSearch() {
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[0];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }       
        }
      }


      function thisMonth(){

        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        console.log(firstDay); // 👉️ Sat Oct 01 2022 ...
        let start_date = moment(firstDay).format('YYYYMMDD');

        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        console.log(lastDay); // 👉️ Mon Oct 31 2022 ...
        let last_day = moment(lastDay).format('YYYYMMDD');

        reload_transactions(start_date,last_day);
      }

      function  startDateCallback(date){

          console.log("here:" + date);
          let udate = moment(date).format('YYYYMMDD');
          console.log(udate);
          //setHours(0,0,0,0))
          setStartDate(udate);
      }

      function  endDateCallback(date){

        console.log("here:" + date);
        //let udate = moment(date).unix();
        let udate = moment(date).format('YYYYMMDD');
        console.log(udate);
        setEndDate(udate);
        //setHours(0,0,0,0))
    }


            return(
              <div>
                <div id="Search">

                  <br />
                           Start Date <MyDate parentCallback={startDateCallback}></MyDate>
                           <br />
                           End Date <MyDate parentCallback={endDateCallback}></MyDate>

                           <br />
                           <Button onClick={()=>{

                              console.log('searching');
                              console.log('start_date:' + StartDate);
                              console.log('end_date: ' + EndDate);

                              let jsonobj = reload_transactions(StartDate, EndDate);

                           }}>Search</Button>
                </div>

              
                <div id="Current Billing">

                  <br />
                        
                           <Button onClick={()=>{
                                thisMonth();
                           }}>View Current Month Billing</Button>
                </div>
                <div>
                          <b>Billing Summary</b>
                          <br/>

                          Total Transactions: {TotalTransactions} 
                          <br/>
                          Cost per Transaction: 0.10
                          <br/>
                          Total Cost: {TotalTransactions * 0.10} 
                          <br/>
                </div>

                <div id="bodyBox">
                    <div><h3><ListItemIcon><DescriptionIcon/></ListItemIcon> Transactions</h3></div><br/>
                    <div>
                    <div>
                        <input
                            id="myInput"
                                type="text"
                                placeholder="Search Timestamp"
                               
                                onChange={handleSearch}
                            />
                          
                          
                            
                    </div>

                    <div>
                      
                    </div>

                  <div>


                  </div>

           
                    
                         <DocumentList2 RepoData={RepoData} ></DocumentList2>
         
                    </div>
                </div>
                </div>
            )

    
}
