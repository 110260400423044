
import React, {useCallback, useState, useMemo, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';

import { sha256 } from 'js-sha256';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://isnet.co.za">
        https://isnet.co.za
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));





export default function ForgotPassword() {



  const classes = useStyles();
  const history = useHistory();

  const [UserName, setUserName] = useState();
  const [Mobile, setMobile ] = useState();

  const [EmailSent, setEmailSent] = useState(false)


  



  function post_forgot(name, username, passhash, company, mobile){

    try{
  
      let server_url = 'http://127.0.0.1:3002'
      //let server_url = 'https://automate.isnet.co.za'


      let doc = {
        "username": username, 
        "mobile": mobile
      }


      fetch(server_url + '/forgotpassword', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(doc)
        }).then((response) =>  response.json()).then((json) => {
  
  
              
              console.log(json);
              setEmailSent(true);


              }
            );
      }
      catch(err){

        console.log('Error posting');

      }
                    
  }

  


  if(EmailSent){

    return(


    <Container component="main" maxWidth="xs">
   
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password Reset sent
        </Typography>
    </div>
    </Container>
    );


  }
  else{
  return (
    <Container component="main" maxWidth="xs">
   
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
            Reset Password
        </Typography>

        <Typography component="h1" variant="h5">
          {Response}
        </Typography>



      
          <TextField
            variant="outlined"
            margin="normal"
            value={UserName}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e)=>{
   
            
              //FieldData[item] = e.target.value;
               setUserName(e.target.value);
               
          

         }}
          />
        

         <TextField
                    variant="outlined"
                    margin="normal"
                    value={Mobile}
                    required
                    fullWidth
                    name="mobile"
                    label="Mobile Number"
                    type="phone"
                    id="mobile"
         
                
            
                    onChange={(e)=>{
        
                        //console.log('testing:');
                        //console.log(e.target.value);
                        //FieldData[item] = e.target.value;
                        setMobile(e.target.value);
                        //console.log(e.target.value);
                        
                    
        
                    }}
                />



          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() =>{
              // history.push("/");

            

              //name, username, passhash, company, mobile
              post_forgot(UserName, Mobile);


            }}
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item xs>
             
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                
              </Link>
            </Grid>
          </Grid>
       
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    
    </Container>
  );
  }
}
