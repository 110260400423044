import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import Select from '@mui/material/Select';

import Button from '@mui/material/Button';

import { MenuItem, InputLabel } from '@material-ui/core';



//redux
import { useSelector, useDispatch } from 'react-redux';
import { loggedin,loggedout, AccessData } from '../redux/authSlice';


import jwt from 'jwt-decode'


const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
});


let server_url = process.env.REACT_APP_SERVER_URL;

//let server_url = 'https://automate.isnet.co.za';
//let server_url = 'http://127.0.0.1:3002';


const token = localStorage.getItem('AccessToken'); 


let full_url = server_url + '/doc_trainer.html?t=' + token;


async function delete_docname(doc_name){
  
  
  return new Promise(function (resolve,reject){

    try{


      const token = localStorage.getItem('AccessToken'); 

    
    


               let dataObject = {
                 "doc_name": doc_name
               }
                
                
                fetch(server_url + "/delete_docname",{
                  method: 'POST',
                  headers: { 
                              'Content-Type': 'application/json',
                              Authorization: 'Bearer ' + token
                  },
                  body: JSON.stringify(dataObject)
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}




async function get_docs(){

  return new Promise(function (resolve,reject){
  
                try{
                
                      const token = localStorage.getItem('AccessToken');
                      
                      let options = {
                      
                      headers: {
                      
                      'Authorization': 'Bearer ' + token
                      
                      },
                      
                      insecure: true,
                      
                      rejectUnauthorized: false
                      
                      }
                      
                      
                      fetch(server_url + "/get_docs", options)
                      
                            .then(response => response.json())
                            
                            .then(function(data){
                            
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                          
                              
                            resolve(data);

                      //}
                      });
                
                }
            
                  catch(err){
                  
                        console.log(err);
                        
                        reject(err);
                        
                  }
            
            })
  
  }





export default function TrainDocument() {
  const classes = useStyles();

  const authstate = useSelector((state) => state.authstate.value);
  const dispatch = useDispatch()


  const [MyToken,setMyToken] = useState();
  const [MyUrl, setMyUrl] = useState();



  const [Docs,setDocs] = useState([]);
  const [Company,setCompany] = useState();
  const [DocName,setDocName] = useState();

  const [RunOnce,setRunOnce] = useState(true);








  async function gdocs(){


  return new Promise(async function(resolve, reject){





    let docsJson = await get_docs();
    console.log("dd: " + JSON.stringify(docsJson));
    console.log(docsJson.length);

    let docArr = new Array();

    let i=0; const iMax = docsJson.length - 1; for(; i<=iMax; i++){


        try{
        let item = docsJson[i];

        console.log(item);
        //process.exit();
        let doc_name = item.doc_name;
        let doc_sender = item.doc_sender;
        let doc_tag = item.doc_tag;
        let company = item.company
        
        console.log("zz doc_name: " + doc_name + " doc_sender: " + doc_sender + " doc_tag: " + doc_tag + " company: " + company);
        let docObj = {
          doc_name: doc_name,
          doc_tag: doc_tag
        }
        docArr.push(docObj);

        if(i == iMax){

          console.log("ddd: " + JSON.stringify(docArr));
          //setDocs(docArr);

          resolve(docArr);
          console.log("DOCS: " + JSON.stringify(Docs));
          //resolve('OK');
        }
        }
        catch(err){

          console.log(err);
          //resolve(err);
        }

      }

      });


      //console.log(docsJson[i]);
    //}

  //});
    //return(docsJson);
}

  useEffect(()=>{


    
    if(RunOnce){

      setRunOnce(false);
      const fetch_data = async () => {

          let zdocs = await gdocs();
          //console.log('cc: ' + JSON.stringify(zdocs));
          setDocs(zdocs);

      };
       


      //if(RunOnce){
       fetch_data()
        .catch(console.error);


        let decoded_token = jwt(token);
        //console.log('decoded:');
        //console.log(decoded_token);
        setCompany(decoded_token.company);

      
    }
      


  });

  const authstate2 = useSelector((state) => state.authstate.value);

  useEffect(()=>{


   if(MyToken == undefined){



   


    console.log('RTOKEN: ' + JSON.stringify(authstate2));
   
    let mytoken = localStorage.getItem('AccessToken'); 
    full_url = server_url + '/doc_trainer.html?t=' + mytoken;
    setMyUrl(full_url);

    setMyToken(mytoken);

    console.log("Full URL: " + full_url);

   }

  });

  console.log('TOKEN: ' + JSON.stringify(authstate));

  full_url = server_url + '/doc_trainer.html?t=' + authstate.AccessToken;

  return (

    <div id='bodyBox'>
        <div><h3>Training Document Below</h3></div>

       {/* <div><a href={full_url} target="blank">Open in New Tab</a></div> */}
       <div><a href={MyUrl} target="blank">Open in New Tab</a></div>

        <div>
     
          <InputLabel id="demo-simple-select-label">Load Document</InputLabel>


          

          <Select 
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={(e)=>{


            //console.log(e.target.value);
            let doc_name = e.target.value;
            console.log(doc_name);
            setDocName(doc_name);
            
          }}
          
          >
       
        
          {Docs.map( item =>{


       
        

            return <MenuItem value={item.doc_name}>{item.doc_name} - {item.doc_tag}</MenuItem>
            
            })}
          
          

          {/*  <MenuItem value={10}>Ten</MenuItem> */}
          
          
          </Select>

          <Button id="btn_load"
          onClick={()=>{

              console.log('Loading Document');
              console.log(authstate);
              console.log(token);
              

              console.log("Company:" + Company + " doc_name: " + DocName);

              //let full_url = server_url + '/doc_trainer.html?t=' + token;


              let full_src = server_url + '/doc_trainer.html?t=' + MyToken + '&company=' + Company + "&load_file=" + DocName;
              let my_iframe = document.getElementById("iframe"); 
              my_iframe.src = full_src;

          }}
          
          >
            Load Document
          </Button>


          <Button id="btn_delete"
          onClick={async ()=>{

             
              console.log(authstate);
              console.log(token);
              

              console.log("Company:" + Company + " doc_name: " + DocName);

              //let full_url = server_url + '/doc_trainer.html?t=' + token;
              console.log('Removing DocName');

              ///delete_docname
              let result = await delete_docname(DocName);
              if(result){
                setRunOnce(true);
              }



              

          }}
          
          >
            Delete Document
          </Button>
        
        </div>


        <iframe id="iframe" src={full_url} width="80%" height="1040px" />
    
    {/*
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem nodeId="1" label="Applications">
        <TreeItem nodeId="2" label="Calendar" />
        <TreeItem nodeId="3" label="Chrome" />
        <TreeItem nodeId="4" label="Webstorm" />
      </TreeItem>

      <TreeItem nodeId="5" label="Documents">
        <TreeItem nodeId="10" label="OSS" />
        <TreeItem nodeId="6" label="Material-UI">
          <TreeItem nodeId="7" label="src">
            <TreeItem nodeId="8" label="index.js" />
            <TreeItem nodeId="9" label="tree-view.js" />
          </TreeItem>
        </TreeItem>
        
      </TreeItem>
    </TreeView>
    */}
    </div>
  );
}