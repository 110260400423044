import React from "react";
import {Link} from 'react-router-dom';
import company from "../images/company.png";

const CompanyDetail = (props) => {
    console.log(props);
    //const {_id, UserName, email, CompanyName, user_role, password, status} =props.location.state.user;
    const { CompanyName, CompanyAdmin, CompanyDatabase, CompanyStatus, api_key, CompanyMailBoxes} =props.location.state.companies;
  return (
   <div className="main">
    <div className="ui card centered">
        <div className="image">
            <img src={company} alt="company"/>
        </div>
        <div className="content">
            <div className="header">{CompanyName}</div>
            {/*<div className="description">{email}</div>*/}
            <div className="description">{CompanyAdmin}</div>
            <div className="description">{CompanyDatabase}</div>
            <div className="description">{CompanyStatus}</div>
            {CompanyMailBoxes.map((mail)=>{
                return(
                <div className="mailBox">
                <p>Email: {mail.email_address}<br/>
                Status: {mail.mailbox_status}<br/>
                Mail Box Type: {mail.mailbox_type}<br/>
                Mail Server: {mail.mailserver}<br/>
                Username: {mail.username}</p>
                </div>
                )
            })}
        
        
           
           
            
        </div>
    </div>
        <div className="center-div">
        <Link to="/superadmin">
            <button className="center MuiButton-root detailBtn">Back to Company List</button>
        </Link>
        

        </div>

   </div>
  );
};

export default CompanyDetail;