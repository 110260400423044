import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import "./UserManagement.css";
//import Header from "./Header";
//import AddUser from "./AddUser";
import UserList from "./UserList";
//import EditUser from './EditUser';
import {CreateUserS, UpdateProfile} from '../templates/SuperAdmin';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';


function UserManagement(props) {

  
  const LOCAL_STORAGE_KEY = "users";
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults]= useState([]);
  

  const retrieveUsers = async () => {
 
  }

  const AddUserHandler = (user) => {
    console.log(user);
    setUsers([...users, { id: uuidv4(), ...user }]);
  };

  
  const UpdateUserHandler = async (user) => {
    const request = {...user}
    const response= () => {

    }
}

  const RemoveUserHandler = (id) => {
    const newUserList = users.filter((user) => {
      return user.id !== id;
    });

    setUsers(newUserList);
  };


  useEffect(() => {
       const getAllUsers = async () => {
      const allUsers = await retrieveUsers();
      if (allUsers) setUsers (allUsers);
    };
    getAllUsers();
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(users));
  }, [users]);


 
  return (
    <div id="rowDiv" className="ui container">
     
      
      
      <UserList {...props} 
              users={searchTerm.length <1 ? users : searchResults} 
              term= {searchTerm} 
             users={users} getUserId={RemoveUserHandler}  UpdateUserHandler={UpdateUserHandler} AddUserHandler={AddUserHandler} />
      
     
    </div>
  );
}


export default UserManagement;