import React, { useEffect,useState } from 'react';
import Dropdown from 'react-dropdown';
import {CreateUserS, UpdateProfile} from '../templates/SuperAdmin';
import {BrowserRouter as Router, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { sha256 } from 'js-sha256';



//const server_url = 'https://automate.isnet.co.za';
let server_url = process.env.REACT_APP_SERVER_URL;

function EditUser (props) {
  console.log(props);
 //const {   CompanyName, UserRole, password, UserStatus} =props.location.state.users;
  //console.log(UserName);
  
 const [UserName, setUserName]= useState(props.location.state.users.UserName);
 const [CompanyName, setCompanyName]= useState(props.location.state.users.CompanyName);
 const [UserRole, setUserRole]= useState(props.location.state.users.UserRole);
 const [password, setpassword]= useState(props.location.state.users.password);
 const [UserStatus, setUserStatus]= useState(props.location.state.users.UserStatus);
 const [UserEmail, setUserEmail]= useState();

 const [Result, setResult] = useState();
 
  
    return(
      <div className="ui main">
        <h2>Update User</h2>

        <form className="ui form">
      <div className="field" >
       
            <label>Company Name</label>
                 <input id="CompanyName"  
                 value={CompanyName} 
                 variant="outlined" 
                 onChange={(e)=>{
                 // console.log('change')
                  setCompanyName(e.target.value)
                  }} />

              
                   
            </div>

         {/* <TextField id="company_name" label="Company Name" variant="outlined" value={company_name}
                onChange={(e)=>{
                  //console.log('change')
                  setcompany_name(e.target.value)
                  }} 
            /> */}
            <div className="field">
            <label>Name</label>
             <input id="username"  variant="outlined" 
             value={UserName}           
             onChange={(e)=>{   
              console.log('change')         
              setUserName(e.target.value)
              }}
             
             /> 
            </div>
       
          
            <div className="field">
         
          <label>Password</label>
             <input id="password" variant="outlined" value={password}
           
             onChange={(e)=>{
             // console.log('change')
                setpassword(e.target.value)
                }}
             /> 
             </div>
             <div className="field" >
             <label>User Role</label>
             
                <Select
                   style={{width: '50%', height: 35}}
                  
                   value={UserRole} 
                   label="User Role"
                   onChange={(e)=>{
                       console.log('change')
                       setUserRole(e.target.value)
                   }
                   
                   }
               >
                   <MenuItem value={"guest"}>Guest</MenuItem>
                   <MenuItem value={"admin"}>Admin</MenuItem>
                   <MenuItem value={"superadmin"}>Super Admin</MenuItem>
               </Select>

            
          </div>
        
          <div className="field" >
       
            {/* <TextField id="CompanyStatus" label="Company Status" variant="outlined" /> */}  
            <label>User Email</label>
            <input id="user_email"  variant="outlined" 
            value={UserEmail}
              onChange={(e)=>{
                console.log('change')
                setUserEmail(e.target.value)
                }}
             /> 
           </div>

           <div className="field">
             <label>Status</label>

             <Select
                   style={{width: '50%', height: 35}}
                  
                   value={UserStatus}
                   label="Status"
                   onChange={(e)=>{
                       console.log('change')
                       setUserStatus(e.target.value)
                   }
                   
                   }
               >
                   <MenuItem value={"Active"}>Active</MenuItem>
                   <MenuItem value={"Disabled"}>Disabled</MenuItem>
               </Select>


                
            
            </div>
                   
        
                
        
   
      
      <div>

     
      <Button variant="contained"
                //color="primary"
                className="buttons"
                onClick={()=>{



                   
                  let document = {
                    "CompanyName": CompanyName,
                    "UserName": UserName,
                    "UserRole": UserRole,
                   "UserEmail": UserEmail,
                    "UserStatus": UserStatus
                  }

                  if(password){

                    let hashed_password = sha256(password);
                    
                    document = {
                      "CompanyName": CompanyName,
                      "UserName": UserName,
                      "UserPassword": hashed_password,
                      "UserRole": UserRole,
                    "UserEmail": UserEmail,
                      "UserStatus": UserStatus,
                    }
                
                }



                  console.log('Setup New User');
                  console.log(document);


                  try{



                    const token = localStorage.getItem('AccessToken'); 

                    let headers =  { 
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token
                     };
        
                   
              
                    fetch(server_url + "/update_user",{
                      method: 'POST',
                      headers: headers,
                      body: JSON.stringify(document)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxxx user status');
                                console.log(data);
                                console.log('xxx2 user status');
                                //resolve(data);
                                //history.push('/superadmin'); 
                                setResult(data.status);
                        });
                 }
                catch(err){
                        console.log(err);
                       // reject(err);
                }

                }}>Update User</Button> 
      </div>
      </form>
      <div>{Result}</div>
      <div className="center-div">
        <Link to="/superadmin">
            <button  className="center MuiButton-root detailBtn buttons">Back to Users List</button>
        </Link>
        

        </div>

      </div>
    );
  }



export default EditUser;