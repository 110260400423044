


import { ControlPointSharp } from '@material-ui/icons';
import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl } from '@material-ui/core';

import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import Box from '@mui/material/Box';

import currency from 'currency.js';

import { DataGrid } from '@material-ui/data-grid';

import BasicDateRangePicker from './basicdaterangepicker';


import MyCards from './mycards';
//import { Toast } from 'react-toastify/dist/components';
//import { toast } from 'react-toastify';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment, { ISO_8601 } from 'moment';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      justifycontent: "center",
      alignitems: "center",
    },
    container: {
        width: '80%',
        justifycontent: "center",
        alignitems: "center",
      },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      color: '#2E8A7C',
      fontSize:'22',
      fontWeight:"bold",
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));




let server_url = 'https://automate.isnet.co.za';
//let server_url = 'http://127.0.0.1:3066';




//For single field data
export function MyTableHeader(props){


  //Loop through single elements
  let singleFieldData = props.jsonData;
  const[FieldData,setFieldData] = useState([props.jsonData]);
  let keys = Object.keys(props.jsonData);
  const[Fields,setFields] = useState(keys);

  const [TestField,setTestField] = useState();

  useEffect(() => {

    console.log('Field Data');
    let fieldData = props.jsonData;
    let keys = Object.keys(props.jsonData);

    //keys.push('DocName');
    console.log(props.jsonData);
    //console.log(keys);
    setFields(keys);
    setFieldData(fieldData);
     // let jdata = props.jsonData;
     console.log(FieldData);

      
      

  },[props])




  return(

  <div>

      
          

  {Fields.map((item,i) => (
 
          <div id="divInp"  m={2} p={2} padding={3}>




         <TextField m={2} p={2} id={item} label={item} variant="outlined" 
         id={'field_id_' + item}
         //value={FieldData[item]}
         value={FieldData[item]}
       
         onChange={(e)=>{

              console.log('testing:');
              console.log(e.target.value);
              //FieldData[item] = e.target.value;

              console.log(FieldData);
              let newData = FieldData;
              newData[item] = e.target.value;
              //setFieldData({...FieldData, newData});
              setFieldData({...FieldData});
              FieldData[item] = e.target.value;
              console.log(newData);
              console.log(FieldData);
              setTestField(e.target.value);
              
              //Send Header Data back to parent
              props.parentCallback({headerData: FieldData});
          

         }}
          ></TextField>
       
          </div>
     ))
   
  }
  </div>

  );
}



export function MyDataGrid(props) {


    const [RowHeader, setRowHeader] = useState([]);
    const [RowData, setRowData] = useState([]);
    const [RowKeys, setRowKeys] = useState([]);
    const [TableName, setTableName] = useState();
    const [ColumnData, setColumnData] = useState([])
  
    const [deletedRows, setDeletedRows] = useState([]);
  
    const [HeaderData, setHeaderData] = useState([]);
    const [SingleFieldData, setSingleFieldData] = useState([]);
  
    const [SingleRowData, setSingleRowData] = useState([]);
    const [SingleColumnData, setSingleColumnData] = useState([]);
  
    const classes = useStyles();
  
  

    
  
    useEffect(() => {


    console.log("SSXX3:" + JSON.stringify(props));
  

      let jdata = props.jsonData;
      setTableName(props.TableName);
      setSingleFieldData(props.SingleFieldData); 
  
      
  
      //Setup Single Line Data
      console.log('setting:' + JSON.stringify(SingleFieldData));
      console.log('setting: r ' + JSON.stringify(jdata));
  
  
  
      setSingleRowData(props.SingleFieldData);
      let mySingleObject = new Object; 
      let singleData = props.SingleFieldData;
  
      let y=0; const yMax = singleData.length -1; for(; y<=yMax; y++){
  
        singleData[y]['id'] = y; //Add id key to each field
  
  
        let myskeys = Object.keys(singleData[x]);
  
       
       
        //Add Headers
        /*
       myskeys.forEach((item) => {
       let singleheaderdata = {
          field: item,
          headerName: item,
          width: 150,
          editable: true,
        };
  
        mySingleObject[item] = singleheaderdata;

       
  
        //Add Rows
        //{ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        //console.log(jdata[x]);
        
  
  
  
  
      })
      */
  
      if(y == yMax){
        
        
     
  
         //add custom columns
         mySingleObject["ReconName"] = {
          field: "ReconName",
          headerName: "ReconName",
          width: 150,
          editable: true,
        };
  
        
          
  
  
        let singleitems = Object.keys(mySingleObject);
        let singlecolArray = new Array();
        let a=0; const aMax = singleitems.length - 1; for(; a <= aMax; a++){
            singlecolArray.push(mySingleObject[singleitems[a]]);
  
            if(a===aMax){
  
              console.log(singlecolArray);
              setSingleColumnData(singlecolArray);
            }
  
          }
        
         
  
  
  
        }
  
  
      }
  
  
  
  
  
  
  
  
  
  
      //set Row Data
  
      setRowData(jdata);
      setRowKeys(Object.keys(jdata));
  
      //Setup Headers
      let toplen = 0;
  
      let myObject = new Object;
      let rowArray = new Array;
      console.log("L: " + jdata.length);
      let x=0; const xMax = jdata.length -1; for(; x<=xMax; x++){
  
            jdata[x]['id'] = x; //Add id key to each field
  
  
            let mykeys = Object.keys(jdata[x]);
  
           
           
            //Add Headers
           mykeys.forEach((item) => {
           let headerdata = {
              field: item,
              headerName: item,
              width: 150,
              editable: true,
            };
  
            myObject[item] = headerdata;
  
            //Add Rows
            //{ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
            //console.log(jdata[x]);
            
  
  
  
  
          })
  
          if(x == xMax){
            
            
         
  
             //add custom columns
             myObject["note"] = {
              field: "Note",
              headerName: "Note",
              width: 150,
              editable: true,
            };
  
            myObject["ledgeramount"] = {
              field: "LedgerAmount",
              headerName: "LedgerAmount",
              width: 150,
              editable: true,
            };
              
  
  
            let items = Object.keys(myObject);
            let colArray = new Array();
            let z=0; const zMax = items.length - 1; for(; z <= zMax; z++){
                colArray.push(myObject[items[z]]);
  
                if(z===zMax){
  
                  console.log(colArray);
                  setColumnData(colArray);
                }
  
              }
            
             
  
  
  
            }
  
  
          }
  
           
      
  
  
  
    },[props]);
  
  
  
    const handleRemove = (id) => {
      const newRowData = RowData.filter((row => row.id !== id));
   
      setRowData(newRowData);
  
      console.log(RowData);
  
  
    };
  
  
    const handleCellEditCommit = (updateitem) => {
  
      console.log(updateitem);
  
      let id = updateitem.id;
  
      let newRowData = new Array();
  
      let i=0; const iMax = RowData.length -1; for(; i <= iMax; i++){
  
        let item = RowData[i];
        let cid = item.id;
        if(id == cid){
  
          console.log('Match');
          //console.log(row);
          console.log(item);
          let field = updateitem.field;
          let value = updateitem.value;
          item[field] = value;
          //console.log(updateitem);
          
          //update Row witn new Row
          newRowData.push(item);
  
        } 
        else{
  
          newRowData.push(item);
        }
  
        if(i === iMax){
          setRowData(newRowData);
        }
  
  }
  
  
  
  
  
    }
  
  
  
  
  if(RowData.length > 0){
  
    return (
  
   
  
  
  
      <div style={{ height: 400, width: '100%', display: 'block',  justifyContent:'center', alignItems:'center'}}>
  
  
  
  
        <div>
        
        </div>
  
  
        <div>
  
  
        </div>
  
        
        <div  style={{display: 'block',  justifyContent:'center', alignItems:'center'}}>


        <div style={{ height: "100%", width: '100%', display: 'flex',  justifyContent:'center', alignItems:'center'}}>

          {/*}
        <DataGrid
          rows={SingleRowData}
          columns={SingleColumnData}
          pageSize={50}
          rowsPerPageOptions={[50]}
          //checkboxSelection
          //checkboxSelection={false}
          onCellEditCommit={handleCellEditCommit}
          //disableSelectionOnClick
          //onSelectionModelChange={({selectionModel}) => {
          //  const rowIds = selectionModel.map(rowId => parseInt(String(rowId), 10));
          //  const rowsToDelete = RowData.filter(row => rowIds.includes(RowData.id));
          //  setDeletedRows(rowsToDelete);
          //
          onSelectionModelChange={(ids) => {
            console.log(ids);
            setDeletedRows(ids);
         
          }}

  
        />
        */}
        </div>
  
  
    
        </div>
     
        <br></br>
        <div style={{ height: "100%", width: '100%', display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <DataGrid
          rows={RowData}
          columns={ColumnData}
          pageSize={50}
          rowsPerPageOptions={[50]}
          //checkboxSelection
          //checkboxSelection={false}
          onCellEditCommit={handleCellEditCommit}
          //disableSelectionOnClick
          //onSelectionModelChange={({selectionModel}) => {
          //  const rowIds = selectionModel.map(rowId => parseInt(String(rowId), 10));
          //  const rowsToDelete = RowData.filter(row => rowIds.includes(RowData.id));
          //  setDeletedRows(rowsToDelete);
          //
          onSelectionModelChange={(ids) => {
            console.log(ids);
            setDeletedRows(ids);
         
          }}
  
        />
        </div>
        <Divider />
  
       
        
       
        
      </div>
  
    );
        }
        else{
          return(
            <div>
            {/*<div id="loading-spinner">
          <div class="spin-icon"></div>
      </div>*/}
      </div>
          )
        }
  }
  


async function get_document(doc_id){



    return new Promise(function (resolve,reject){
  
      try{

                      let clean_id = doc_id.replace(/\'/g, "");
                      console.log(clean_id)

                      fetch(server_url + "/get_repo_doc?docid=" + clean_id)
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);
                      
                      });
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
    }
  
  


async function search_payments(supplier_name, search_item){



  return new Promise(function (resolve,reject){

    try{

                    //let clean_id = doc_id.replace(/\'/g, "");
                    //console.log(clean_id)

                  
                    //let server_url = 'http://127.0.0.1:3002';

                    let url = "search_payments?search_item=" + search_item + "&supplier_name=" + supplier_name; 

                    fetch(server_url + "/" + url)
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })
  }


  async function search_invoices(supplier_name, search_item){



    return new Promise(function (resolve,reject){
  
      try{
  
                      //let clean_id = doc_id.replace(/\'/g, "");
                      //console.log(clean_id)
  
                    
                      //let server_url = 'http://127.0.0.1:3002';
                      let url = "search_invoices?search_item=" + search_item + "&supplier_name=" + supplier_name; 
  
                      fetch(server_url + "/" + url)
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);
                      
                      });
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
    }
    
  
  


function OpenDocumentStatement(props){

   
    let listItems = props.RepoData;

    console.log(listItems.length);
    console.log(listItems);

    
    

    if(listItems.length > 0){
    return(
        <div>
   
        <div>
       {
            listItems.map((item)=> {

                let doc_name = item.doc_name;
                let single_data = item.single_data;
                let row_data = item.row_data;
                let status = item.status;
                let created = item.created;
                let updated = item.updated;


              

                return(
                <div id="container">
                  <table>
                    <tr>
                     <td>
                       Document: {doc_name} 
                    </td>
                     <td>
                        Status: {status}
                    </td>
                    <td>
                        Created: {created}
                    </td>
                    <td>
                        Updated: {updated} 
                   </td>
                 </tr>
                 </table>
                 </div>
                
              
             )  
        })
        }

        </div>
        </div>
    )
    }
    else{
        return(null);
    }

}

function DataTableCallback(){
    console.log("here");
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }


export default function OpenArchiveDocument(props){


    const [RepoData,setRepoData] = useState({});
    const [RowData,setRowData] = useState({});
    const [RowKeys, setRowKeys] = useState();
    const [SingleFieldData, setSingleFieldData] = useState({});

    const [CreatedDate, setCreatedDate] = useState();
    const [UpdatedDate, setUpdatedDate] = useState();

    const [RunOnce,setRunOnce] = useState(true);

    const history = useHistory();


    const [HeaderData, setHeaderData] = useState([]);

    const [StartDate, setStartdate] = useState();
    const [EndDate, setEndDate] = useState();

    const [SupplierName, setSupplierName] = useState();


    const [LedgerData, setLedgerData] = useState({});
    const [docStatus, setdocStatus] = useState({});

    //const [docstatus, setdocstatus] = useState();


    async function HeaderCallback(data){


      console.log(data);
      setHeaderData(data);
    }

    async function DateCallback(data){

      console.log('DateCallback:');
      console.log(data);

      
      setStartdate(data["DateData"][0]);
      setEndDate(data["DateData"][1]);
      //setHeaderData(data);
    }
    
    
  
    

    useEffect(()=>{



            let doc_id =  query.get('docid');
     
        

            //Get All Documents from database

            if(RunOnce){
            async function get_doc(){

                let doc_data = await get_document(doc_id);
                //console.log('DD: ' + JSON.stringify(doc_data));
                setRepoData(doc_data);
                console.log(RepoData.length)
                console.log(doc_data);
                console.log('hhhh');
                let singleData = JSON.parse(doc_data[0].single_data);
                let rowData = JSON.parse(doc_data[0].row_data);
                console.log("SSXX2: " + JSON.stringify(rowData));
                console.log("SSXX2: " + JSON.stringify(singleData));


                setRowData(rowData);
                console.log(JSON.stringify(rowData));
                if(RowData){
                setRowKeys(Object.keys(rowData[0]));
                }
                console.log("RRRR: " + JSON.stringify(rowData[0]));
                console.log("KEYS: " + RowKeys);

                setSingleFieldData(singleData);
                let cDate = moment.unix(doc_data[0].created).format('YYYY-MM-DD HH:mm:ss');
                setCreatedDate(cDate);

                let uDate = moment.unix(doc_data[0].updated).format('YYYY-MM-DD HH:mm:ss');
                setUpdatedDate(uDate);
                setRunOnce(false);


            }
            get_doc();

          }
          
            
            



    })

    
  

    let query = useQuery();

            let doc_name = query.get("docname");
            let doc_id = query.get("docid");
            let doc_sender = query.get("docsender");
            let doc_tag = query.get("doctag");
            let doc_status = query.get("docstatus");
           
            

            const classes = useStyles();
            const [expanded, setExpanded] = React.useState(false);

            const [FieldtoMatch,setFieldtoMatch] = useState(); 
            const [AmountToMatch, setAmountToMatch] = useState();

            const handleChange = (panel) => (event, isExpanded) => {
                setExpanded(isExpanded ? panel : false);
            };

            var myStatus= {doc_status};
            console.log(myStatus);
                 

            return(


    <div className={classes.root}>



                    <div>
                    <ToastContainer />
                    
                    <div id="bodyBoxHome">
                  <div id="spaceBox"><h3>Archived Document: {doc_name} </h3></div>

                    <Grid container spacing={2} justify="center">
                   {/* <OpenDocumentStatement RepoData={RepoData} ></OpenDocumentStatement> */}
                    </Grid>


<Grid container spacing={2} justify="center">

    <div>
    

          <div className="docDetailBox glass">
            <div className="inlineBoxes">
              <Typography className={classes.heading}>Document Name</Typography>
              <Typography className={classes.secondaryHeading}>{doc_name}</Typography>
            </div>
            <div className="inlineBoxes">
              <Typography className={classes.heading}>Document Tag</Typography>
              <Typography className={classes.secondaryHeading}>{doc_tag}</Typography>
            </div>
            <div className="inlineBoxes">
              <Typography className={classes.heading}>Document Sender</Typography>
              <Typography className={classes.secondaryHeading}>{doc_sender}</Typography>
            </div>
            <div className="inlineBoxes">
              <Typography className={classes.heading}>Status</Typography>
              <Typography id="myStatus" className={classes.secondaryHeading}>{doc_status}</Typography>
            </div>
            <Divider/>
              <div>
            <div className="inlineBoxes">
          <Typography className={classes.heading}>Created</Typography>
          <Typography className={classes.secondaryHeading}>{CreatedDate}</Typography>
          </div>
          <div className="inlineBoxes">
          <Typography className={classes.heading}>Updated</Typography>
          <Typography className={classes.secondaryHeading}>{UpdatedDate}</Typography>
          </div>
          </div>

          <div>
          <Typography className={classes.secondaryHeading}>
          <div style={{ justifyContent:'center', alignItems:'center', width: "100%", textAlign: "center"}}>
          
          <div id="blockBox">
            <MyTableHeader jsonData={SingleFieldData} parentCallback = {HeaderCallback}></MyTableHeader>
          
          </div>

        </div>

        </Typography>
     
          </div>
          </div>
        
     
       <div className="docDetailBox glass">
       <div >
          <Typography className={classes.heading}>Statement Vs Ledger</Typography>
          <Typography className={classes.secondaryHeading}>
            View Data on Statement 
          </Typography>

          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: "100%", height:"100%", flexDirection:"column"}}>
            <div className="divWrapperL"spacing={2}>

            <div className="blocksL">
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Field to Reference</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={FieldtoMatch}
              label="Field to Reference"
              onChange={(e)=>{
                    console.log(e.target.value);
                    setFieldtoMatch(e.target.value);
                    //console.log(Fieldh);
              }
              }
            >

          
              
          {
          RowKeys &&
          RowKeys.map((item) => {

            return(

              <MenuItem value={item}>{item}</MenuItem>
              

            );

            })
          }
          

            </Select>
            </FormControl>
            </div>
            <div className="blocksL">
            <FormControl fullWidth>
            <InputLabel id="amount-select-label">Amount</InputLabel>
            <Select
              labelId="amount_select-label"
              id="amount-select"
              value={AmountToMatch}
              label="Amount Field"
              onChange={(e)=>{
                    console.log(e.target.value);
                    setAmountToMatch(e.target.value);
                    //console.log(Fieldh);
              }
              }
            >

          
              
          {
          RowKeys &&
          RowKeys.map((item) => {

            return(

              <MenuItem value={item}>{item}</MenuItem>
              

            );

            })
          }
          

            </Select>
            </FormControl>
           </div>
           <div id="btnLedger" className="blocksL">
           <Button
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                    toast('Comparing Ledger');


                    console.log('search ledger for each item: ');
                   //http://127.0.0.1:3002/search_payments?search_item=1115178747
                    console.log('ssss');
                    console.log(RowData);
                    if(RowData){
                      let x=0; const xMax = RowData.length - 1; for(; x<=xMax; x++){



                        let field_to_check = FieldtoMatch;
                        console.log('Checking: ' + field_to_check);





                        //split descs
                        //let desc_arr = RowData[x].desc.split(' ');
                        let desc_arr = RowData[x][field_to_check].split(' ');
                        //console.log(desc_arr[0]);
                        let i=0; const iMax = desc_arr.length - 1; for(; i <= iMax; i++){

                            if(desc_arr[i].length > 1){
                              console.log(desc_arr[i]);

                              console.log('Searching; ' + desc_arr[i]);
                              //let item = await search_payments('Westcon', desc_arr[i]);
                              let item = await search_invoices(doc_sender, desc_arr[i]);

                              console.log(`Searching: ${desc_arr[i]} Found: ${desc_arr[i]}`); 
                              
                              console.log("SUPPLIER: " + doc_sender);


                              console.log(item);
                              if(item[0]){

                                console.log(RowData[x]);
                                RowData[x]["Note"] = 'Authorized';


                                //Check Amount
                                console.log('AMT:' + JSON.stringify(item));
                                console.log('AMT: ' + item[0].total + " " + RowData[x]['amt']);
                                console.log('AMT: ' + currency(item[0].total) + " " + currency(RowData[x]['amt']));

                                let ledger_amt =  currency(item[0].total);
                               //let table_amt = currency(RowData[x]['amount']);
                               let table_amt = RowData[x]['amt'];

                                let ledger_status = item[0].status;
                                //if(item[0].amount === RowData[x]['amt']){

                                 // let clean_id = doc_id.replace(/\'/g, "");
                                let fixed_table_amt = table_amt.replace(/\s+/gi, ""); 


                                console.log("AMT: " + ledger_amt + " " + table_amt);

                                let lledger_amt = ledger_amt.toLocaleString();
                                let ltable_amt = currency(fixed_table_amt.toLocaleString());
                                console.log("MMM: " + lledger_amt + " " + currency(ltable_amt, { symbol: "R", separator: ".", decimal: "," }));
                              
                                if(lledger_amt.toString() == ltable_amt.toString()){

                                  console.log('AMT OK');
                                  RowData[x]["Note"] = ledger_status + ' - Amount Matches';
                                  RowData[x]["LedgerAmount"] = ledger_amt;
                        
                                }
                                else{
                                  RowData[x]["Note"] = ledger_status + ' - Issue Amount';
                                  RowData[x]["LedgerAmount"] = ledger_amt;
                                }

                              }
                              
                              if(item.amount){
                              console.log(item);
                            
                              }


                            }

                        }
                        
                      }
                    }


                  }}
                  className={classes.button}
                >
                  Check Ledger
                </Button>
                  </div>
                  </div>

                  <Typography style={{width:"100%"}}>
      
      <div className="container" style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: "100%", marginBottom: 100}}>
    <MyDataGrid TableName="SupplierStatement22" SingleFieldData={SingleFieldData} jsonData={RowData} ></MyDataGrid>
  </div>
    </Typography>
                  <div>
            </div>
            </div>

          
    </div>
    </div>
     
       

       
      
        
    {/*<div className="docDetailBox glass">
          <Typography className={classes.heading}>Ledger vs Statement</Typography>
          <Typography className={classes.secondaryHeading}>
                 View Data on Ledger not on Statement
          </Typography>
          <Typography>
                  Show all the invoices in the ERP here

          </Typography>
       

                   <div m={2} p={2} padding={3}>

                  <div className="divWrapperL">
                    <div className="blocks">
                    <TextField m={2} p={2} id="SupplierName" label="Suppiler" variant="outlined"
                    //value={FieldData[item]}
                    value={SupplierName}

                    onChange={(e)=>{

                       console.log('changed')
                       setSupplierName(e.target.value);

                    }}
                    ></TextField>
                    &nbsp;&nbsp;
                    </div>
                    <div id="dateInp" className="blocks">
                    
                    <BasicDateRangePicker parentCallback={DateCallback}></BasicDateRangePicker>
                  </div>  
                  &nbsp;&nbsp;
                  
            <div className="blocks">

                  <Button
                  id="retrieveBtn"
                  variant="contained"
                  //color="primary"
                  onClick={()=>{

                    toast('Retrieving Payments');

                    console.log(StartDate);
                    console.log(EndDate);
                    let sDate = moment(StartDate).format('YYYY-MM-DD');
                    let eDate = moment(EndDate).format('YYYY-MM-DD');
                    console.log(sDate + " " + eDate);

                    //fetch data

                    let payment_data = new Promise(function(resolve,reject){
                        //let server_url = 'http://127.0.0.1:3002';
                        fetch(`${server_url}/search_payments_dates?start_date=${sDate}&end_date=${eDate}&supplier_name=${SupplierName}`)
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
                                setLedgerData(data);
                        
                        });
                    });

                    console.log(payment_data);


                  }}
                  className={classes.button}
                >
                  
                  Retrieve Payments
                </Button>
                </div>
   </div>
   </div>
       
          <Typography>
                  Show all the invoices in the ERP here
                  
          </Typography>
        </div>*/}
        <div className="docDetailBox glass">
        <Typography style={{width:"100%"}}>
      
                <div className="container" style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: "100%"}}>
              <MyDataGrid TableName="Paymentss" SingleFieldData={SingleFieldData} jsonData={LedgerData} ></MyDataGrid>
            </div>
              </Typography>
       
      
          <Typography className={classes.heading}>Operations</Typography>
          <Typography className={classes.secondaryHeading}>
            Document Operations eg: Save, Change Status
          </Typography>
       
          <Typography style={{width:"100%"}}>
          <div className="container" style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: "100%"}}>
            <div spacing={2}>
          <Button
                  variant="contained"
                  color="primary"
                  onClick={()=>{

                    toast('Saving Document');

                  }}
                  className={classes.button}
                >
                  Save Work
                </Button>

                &nbsp;&nbsp;&nbsp; 

                <Button
                  variant="contained"
                  color="primary"
                  onClick={()=>{

                    toast('Deleting Document');
                    console.log('deleting docid;' + doc_id);
                    //delete_repo_doc'

                    try{
                          
                      let dataObject = {
                        'doc_id': doc_id
                      }


                      const token = localStorage.getItem('AccessToken'); 

                
                      fetch(server_url + "/delete_repo_doc",{
                        method: 'POST',
                        headers: { 
                              'Content-Type': 'application/json',
                              Authorization: 'Bearer ' + token
                        },
                        body: JSON.stringify(dataObject)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxxx doc delete status');
                                  console.log(data);
                                  console.log('xxx2 doc delete status');
                                  //resolve(data);
                                  
                          
                          });
                   }
                  catch(err){
                          console.log(err);
                         // reject(err);
                  }



                  }}
                  className={classes.button}
                >
                  Delete Document
                </Button>


                &nbsp;&nbsp;&nbsp; 


              </div>
            </div>
          </Typography>
       </div>
      </div>
      </Grid>


     
                    
                         
                    
                 
                    </div>
                </div>

            </div>
            )

    
}
