


import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';


import moment from 'moment';
import './documentlisting.css';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import Button from '@material-ui/core/Button';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


import jwt from 'jwt-decode'
import { PDFReader } from 'reactjs-pdf-reader';


import MyModal from './modal.js';
import { Tooltip } from '@mui/material';





//let server_url = 'https://automate.isnet.co.za';
//let server_url = 'http://127.0.0.1:3002';
let server_url = process.env.REACT_APP_SERVER_URL;

var date;




const open_file = (doc_link) => {

  console.log('opening file: ' + doc_link);

  return new Promise(function (resolve,reject){

    try{
                      const token = localStorage.getItem('AccessToken'); 
                      let options = {
                      headers: {
                          'Authorization': 'Bearer ' + token,
                          Accept: 'application/pdf'
                      },
                      insecure: true,
                      rejectUnauthorized: false


                      }



                      const requestOptions = {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/pdf',
                        'Authorization': 'Bearer ' + token
                         }
                        };
                        
                        //fetch(server_url + "/get_pdf_file?file_name=" + doc_link , requestOptions)
                        fetch(doc_link, requestOptions)
                        .then((res) => {
                            return res.blob();
                        })
                        .then((blob) => {
                            const href = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', 'file.pdf'); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch((err) => {
                            return Promise.reject({ Error: 'Something Went Wrong', err });
                        })
                        

                    /*
                    fetch(server_url + "/get_pdf_file?file_name=" + doc_link , options)
                    .then(response => response.text())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);

                            //const file = new Blob(
                            //  [data], 
                            //  {type: 'application/pdf'})

                              //Build a URL from the file
                              //const fileURL = URL.createObjectURL(file);
                              //console.log(fileURL);

                            //Build a URL from the file
                            //window.open(fileURL);

                            console.log('xxx2');
                            //resolve(data.toString('base64'));

                            resolve(data);
                            //resolve(fileURL);
                            //resolve(file);

                           
                            
                    });
                    */


                    
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}




async function load_pdf(doc_link){


    return new Promise(function (resolve,reject){
  
        try{
                          const token = localStorage.getItem('AccessToken'); 



                          let options = {
                          headers: {
                              'Authorization': 'Bearer ' + token
                          },
                          insecure: true
                          //rejectUnauthorized: false
                          }


  
  
                        fetch(server_url + "/", options)
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
  
                               
                                
                        });
  
                        
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })



}

async function get_documents(){





    return new Promise(function (resolve,reject){
  
      try{
                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                        insecure: true,
                        rejectUnauthorized: false
                        }


                      fetch(server_url + "/processing_queue", options)
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);

                             
                              
                      });

                      
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
    }
  
  
    async function get_pdf_data(filename, company){
  
  
        return new Promise(function (resolve,reject){
      
          try{
      
      
            const token = localStorage.getItem('AccessToken'); 
      
          
          
      
      
                      
                      fetch(server_url + "/get_queue_pdf_file?company=" + company + "&filename=" + filename,{
                        method: 'GET',
                        headers: { 
                                    'Content-Type': 'application/pdf'
                        },
                        })
                        .then((res) => {
                           // return res.blob();
                           //resolve(res.blob());]
                           const file = new Blob([res.data], { type: 'application/pdf' });
                           const fileURL = URL.createObjectURL(file);
                           resolve( fileURL);
                           //resolve(res.data);

                        })
                    
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
      
      
      
      
      }
  
function DocumentList(props){

   
    let listItems = props.RepoData;

    console.log(listItems.length);
    console.log(listItems);
   
    

    if(listItems.length > 0){
    return(
        <div>
       {
            listItems.map((item)=> {

               

                return(
                    
                    <div>
                     <li key={item.id}>
                          {item.doc_name}
                    </li> 
                  </div>
                )

            }  )  
        }
        </div>
    )
    }
    else{
        return(null);
    }

}


async function delete_from_processing_queue(dataObject){

  
    return new Promise(function (resolve,reject){

      try{

                const token = localStorage.getItem('AccessToken'); 
                let options = {
                  headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  },
                  insecure: true,
                  rejectUnauthorized: false
                }

                let myheaders = {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                  
        
                  fetch(server_url + "/delete_from_processing_queue",{
                    method: 'POST',
                    //headers: { 'Content-Type': 'application/json' },
                    headers: myheaders,
                    body: JSON.stringify(dataObject)
                    })
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              //resolve(data);
                              window.location.reload();
                      
                      });
      }
      catch(err){
              console.log(err);
              reject(err);
      }

      })




  }



async function process_queue_file(filename){

    //https://automate.isnet.co.za/process_file2?filename=mustek&trained=true

  
        return new Promise(function (resolve,reject){
      
          try{
      
      
            const token = localStorage.getItem('AccessToken'); 
      
          

      
      
                      
                      fetch(server_url + "/process_file2?filename=" + filename + "&queue=true",{
                        method: 'GET',
                        headers: { 
                                    'Content-Type': 'application/json',
                                    Authorization: 'Bearer ' + token
                        }
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
      
      
      
      
      
      


}


async function get_invoice_data(filename){

  //https://automate.isnet.co.za/process_file2?filename=mustek&trained=true


      return new Promise(function (resolve,reject){
    
        try{
    
    
          const token = localStorage.getItem('AccessToken'); 
    
        

    
    
                    
                    fetch(server_url + "/get_invoice_data?filename=" + filename + "&queue=true",{
                      method: 'GET',
                      headers: { 
                                  'Content-Type': 'application/json',
                                  Authorization: 'Bearer ' + token
                      }
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    
    


}



function DocumentList2(props){

   
    let listItems = props.RepoData;

    console.log(listItems.length);
    console.log(listItems);
  
   
    const [Company,setCompany] = useState();
    const [PDFData,setPDFData] = useState();
    const [ModalShow,setModalShow] = useState();
    


    const history = useHistory();


    useEffect(()=>{

    let token = localStorage.getItem('AccessToken');
    let decoded_token = jwt(token);
    //console.log('decoded:');
    //console.log(decoded_token);
    setCompany(decoded_token.company);

    },[]);

    

    

    if(listItems.length > 0){
    return(
        
        <div>


        {/*
        <MyModal show={ModalShow} pdf_url={PDFData}>
          <p>Modal</p>
        </MyModal>
        */}

      
        <table id="myTable">
        
        <thead>
                            <tr>
                            <th>uuid</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Subject</th>
                            <th>Date</th>
                            <th width='20px'>FileName</th>
                            {/*<th>Detect</th>*/}
                            <th>Process</th>
                            <th>Preview</th>
                            <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                       

        
       {
            listItems.map((item, index)=> {
               

                let item_url = '';

         
           
                
                date = moment.unix(item.updated).format('YYYY-MM-DD HH:mm:ss');
                //var sender= item.doc_sender;
               // var docStatus= item.status;

               item_url = "/openfile?company=" + Company + "&filename=" + item.filename + "&from='" + item.from+ "'&to='" + item.to + "&subject='" + item.subject + "'&date=" +item.date + "&doclink=" + item.filename;
               let myurl = server_url + "/get_queue_pdf_file?filename=" + item.filename + "&company=" + Company;
                
                return(
                   
                            <tr key={index}>
                           {/* <td><Link to={item_url}>{item.doc_name}</Link></td> */}
                            <td>{item.uuid}</td>
                            <td>{item.from}</td>
                            <td>{item.to}</td>
                            <td>{item.subject}</td>
                            <td>{item.date}</td>
                            <td width="20px">

                            <CloudDownloadIcon  onClick={()=>{
                                      console.log('Downloading PDF');
                                      open_file(myurl)}
                                      // history.push(myurl);}
                                      } 
                                      className='iconButton'
                                      />
                            </td>
                            {/*<td width="20px">
                              <DocumentScannerIcon

                               className='iconButton'
                               onClick={async ()=>{
                                       //https://automate.isnet.co.za/process_file2?filename=' + item.filename + '&queue=true';

                                       console.log('Scanning Document');

                                       console.log(item.filename);
                                       let json_data = get_invoice_data(item.filename);

                                       ///get_invoice_data

                                      // history.push('/process_data?filename=' + item.filename);
                                                                               
                                       //let result = await process_queue_file(item.filename);
                                       //let url = "/process_data?filename=" + item.filename;
                                       

                                      // console.log(result);

                               }}
                               >


                              </DocumentScannerIcon>
                            </td>*/}
                            <td>

                            <RotateRightIcon 

                                className='iconButton'
                                onClick={async ()=>{
                                        //https://automate.isnet.co.za/process_file2?filename=' + item.filename + '&queue=true';

                                        console.log('Processing');

                                        // const history = useHistory();
                                        //const handleOnClick = useCallback(() => history.push('/sample'), [history]);

                                        history.push('/process_data?filename=' + item.filename + '&uuid=' + item.uuid);
                                                                                
                                        //let result = await process_queue_file(item.filename);
                                        //let url = "/process_data?filename=" + item.filename;
                                        

                                       // console.log(result);

                                }}

                            />

                           
                               

                            </td>

                            <td>
                               


                            <MyModal show={ModalShow} pdf_url={myurl}>
                                        <p>Preview</p>
                                        </MyModal>
                               
                                
                               </td>
                            <td> 

                                <DeleteOutlineOutlinedIcon className='trashBtn' onClick={async ()=>{

                                console.log("Deleting: " + item.filename  + " " + Company );


                                ///delete_from_processing_queue
                                let doc = {
                                    filename: item.filename,
                                    date: item.date
                                }
                                delete_from_processing_queue(doc);
                            

                                }}/>
                               {/*<Button onClick={async ()=>{

                                console.log("Deleting: " + item.filename  + " " + Company );


                                ///delete_from_processing_queue
                                let doc = {
                                    filename: item.filename,
                                    date: item.date
                                }
                                delete_from_processing_queue(doc);

                                }}>
                                Delete
                                </Button>*/}
                            </td>
                           
                            </tr>
                
                     
                )

            }  )  
        }
        </tbody>
        </table>


       

        <div >

       


   
        {PDFData &&
         <PDFReader  url={PDFData}></PDFReader> 
            
        }
        
        </div>

        </div>
      
    )
    }
    else{
        return(null);
    }

}




export default function ProcessingQueue(){


    const [RepoData,setRepoData] = useState({});
    const history = useHistory();

    useEffect(()=>{


            //Get All Documents from database
            async function get_docs(){

              try{
                let doc_data = await get_documents();
                console.log('DD: ' + JSON.stringify(doc_data[0].New));
                setRepoData(doc_data[0].New.reverse());
                console.log(RepoData.length)
              }
              catch(err){
                console.log(err);
              }
            }
            get_docs();
            
            



    },[])


    function handleSearch() {
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[0];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }       
        }
      }


            return(

                <div id="bodyBox">
                    <div><h3><ListItemIcon><DescriptionIcon/></ListItemIcon> Processing Queue</h3></div><br/>
                    <div>
                    <div>
                        <input
                            id="myInput"
                                type="text"
                                placeholder="Search sender"
                               
                                onChange={handleSearch}
                            />
                            
                    </div>
                    <Button id="uploadButton"
                              onClick={()=>history.push('/uploadnew')}
                            >
                              Upload Trained Document
                            </Button>
                    
                         <DocumentList2 RepoData={RepoData} ></DocumentList2>
                       {/*  <pre>{JSON.stringify(RepoData)}</pre> */}
                    {/*
                    {RepoData.map((item) => {
                    
                    return(
                        <li>{item.doc_name}</li>
                    )
                    })
                    }
                    */}
                    </div>
                </div>
            )

    
}
