import React from "react";
import Dropdown from 'react-dropdown';
import Button from '@material-ui/core/Button';
import {CreateUserS} from '../templates/SuperAdmin';

//const server_url = 'https://automate.isnet.co.za';
let server_url = process.env.REACT_APP_SERVER_URL;

function AddUser(props) {

    return (
      <div className="ui main">
     
        <CreateUserS></CreateUserS>
 
      </div>
    );
  }


export default AddUser;