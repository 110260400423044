import React from "react";
import Dropdown from 'react-dropdown';
import Button from '@material-ui/core/Button';
import {CreateMailbox} from '../templates/SuperAdmin';

//const server_url = 'https://automate.isnet.co.za';
let server_url = process.env.REACT_APP_SERVER_URL;

function AddMailbox(props) {

    return (
      <div className="ui main">


        <h2>Add New Mailbox</h2>
       <CreateMailbox></CreateMailbox>
       
      </div>
    );
  }


export default AddMailbox;