import React from 'react';

function API() {
  return (<div>


      <h2>API Screen</h2>
      <iframe id="iframe" src={'https://automate.isnet.co.za/isn-api-docs/'} width="80%" height="1040px" />
  </div>);
}

export default API;
