import React, {useCallback, useState, useMemo, useEffect} from 'react';
import { Redirect, Route } from "react-router-dom";
import jwt from 'jwt-decode';
import moment from 'moment';


//redux
import { useSelector, useDispatch } from 'react-redux';
import { loggedin,loggedout, AccessData } from '../redux/authSlice';



function checkTokenExpiry(authstate){

  //const history = useHistory();

    try{
        console.log("AUTHAuTH: " + JSON.stringify(authstate));
        //jwt.decodedData(authst);

        let token = authstate.AccessToken
        let decodedData = jwt(token);
        console.log("Authauth: " + JSON.stringify(decodedData));
        console.log("authstate: " + authstate);


        let timeNow = moment().unix();
        let expirytime = decodedData.exp;

        if(timeNow  > expirytime){

          console.log("authauth: token expired - please login again");
          //setAuthenticated(0);
          //history.push('/signin');

          return(1);

        }
        else{
          return(0);
        }
      }
      catch(err){

        console.log("TokenError:" + JSON.stringify(err));
        return(1);
      }
}


function ProtectedRoute({ component: Component, ...restOfProps }) {


  //const [IsAuthenticated, setIsAuthenticated] = useState();
  const authstate = useSelector((state) => state.authstate.value);
  const dispatch = useDispatch()

  let isAuthenticated = localStorage.getItem("isAuthenticated");
  console.log("AUTHAR: " + JSON.stringify(authstate));
  let tokenExpired = checkTokenExpiry(authstate);
  console.log("AUTHAR TokenExpired: " + tokenExpired);
  if(tokenExpired){
    dispatch(AccessData({AccessToken: '',  isAuthenticated: false}));
  }


 // setIsAuthenticated(authstate.AccessToken);


  /*
  useEffect(()=>{
  //let isAuthenticated = localStorage.getItem("isAuthenticated");
  console.log("this", IsAuthenticated);
  console.log('Check Auth: ' + IsAuthenticated);





  setIsAuthenticated(authstate.AccessToken);




  if(checkTokenExpiry(authstate)){


    console.log("token is expired --- redirect to signin");
    //isAuthenticated = 0;
    setIsAuthenticated();
  }
},[]);
*/


  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
}

export default ProtectedRoute;
