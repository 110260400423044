


import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';


import moment from 'moment';
import MyCards from './mycards';
import './documentlisting.css';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';

let server_url = 'https://automate.isnet.co.za';
//let server_url = 'http://127.0.0.1:3002';

var date;





async function load_pdf(doc_link){


    return new Promise(function (resolve,reject){
  
        try{
                          const token = localStorage.getItem('AccessToken'); 



                          let options = {
                          headers: {
                              'Authorization': 'Bearer ' + token
                          },
                          insecure: true
                          //rejectUnauthorized: false
                          }


  
  
                        fetch(server_url + "/", options)
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
  
                               
                                
                        });
  
                        
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })



}

async function get_documents(){



    return new Promise(function (resolve,reject){
  
      try{
                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                        insecure: true,
                        rejectUnauthorized: false
                        }


                      fetch(server_url + "/get_repo", options)
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);

                             
                              
                      });

                      
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
    }
  
  
  
  
function DocumentList(props){

   
    let listItems = props.RepoData;

    console.log(listItems.length);
    console.log(listItems);
   
    

    if(listItems.length > 0){
    return(
        <div>
       {
            listItems.map((item)=> {

               

                return(
                    
                    <div>
                     <li key={item.id}>
                          {item.doc_name}
                    </li> 
                  </div>
                )

            }  )  
        }
        </div>
    )
    }
    else{
        return(null);
    }

}




function DocumentList2(props){
console.log(props);
   
    let listItems = props.RepoData;

    console.log(listItems.length);
    console.log(listItems);
  
   
    
    

    

    if(listItems.length > 0){
    return(
        
        <div>
        <table id="myTable">
        <thead>
                            <tr>
                            
                            <th>Document name</th>
                            <th>Last modified</th>
                            <th>Sender</th>
                            <th>Tag</th>
                            <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                       

        
       {
            listItems.map((item)=> {
               

                let item_url = '';


                if(item.doc_tag === 'Statement'){ //Open Statement

                   item_url = "/opendoc?docid='" + item.id + "'&docname='" + item.doc_name + "'&doctag='" + item.doc_tag + "&docsender='" + item.doc_sender + "'&docstatus=" +item.status + "&doclink=" + item.doc_link;
                //state: { fromDashboard: true, id: 1 }
                
                }
                else if(item.doc_tag === 'Invoice'){ //Open Statement

                    //Adjust for displaying saved invoices


                    //http://127.0.0.1:3000/process_data?filename=invrsa00228062.pdf&uuid=90906852-3d4b-49dc-b3f3-209bc80fdd2b

                    //item_url = "/openinvoice2?docid='" + item.id + "'&docname='" + item.doc_name + "'&doctag='" + item.doc_tag + "&docsender='" + item.doc_sender + "'&docstatus=" +item.status + "&doclink=" + item.doc_link;
                      item_url = "/process_data?filename=" + item.doc_link + "&uuid=" + item.uuid + '&stored=true'; ; 
                }
                else{
                    item_url = "opencustom?docid='" + item.id + "'&docname='" + item.doc_name + "'&doctag='" + item.doc_tag + "&docsender='" + item.doc_sender + "'&docstatus=" +item.status + "&doclink=" + item.doc_link;
                }
                
                date = moment.unix(item.updated).format('YYYY-MM-DD HH:mm:ss');
                var sender= item.doc_sender;
                var docStatus= item.status;
              
                
                return(
                   
                            <tr key={item.id}>
                            <td><Link to={item_url}>{item.doc_name}</Link></td>
                            <td>{date}</td>
                            <td>{sender}</td>
                            <td>{item.doc_tag}</td>
                            <td>{docStatus}</td>
                            </tr>
                
                     
                )

            }  )  
        }
        </tbody>
        </table>
        </div>
      
    )
    }
    else{
        return(null);
    }

}




export default function DocumentListing(){


    const [RepoData,setRepoData] = useState({});

    useEffect(()=>{


            //Get All Documents from database
            async function get_docs(){
                let doc_data = await get_documents();
                //console.log('DD: ' + JSON.stringify(doc_data));
                setRepoData(doc_data.reverse());
                console.log(RepoData.length)
            }
            get_docs();
            
            



    },[])


    function handleSearch() {
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[0];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }       
        }
      }


            return(

                <div id="bodyBox">
                    <div><h3><ListItemIcon><DescriptionIcon/></ListItemIcon> Active Documents</h3></div><br/>
                    <div>Select documents you wish to work on below</div>
                    <div>
                    <div>
                        <input
                            id="myInput"
                                type="text"
                                placeholder="Search"
                               
                                onChange={handleSearch}
                            />
                    </div>
                    
                         <DocumentList2 RepoData={RepoData} ></DocumentList2>
                       {/*  <pre>{JSON.stringify(RepoData)}</pre> */}
                    {/*
                    {RepoData.map((item) => {
                    
                    return(
                        <li>{item.doc_name}</li>
                    )
                    })
                    }
                    */}
                    </div>
                </div>
            )

    
}
