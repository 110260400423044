import React, {useState, useEffect, useStyles} from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import { DataGrid } from '@material-ui/data-grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import moment from 'moment';


import { v4 as uuidv4 } from 'uuid';


let server_url = process.env.REACT_APP_SERVER_URL;



async function create_document_for_training(dataObject){

  
  return new Promise(function (resolve,reject){

    try{

              const token = localStorage.getItem('AccessToken'); 
              let options = {
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                },
                insecure: true,
                rejectUnauthorized: false
              }

              let myheaders = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
              };
                
      
                fetch(server_url + "/create_document_for_training",{
                  method: 'POST',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  body: JSON.stringify(dataObject)
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}

export function MyTableHeader(props){
        
        
  //Loop through single elements
  let singleFieldData = props.jsonData;


  const [DocTag,setDocTag] = useState(props.DocTag);

  const[FieldData,setFieldData] = useState([props.jsonData]);
  let keys = Object.keys(props.jsonData);
  const[Fields,setFields] = useState(keys);

  const [TestField,setTestField] = useState();
  const [RunOnce, setRunOnce] = useState(true);

  const [DocName,setDocName] = useState();
  //const [DocSender,setDocSender] = useState();
  //const [DocTag,setDocTag] = useState();

  const [UUID, setUUID] = useState();



  
  useEffect(() => {

    console.log('Field Data');
    let fieldData = props.jsonData;
    let keys = Object.keys(props.jsonData);


    let docTag = props.DocTag;
    setDocTag(docTag);

    //setDocName(props.document_name);
    //setDocTag(props.doc_tag);
    //setDocSender(props.doc_sender);
    setUUID(props.UUID);

    

    if(keys.indexOf('DocName') < 0 ){
        keys.push('DocName');
    }

    

    console.log(props.jsonData);
    //console.log(keys);
    setFields(keys);
    setFieldData(fieldData);
     // let jdata = props.jsonData;
     console.log(FieldData);

      //f(RunOnce){
      //  keys.push('DocName');
       // setRunOnce(false);
      //}

      
      
    //},[props])
  },[props])

  //},[props])





  return(

    

  <div >
  

  {Fields.map((item,i) => (

          
 
          <div m={2} p={2} padding={3} >



          <p style={{fontSize:10, paddingTop: 10, margin:0, textAlign:'left', color:'#2E8A7C',}}>{item}</p>
         <TextField style={{marginTop: 0, width:'100%'}} m={2} p={2} id={item}  variant="outlined" id={'field_id_' + item}
         
         //value={FieldData[item]}
         value={FieldData[item]}
         

        
       
         onChange={(e)=>{


          
              console.log('testing:');
              console.log(e.target.value);
              //FieldData[item] = e.target.value;

              console.log(FieldData);
              let newData = FieldData;
              newData[item] = e.target.value;
              //setFieldData({...FieldData, newData});
              setFieldData({...FieldData});
              FieldData[item] = e.target.value;
              console.log(newData);
              console.log(FieldData);
              setTestField(e.target.value);
              //Send Header Data back to parent
              console.log('iiii:' + JSON.stringify(FieldData));
              props.parentCallback({headerData: FieldData});

              
          

         }}
          ></TextField>
         
          </div>
     ))
   
  }
  </div>

  );
}


  
  

function UploadNew(props) {



    const notify = (mytext) => {
        toast(mytext);
      } 
  
  
        const [JsonData, setJsonData] = useState([]);
        const [SingleFieldData,setSingleFieldData] = useState([]);
        const [FileName, setFileName] = useState();

        const [DocName,setDocName] = useState();
        const [DocSender,setDocSender] = useState();
        const [DocTag,setDocTag] = useState();
        const [TrainActive,setTrainActive] = useState();
        const [TrainFileName,setTrainFilename] = useState();
        const [DocLink, setDocLink] = useState();
        const [mJsonData,setmJsonData] = useState([]);

        function MyDataGrid(props) {


            const [RowHeader, setRowHeader] = useState([]);
            const [RowData, setRowData] = useState([]);
            const [TableName, setTableName] = useState();
            const [ColumnData, setColumnData] = useState([])
          
            const [deletedRows, setDeletedRows] = useState([]);
          
            const [HeaderData, setHeaderData] = useState([]);
          
          
            const [SingleFieldData, setSingleFieldData] = useState([]);
          
            const [SingleRowData, setSingleRowData] = useState([]);
            const [SingleColumnData, setSingleColumnData] = useState([]);
          
            const [DocName,setDocName] = useState();
            const [DocSender,setDocSender] = useState();
            const [DocTag,setDocTag] = useState();
          
            //const classes = useStyles();



           
          
        
      
          
          
            async function HeaderCallback(data, DocName,DocTag,DocSender, UUID){
          
          
              console.log(data);
              setDocName(DocName);
              //setDocTag(DocTag);
              //setDocSender(DocSender);
              console.log("xx:" + DocSender);
              setHeaderData(data);
            }
            
          
            useEffect(() => {
            console.log(props)
          
              let jdata = props.jsonData;
          
          
          
              setTableName(props.TableName);
              setSingleFieldData(props.SingleFieldData); 
          
              setDocName(props.document_name);
              setDocSender(props.doc_sender);
              setDocTag(props.DocTag);
          
          
          
          
              //Setup Single Line Data
              console.log('setting:' + JSON.stringify(SingleFieldData));
          
          
          
              setSingleRowData(props.SingleFieldData);
              let mySingleObject = new Object; 
              let singleData = props.SingleFieldData;
          
              let y=0; const yMax = singleData.length -1; for(; y<=yMax; y++){
          
                singleData[y]['id'] = y; //Add id key to each field
          
          
                let myskeys = Object.keys(singleData[x]);
          
               
               
                //Add Headers
               myskeys.forEach((item) => {
               let singleheaderdata = {
                  field: item,
                  headerName: item,
                  //rowHeight:30,
                  width: 150,
                  editable: true,
                };
          
                mySingleObject[item] = singleheaderdata;
          
                //Add Rows
                //{ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
                //console.log(jdata[x]);
                
          
          
          
          
              })
          
              if(y == yMax){
                
                
             
          
                 //add custom columns
                 mySingleObject["ReconName"] = {
                  field: "ReconName",
                  headerName: "ReconName",
                  width: 150,
                  editable: true,
                };
          
                
                  
          
          
                let singleitems = Object.keys(mySingleObject);
                let singlecolArray = new Array();
                let a=0; const aMax = singleitems.length - 1; for(; a <= aMax; a++){
                    singlecolArray.push(mySingleObject[singleitems[a]]);
          
                    if(a===aMax){
          
                      console.log(singlecolArray);
                      setSingleColumnData(singlecolArray);
                    }
          
                  }
               
          
                }
          
          
              }
          
          
          
              //set Row Data
          
              setRowData(jdata);
          
              //Setup Headers
              let toplen = 0;
          
              let myObject = new Object;
              let rowArray = new Array;
              console.log("L: " + jdata.length);
              let x=0; const xMax = jdata.length -1; for(; x<=xMax; x++){
          
                    jdata[x]['id'] = x; //Add id key to each field
          
          
                    let mykeys = Object.keys(jdata[x]);
          
                   
                   
                    //Add Headers
                   mykeys.forEach((item) => {
                   let headerdata = {
                      field: item,
                      headerName: item,
                      width: 150,
                      editable: true,
                    };
          
                    myObject[item] = headerdata;
          
                    //Add Rows
                    //{ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
                    //console.log(jdata[x]);
                    
          
          
          
          
                  })
          
                  if(x == xMax){
                    
                    
                 
          
                     //add custom columns
                     myObject["note"] = {
                      field: "Note",
                      headerName: "Note",
                      width: 150,
                      editable: true,
                    };
          
                    
                      
          
          
                    let items = Object.keys(myObject);
                    let colArray = new Array();
                    let z=0; const zMax = items.length - 1; for(; z <= zMax; z++){
                        colArray.push(myObject[items[z]]);
          
                        if(z===zMax){
          
                          console.log(colArray);
                          setColumnData(colArray);
                        }
          
                      }
                    
                     
          
          
          
                    }
          
          
                  }
          
                   
              
          
          
          
            },[props]);
          
          
          
            const handleRemove = (id) => {
              const newRowData = RowData.filter((row => row.id !== id));
           
              setRowData(newRowData);
          
              console.log(RowData);
          
          
            };
          
          
            const handleCellEditCommit = (updateitem) => {
          
              console.log(updateitem);
          
              let id = updateitem.id;
          
              let newRowData = new Array();
          
              let i=0; const iMax = RowData.length -1; for(; i <= iMax; i++){
          
                let item = RowData[i];
                let cid = item.id;
                if(id == cid){
          
                  console.log('Match');
                  //console.log(row);
                  console.log(item);
                  let field = updateitem.field;
                  let value = updateitem.value;
                  item[field] = value;
                  //console.log(updateitem);
                  
                  //update Row witn new Row
                  newRowData.push(item);
          
                } 
                else{
          
                  newRowData.push(item);
                }
          
                if(i === iMax){
                  setRowData(newRowData);
                }
          
              }
          
            }
          
            return (
          
              <>
            <div style={{ height: 900, width: '100%', display: 'block',  alignItems:'center'}}  >
          
               
          
                <div>
                   <MyTableHeader jsonData={SingleFieldData} document_name={DocName} DocTag={DocTag} parentCallback = {HeaderCallback}></MyTableHeader>
                </div>
          
                <div>
          
            </div>
          
                
                
             
                <br></br>
            <div style={{ height: 600, width: '100%', display: 'flex',  justifyContent:'center', alignItems:'center', }}>
                <DataGrid
                  rows={RowData}
                  columns={ColumnData}
                  pageSize={50}
                  rowHeight={30}
                  
                  rowsPerPageOptions={[50]}
                  //checkboxSelection
                  //checkboxSelection={false}
                  onCellEditCommit={handleCellEditCommit}
                  //disableSelectionOnClick
                  //onSelectionModelChange={({selectionModel}) => {
                  //  const rowIds = selectionModel.map(rowId => parseInt(String(rowId), 10));
                  //  const rowsToDelete = RowData.filter(row => rowIds.includes(RowData.id));
                  //  setDeletedRows(rowsToDelete);
                  //
                  onSelectionModelChange={(ids) => {
                    console.log(ids);
                    setDeletedRows(ids);
                 
                  }}
          
                />
            </div>
          
                <Divider />
          </div>
              <div id="my_wrapper">
                <div id="looksgood">
                    <Button 
                      variant="contained" 
                     // className={classes.button}
                      //color="primary"
                      onClick={()=> {
          
                        var docName = document.getElementById('field_id_DocName').value.length;
                        
                        console.log(docName);
                        if(docName < 1 || docName == undefined){
                          alert('Please enter Document Name');
                          return
                        }else{
                          console.log('saving');
                            props.parentCallback({header_data: HeaderData, mydata: RowData});

                            alert("Recon Successfully completed!");
                            window.location.reload();
                            
                            
          
                        }
                            
          
                      }}
                      >
                    Looks Good - Lets Start a Recon
                  </Button>
                
                </div>
          
                <div id="deleteBtn" style={{display: 'block',  justifyContent:'center', alignItems:'center'}}>
                    <Button
                        variant="contained" 
                        //color="primary" 
                        //className={classes.button}
                        onClick={() => { 
                            
          
                            console.log("deleting:" + JSON.stringify(deletedRows));
                            //handleRowSelection();
          
          
          
                            deletedRows.forEach((item) => {
          
                              console.log('removing: ' + item);
                              handleRemove(item);
          
                          })
          
                          
                          }}>
                            Delete Row
                    </Button>
                 </div>
              </div>
              
           </>
            );
          }
          

        
        
        //function to get data from back upload component
        function callbackFunction(childData){
        
          //this.setState({message: childData})
          console.log('here');
          console.log(childData);
          console.log('here2');

          //Figure this out, got data from page1
          setJsonData(childData.mydata.pages[0].table_data); //table data
          setSingleFieldData(childData.mydata.pages[0].single_field_data); //single field data

          setFileName(childData.filename);
          setDocName(childData.mydata.doc_name);
          setDocTag(childData.mydata.doc_tag);
          setDocSender(childData.mydata.doc_sender);
          setDocLink(childData.mydata.doc_link);



          if(childData.mydata.doc_tag){

                console.log('Generating CSV FILE');
                console.log(childData);
                setmJsonData(childData);
          }
        
        }

    const getUploadParams = () => {
        return { url: server_url + '/upload-pdf' }
      }


      const handleChangeStatus = async ({ meta, remove }, status) => {


        let myWrapper = document.getElementById('filewrappers');
        if (status === 'headers_received') {
          toast(`${meta.name} uploaded!`)
    
          let filename = meta.name;
           //myWrapper.style.display = "block";
          console.log('process file: ' + filename );
          let mydata = await process_file(filename);
    
          //return firsi item in the array
          console.log('SSS: ' + JSON.stringify(mydata[0]));
          if(mydata.docname === 'notfound'){
            toast('Document not matched - please train one first');
            myWrapper.style.display = "none";
            setTrainActive("true");
            setTrainFilename(filename);
            console.log('setting filename: ' + filename);

          }
          else{
            //props.parentCallback({mydata: mydata, filename: filename});

            console.log('KKKK:' + JSON.stringify(mydata));

           // if(mydata.doc_tag === 'Statement'){
          if(mydata.doc_tag){
            if(mydata.doc_tag.toLowerCase() === 'statement'){
             myWrapper.style.display = "block";
            callbackFunction({mydata: mydata, filename: filename});

            setTrainActive();


            console.log(mydata);

            }
            //if(mydata.doc_tag === 'Invoice'){
           if(mydata.doc_tag.toLowerCase() === 'invoice'){
             myWrapper.style.display = "none";
             // callbackFunction({mydata: mydata, filename: filename});

             post_to_processing_queue(filename);

             console.log("post data to processing queue");

             //setSentToProcessing('true');

             setTrainActive();


             

             // Post invoice to processing queue

            
             console.log(mydata);
 
             }
            


             //Workflow Detected
             if(mydata.doc_tag.toLowerCase() == "workflow"){


              console.log('Workflow Process Initiated');
              //setProcessRouting("InvoiceProcess");

              console.log(mydata);

              myWrapper.style.display = "none";



              //setTrainFilename(filename);

              console.log("Workflow detected... Initiated");
              run_workflow(mydata.doc_name, mydata.doc_file_link);

        
        
            }

            }

             if(mydata.status === 'error no data'){

              //let myTrainWrapper = document.getElementById('TrainWrapper');

              //myTrainWrapper.style.display = "block";
              
              myWrapper.style.display = "none";

              setTrainFilename(filename);

              console.log("document not detected please train one first");
              setTrainActive("true");


             }

          
          }
     
    
          //setDocName(mydata.doccname);
          //setDistance(mydata.distance);
    
    
          remove()
        } else if (status === 'aborted') {
          toast(`${meta.name}, upload failed...`)
        }
      }


      async function process_file(filename){


        //let server_url = 'http://127.0.0.1:3002';
        return new Promise(function (resolve,reject){
      
          try{
      
      
                          const token = localStorage.getItem('AccessToken'); 
                          let options = {
                            headers: {
                              'Authorization': 'Bearer ' + token
                            },
                            insecure: true,
                            rejectUnauthorized: false
                          }
      
                          
      
                          fetch(server_url + "/process_file2?filename=" + filename, options)
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
      
      
      
      }


      async function post_to_processing_queue(filename){


        return new Promise(function(resolve,reject){

        try{

          const token = localStorage.getItem('AccessToken'); 
          let options = {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            },
            insecure: true,
            rejectUnauthorized: false
          }

          let myheaders = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          };
            
          let dataObject = {
            "filename": filename
          }

          console.log("Posting: " + filename);
          console.log(dataObject);
  
            fetch(server_url + "/create_document_for_processing",{
              method: 'POST',
              //headers: { 'Content-Type': 'application/json' },
              headers: myheaders,
              body: JSON.stringify(dataObject)
              })
                .then(response => response.json())
                .then(function(data){
                        console.log('xxx');
                        console.log(data);
                        console.log('xxx2');
                        //resolve(data);
                
                });
            }
            catch(err){
                    console.log(err);
                    //reject(err);
                    reject(err);
            }

          });
         


        
      }


      async function run_workflow(workflow_name, input_filename){


        return new Promise(function(resolve,reject){

        try{

          const token = localStorage.getItem('AccessToken'); 
          let options = {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            },
            insecure: true,
            rejectUnauthorized: false
          }

          let myheaders = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          };
            
          let dataObject = {
            "workflow_name": workflow_name,
            "input_filename": input_filename
          }

          console.log("Posting: " + input_filename);
          console.log(dataObject);
  
            fetch(server_url + "/run_workflow",{
              method: 'POST',
              //headers: { 'Content-Type': 'application/json' },
              headers: myheaders,
              body: JSON.stringify(dataObject)
              })
                .then(response => response.json())
                .then(function(data){
                        console.log('xxx');
                        console.log(data);
                        console.log('xxx2');
                        //resolve(data);
                
                });
            }
            catch(err){
                    console.log(err);
                    //reject(err);
                    reject(err);
            }

          });
         


        
      }






      async function create_data(dataObject){
  
  
        return new Promise(function (resolve,reject){

          try{

                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    let myheaders = {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    };
                      
            
                      fetch(server_url + "/create_data",{
                        method: 'POST',
                        //headers: { 'Content-Type': 'application/json' },
                        headers: myheaders,
                        body: JSON.stringify(dataObject)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  //resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })




      }

        async function DataTableCallback(childData){

          console.log('here dt callback');
          console.log(childData);
          let header_data = childData.header_data;
          console.log('HEADER');
          //console.log(header_data.headerData);



          let header = header_data.headerData;
          let docName = header.DocName;
          //let docName = DocName;
          let docTag = DocTag;
          let docSender = DocSender;
          let docLink = DocLink;


          let row_data = childData.mydata;

          let uuid = uuidv4();

          let mobj = {
            docname: docName,
            docSender: docSender,
            docTag: docTag,
            docLink: docLink,
            single_data: header,
            row_data: row_data,
            uuid: uuid
          }

          console.log(mobj);


          //Post to create recon
          let resp = await create_data(mobj);
          console.log(resp);

        }



        async function create_csv(dataObject){



          console.log(dataObject);

          return new Promise(function (resolve,reject){

            try{

                      const token = localStorage.getItem('AccessToken'); 
                      let options = {
                        headers: {
                          'Authorization': 'Bearer ' + token,
                          'Content-Type': 'application/json'
                        },
                        insecure: true,
                        rejectUnauthorized: false
                      }

                      let myheaders = {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      };
                        
              
                        fetch(server_url + "/generate_csv",{
                          method: 'POST',
                          //headers: { 'Content-Type': 'application/json' },
                          headers: myheaders,
                          body: JSON.stringify(dataObject)
                          })
                            .then(response => response.json())
                            .then(data=>{
                                     console.log('xxx');
                                    console.log(data);
                                    console.log('xxx2');
                                    resolve(data);
                            
                            });
            }
            catch(err){
                    console.log(err);
                    reject(err);
            }

            })




        }

        async function generateCSV(){


          console.log(mJsonData);
          let csv_data = await create_csv(mJsonData);
          console.log('here');
          console.log(csv_data.filename);


                          const token = localStorage.getItem('AccessToken'); 
                          let options = {
                          headers: {
                              'Authorization': 'Bearer ' + token,
                              Accept: 'application/pdf'
                          },
                          insecure: true,
                          rejectUnauthorized: false


                          }



                          const requestOptions = {
                            method: 'GET',
                            headers: { 'Content-Type': 'text/csv',
                            'Authorization': 'Bearer ' + token
                             }
                            };
                            
                            fetch(server_url + "/get_pdf_file?file_name=" + csv_data.filename , requestOptions)
                            .then((res) => {
                                return res.blob();
                            })
                            .then((blob) => {
                                const href = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.href = href;
                                link.setAttribute('download', csv_data.filename + '.csv'); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            })
                            .catch((err) => {
                                return Promise.reject({ Error: 'Something Went Wrong', err });
                            })

          



        }


        
function PdfViewer(props){





    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [FileName, setFileName] = useState('');
  
    useEffect(()=>{
  
  
            console.log(props);
            //let server_url = 'http://127.0.0.1:3002';
            setFileName(server_url + "/uploads/" +  props.FileName);
            console.log("FNAME: " + FileName);
            
  
    },[props])
  
    function onDocumentLoadSuccess({ numPages }) {
      const spinner = document.getElementById('loading-spinner');
      var pages = document.getElementById('pageNumberP');
      
      spinner.style.display='none';
      pages.style.display= 'block';

      //let myTrainWrapper = document.getElementById('TrainWrapper');
      //myTrainWrapper.style.display = "none";

      setNumPages(numPages);
    }
  
    function onDocumentLoadError(err){
      console.log(err);
    }
  
   
  
  
    return(
  
  
      <div>
              <Document
                file={FileName}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
  
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <p id="pageNumberP">Page {pageNumber} of {numPages}</p>
            </div>
  
  
  
    )
  
  
  
  }
  


      
  return (
    <>
    <div><h3>Upload Trained Document</h3></div>


    <div id="toast">Upload</div>
      <Dropzone
        classNames="glass"
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent="Drop A File"
        inputContentColor="#2E8A7C"
        id="drop"
        styles={{
          dropzone: { 
            backgroundColor: '#FFFFFF',
            width: 600, 
            height: 200, 
            border:"dotted", 
            borderWidth:2, 
            borderColor:"#2E8A7C", 
            overflow: "hidden", 
            marginTop:20, 
            marginBottom:20, },
          dropzoneActive: { borderColor: 'green' },
          inputLabel:{color:'#2E8A7C', textTransform:"uppercase", letterSpacing:2, fontWeight:300,}
        }}
      />


    {DocTag==='Invoice' &&
       <div id='csv_wrapper'>

           
            File Detected as: {DocTag}

           {/* <Button onClick={generateCSV}>Download CSV</Button> */}


        </div>

       
       }


    {DocTag==='CSV' &&
       <div id='csv_wrapper'>

            File Detected as: {DocTag}

            <Button onClick={generateCSV}>Download CSV</Button>


        </div>

       
       }

  {TrainActive === "true" &&
      <div id="TrainWrapper">
          ** Document not detected **
          <br></br>

          <Button 
                      variant="contained" 
                     // className={classes.button}
                      //color="primary"
                      onClick={()=> {


                        /*
          
                        var docName = document.getElementById('field_id_DocName').value.length;
                        
                        console.log(docName);
                        if(docName < 1 || docName == undefined){
                          alert('Please enter Document Name');
                          return
                        }else{
                          console.log('saving');
                            props.parentCallback({header_data: HeaderData, mydata: RowData});

                            alert("Recon Successfully completed!");
                            window.location.reload();
                            */
                           console.log("Sending document to training queue");
                           console.log("FileName:" + TrainFileName);

                           let doc = {
                            filename: TrainFileName,
                            date: moment().format("YYYY-MM-DD HH:mm:ss")
                            }

                            let myres = create_document_for_training(doc);
                            console.log(myres);
                            
                            
          
                        
                            
          
                      }}
                      >
                    Send to Training Queue
                  </Button>



      </div>

    }
      <div id="filewrappers">
        <div id="loading-spinner">
        <div class="spin-icon"></div>
      </div>
    
        <Splitter id="sppliter" direction={SplitDirection.Horizontal}>
      <div id="firstdoc" className="container"> 
      <div>

      DOCUMENT PREVIEW
        <PdfViewer FileName={FileName}></PdfViewer>
        </div>
  
      </div>



       

        <div id="seconddoc">
     
    
        <div id="infoTable" className="container" style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: "100%"}}>
          <MyDataGrid  TableName="SupplierStatement" document_name={DocName} doc_tag={DocTag} doc_sender={DocSender} SingleFieldData={SingleFieldData} jsonData={JsonData} parentCallback = {DataTableCallback}></MyDataGrid>
        </div>
  
        </div>
        
        </Splitter>
   
  
  
     
  
        </div>
    </>
  )
}

export default UploadNew