
import React, {useCallback, useState, useMemo, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';

import { sha256 } from 'js-sha256';


let server_url = process.env.REACT_APP_SERVER_URL;


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://isnet.co.za">
        https://isnet.co.za
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));





export default function SignUp(props) {



  const classes = useStyles();
  const history = useHistory();


  const [Name, setName] = useState();
  const [Company, setCompany] = useState();
  const [UserName, setUserName] = useState();
  const [UserPassword, setUserPassword ] = useState();
  const [UserPassword2, setUserPassword2 ] = useState();

  const [Mobile, setMobile ] = useState();

  const [UserHash, setUserHash] = useState();
  const [AccessToken,setAccessToken] = useState();
  const [Response, setResponse] = useState();

  const [Status, setStatus] = useState();



  //const history = useHistory();

  function redirect_loggedin(){

    
      history.push('/');
  
  }



  function post_signup(name, username, passhash, company, mobile){

    try{
  
      //let server_url = 'http://127.0.0.1:3002'
      //let server_url = 'https://automate.isnet.co.za'


      let doc = {
        "name": name,
        "username": username, 
        "password": passhash,
        "company": company,
        "mobile": mobile
      }


      fetch(server_url + '/signup', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(doc)
        }).then((response) =>  response.json()).then((json) => {
  
  
              
              console.log(json);
  
          if(json.status === 'failed'){
  
                  //setresp('Login Failed');
                  //setResponse('Login Failed');
                  //localStorage.setItem('AccessToken', '');
                  //props.parentCallback({AccessToken: '', isAuthenticated: false});
                  setStatus("Signup Error");
  
              }
              else{
  
                    //setResponse('Successful Login');
                    //setAccessToken(json.token);
                    //console.log(json.token);
                    //localStorage.setItem('AccessToken', json.token);
                    //localStorage.setItem('isAuthenticated', 'true');
                    setStatus("Successful Signup");

                    //props.parentCallback({AccessToken: json.token, isAuthenticated: true});

                    //redirect_loggedin();
                    history.push('/signin');

              }
            });
      }
      catch(err){

        console.log('Error posting');

      }
                    
  }

  



  return (
    <Container component="main" maxWidth="xs">
   
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>

        <Typography component="h1" variant="h5">
          {Response}
        </Typography>


        <TextField
            variant="outlined"
            margin="normal"
            value={Name}
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            onChange={(e)=>{
   
            
              //FieldData[item] = e.target.value;
               setName(e.target.value);
               
          

         }}
          />


<TextField
            variant="outlined"
            margin="normal"
            value={Company}
            required
            fullWidth
            id="company"
            label="Company"
            name="company"
            autoComplete="company"
            autoFocus
            onChange={(e)=>{
   
            
           
               setCompany(e.target.value);
               
          

         }}
          />
      
      
          <TextField
            variant="outlined"
            margin="normal"
            value={UserName}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e)=>{
   
            
              //FieldData[item] = e.target.value;
               setUserName(e.target.value);
               
          

         }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={UserPassword}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            
        
       
            onChange={(e)=>{
   
                 //console.log('testing:');
                 //console.log(e.target.value);
                 //FieldData[item] = e.target.value;
                  setUserPassword(e.target.value);
                  //console.log(e.target.value);
                 
             
   
            }}
          />


        <TextField
                    variant="outlined"
                    margin="normal"
                    value={UserPassword2}
                    required
                    fullWidth
                    name="password2"
                    label="Confirm Password"
                    type="password"
                    id="password2"
         
                
            
                    onChange={(e)=>{
        
                        //console.log('testing:');
                        //console.log(e.target.value);
                        //FieldData[item] = e.target.value;
                        setUserPassword2(e.target.value);
                        //console.log(e.target.value);
                        
                    
        
                    }}
                />


                <TextField
                    variant="outlined"
                    margin="normal"
                    value={Mobile}
                    required
                    fullWidth
                    name="mobile"
                    label="Mobile Number"
                    type="phone"
                    id="mobile"
         
                
            
                    onChange={(e)=>{
        
                        //console.log('testing:');
                        //console.log(e.target.value);
                        //FieldData[item] = e.target.value;
                        setMobile(e.target.value);
                        //console.log(e.target.value);
                        
                    
        
                    }}
                />



          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() =>{
              // history.push("/");

              let hashed_password = sha256(UserPassword);
              setUserHash(hashed_password);

              console.log('UserName:' + UserName + 'Hash: ' + hashed_password);

              setCompany(Company.replace(/ /g, ""));
              //console.log(Company.replace(/ /g, ""));
              //name, username, passhash, company, mobile
              post_signup(Name, UserName, hashed_password, Company.replace(/ /g, ""), Mobile);
              

            }}
          >
            Sign Up
          </Button>
          {Status}
          <Grid container>
            <Grid item xs>
             
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                
              </Link>
            </Grid>
          </Grid>
       
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    
    </Container>
  );
}
