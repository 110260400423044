import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { TextField } from '@material-ui/core';
import AutoCompleteInput from '../components/AutoCompleteInput';


import InvoiceTable from './invoicetable';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

import moment from 'moment';


const CheckStyle= {
  match: { 
    color: 'green'
  },
  nomatch:{
    color: 'red',
  }
};


let server_url = process.env.REACT_APP_SERVER_URL;



//Converts invoice strings to decimals
function clean_currency(mystr){
 if(mystr!== undefined){
  try{
    console.log("MMM:" + Number(mystr));
    let out_str = Number(mystr.replace(/[^0-9-\.]+/g,""));
    console.log("MMM2:" + out_str);
  
    return(out_str);
    }
    catch(err){
      console.log(err);
      return('0');
    }
 }
 
}

    //Post Recon Data to API
    async function create_data(dataObject){
  
  
      return new Promise(function (resolve,reject){
    
        try{
    
                  const token = localStorage.getItem('AccessToken'); 
                  let options = {
                    headers: {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    },
                    insecure: true,
                    rejectUnauthorized: false
                  }
    
                  let myheaders = {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  };
                    
          
                    fetch(server_url + "/create_data",{
                      method: 'POST',
                      //headers: { 'Content-Type': 'application/json' },
                      headers: myheaders,
                      body: JSON.stringify(dataObject)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                //resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    }


    async function update_acc_code_table(dataObject){
  
  
      return new Promise(function (resolve,reject){
    
        try{
    
                  const token = localStorage.getItem('AccessToken'); 
                  let options = {
                    headers: {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    },
                    insecure: true,
                    rejectUnauthorized: false
                  }
    
                  let myheaders = {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  };
                    
          
                    fetch(server_url + "/update_acc_code_table",{
                      method: 'POST',
                      //headers: { 'Content-Type': 'application/json' },
                      headers: myheaders,
                      body: JSON.stringify(dataObject)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                //resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    }


    async function update_uuid_data(dataObject){
  
  
      return new Promise(function (resolve,reject){
    
        try{
    
                  const token = localStorage.getItem('AccessToken'); 
                  let options = {
                    headers: {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    },
                    insecure: true,
                    rejectUnauthorized: false
                  }
    
                  let myheaders = {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  };
                    
          
                    fetch(server_url + "/update_uuid_data",{
                      method: 'POST',
                      //headers: { 'Content-Type': 'application/json' },
                      headers: myheaders,
                      body: JSON.stringify(dataObject)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                //resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    }

    ///delete_from_processing_queue_uuid
    async function delete_from_processing_queue_uuid(dataObject){
  
  
      return new Promise(function (resolve,reject){
    
        try{
    
                  const token = localStorage.getItem('AccessToken'); 
                  let options = {
                    headers: {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    },
                    insecure: true,
                    rejectUnauthorized: false
                  }
    
                  let myheaders = {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  };
                    
          
                    fetch(server_url + "/delete_from_processing_queue_uuid",{
                      method: 'POST',
                      //headers: { 'Content-Type': 'application/json' },
                      headers: myheaders,
                      body: JSON.stringify(dataObject)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                //resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    }


    async function upload_doc_repo_erpref(dataObject){
  
  
      return new Promise(function (resolve,reject){
    
        try{
    
                  const token = localStorage.getItem('AccessToken'); 
                  let options = {
                    headers: {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    },
                    insecure: true,
                    rejectUnauthorized: false
                  }
    
                  let myheaders = {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  };
                    
          
                    fetch(server_url + "/upload_doc_repo_erpref",{
                      method: 'POST',
                      //headers: { 'Content-Type': 'application/json' },
                      headers: myheaders,
                      body: JSON.stringify(dataObject)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                //resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    }



async function get_invoice_data(filename){

  //https://automate.isnet.co.za/process_file2?filename=mustek&trained=true


      return new Promise(function (resolve,reject){
    
        try{
    
    
          const token = localStorage.getItem('AccessToken'); 
    
        

    
    
                    
                    fetch(server_url + "/get_invoice_data?filename=" + filename + "&queue=true",{
                      method: 'GET',
                      headers: { 
                                  'Content-Type': 'application/json',
                                  Authorization: 'Bearer ' + token
                      }
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    
    


}


async function check_uuid_exists(uuid){

  //https://automate.isnet.co.za/process_file2?filename=mustek&trained=true


      return new Promise(function (resolve,reject){
    
        try{
    
    
          const token = localStorage.getItem('AccessToken'); 
    
        

    
    
                    
                    fetch(server_url + "/check_uuid_exists?uuid=" + uuid,{
                      method: 'GET',
                      headers: { 
                                  'Content-Type': 'application/json',
                                  Authorization: 'Bearer ' + token
                      }
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    
    


}



async function post_xero_upload_pdf_invoice(InvoiceID,FileName){
  
  
  return new Promise(function (resolve,reject){

    try{

            

              const token = localStorage.getItem('AccessToken'); 

              //console.log(dataObject);


              let myheaders = {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                  




              let dataObject = {
                InvoiceID: InvoiceID,
                FileName: FileName
              }


              
                
      
                fetch(server_url + "/post_xero_pdf_invoice",{
                  method: 'POST',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  body: JSON.stringify(dataObject)
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                         
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}




async function post_xero_invoice(dataObject){
  
  
  return new Promise(function (resolve,reject){

    try{

            

              const token = localStorage.getItem('AccessToken'); 

              console.log(dataObject);


              let myheaders = {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                  



              
                
      
                fetch(server_url + "/post_xero_invoice",{
                  method: 'POST',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  body: JSON.stringify(dataObject)
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                         
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}


async function get_xero_purchase_order(po_number){

    //get_xero_purchase_order

    return new Promise(function (resolve,reject){

        try{
    
    
                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                          headers: {
                            'Authorization': 'Bearer ' + token
                          },
                          insecure: true,
                          rejectUnauthorized: false
                        }
    
                        
    
                        fetch(server_url + "/get_xero_purchase_order?po_number=" + po_number, options)
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
}




async function SaveCallback(data){
  let mydate = moment().format('YYYY-MM-DD HH:mm:ss');
  console.log("saving data:" + mydate );
  console.log(data);
  //setINVLineItems(data.line_item_data);

  if(data.uuid !== 'undefined'){

  console.log('Checking uuid: ' + data.uuid);

  let uuid_data = await check_uuid_exists(data.uuid);
  console.log(uuid_data);

  if(uuid_data.length > 0){

    console.log('uuid exists - update it');


    //Save description lookup
    try{
      let rowsData = data.row_data;

      let cnt = rowsData.rowsData.length;
      let lookup_array = new Array();

      for(let i=0; i < cnt; i++){
      //rowsData.rowsData.forEach((item)=>{
        let item = rowsData.rowsData[i]
          console.log('x:' + item.Description);
          console.log('y:' + item.AccountCode);
      //});
          let llobj = {
             Description: item.Description,
             AccountCode: item.AccountCode
          }
          lookup_array.push(llobj);

        if(i == cnt - 1){
          console.log('finsihed');

          console.log(lookup_array);

          //post lookup array to sqlite lookup table
          update_acc_code_table(lookup_array);



        }
      }

    }
    catch(err){
        console.log(err);
    }

    

    update_uuid_data(data);

  }
  else{

    console.log('uuid_data doesnt exists in company repo - create it');
   
    console.log(data);


    create_data(data);
  }
}
else{

  console.log('error uuid is undefined');
}

  //create_data(data);
}







export default function InvoiceWorkflow(props) {
  const [open, setOpen] = React.useState(false);
  const [PDFData, setPDFData] = React.useState();
  const [PONumber, setPONumber] = React.useState();

  const [POData, setPOData] = React.useState([]);



  //const[PONumber,setPONumber] = useState();
  const[POCurrencyCode, setPOCurrencyCode] = React.useState();
  const[POStatus, setPOStatus] = React.useState()
  const[POReference, setPOReference] = React.useState();
  const[POSupplierName, setPOSupplierName] = React.useState(); 
  const[POLineItems, setPOLineItems] = React.useState([]);
  const[POSubTotal, setPOSubTotal] = React.useState(); 
  const[POTax, setPOTax] = React.useState();
  const[POTotal, setPOTotal] = React.useState();
  const[LineSubTotal, setLineSubTotal] = React.useState();


  const[InvoiceData,setInvoiceData] = React.useState([]);

  const[INVPurchaseOrder, setINVPurchaseOrder] = React.useState();
  const[INVCurrencyCode, setINVCurrencyCode] = React.useState();
  const[INVStatus, setINVStatus] = React.useState("DRAFT");
  const[INVReference, setINVReference] = React.useState();
  const[INVSupplierName, setINVSupplierName] = React.useState(); 
  const[INVLineItems, setINVLineItems] = React.useState([]);
  const[INVSubTotal, setINVSubTotal] = React.useState(); 
  const[INVTax, setINVTax] = React.useState();
  const[INVTotal, setINVTotal] = React.useState();
  const[INVNumber, setINVNumber] = React.useState();

  const [ShowINVSubTotalField, setShowINVSubTotalField] = React.useState(true);
  const [ShowINVTaxField, setShowINVTaxField] = React.useState(true);
  const [ShowINVTotalField, setShowINVTotalField] = React.useState(true);
  const [ShowINVStatusField, setShowINVStatusField] = React.useState(true);
  const [ShowINVReferenceField, setShowINVReferenceField] = React.useState(true);
  const [ShowINVCurrencyCodeField, setShowINVCurrencyCodeField] = React.useState(true);
  const [ShowINVSupplierNameField, setShowINVSupplierNameField] = React.useState(true);
  const [ShowINVPurchaseOrderField, setShowINVPurchaseOrderField] = React.useState(true);
  const [AccountCodeData, setAccountCodeData] =React.useState(["option 1", "option 2", "option 3"]);
  const [SuppliersData, setSuppliersData]=React.useState([]);
  const [TableInv, setTableInv]=React.useState();



  const [DocName, setDocName] = React.useState();
  const [DocSender, setDocSender] = React.useState();
  const [DocTag, setDocTag] = React.useState();
  const [DocLink,setDocLink] = React.useState();

  const [UUID, setUUID] = React.useState();

  
  

  const [openVerification, setOpenVerification] = React.useState(false);
  const handleOpenVerification = () => setOpenVerification(true);
  const handleCloseVerification = () => setOpenVerification(false);

  const [VerifyStatus,setVerifyStatus] = React.useState();
  const [XeroStatus, setXeroStatus] = React.useState();
  const [SaveHide, setSaveHide] = React.useState(false);
  const [CloseHide,setCloseHide] = React.useState('hidden');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  async function VerifyCallback(data){

    console.log('verifying data');
    console.log(data);
  
    //setOpenVerification(true);
    //handleOpenVerification();

    //Check totals against data....
    console.log(INVSubTotal + " " + INVTax + " " + INVTotal);
    //Check total
    let mismatch_detected = false;

    try{

    if(INVTotal === undefined || INVTax === undefined || INVSubTotal === undefined){
      mismatch_detected = true;
      console.log("Mismatch Detected Undefined Values");
      setVerifyStatus("Mismatch Detected Undefined Values");
    
    }


    if(parseFloat(data.SubTotal) !== parseFloat(INVSubTotal)){
        console.log('SubTotal Mismatch:' + INVSubTotal + " " + data.SubTotal);
        if(!mismatch_detected){
          setVerifyStatus('SubTotal Mismatch:' + INVSubTotal + " " + data.SubTotal);
          mismatch_detected = true;
        }
    }
    else{
      if(!mismatch_detected){
      setVerifyStatus('Amounts Verified');
      }
    }

    if(parseFloat(data.TaxTotal) !== parseFloat(INVTax)){
      console.log('Tax Mismatch:' + INVTax + " " + data.Tax);
      if(!mismatch_detected){
      setVerifyStatus('Tax Mismatch:' + INVTax + " " + data.Tax);
      mismatch_detected = true;
      }
    }
    else{
      if(!mismatch_detected){
      setVerifyStatus('Amounts Verified');
      }
    }

    if(parseFloat(data.Total) !== parseFloat(INVTotal)){
      console.log('Total Mismatch: ' + INVTotal + " " + data.Total);
      if(!mismatch_detected){
      setVerifyStatus('Total Mismatch: ' + INVTotal + " " + data.Total);
      mismatch_detected = true;
      }
    }
    else{
      if(!mismatch_detected){
      setVerifyStatus('Amounts Verified');
      }
    }

    }
    catch(err){
      setVerifyStatus('Cant Verify: ' + JSON.stringify(err));
    }


  }


  async function lookup_acc_code(Description, index){



    return new Promise(function(resolve,reject){
    console.log('checking account code');
  
    try{
      
      
      const token = localStorage.getItem('AccessToken'); 
  
    
  
  
        fetch("https://automate.isnet.co.za/lookup_acc_code?Description=" + Description,{
            method: 'GET',
            headers: { 
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
            }
            })
            .then(response => response.json())
            .then(function(data){
                    console.log('fff');
                    console.log(data);
                    console.log('xxx2');
  
                    //handleChangeCode(data[0].AccountCode,index);
                    try{
                      if(data[0]){
                              console.log('here 19999');
                              console.log(data[0]);
                              if(data[0].AccountCode){
                                //setFoundCode(data[0].AccountCode);
                                console.log('updating code for index: ' + index + " " + data[0].AccountCode);
                                //handleChangeCode(data[0].AccountCode,index);
                                //setFoundCode(data[0].AccountCode);
                                if(data[0].AccountCode == ""){
                                  console.log('2000 HHere');
                                  resolve([]);
                                }
                                else{
                                  console.log('2002 HHere');

                                 resolve(data[0].AccountCode);
                                }
                                
                                //setLookupIndex(lookupObject);
                              }
                              else{
                                  resolve([]);
                              }
                      }
                      else{
                        resolve([]);
                      }


                    }
                      catch(err){
                        console.log("844:"  +  err);
                        resolve([]);
                        //reject(err);
                      }
                    //setSuppliersData(data.body.contats);
                   
            
            });
           
           
        }
        catch(err){
          console.log("855: " + err); 
          //reject(err);
        }
  

      });

   
  
  }

  


  async function set_invoice_line_data(tdata){

    let table_data = new Array()


    console.log(tdata);


    let jlen = tdata.length;
    console.log("JLEN: " + jlen);
    tdata.forEach(async (lineItem,index)=>{


      console.log("lineitem: ");
      console.log(lineItem);

      let id=index;
      console.log('id: ' + id  + " here");
      //let UnitAmount= lineItem.rate;
      let UnitAmount = lineItem.UnitAmount;

      let LineAmount;
      let typeCheck = typeof lineItem.LineAmount;

      if(typeCheck === "string"){
        LineAmount= clean_currency(lineItem.LineAmount);
      }
      else{
        LineAmount = lineItem.LineAmount;
      }

      let Quantity=lineItem.Quantity;
   

      let Description = "";
      if(lineItem.line1_description){


          Description= lineItem.line1_description;

       
        if(lineItem.line2_description){
          //Do nothig it description not set
          Description= lineItem.line1_description + " " + lineItem.line2_description;
        }
        
        
      }
      else{
        Description = lineItem.Description;
      }



      let AccountCode;
      if(lineItem.AccountCode){
      AccountCode = lineItem.AccountCode;
      }
      else{
        console.log("Account Code not found look it up");
        console.log('1999 _____');
        if(Description){
             let acccode = await lookup_acc_code(Description);
             //let acccode = "xx";
             AccountCode = acccode;
            console.log('Set Account Code: ' + acccode);
        }
        console.log('2000 _____');

      }
    
    

      let taxUnRounded = LineAmount * 0.15; //Workout Tax
      let TaxAmount = (Math.round(taxUnRounded*Math.pow(10,2))/Math.pow(10,2)).toFixed(2); //workout tax

      //if((LineAmount) && (Quantity)){

      console.log("___________");
      console.log(LineAmount);
      console.log(UnitAmount);
      console.log("___________");

      if((LineAmount) || (UnitAmount)){
      let line_json = {
        id: id,
        UnitAmount: UnitAmount,
        LineAmount: LineAmount,
        Quantity: Quantity,
        Description: Description,
        TaxAmount: TaxAmount,
        AccountCode: AccountCode

      }
      table_data.push(line_json);
      }

      console.log('TotalIndex: ' + index + "JLEN: " + jlen);

      if(index == jlen - 1){

        console.log('Table Data');
        console.log(table_data);
        setINVLineItems(table_data);
      }

    

    })
  }

  React.useEffect(()=> {
    let pdfurl = props.pdf_url;
    setPDFData(pdfurl);


    try{ //Catch error
    let po_number = props.po_number;
    const value = po_number.replace(/\D/g, "");
   // if(po_number.length > 1){
   //   console.log('PPP val:' + po_number);
      
      setPONumber(value);
    //}
    //else{
    //  console.log('PONumber is null');
     // setPONumber();
    //}
    }
    catch(err){

      console.log(err);
      setPONumber(0);
    }
   
    //setPONumber(po_number);



    setInvoiceData(props.invoice_data);

    console.log("IIIII INVOICE");
    console.log(props.invoice_data);

    setUUID(props.UUID);
    console.log('settnig my uuid: ' + props.UUID);


    //Lookup Data
    let invItem = props.invoice_data;
    console.log('single_field_data:');
    console.log(invItem.pages[0].single_field_data);


    let singleData = invItem.pages[0].single_field_data;
    setINVSupplierName(invItem.doc_sender);
    

    setINVNumber(invItem.pages[0].single_field_data.InvoiceNumber);
    setINVTotal(clean_currency(invItem.pages[0].single_field_data.Total));
    setPONumber(singleData.PurchaseOrder);
    setINVNumber(singleData.InvoiceNumber);


    if(INVReference){
        if((singleData.InvoiceNumber) && (singleData.PurchaseOrder)){
          setINVReference(singleData.InvoiceNumber + " " + singleData.PurchaseOrder);
        }

        if(singleData.InvoiceNumber && !(singleData.PurchaseOrder)){
          setINVReference(singleData.InvoiceNumber);
        }
    }
    else{
      if(singleData.InvoiceNumber){
        setINVReference(singleData.InvoiceNumber);
      }
      if(singleData.PurchaseOrder){
       // setINVReference(INVReference + " " + singleData.PurchaseOrder);
        }

    }

  
    setINVSubTotal(clean_currency(singleData.SubTotal));




    //setINVSubTotal(invItem.pages[0].single_field_data.NetTotal);
    setINVTax(clean_currency(invItem.pages[0].single_field_data.TaxTotal));


    setPOCurrencyCode(invItem.pages[0].single_field_data.POCurrencyCode);
    setINVCurrencyCode(invItem.pages[0].single_field_data.currency);
    setPONumber(invItem.pages[0].single_field_data.PONumber);
    setPOReference(invItem.pages[0].single_field_data.POReference);
    setPOSupplierName(invItem.pages[0].single_field_data.POSupplierName);


    console.log('setting po')
    setPOReference(singleData.POReference);
    

    setINVTotal(clean_currency(invItem.pages[0].single_field_data.Total));
    setINVTax(clean_currency(invItem.pages[0].single_field_data.TaxTotal));
  //  setINVTax(invItem.pages[0].single_field_data.TaxTotal);


    /*
    
      INVStatus	"DRAFT"
      INVSubTotal	"9787.67"
      INVTax	"1468.15"
      INVTotal	" 11255.82"
      POCurrencyCode	"ZAR"
      PONumber	"123"
      POReference	"GEA-ADC"
      POSupplierName	"Accelerate Networks"

    */


    let invdata = props.invoice_data;


    console.log('IIIINNNN');
    console.log(invdata);
    setDocName(invdata.doc_name);
    setDocSender(invdata.doc_sender);
    setDocTag(invdata.doc_tag);
    setDocLink(invdata.doc_link);

    //setINVReference(invdata.InvoiceNumber + " " + invdata.PurchaseOrder);




    //Set Inv data here
    console.log("Setting Invoice Lines: ")
    console.log(invItem.pages[0].table_data);
    set_invoice_line_data(invItem.pages[0].table_data);


    ///
  

  },[]);


  function DataTableCallback(data){
    console.log("here - hi");
    console.log(data);
    setINVLineItems(data.line_item_data);
}







  function get_po_data(po_number){

    async function get_data(){

            let res_data = await get_xero_purchase_order(po_number);
            //return(res_data);

            if(res_data.status){
              console.log('Error Purcharse Order not found');
              //popup
              setPONumber("NA");

              setPOData();
              //let pitem = res_data.response.body.PurchaseOrders[0];
              //setPONumber(pitem.PurchaseOrderNumber);
              setPOCurrencyCode();
              setPOStatus();
              setPOReference("Purchase order Not found");
              setPOSupplierName();
              setPOLineItems();
              setPOSubTotal();
              setPOTax();
              setPOTotal();
            }

            
            else{

            console.log(res_data.response.body.PurchaseOrders);
            setPOData(JSON.stringify(res_data.response.body.PurchaseOrders));
            let pitem = res_data.response.body.PurchaseOrders[0];
            //setPONumber(pitem.PurchaseOrderNumber);
            setPOCurrencyCode(pitem.CurrencyCode);
            setPOStatus(pitem.Status);
            setPOReference(pitem.Reference);
            setPOSupplierName(pitem.Contact.Name);
            setPOLineItems(pitem.LineItems);
            setPOSubTotal(pitem.SubTotal.toFixed(2));
            setPOTax(pitem.TotalTax.toFixed(2));
            setPOTotal(pitem.Total.toFixed(2));

            

            console.log("XX:");
            console.log(pitem.LineItems);
            }
        
          


    }
    get_data();


}

const invoiceDataForm = ()=>{

  let formContent = {
    "PONumber":PONumber, 
    "POSupplierName": POSupplierName,
   "POCurrencyCode": POCurrencyCode,
    "POReference": POReference,
    "POStatus": POStatus,
    "POSubTotal": POSubTotal,
    "POTax": POTax,
    "POTotal": POTotal
  }

  console.log(formContent);

  setTableInv(formContent);


}


React.useEffect(()=> {
  invoiceDataForm();


},[])



  const XeroSuppliers = async ()=>{
   
  return new Promise(function (resolve,reject){
    
      try{
  
  
        const token = localStorage.getItem('AccessToken'); 
  
      
  

          fetch("https://automate.isnet.co.za/get_xero_suppliers",{
              method: 'GET',
              headers: { 
                          'Content-Type': 'application/json',
                          Authorization: 'Bearer ' + token
              }
              })
              .then(response => response.json())
              .then(function(data){
                      console.log('fff');
                      console.log(data.body.contacts);
                      console.log('xxx2');
                      setSuppliersData(data.body.contacts);
                      resolve(data);
                      
              
              });
             
              //console.log(SuppliersData);
          }
          catch(err){
          console.log(err);
          reject(err);
          }

          })



}

// XeroSuppliers();

React.useEffect( ()=>{


XeroSuppliers();
//setSuppliersData(XeroSuppliers);


},[]);

//console.log(SuppliersData);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPONumber(value);
  };


  const inputAuto = <AutoCompleteInput options={AccountCodeData}></AutoCompleteInput>
 

  return (
    <div>
     
      <div>
       
          <h2>Invoice Process Workflow</h2>
          <p>
            Invoice Detected
          </p>

            <div id="options">
            <>
            <TextField
                    required
                    id="textFieldPO outlined-required"
                    
                   // label="Purchase Order"
                    defaultValue={PONumber}
                    value= {PONumber}
                    //disabled
                    onChange={(e)=>{

                        console.log('testing:');
                        console.log(e.target.value.replace(/\+|-/ig, ''));
                        //FieldData[item] = e.target.value;
                        
                        setPONumber(e.target.value);
                        //setPDFData(NewPDF);
                  
          
                   }}

                  // onChange={handleChange}
                    />

                 



                <Button 
                
                disabled={!PONumber}
                id="searchBtn"
                onClick={()=>{
                    
                        var myHeaderTables = document.getElementById("POHeader");
                       var myPOTables = document.getElementById("invoiceTableContainer");
                        var myTables = document.getElementById("infoTable");
                        
                        console.log("Searching ERP for PO Number: " + PONumber);
                       let po_data =  get_po_data(PONumber);
                      
                        console.log(po_data);


                      
                        myHeaderTables.classList.add("displayTbl");
                        myPOTables.classList.add("displayTbl");
                        myPOTables.classList.remove("hideTbl");
                        myTables.classList.add("displayTbl");

                        
                       
                }

                }>Search ERP for Purchase Order</Button>

                </>

            {PONumber < 0 || PONumber == undefined || PONumber == null || PONumber == "" ?

            <Button onClick={()=>{
                        var myPOTables = document.getElementById("invoiceTableContainer");
                        var myTables = document.getElementById("infoTable");
                        //var newPONumber = PONumber.replace(/\+|-/ig, '');
                        //setPONumber(PONumber);
                        //console.log("Searching ERP for new PO Number: " + newPONumber);
                        console.log("No PO number " );
                       //let po_data =  get_po_data(PONumber);
                      
                      // let po_data =  (get_po_data(PONumber)).replace(/\+|-/ig, '');
                        //console.log(po_data);
                        myPOTables.classList.add("displayTbl");
                        myPOTables.classList.remove("hideTbl");
                        myTables.classList.add("displayTbl");

                        
                       
                }

                }>Display Invoice tables</Button>

                :

                
                  console.log("not empty")
               
            
            
            
            }

           
       

            </div>
            
            <div className='tb_container' id="POHeader" style={{overflow: "auto",  width:'100%'}}>

                {/*
                
                    const[PONumber,setPONumber] = useState();
                    const[POCurrencyCode, setPOCurrencyCode] = useState();
                    const[POStatus, setPOStatus] = useState()
                    const[POReference, setPOReference] = useState();
                    const[POSupplierName, setPOSupplierName] = useState(); 
                    const[POLineItems, setPOLineItems] = useState();
                    const[POSubTotal, setPOSubTotal] = useState(); 
                    const[POTax, setPOTax] = useState();
                    const[POTotal, setPOTotal] = useState();
                */}


            <div id='POTableContainer'>
            <h2>Purchase Order Data:</h2>
            <table className='invoice_table' id='invoice_table'>

            <tr>
                     <th>
                        Purchase Order
                    </th>
                    <th>
                        SupplierID
                    </th>
                     <th>
                        Currency Code
                    </th>
                    <th>
                        Reference
                    </th>
                    <th>
                        Status
                   </th>
                   <th>
                        SubTotal
                   </th>
                   <th>
                        totalTax
                   </th>
                   <th>
                        Total
                   </th>
            </tr>


                    <tr>
                     <td style={PONumber == PONumber ? CheckStyle.match : CheckStyle.nomatch}>
                        {PONumber} 
                    </td>
                    <td>
                        {POSupplierName} 
                    </td>
                     <td>
                        {POCurrencyCode}
                    </td>
                    <td>
                        {POReference}
                    </td>
                    <td>
                        {POStatus} 
                   </td>
                   <td style={POSubTotal == INVSubTotal ? CheckStyle.match : CheckStyle.nomatch}>
                        {POSubTotal} 
                   </td>
                   <td style={POTax == INVTax ? CheckStyle.match : CheckStyle.nomatch}>
                        {POTax} 
                   </td>
                   <td style={POTotal == INVTotal ? CheckStyle.match : CheckStyle.nomatch}>
                     
                       {POTotal} 
                   </td>
                 </tr>
                 </table>

                 <br></br>

                <table id="po_line_items" className='invoice_table'>

                <tr>    
                    
                                     <th>
                                        Line
                                    </th>
                    
                                     <th>
                                        Description
                                    </th>
                                    <th>
                                        UnitAmount
                                    </th>
                                    <th>
                                        TaxAmount
                                    </th>
                                    <th>
                                        LineAmount
                                    </th>
                                    <th>
                                        AccountCode
                                    </th>
                            </tr>



                    {
                   
                    
                    POLineItems &&

                   
                    POLineItems.map((line_item,index) => {

                     
                        //setLineSubTotal(total);


                        return(
                            <tr>
                                     <td>
                                        {index} 
                                    </td>
                                     <td>
                                        {line_item.Description} 
                                    </td>
                                    <td>
                                        {line_item.UnitAmount} 
                                    </td>
                                    <td>
                                        {line_item.TaxAmount.toFixed(2)} 
                                    </td>
                                    <td>
                                        {line_item.LineAmount.toFixed(2)} 
                                    </td>
                                    <td>
                                        {line_item.AccountCode} 
                                    </td>
                            </tr>
                        );

                    })


                    
                    
                    }

<tr>
                                     <td>
                                        
                                    </td>
                                     <td>
                                      
                                    </td>
                                    <td>
                                        
                                    </td>
                                    <td>
                                        
                                    </td>
                                    <td>
                                      {LineSubTotal}
                                    </td>
                                    <td>
                                        
                                    </td>
                            </tr>


                </table>
                </div>
                 </div>

                 <div id='invoiceTableContainer' className='hideTbl'>
            <h2>Invoice Data:
                 &nbsp;
            <DocumentScannerIcon 
                className='iconButton'
                onClick={async ()=>{
                                       //https://automate.isnet.co.za/process_file2?filename=' + item.filename + '&queue=true';
                                    console.log('processing invoice')

                                    let json_data = await get_invoice_data(PDFData);

                                    let tdata = json_data.pages[0].table_data;


                                    //Set single field data
                                    let sdata = json_data.pages[0].single_field_data;
                                    let Total = sdata.Total;
                                    let SubTotal = sdata.SubTotal;
                                    let TaxTotal = sdata.TaxTotal
                                    let currency = sdata.currency;
                                    let date = sdata.date;
                                    let issuer = sdata.issuer;
                                    let invoice_number = sdata.invoice_number;
                                    let po_number =  sdata.po_number;

                                    //let Reference = sdata.POReference;
                                    let Reference = sdata.InvoiceNumber + " " + sdata.PurchaseOrder;
                                    console.log('999:' + JSON.stringify(sdata));

                                    console.log("SSS");
                                    console.log(sdata);
                                    console.log(Total + " sub:" + SubTotal + " Tax: " + TaxTotal);

                                    try{
                                    setINVTotal((clean_currency(Total)).toFixed(2));
                                    setINVTax((clean_currency(TaxTotal)).toFixed(2));
                                    setINVSubTotal((clean_currency(SubTotal)).toFixed(2));
                                    setPOReference(Reference);
                                    setINVReference(Reference);

                                    }
                                    catch(err){
                                      console.log("err" + err);
                                    }



                                    //Remap json to table format
                                    let table_data = new Array()


                                    console.log(tdata);


                                    let jlen = tdata.length;
                                    tdata.forEach((lineItem,index)=>{

                                      let id=index;
                                      //let UnitAmount= lineItem.rate;
                                      let UnitAmount = lineItem.UnitAmount;
                                      let LineAmount= clean_currency(lineItem.LineAmount);
                                      let Quantity=lineItem.Quantity;
                                      let Description= lineItem.line1_description + " " + lineItem.line2_description;


                                      let taxUnRounded = LineAmount * 0.15; //Workout Tax
                                      let TaxAmount = (Math.round(taxUnRounded*Math.pow(10,2))/Math.pow(10,2)).toFixed(2); //workout tax
                                
                                      //if((LineAmount) && (Quantity)){


                                      if((LineAmount) || (UnitAmount)){
                                      let line_json = {
                                        id: id,
                                        UnitAmount: UnitAmount,
                                        LineAmount: LineAmount,
                                        Quantity: Quantity,
                                        Description: Description,
                                        TaxAmount: TaxAmount

                                      }
                                      table_data.push(line_json);
                                      }

                                      if(index == jlen - 1){

                                        console.log('Table Data');
                                        console.log(table_data);
                                        setINVLineItems(table_data);
                                      }

                                    

                                    })

                                    //setINVLineItems(json_data.pages[0].table_data);


                                   
                }}
                >

              
            </DocumentScannerIcon>

            </h2>


            <table className='invoice_table' id="invoice_data_table">
                <tr>
                        <th>
                            Purchase Order
                        </th>
                        <th>
                            SupplierID
                        </th>
                        <th>
                            Currency Code
                        </th>
                        <th>
                            Reference
                        </th>
                        <th>
                            Status
                    </th>
                    <th>
                            SubTotal
                    </th>
                    <th>
                            totalTax
                    </th>
                    <th>
                            Total
                    </th>
                </tr>



                    <tr>

                     <td style={PONumber == INVPurchaseOrder ? CheckStyle.match : CheckStyle.nomatch}>

                   
                        <input
                        className='invValue'
                        required
                        type="text"
                        id="PONumber outlined-required "
                        label="PurchaseOrder"
                        defaultValue={PONumber}
                        //disabled
                        //value={INVPurchaseOrder}
                        onChange={(e)=>{


                          console.log(INVPurchaseOrder + " " + PONumber);

                            console.log('testing:');
                            console.log(e.target.value);
                            //FieldData[item] = e.target.value;
            
                            setINVPurchaseOrder(e.target.value);

                        
            
                        }}
                        />


                    </td>



                    <td>
                    
                    
                <select id="POSupplierName" type="text" onChange={(e)=>setPOSupplierName(e.target.value)} name="POSupplierName" className="invValue form-control" placeholder='Select an option' >
                <option disabled>Select an option</option>
              {SuppliersData.map((item)=>{

                  //Check 
                  //console.log("eq: " + item.name + " supplier_name:" + INVSupplierName);
                  let Supp = item.name;
                  //let selected;
                  if (Supp.includes(INVSupplierName)){
                     // selected = 'selected';

                      return(
                          <option selected>{item.name}</option>
                      )
                  }
                  else{
                    return(
                         <option>{item.name}</option>
                    )
                  }
              })}
                
                
              </select>


                    {/*<input
                      type="text"
                        required
                        id="outlined-required"
                        label="Supplier ID"
                        defaultValue={POSupplierName}
                        //disabled
                        //value={POSupplierName}

                        onChange={(e)=>{


                          console.log(INVPurchaseOrder + " " + PONumber);

                            console.log('testing:');
                            console.log(e.target.value);
                            //FieldData[item] = e.target.value;
            
                            setINVSupplierName(e.target.value);

                        
            
                        }}
                        />

                        

                        <Button 
                        onClick={()=>{
                          //setShowINVReferenceField(true);
                          console.log("Xero Autocomplete")
                        }}
                        
                        >Search Xero</Button>*/}
                   
                   
                    </td>



                     <td>
                   
                        <input
                        className='invValue'
                        required
                        type="text"
                        id="outlined-required POCurrencyCode"
                        label="CurrencyCode"
                        defaultValue={POCurrencyCode}
                        //disabled
                        value={INVCurrencyCode}
                        onChange={(e)=>{

                            console.log('testing:');
                            console.log(e.target.value);
                            //FieldData[item] = e.target.value;
            
                            setINVCurrencyCode(e.target.value);
                        
            
                        }}
                        />
                    </td>



                    <td>

                   
                        <input
                          className='invValue'
                            required
                            type="text"
                            id="INVReference"
                            label="Invoice Reference"
                            defaultValue={POReference}
                            //disabled
                            value={INVReference}
                            onChange={(e)=>{

                          
                                console.log(e.target.value);
                                //FieldData[item] = e.target.value;
                
                                setINVReference(e.target.value);
                            
                
                            }}
                            />
                       </td>


                    <td>

                   
                    <input
                      className='invValue'
                        required
                        type="text"
                        id="outlined-required INVStatus"
                        label="Invoice Status"
                        defaultValue="DRAFT"
                        //disabled
                        //value={INVStatus}
                        onChange={(e)=>{

                      
                            console.log(e.target.value);
                            //FieldData[item] = e.target.value;
            
                            setINVStatus(e.target.value);
                        
            
                        }}
                        />
                   </td>



                   <td style={POSubTotal == INVSubTotal ? CheckStyle.match : CheckStyle.nomatch}>


                 
                   <input
                       className='invValue'
                        required
                        type="text"
                        id="INVSubTotal"
                        label="Sub Total"
                        defaultValue={POSubTotal}
                        value={INVSubTotal}
                        //disabled
                        //value={POSubTotal}
                        onChange={(e)=>{

                      
                            console.log(e.target.value);
                            //FieldData[item] = e.target.value;
            
                            setINVSubTotal(e.target.value);
                        
            
                        }}
                     
                        />
                   </td>




                   <td style={POTax == INVTax ? CheckStyle.match : CheckStyle.nomatch}>


                      <input
                        className='invValue'
                            required
                            type="text"
                            id="INVTax outlined-required "
                            label="Tax"
                            defaultValue={POTax}
                            value={INVTax}
                            //disabled
                            //value={POTax}
                            onChange={(e)=>{

                          
                                console.log(e.target.value);
                                //FieldData[item] = e.target.value;
                
                                setINVTax(e.target.value);
                            
                
                            }}
                            />

                   </td>



                   <td style={POTotal == INVTotal ? CheckStyle.match : CheckStyle.nomatch}>


                 
                          <input
                               className='invValue'
                                required
                                type="text"
                                id=" INVTotal outlined-required "
                                label="Total"
                                defaultValue={POTotal}
                                value={INVTotal}
                                //disabled
                                //value={POTotal}
                                onChange={(e)=>{

                              
                                    console.log(e.target.value);
                                    //FieldData[item] = e.target.value;

                                    setINVTotal(e.target.value);
                                
                    
                                }}
                                />

                   </td>



                 </tr>
                 </table>
                 <br></br>


              

                      
            <div style={{overflow:'auto',height:"80%"}}>

                    
             {/* <InvoiceTable Sources={POLineItems} parentCallback = {DataTableCallback}></InvoiceTable> */}
                                
             <InvoiceTable Sources={INVLineItems} DocName={DocName} DocSender={DocSender} DocTag={DocTag} DocLink={DocLink} UUID={UUID} parentCallback = {DataTableCallback} saveCallback = {SaveCallback} verifyCallback={VerifyCallback}></InvoiceTable>



              </div>
              
                 <div align="center">
                   {/* Status: {VerifyStatus} */}
                  
                  </div>

                 <div align="center" className={SaveHide? 'hidden' : undefined}>

                    <Button
                      
                      onClick={async ()=>{
                      


                        let invLineItems = new Array();

                        //POLineItems.map((item,index)=> {

                        

                        INVLineItems.map((item,index) => {

                          let line_item = {
                            "lineItemID": "",
                            "description": item.Description,
                            "quantity": item.Quantity,
                            "unitAmount": item.UnitAmount,
                            "lineAmount": item.LineAmount,
                            "taxType": "INPUT3", //Adjust this tax type
                            "accountCode": item.AccountCode,
                         
                        }
                          invLineItems.push(line_item);

                        });

                        let invoice_json = {
                          "invoices": [
                          {
                              "type": "ACCPAY",
                              "contact": {
                              "name": INVSupplierName
                              },
                              "dateString": moment().format('YYYY-MM-DD'),
                              "dueDate": "2022-02-09",
                              "status": "SUBMITTED",
                              //"reference": INVReference,
                              "reference": INVReference, //https://community.xero.com/developer/discussion/1785432
                              "currencyCode": INVCurrencyCode,
                              "lineItems": invLineItems,
                              "invoiceNumber": INVReference,
                         }
                         ]
                      }

                      console.log(invoice_json);
                      let status = await post_xero_invoice(invoice_json);


                      console.log("SSS: ");
                      console.log(status);

                      if(status.response.statusCode == 200){
                          if(status.response.body.Invoices){
                            let InvoiceID = status.response.body.Invoices[0].InvoiceID
                            //upload invoice
                            let uploadpdf = post_xero_upload_pdf_invoice(InvoiceID, PDFData);
                            console.log(uploadpdf);

                            console.log("Invoice upload into xero with InvoiceID: " + InvoiceID);
                            //Save Invoice id to doc_repo and close invoice



                            
                              let jdoc={
                                  uuid: UUID,
                                  erp_ref: InvoiceID
                              }
                            

                              upload_doc_repo_erpref(jdoc);

                              delete_from_processing_queue_uuid(jdoc);

                              setXeroStatus('Invoice Posted OK');
                              setSaveHide("hidden");
                              setCloseHide(false);
                              



                          }
                      }
                      else{
                          console.log('Please check invoice - Error posting invoice to Xero');
                          console.log(status.response.statusCode );
                          console.log(status.response.body.Message );


                          if(status.response.body.Message){
                          setXeroStatus('Please check invoice - Error Posting Invoice to Xero: ' + JSON.stringify(status.response.body));
                          }
                          else{
                            setXeroStatus('Please check invoice - Error Posting Invoice to Xero: ' + JSON.stringify(status.response.body));
                          }
                          
                          
                          return
                      }


                      }}
                    
                    >
                      Everything Looks Good Approve Invoice and Post to Xero
                    </Button>

          

                   {/* <Button>Create an Approval Request for this Invoice</Button> */}


                    
                </div>      
                <div align="center" className={CloseHide? 'hidden' : undefined}>
                  <Button onClick={()=>{

                  window.location.href = "/processing_queue";

                  }}>Close Document</Button>

                </div>
                <div>{XeroStatus}</div>

            </div>
            <div>   
           
            </div>

  


            <Modal
                //key={mail._id}
                open={openVerification}
                onClose={handleCloseVerification}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
              
                <div>

                                Edit Training Data
                                <form>
                                  
                              <div>
                                <tr>
                                  <td>SubTotal</td><td>Tax</td><td>Total</td>
                                </tr>
                                     
                                <tr>
                                    Verified
                                </tr>
                              </div>
                           


                                  <input type='Button' id='Proceed' value='Proceed' onClick={(e)=>{
                                        console.log('Proceeding');
                                        //let fres =  TrainingUpdate(); //Upload file
                                        handleCloseVerification();
                                        

                                  }} ></input>
                                </form>
                            </div>
            
                
                </Box>
              </Modal>   
          

        </div>
      </div>
    
  );
}

