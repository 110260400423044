


import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';


import moment from 'moment';
import './documentlisting.css';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import Button from '@material-ui/core/Button';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


import jwt from 'jwt-decode'
import { PDFReader } from 'reactjs-pdf-reader';


import MyModal from './modal.js';
import { Tooltip } from '@mui/material';


import DatePicker from "react-datepicker";
import "./react-datepicker.css";
import "./react-datepicker-cssmodules.css";
import { Typography } from '@material-ui/core';




//let server_url = 'https://automate.isnet.co.za';
//let server_url = 'http://127.0.0.1:3002';
let server_url = process.env.REACT_APP_SERVER_URL;

var date;









  








const MyDate = (props) => {
  const [startDate, setStartDate] = useState(new Date().setHours(0,0,0,0));
  return (
    <DatePicker selected={startDate} onChange={(date:Date)=>{

      //(date:Date) => setStartDate(date);
      setStartDate(date);
      props.parentCallback(date);
      console.log(date);

    }


    } className="red-border" />
  );
};


export default function SyncData(){


    const [RepoData,setRepoData] = useState({});
    const [TotalTransactions, setTotalTransactions] = useState();
    const history = useHistory();

    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());

    const [DocsSynced, setDocsSynced] = useState(0);



    async function sync_data(state_date){





        return new Promise(function (resolve,reject){
      
          try{
                            const token = localStorage.getItem('AccessToken'); 
                            let options = {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            },
                            insecure: true,
                            rejectUnauthorized: false
                            }
    
    
                            //https://automate.isnet.co.za/sync_xero_invoices?datestamp=2022-06-01
    
                          fetch(server_url + "/sync_xero_invoices?datestamp=" + state_date, options)
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  setDocsSynced(data.length);
                                  resolve(data);
    
                                 
                                  
                          });
    
                          
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
        }
      
    


    /*
    async function reload_transactions(start_date,end_date){
      try{
        let doc_data = await get_documents(start_date, end_date);
        console.log('DD: ' + JSON.stringify(doc_data));
        setRepoData(doc_data.reverse());
        setTotalTransactions(doc_data.length);
        console.log(RepoData.length)
      }
      catch(err){
        console.log(err);
      }
    }
    */


    useEffect(()=>{


            //Get All Documents from database
      
            



    },[])


    function handleSearch() {
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[0];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }       
        }
      }


      

      function  startDateCallback(date){

          console.log("here:" + date);
          let udate = moment(date).format('YYYY-MM-DD');
          console.log(udate);
          //setHours(0,0,0,0))
          setStartDate(udate);
      }

      function  endDateCallback(date){

        console.log("here:" + date);
        //let udate = moment(date).unix();
        let udate = moment(date).format('YYYYMMDD');
        console.log(udate);
        setEndDate(udate);
        //setHours(0,0,0,0))
    }


            return(
              <div>
                <div id="Search">

                  <br />
                            Sync From <MyDate parentCallback={startDateCallback}></MyDate>
                           <br />
                          
                           <br />
                           <Button onClick={()=>{

                              console.log('searching');
                              console.log('start_date:' + StartDate);
                      

                             // let jsonobj = reload_transactions(StartDate, EndDate);
                             let jsonobj = sync_data(StartDate);
                             console.log(jsonobj.length);
                             setDocsSynced(jsonobj.length);
                             

                           }}>Syncronize Xero Payments</Button>
                </div>

              
          
      
             

                
                    <div>
                            Payments synced: {DocsSynced}
                    </div>

                  <div>


                  </div>
              

           
                    
                       
         
                    </div>
        
            )

    
}
