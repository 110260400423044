
import React, {useCallback, useState, useMemo, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import dotenv from  'dotenv'


//redux
import { useSelector, useDispatch } from 'react-redux';
import { loggedin,loggedout,AccessData } from '../redux/authSlice';



import { sha256 } from 'js-sha256';

import QRCode from "react-qr-code";


//let server_url = 'https://automate.isnet.co.za';
let server_url = process.env.REACT_APP_SERVER_URL;

console.log('ssSS: ' + server_url);
//let dev=false;
//if(dev){
//  server_url = 'http://127.0.0.1:3002';
//}


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://isnet.co.za">
        https://isnet.co.za
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));





export default function SignIn(props) {



  const classes = useStyles();
  const history = useHistory();


  const [UserName, setUserName] = useState();
  const [UserPassword, setUserPassword ] = useState();
  const [UserHash, setUserHash] = useState();
  const [AccessToken,setAccessToken] = useState();
  const [Response, setResponse] = useState();

  const [OTPCode, setOTPCode] = useState();
  const [EncToken, setEncToken] = useState();


  const [DisplaySignIn, setDisplaySignIn] = useState(true);
  const [DisplayOTP, setDisplayOTP] = useState(false);
  const [DisplayOTPSetup, setDisplayOTPSetup] = useState(false);

  const [OTPURI, setOTPURI] = useState();
  const [OTPSecret, setOTPSecret] = useState();




  const authstate = useSelector((state) => state.authstate.value);
  const dispatch = useDispatch();





  //const history = useHistory();

  function redirect_loggedin(){

    
      history.push('/');
  
  }



  function post_login(username, passhash){





    try{
  
     // let server_url = 'http://127.0.0.1:3002'
      //let server_url = 'https://automate.isnet.co.za'
      fetch(server_url + '/login', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({"username": username, "password": passhash})
        }).then((response) =>  response.json()).then((json) => {
  
  
              
              console.log(json);
             

  
              if(json.status === 'failed'){
                var topBar = document.getElementById('iconMenu');
                
                  //setresp('Login Failed');
                  setResponse('Login Failed');
                  localStorage.setItem('AccessToken', '');
                  
                  props.parentCallback({AccessToken: '', isAuthenticated: false});

                  dispatch(AccessData({AccessToken: '',  isAuthenticated: false}));
                  
                  document.getElementById('logoutBtn').style.display="none";
                  document.getElementById('iconMenu').style.display="none";
                   document.getElementById('drawerBar').style.display="none";
                   document.getElementById('mainCont').style.display="none";

                  

                  

                  
                
              }
              else if(json.status === 'otp_setup'){

                  console.log('OTP Setup required');
                  //setDisplaySignIn(false);
                  setOTPURI(json.otp_uri);
                  setOTPSecret(json.otp_secret);
                  setEncToken(json.enc_token);

                  setDisplayOTPSetup(true);

              }
              else if(json.status === 'otp_auth'){


                  console.log('OTP Auth Required');
                  //setDisplaySignIn(false);
                  setDisplayOTP(true);

                  setEncToken(json.enc_token);


              }
              else if(json.status === 'disabled'){

             
                  dispatch(AccessData({AccessToken:  '',  isAuthenticated: false}));
                  setResponse('Account is Disabled - Please contact an admin or wait to be provisioned');
                  document.getElementById('logoutBtn').style.display="none";
                  document.getElementById('iconMenu').style.display="none";
                   document.getElementById('drawerBar').style.display="none";
                   document.getElementById('mainCont').style.display="none";
  
  
            
              }
              else{
               
                    setResponse('Successful Login');

                    dispatch(AccessData({AccessToken: json.token,  isAuthenticated: true}));

                    document.getElementById('logoutBtn').style.display="block";
                    document.getElementById('iconMenu').style.display="block";
                  document.getElementById('drawerBar').style.display="block";
                 document.getElementById('mainCont').style.display="block";

                    console.log("AS:" + authstate);
                    setAccessToken(json.token);
                    console.log(json.token);
                    localStorage.setItem('AccessToken', json.token);
                    localStorage.setItem('isAuthenticated', 'true');

                    props.parentCallback({AccessToken: json.token, isAuthenticated: true});

                  
                   
                    redirect_loggedin();
                    

              }
            });
      }
      catch(err){

        console.log('Error posting');

      }
                    
  }



  async function post_otp(username, otp_code, enc_token){



    return new Promise(function (resolve,reject){


    try{



      let doc_json = {
        username: username,
        otp_code: otp_code,
        enc_token: enc_token
      }

  
     // let server_url = 'http://127.0.0.1:3002'
      //let server_url = 'https://automate.isnet.co.za'
      fetch(server_url + '/verify_2fa_token', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(doc_json)
        }).then((response) =>  response.json()).then((json) => {

          //return(json);
          resolve(json);


        })

      }
      catch(err){
        console.log(err);
        resolve(err);
      }

    });

    }


    async function post_otp_setup(username, otp_code, enc_token){



      return new Promise(function (resolve,reject){
  
  
      try{
  
  
  
        let doc_json = {
          username: username,
          otp_code: otp_code,
          enc_token: enc_token
        }
  
    
       // let server_url = 'http://127.0.0.1:3002'
        //let server_url = 'https://automate.isnet.co.za'
        fetch(server_url + '/verify_2fa_token_setup', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(doc_json)
          }).then((response) =>  response.json()).then((json) => {
  
            //return(json);
            resolve(json);
  
  
          })
  
        }
        catch(err){
          console.log(err);
          resolve(err);
        }
  
      });
  
      }
  

  
  if(DisplayOTP){

    return(


      <Container  component="main" maxWidth="xs">
   
      <CssBaseline />
      <div  className={classes.paper}>
        <Avatar id="avataBox" className={classes.avatar}>
          <LockOutlinedIcon id="lockoutIcon"/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Enter OTP Code
        </Typography>

        <Typography component="h1" variant="h5">
          {Response}
        </Typography>
      
   
     

   
          <TextField
            variant="outlined"
            type="number"
            margin="normal"
            defaultValue="Normal"
            value={OTPCode}
            required
            fullWidth
            id="myotp"
            label="OTP Code"
            name="myotp"
            autoFocus
            onChange={(e)=>{
   
            
              //FieldData[item] = e.target.value;
               setOTPCode(e.target.value);

               
               
          

         }}
          />


        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={async () =>{
              // history.push("/");

              if(OTPCode){

              //let hashed_password = sha256(UserPassword);
              //setUserHash(hashed_password);

              //console.log('UserName:' + UserName + 'Hash: ' + hashed_password);
              //post_login(UserName, hashed_password);

              //post_otp(OTPCode, enc_token)
              console.log('snding otp code:' + OTPCode);
              console.log('Enc Auth Code: ' + EncToken);

              let doc_json = {
                username: UserName,
                otp_code: OTPCode,
                enc_token: EncToken
              }

              let res =  await post_otp(UserName, OTPCode, EncToken);
              console.log(res);

                  //console.log(res.status);
                  if(res){
                  if(res.status === 'otp_ok'){
                        let access_token = res.token;

                        console.log('logged in');
                        console.log(access_token);

                        dispatch(AccessData({AccessToken: access_token,  isAuthenticated: true}));

                          document.getElementById('logoutBtn').style.display="block";
                          document.getElementById('iconMenu').style.display="block";
                        document.getElementById('drawerBar').style.display="block";
                      document.getElementById('mainCont').style.display="block";

                          console.log("AS:" + authstate);
                          setAccessToken(access_token);
                          console.log(access_token);
                          localStorage.setItem('AccessToken', access_token);
                          localStorage.setItem('isAuthenticated', 'true');

                          props.parentCallback({AccessToken: access_token, isAuthenticated: true});

                        
                        
                          redirect_loggedin();
                        
                  }
                  else{
                        setResponse(res.status);
                  }
                 }

              }
              else{
                setResponse("Enter an OTP Code");
              }


            }}
          >Verify OTP</Button>

        </div>
              <Box mt={8}>
                <Copyright />
              </Box>
            
            </Container>




    )


  }

  if(DisplayOTPSetup){


    return(



      <Container  component="main" maxWidth="xs">
   
      <CssBaseline />
      <div  className={classes.paper}>
        <Avatar id="avataBox" className={classes.avatar}>
          <LockOutlinedIcon id="lockoutIcon"/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Scan QR Code in your Auth App
        </Typography>

        <Typography component="h1" variant="h5">
          {Response}
        </Typography>
      
   
          <QRCode value={OTPURI}></QRCode>
          <div>{OTPSecret}</div>

   
          <TextField
            variant="outlined"
            type="number"
            margin="normal"
            defaultValue="Normal"
            value={OTPCode}
            required
            fullWidth
            id="myotp"
            label="OTP Code"
            name="myotp"
            autoFocus
            onChange={(e)=>{
   
            
              //FieldData[item] = e.target.value;
               setOTPCode(e.target.value);

               
               
          

         }}
          />


        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={async () =>{
              // history.push("/");

              if(OTPCode){

              //let hashed_password = sha256(UserPassword);
              //setUserHash(hashed_password);

              //console.log('UserName:' + UserName + 'Hash: ' + hashed_password);
              //post_login(UserName, hashed_password);

              //post_otp(OTPCode, enc_token)
              console.log('snding otp code:' + OTPCode);
              console.log('Enc Auth Code: ' + EncToken);

              let doc_json = {
                username: UserName,
                otp_code: OTPCode,
                enc_token: EncToken
              }

              let res =  await post_otp_setup(UserName, OTPCode, EncToken);
              console.log(res);

                  //console.log(res.status);
                  if(res){
                  if(res.status === 'otp_ok'){
                        let access_token = res.token;

                        console.log('logged in');
                        console.log(access_token);

                        dispatch(AccessData({AccessToken: access_token,  isAuthenticated: true}));

                          document.getElementById('logoutBtn').style.display="block";
                          document.getElementById('iconMenu').style.display="block";
                        document.getElementById('drawerBar').style.display="block";
                      document.getElementById('mainCont').style.display="block";

                          console.log("AS:" + authstate);
                          setAccessToken(access_token);
                          console.log(access_token);
                          localStorage.setItem('AccessToken', access_token);
                          localStorage.setItem('isAuthenticated', 'true');

                          props.parentCallback({AccessToken: access_token, isAuthenticated: true});

                        
                        
                          redirect_loggedin();
                        
                  }
                  else{
                        setResponse(res.status);
                  }
                 }

              }
              else{
                setResponse("Enter an OTP Code");
              }


            }}
          >Verify OTP</Button>

        </div>
              <Box mt={8}>
                <Copyright />
              </Box>
            
            </Container>


    );
  }


  if(DisplaySignIn){
  return (
    <Container  component="main" maxWidth="xs">
   
      <CssBaseline />
      <div  className={classes.paper}>
        <Avatar id="avataBox" className={classes.avatar}>
          <LockOutlinedIcon id="lockoutIcon"/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <Typography component="h1" variant="h5">
          {Response}
        </Typography>
      
   
     

   
          <TextField
            variant="outlined"
            margin="normal"
            value={UserName}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            //autoComplete="email"
            autoFocus
            onChange={(e)=>{
   
            
              //FieldData[item] = e.target.value;
               setUserName(e.target.value);
               
          

         }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={UserPassword}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
        
       
            onChange={(e)=>{
   
                 //console.log('testing:');
                 //console.log(e.target.value);
                 //FieldData[item] = e.target.value;
                  setUserPassword(e.target.value);
                  //console.log(e.target.value);
                 
             
   
            }}
          />
          

      

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() =>{
              // history.push("/");

              if(UserPassword){

              let hashed_password = sha256(UserPassword);
              setUserHash(hashed_password);

              console.log('UserName:' + UserName + 'Hash: ' + hashed_password);
              post_login(UserName, hashed_password);
              }
              else{
                setResponse("Enter a Password");
              }


            }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link variant="body2"
              
              onClick={() =>{
             history.push("/forgotpassword");

            

            }}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link  variant="body2"
               onClick={() =>{
               history.push("/signup");

            }}
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>


       
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    
    </Container>
  );
  }
}
