
import React, {useCallback, useState, useMemo, useEffect} from 'react'
import dotenv from  'dotenv'

import {useDropzone} from 'react-dropzone'

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

//import { Document, Page } from 'react-pdf';

// using CommonJS modules
// import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';





import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
//import { FullFileBrowser } from 'chonky';
import { ChonkyActions, FileBrowser, FullFileBrowser, FileNavbar, FileToolbar, FileList } from 'chonky';
import OpenInNewIcon from '@material-ui/icons/OpenInNew'



//import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { DataGrid } from '@material-ui/data-grid';
import MuiDrawer from '@mui/material/Drawer';


// Somewhere in your `index.ts`:


import clsx from 'clsx';
import { styled, makeStyles, useTheme } from '@material-ui/core/styles';
//import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import LogOutIcon from '@mui/icons-material/Logout';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import PaidIcon from '@mui/icons-material/Paid';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Splitter, { SplitDirection } from '@devbookhq/splitter'
 


//Nav Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@material-ui/icons/Description';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SyncIcon from '@mui/icons-material/Sync';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import ArchiveIcon from '@mui/icons-material/Archive';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';


import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';


import logo from './recon_logo.svg';
import './App.css';
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';

import SignIn from './templates/signin';
import SignUp from './templates/signup';
import ForgotPassword from './templates/forgotpassword';

import DocumentListing from './templates/documentlisting';
import ArchiveDocumentList from './templates/historylisting';
import OpenDocument from './templates/opendocument';
import OpenInvoice from './templates/openinvoice';
import OpenInvoice2 from './templates/openinvoice2';
import OpenCustom from './templates/opencustom';
import OpenArchiveDocument from './templates/openarchivedocs';
import FileSystemNavigator from './templates/treeview';
import TrainDocument from './templates/traindocument';
import MyEditor from './templates/editor';
import IPDFEditor from './templates/ipdfeditor';
import AdminCentre from './templates/admincentre.js';
import ErpLink from './templates/erplinksettings';

import CheckLoggedIn from './templates/checkloggedin.js';
import  SuperAdmin from './templates/SuperAdmin';
import  API from './templates/API';
import AddCompany from './components/AddCompany';
import AddMailbox from './components/AddMailbox';
import ProcessingQueue from './templates/processing_queue';
import ProcessData from './templates/processdata';
import OpenFile from './templates/openfile';
import ProcessInvoice from './templates/processinvoice';
import Transactions from './templates/transactions';
import SyncData from './templates/sync_data';

import TrainingQueue from './templates/training_queue';


//import MainAdmin from './templates/MainAdmin';
import { v4 as uuidv4 } from 'uuid';

import jwt from 'jwt-decode';
import moment from 'moment';


import AddUser from "./components/AddUser";
import EditUser from "./components/EditUser";
import EditCompany from "./components/EditCompany";
import UserList from "./components/UserList";
import UserDetail from "./components/UserDetail";
import CompanyDetail from "./components/CompanyDetail";
import AdminEditUser from './components/AdminEditUser';

import AdminUserDetail from './components/AminUserDetail';
import AdminList from './components/AdminList';
import UserManagement from './components/UserManagement';
import CompanyManagement from './components/CompanyManagement';
import UserCard from './components/UserCard';
import AdminScreensuper from './templates/AdminScreensuper';
import AdminUserManagement from './components/AdminUserManagement';
import AdminScreen from './templates/AdminScreen';
import UploadNew from './components/UploadNew';

import ProtectedRoute from './templates/protectedroutes.js';



//redux
import { useSelector, useDispatch } from 'react-redux';
import { loggedin,loggedout, AccessData } from './redux/authSlice';



import { Domain, SingleBedOutlined } from '@material-ui/icons';

import jwt_decode from "jwt-decode";
import Dashboard from './templates/Dashboard';
import {Context} from "./templates/Context";
import Tooltip from '@mui/material/Tooltip';
import { create } from '@mui/material/styles/createTransitions';








//let server_url = 'http://127.0.0.1:3066';
//let server_url = 'http://127.0.0.1:3002';

//let server_url = 'https://automate.isnet.co.za';
let server_url = process.env.REACT_APP_SERVER_URL;

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});



const useStyles = makeStyles((theme) => ({

  button:{

    background:'#2E8A7C',
    color: 'white',
    padding: 5,
    paddingRight:12,
    paddingLeft: 12, 
    margin: 5,
    minWidth: 90,

  },

  
  
  table: {
    minWidth: 400,
    maxWidth: 400,
    
    
  },

  

  root: {
    
    display: 'flex',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },'& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  appBar: {
   
    background:'#535353',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    
    
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    
  },
  hide: {
    display: 'none',
  },
  drawer: {
    
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    color: 'white',
    //textTransform:'uppercase',
    background:'#545454b4',
    width: drawerWidth,
   
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    
     
  },
  drawerClose: {
    
    background:'#545454b4',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    
    flexGrow: 1,
    padding: theme.spacing(3),
    
  },
  instructions: {
    color: 'red',
    fontFamily: 'Arial',
    fontSize:20,
    textTransform:'uppercase',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  
}));

setChonkyDefaults({ iconComponent: ChonkyIconFA });



function getSteps() {
  return ['Upload a Statement', 'Validate Statement', 'Create Recon'];
  
}



function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Upload a statement file';
    case 1:
      return 'Validate the data detected on the Statement';
    case 2:
      return 'Create the recon';
    default:
      return 'Unknown step';
  }
}



export function HorizontalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    //return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    


    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };



  return (
    <div className="container" >
   
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              {isStepOptional(activeStep) && ( 
                <Button
                  variant="contained"
                  //color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}

              <Button
                variant="contained"
                //color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  console.log(props);
  const [Authenticated, setAuthenticated] = useState();
  const [UserName, setUserName] = useState();
  const LOCAL_STORAGE_KEY = "users";
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults]= useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [usersCompany, setusersCompany]= useState();
  

  //Retrieve Contacts
  const retrieveUsers = async () => {
  //  const response = await api.get("/users");
  //  return response.data;
  }

 

  const AddUserHandler = async (user) => {
    console.log(user);
    const request = {
      id: uuidv4(),
      ...user
    }
    const response =  () => {

    }
  }


  const UpdateUserHandler = async (user) => {
    const request = {...user}
    const response= () => {

    }
}

   

  const SearchHandler = (searchTerm)=>{
    setSearchTerm(searchTerm);
    if (searchTerm !== ""){
      const newUserList = users.filter((user)=> {
       return Object.values(user).join("").toLowerCase().includes(searchTerm.toLowerCase());
      });
      setSearchResults(newUserList);
    }else{
      setSearchResults(users);
    }
  };

  useEffect(() => {
    //const retriveContacts = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
   // if (retriveContacts) setContacts(retriveContacts);
   const getAllUsers = async () => {
     const allUsers = await retrieveUsers();
     if (allUsers) setUsers (allUsers);
   };
   getAllUsers();
  }, []);

  useEffect(() => {
   // localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(contacts));
  }, [users]);

  

            //setup selector & dispatch for redux
            const authstate = useSelector((state) => state.authstate.value);
            const dispatch = useDispatch()
  




  useEffect(()=>{
    setAuthenticated(props.Authenticated);
    setUserName(props.UserName);
    setusersCompany(props.CompanyName);
    console.log('setting: ' + props.Authenticated);

  });



  const history = useHistory();

 

  

  const handleDrawerOpen = () => {
    
    
    setOpen(true);
   

    
  };

  const handleDrawerClose = () => {
    setOpen(false);
 
   
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  
  
 
  return (
  
    <Box className={classes.root} sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar
        id="aBar"
        position="fixed"
        open={open}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >


        <Toolbar>
          <IconButton
            color="inherit"
            id="iconMenu"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
         <img 
         onClick={(img) => history.push('/')}
          className="isnlogo" src={logo} alt="Logo" />
          
          <Typography variant="h6" noWrap>
            {/* <CheckLoggedIn></CheckLoggedIn> */}
            { Authenticated  ? <div id="userTab">Logged in as User: {UserName}</div>
                             : <div></div>
            }
                
           
            
          </Typography>
          
          <LogoutIcon
            id="logoutBtn"
          
              onClick={()=> {
                props.parentCallback();
                localStorage.clear(); 
                setUserName(); 
                setAuthenticated(0);
                
                
                dispatch(AccessData({AccessToken: '',  isAuthenticated: false}));
                                 
                history.push('/signin');
              
              }
              }
            />
            
        </Toolbar>
      </AppBar>
      <Drawer
      
       id="drawerBar"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
     <DrawerHeader>
        <div className={classes.toolbar}>
        
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        </DrawerHeader>

        <List id="my_list">


        
        {/*
        <ListItem button onClick={ (text) => {
                  
                  history.push('/upload');
                  
            }} >
              <CloudUploadIcon></CloudUploadIcon>
              <ListItemText primary="UploadIt" />
              </ListItem> 
          */}
          
{/* {['Upload Documents', 'Active Documents', 'Archive', 'Train Documents', 'Editor', 'Sync Data', 'Erp Link Settings', 'AdminCentre', 'Super Admin'].map((text, index) => (*/}
         {/* {['Upload Documents','Upload New', 'Processing Queue' , 'Active Documents', 'Archive', 'Train Documents', 'Sync Data', 'Erp Link Settings', 'AdminCentre', 'Super Admin', 'API'].map((text, index) => (
          */}
           {['Upload New', 'Processing Queue' , 'Active Documents', 'Archive', 'Train Documents', 'Sync Data', 'Erp Link Settings', 'AdminCentre', 'Super Admin', 'Training Queue','API', 'Transactions'].map((text, index) => (
          <ListItem  button key={text}  onClick={ (index) => {
                  console.log(text);
                  console.log(index);
                  console.log('here');
                 
                
               // var my_upload = document.getElementById("my_list").children[0];
               // var my_upload_Box= document.getElementById("bodyBoxU");

                var my_upload_new = document.getElementById("my_list").children[0];
                var my_upload_new_Box= document.getElementById("bodyBoxU");

                var my_processing_queue = document.getElementById("my_list").children[1];
                var my_processing_queue_Box= document.getElementById("bodyBoxU");

                var my_activeDocs = document.getElementById("my_list").children[2];
                var my_activeDocs_Box= document.getElementById("bodyBoxU");
                var my_archive = document.getElementById("my_list").children[3];
                var my_archive_Box= document.getElementById("bodyBoxU");
                var my_train = document.getElementById("my_list").children[4];
                var my_train_Box= document.getElementById("bodyBoxU");
               // var my_editor = document.getElementById("my_list").children[4];
               // var my_editor_Box= document.getElementById("bodyBoxU");
                var my_sync = document.getElementById("my_list").children[5];
                var my_sync_Box= document.getElementById("bodyBoxU");
                var my_erp = document.getElementById("my_list").children[6];
                var my_erp_Box= document.getElementById("bodyBoxU");
                var my_admin = document.getElementById("my_list").children[7];
                var my_admin_Box= document.getElementById("bodyBoxU");
                var my_superadmin = document.getElementById("my_list").children[8];
                var my_training_queue = document.getElementById("my_list").children[9];
                var my_api = document.getElementById("my_list").children[10];

                var my_transactions = document.getElementById("my_list").children[11]; 



                if(text === 'Processing Queue'){
                  // console.log(c);
                   my_processing_queue.classList.add("active");
                   history.push('/processing_queue');
                  
                 
                  
                } else{

                  my_processing_queue.classList.remove("active");
                
                  }

                
                if(text === 'API'){
                  // console.log(c);
                   my_api.classList.add("active");
                   history.push('/api');
                  
                 
                  
                } else{

                  my_api.classList.remove("active");
                
                  }
                 
                  if(text === 'Upload Documents'){
                  // console.log(c);
                   //my_upload.classList.add("active");
                   history.push('/upload');
                  
                 
                  
                } else{

                  //my_upload.classList.remove("active");
                
                  }

                  if(text === 'Upload New'){
                  // console.log(c);
                   my_upload_new.classList.add("active");
                   history.push('/uploadnew');
                  
                 
                  
                } else{

                  my_upload_new.classList.remove("active");
                
                  }

                  if(text === 'Active Documents'){
                    history.push('/documentlisting');
                    my_activeDocs.classList.add("active");
                 
                  
                 
                  
                } else{

                  my_activeDocs.classList.remove("active");
                  

                  }

                  if(text === 'Archive'){
                    history.push('/archivelisting');
                    my_archive.classList.add("active");
             
                  
                 
                  
                } else{

                  my_archive.classList.remove("active");
                   

                  }

                  if(text === 'Train Documents'){
                history.push('/traindocument');
                my_train.classList.add("active");
                   
                  
                 
                  
                } else{

                  my_train.classList.remove("active");

                  }
                  /*if(text === 'Editor'){
                history.push('/editor');
                my_editor.classList.add("active");
                  
                  
                 
                  
                } else{

                  my_editor.classList.remove("active");

                  }*/

                  if(text === 'AdminCentre'){
                history.push('/admincentre');
                
                my_admin.classList.add("active");
                   
                  
                 
                  
                } else{

                  my_admin.classList.remove("active");
                  }


                  if(text === 'Super Admin'){
                history.push('/superadmin');
                
                my_superadmin.classList.add("active");
                   
                  
                 
                  
                } else{

                  my_superadmin.classList.remove("active");
                  }

                  if(text === 'Erp Link Settings'){
                history.push('/erplinksettings');
                my_erp.classList.add("active");
                   
                  
                 
                  
                } else{

                  my_erp.classList.remove("active");

                  }


                  if(text === 'Training Queue'){
                    history.push('/training_queue');
                    
                    my_training_queue.classList.add("active");
                       
                      
                     
                      
                    } else{
    
                      my_training_queue.classList.remove("active");
                      }




                      if(text === 'Sync Data'){


                        console.log('syncing data....');
                     
                        history.push('/sync_data');
                        
                   
                          
                         
                          
                        }
                    




                      if(text === 'Transactions'){

                        console.log('FF hhere');
                        history.push('/transactions');
                        
                        my_transactions.classList.add("active");
                           
                          
                         
                          
                        } else{
        
                          my_transactions.classList.remove("active");
                          }
                    
                
                  
                  
                  
                  
            }}>
              {/* <ListItemIcon>{index % 2 === 0 ? <CloudUploadIcon /> : <DescriptionIcon />}</ListItemIcon> */}
              <ListItemIcon>{
                 
                    index == 0 ? <Tooltip title="Upload New"><CloudUploadIcon /></Tooltip>
                  : index == 1 ? <Tooltip title="Processing Queue"><AttachEmailIcon /></Tooltip>
                  : index == 2 ? <Tooltip title="Active Documents"><DescriptionIcon /></Tooltip>
                  : index == 3 ? <Tooltip title="Archive"><ArchiveIcon/></Tooltip>
                  : index == 4 ? <Tooltip title="Train Documents"><ModelTrainingIcon /></Tooltip>
                  //: index == 4 ? <Tooltip title="Editor"><EditIcon /></Tooltip>
                  : index == 5 ? <Tooltip title="Sync Data"><SyncIcon /></Tooltip>
                  : index == 6 ? <Tooltip title="ERP Link Settings"><AddLinkIcon /></Tooltip>
                  : index == 7 ? <Tooltip title="Admin Centre"><AdminPanelSettingsIcon /></Tooltip>
                  : index == 8 ? <Tooltip title="Super Admin Centre"><AddModeratorIcon/></Tooltip>
                  : index == 9 ? <Tooltip title="Training Queue"><AssuredWorkloadIcon/></Tooltip>
                  : index == 11 ? <Tooltip title="Transactions"><PaidIcon/></Tooltip>
                  : <RestorePageIcon />
              
              }</ListItemIcon> 
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
               
        
      </Drawer>

      

      <Box component="main" sx={{ flexGrow: 1, p: 3 }} UserName={UserName}>
      <DrawerHeader/>
     
      <main id="containerBox" className={classes.content}>
        <div id="mainCont">

        <Switch>
       
        <ProtectedRoute path="/upload" component={Upload}>
           
          </ProtectedRoute>
          <ProtectedRoute path="/uploadnew" component={UploadNew}>
           
          </ProtectedRoute>
          <ProtectedRoute path="/documentlisting" component={DocumentListing}>
        
          </ProtectedRoute>
         
          <ProtectedRoute path='/archivelisting' component={ArchiveDocumentList}>
         
          </ProtectedRoute>
          <ProtectedRoute path="/opendoc" component={OpenDocument}>
          
          </ProtectedRoute>
          <ProtectedRoute path="/openinvoice" component={OpenInvoice}>
         
          </ProtectedRoute>
          <ProtectedRoute path="/openinvoice2" component={OpenInvoice2}>
         
         </ProtectedRoute>
          <ProtectedRoute path="/opencustom" component={OpenCustom}>
         
          </ProtectedRoute>
          <ProtectedRoute path="/openarch" component={OpenArchiveDocument}>
        
          </ProtectedRoute>          
          <ProtectedRoute path="/traindocument" component={TrainDocument}>
          </ProtectedRoute>
          <ProtectedRoute path="/editor" component={MyEditor}>
          </ProtectedRoute>
          <ProtectedRoute path="/admincentre"  >
              <AdminScreen {...props} UserName={UserName} />
          </ProtectedRoute>
          
            <ProtectedRoute path="/superadmin" component={AdminScreensuper}>
            </ProtectedRoute>
            
         
          
          <ProtectedRoute path="/erplinksettings" component={ErpLink}>
          </ProtectedRoute>
          <ProtectedRoute path="/" exact>    
            <Dashboard {...props} UserName={UserName} />      
          </ProtectedRoute>
          <ProtectedRoute path="/add" component={AddUser}>
          </ProtectedRoute>
          <ProtectedRoute path="/addcompany" component={AddCompany}>
          </ProtectedRoute>

          <ProtectedRoute path="/addmailbox" component={AddMailbox}>
          </ProtectedRoute>

          <ProtectedRoute path="/api" component={API}>
          </ProtectedRoute>


          <ProtectedRoute path="/processing_queue" component={ProcessingQueue}>
          </ProtectedRoute>
          <ProtectedRoute path="/process_data" component={ProcessData}>
          </ProtectedRoute>
          <ProtectedRoute path="/openfile" component={OpenFile}>
            {/* <OpenDocument></OpenDocument> */}
          </ProtectedRoute>


          <ProtectedRoute path="/sync_data" component={SyncData}>
          </ProtectedRoute>


          <ProtectedRoute path="/transactions" component={Transactions}>
          </ProtectedRoute>

          <ProtectedRoute path="/process_invoice" component={ProcessInvoice}>
        
        </ProtectedRoute> 

        <ProtectedRoute path="/training_queue" component={TrainingQueue}>
        
        </ProtectedRoute> 

    
         
          
          {/*<ProtectedRoute path="/edit" 
      component={EditUser} {...props} UpdateUserHandler={UpdateUserHandler}

          />*/}

          <ProtectedRoute path="/edit/:id" component={EditUser}/>
          <ProtectedRoute path="/editcompany/:id" component={EditCompany}/>
         <ProtectedRoute path="/user/:id" component={UserDetail}/>
         <ProtectedRoute path="/company/:id" component={CompanyDetail}/>
         {/*<ProtectedRoute path="/superadmin" component={SuperAdmin}>
          </ProtectedRoute>*/}
          {/*<ProtectedRoute path="/superadmin" exact component={UserCard}>          
          </ProtectedRoute>
          
          <ProtectedRoute path="/superadmin" component={UserList}>          
          </ProtectedRoute>*/}
          {/*<ProtectedRoute path="/mainAdmin"
            render={(props) =>(
              <UserList 
              {...props} 
              contacts={searchTerm.length <1 ? users : searchResults} 
              getUserId={RemoveUserHandler}
              term= {searchTerm} 
              searchKeyword={SearchHandler}
              /> 
            )}
          />*/}

          
        </Switch>
      
        
      </div>
        

       
     
        
        
        

        
             
      </main>
      </Box>
      </Box>
   
  );
}





export function MyTableHeader(props){


  //Loop through single elements
  let singleFieldData = props.jsonData;


  const [DocTag,setDocTag] = useState(props.DocTag);

  const[FieldData,setFieldData] = useState([props.jsonData]);
  let keys = Object.keys(props.jsonData);
  const[Fields,setFields] = useState(keys);

  const [TestField,setTestField] = useState();
  const [RunOnce, setRunOnce] = useState(true);

  const [DocName,setDocName] = useState();
  //const [DocSender,setDocSender] = useState();
  //const [DocTag,setDocTag] = useState();


  
  useEffect(() => {

    console.log('Field Data');
    let fieldData = props.jsonData;
    let keys = Object.keys(props.jsonData);


    let docTag = props.DocTag;
    setDocTag(docTag);

    //setDocName(props.document_name);
    //setDocTag(props.doc_tag);
    //setDocSender(props.doc_sender);

    

    if(keys.indexOf('DocName') < 0 ){
        keys.push('DocName');
    }

    

    console.log(props.jsonData);
    //console.log(keys);
    setFields(keys);
    setFieldData(fieldData);
     // let jdata = props.jsonData;
     console.log(FieldData);

      //f(RunOnce){
      //  keys.push('DocName');
       // setRunOnce(false);
      //}

      
      
    },[props])

  //},[props])





  return(

  <div >

      {/*
      <div m={2} p={2} padding={3}>

              <p style={{fontSize:10, paddingTop: 10, margin:0, textAlign:'left', color:'#2E8A7C',}}>TemplateName</p>
              <TextField style={{marginTop: 0, width:'100%'}} m={2} p={2} id="DocName"  variant="outlined"
              id='DocName'
              //value={FieldData[item]}
              value={DocName}

              onChange={(e)=>{

                  console.log(e.target.value);
                  setDocName(e.target.vallue);
                  //Send Header Data back to parent
                  //props.parentCallback({headerData: FieldData});
                  props.parentCallback({DocName: DocName});

              }}
              ></TextField>
              
              <p style={{fontSize:10, paddingTop: 10, margin:0, textAlign:'left', color:'#2E8A7C',}}>DocTag</p>
              <TextField style={{marginTop: 0, width:'100%'}} m={2} p={2} id="DocTag"  variant="outlined"
              id='DocTag'
              //value={FieldData[item]}
              value={DocTag}

              onChange={(e)=>{

                  console.log(e.target.value);
                  setDocTag(e.target.vallue);
                  //Send Header Data back to parent
                  //props.parentCallback({headerData: FieldData});
                  props.parentCallback({DocTag: DocTag});

              }}
              ></TextField>

<p style={{fontSize:10, paddingTop: 10, margin:0, textAlign:'left', color:'#2E8A7C',}}>DocTag</p>
              <TextField style={{marginTop: 0, width:'100%'}} m={2} p={2} id="DocSender"  variant="outlined"
              id='DocSender'
              //value={FieldData[item]}
              value={DocSender}

              onChange={(e)=>{

                  console.log(e.target.value);
                  setDocSender(e.target.vallue);
                  //Send Header Data back to parent
                  //props.parentCallback({headerData: FieldData});
                  props.parentCallback({DocSender: e.target.value});

              }}
              ></TextField>

      </div>
            */}

  
        

  {Fields.map((item,i) => (
 
          <div m={2} p={2} padding={3} >



          <p style={{fontSize:10, paddingTop: 10, margin:0, textAlign:'left', color:'#2E8A7C',}}>{item}</p>
         <TextField style={{marginTop: 0, width:'100%'}} m={2} p={2} id={item}  variant="outlined"
         id={'field_id_' + item}
         
         //value={FieldData[item]}
         value={FieldData[item]}
         

        
       
         onChange={(e)=>{

              console.log('testing:');
              console.log(e.target.value);
              //FieldData[item] = e.target.value;

              console.log(FieldData);
              let newData = FieldData;
              newData[item] = e.target.value;
              //setFieldData({...FieldData, newData});
              setFieldData({...FieldData});
              FieldData[item] = e.target.value;
              console.log(newData);
              console.log(FieldData);
              setTestField(e.target.value);
              //Send Header Data back to parent
              props.parentCallback({headerData: FieldData});
          

         }}
          ></TextField>
         
          </div>
     ))
   
  }
  </div>

  );
}

//}



export function MyDataGrid(props) {


  const [RowHeader, setRowHeader] = useState([]);
  const [RowData, setRowData] = useState([]);
  const [TableName, setTableName] = useState();
  const [ColumnData, setColumnData] = useState([])

  const [deletedRows, setDeletedRows] = useState([]);

  const [HeaderData, setHeaderData] = useState([]);


  const [SingleFieldData, setSingleFieldData] = useState([]);

  const [SingleRowData, setSingleRowData] = useState([]);
  const [SingleColumnData, setSingleColumnData] = useState([]);

  const [DocName,setDocName] = useState();
  const [DocSender,setDocSender] = useState();
  const [DocTag,setDocTag] = useState();

  const classes = useStyles();



  async function HeaderCallback(data, DocName,DocTag,DocSender){


    console.log(data);
    setDocName(DocName);
    setDocTag(DocTag);
    setDocSender(DocSender);
    console.log("xx:" + DocSender);
    setHeaderData(data);
  }
  

  useEffect(() => {
  console.log(props)

    let jdata = props.jsonData;



    setTableName(props.TableName);
    setSingleFieldData(props.SingleFieldData); 

    setDocName(props.document_name);
    setDocSender(props.doc_sender);
    setDocTag(props.DocTag);




    //Setup Single Line Data
    console.log('setting:' + JSON.stringify(SingleFieldData));



    setSingleRowData(props.SingleFieldData);
    let mySingleObject = new Object; 
    let singleData = props.SingleFieldData;

    let y=0; const yMax = singleData.length -1; for(; y<=yMax; y++){

      singleData[y]['id'] = y; //Add id key to each field


      let myskeys = Object.keys(singleData[x]);

     
     
      //Add Headers
     myskeys.forEach((item) => {
     let singleheaderdata = {
        field: item,
        headerName: item,
        //rowHeight:30,
        width: 150,
        editable: true,
      };

      mySingleObject[item] = singleheaderdata;

      //Add Rows
      //{ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
      //console.log(jdata[x]);
      




    })

    if(y == yMax){
      
      
   

       //add custom columns
       mySingleObject["ReconName"] = {
        field: "ReconName",
        headerName: "ReconName",
        width: 150,
        editable: true,
      };

      
        


      let singleitems = Object.keys(mySingleObject);
      let singlecolArray = new Array();
      let a=0; const aMax = singleitems.length - 1; for(; a <= aMax; a++){
          singlecolArray.push(mySingleObject[singleitems[a]]);

          if(a===aMax){

            console.log(singlecolArray);
            setSingleColumnData(singlecolArray);
          }

        }
     

      }


    }



    //set Row Data

    setRowData(jdata);

    //Setup Headers
    let toplen = 0;

    let myObject = new Object;
    let rowArray = new Array;
    console.log("L: " + jdata.length);
    let x=0; const xMax = jdata.length -1; for(; x<=xMax; x++){

          jdata[x]['id'] = x; //Add id key to each field


          let mykeys = Object.keys(jdata[x]);

         
         
          //Add Headers
         mykeys.forEach((item) => {
         let headerdata = {
            field: item,
            headerName: item,
            width: 150,
            editable: true,
          };

          myObject[item] = headerdata;

          //Add Rows
          //{ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
          //console.log(jdata[x]);
          




        })

        if(x == xMax){
          
          
       

           //add custom columns
           myObject["note"] = {
            field: "Note",
            headerName: "Note",
            width: 150,
            editable: true,
          };

          
            


          let items = Object.keys(myObject);
          let colArray = new Array();
          let z=0; const zMax = items.length - 1; for(; z <= zMax; z++){
              colArray.push(myObject[items[z]]);

              if(z===zMax){

                console.log(colArray);
                setColumnData(colArray);
              }

            }
          
           



          }


        }

         
    



  },[props]);



  const handleRemove = (id) => {
    const newRowData = RowData.filter((row => row.id !== id));
 
    setRowData(newRowData);

    console.log(RowData);


  };


  const handleCellEditCommit = (updateitem) => {

    console.log(updateitem);

    let id = updateitem.id;

    let newRowData = new Array();

    let i=0; const iMax = RowData.length -1; for(; i <= iMax; i++){

      let item = RowData[i];
      let cid = item.id;
      if(id == cid){

        console.log('Match');
        //console.log(row);
        console.log(item);
        let field = updateitem.field;
        let value = updateitem.value;
        item[field] = value;
        //console.log(updateitem);
        
        //update Row witn new Row
        newRowData.push(item);

      } 
      else{

        newRowData.push(item);
      }

      if(i === iMax){
        setRowData(newRowData);
      }

    }

  }

  return (

    <>
  <div style={{ height: 900, width: '100%', display: 'block',  alignItems:'center'}}  >

     

      <div>
         <MyTableHeader jsonData={SingleFieldData} document_name={DocName} DocTag={DocTag} parentCallback = {HeaderCallback}></MyTableHeader>
      </div>

      <div>

  </div>

      
      
   
      <br></br>
  <div style={{ height: 600, width: '100%', display: 'flex',  justifyContent:'center', alignItems:'center', }}>
      <DataGrid
        rows={RowData}
        columns={ColumnData}
        pageSize={50}
        rowHeight={30}
        
        rowsPerPageOptions={[50]}
        //checkboxSelection
        //checkboxSelection={false}
        onCellEditCommit={handleCellEditCommit}
        //disableSelectionOnClick
        //onSelectionModelChange={({selectionModel}) => {
        //  const rowIds = selectionModel.map(rowId => parseInt(String(rowId), 10));
        //  const rowsToDelete = RowData.filter(row => rowIds.includes(RowData.id));
        //  setDeletedRows(rowsToDelete);
        //
        onSelectionModelChange={(ids) => {
          console.log(ids);
          setDeletedRows(ids);
       
        }}

      />
  </div>

      <Divider />
</div>
    <div id="my_wrapper">
      <div id="looksgood">
          <Button 
            variant="contained" 
            className={classes.button}
            //color="primary"
            onClick={()=> {

              var docName = document.getElementById('field_id_DocName').value.length;
              
              console.log(docName);
              if(docName < 1 || docName == undefined){
                alert('Please enter Document Name');
                return
              }else{
                console.log('saving');
                  props.parentCallback({header_data: HeaderData, mydata: RowData});
                  alert("Recon Successfully completed!");
                  window.location.reload();
                  
                  

              }
                  

            }}
            >
          Looks Good - Lets Start a Recon
        </Button>
      
      </div>

      <div id="deleteBtn" style={{display: 'block',  justifyContent:'center', alignItems:'center'}}>
          <Button
              variant="contained" 
              //color="primary" 
              className={classes.button}
              onClick={() => { 
                  

                  console.log("deleting:" + JSON.stringify(deletedRows));
                  //handleRowSelection();



                  deletedRows.forEach((item) => {

                    console.log('removing: ' + item);
                    handleRemove(item);

                })

                
                }}>
                  Delete Row
          </Button>
       </div>
    </div>
    
 </>
  );
}







function BasicTable(props) {

  const classes = useStyles();


  const [RowHeader, setRowHeader] = useState([]);
  const [RowData, setRowData] = useState([]);
  const [TableName, setTableName] = useState();
  

  useEffect(() => {
  console.log(props)

    let jdata = props.jsonData;
    setTableName(props.TableName);
    setRowData(jdata);
    console.log('Updating Table Data');

    //Get Headers
    let toplen = 0;
    let x=0; const xMax = jdata.length -1; for(; x<=xMax; x++){

          let mykeys = Object.keys(jdata[x]);
          console.log(mykeys.length)
          if(parseInt(mykeys.length) > parseInt(toplen)){
            setRowHeader(mykeys);
            toplen = parseInt(mykeys.length);
          }
          console.log(mykeys);

          if(x === xMax){
            console.log('RowHeader');
            console.log(RowHeader);
          }
    }



  },[props]);




  return (

 
    <TableContainer component={Paper}>
      


         <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
              <TableCell>{TableName}</TableCell>
          </TableRow>
          <TableRow>
                <TableCell>Date</TableCell>
          </TableRow>

          <TableRow>
                <TableCell>Status</TableCell>
          </TableRow>
          
        </TableHead>
        </Table>


      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>

          

          {RowHeader.map((row,i) => (
                       
                        <TableCell><b>{row}</b></TableCell>
                        

          ))}

       
          </TableRow>
        </TableHead>
        <TableBody>

          {RowData.map((row,i) => (

              <TableRow key={row}>

                {Object.keys(row).map((key) => {

                      return(

                    
                        <TableCell component="th" scope="row">
                          {JSON.stringify(row[key])}

                          </TableCell>
                     

                          )
                  })}

                  </TableRow>
                 

                

              
          ))}
    
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export const MyFileBrowser = () => {

  

  const fileActions = React.useMemo(
    () => [
        ChonkyActions.CreateFolder, // Adds a button to the toolbar
        ChonkyActions.UploadFiles, // Adds a button
        ChonkyActions.DownloadFiles, // Adds a button
        ChonkyActions.CopyFiles, // Adds a button and a shortcut: Ctrl+C
        ChonkyActions.DeleteFiles, // Adds a button and a shortcut: Delete
    ],
    []
);


  const files = [
      { id: 'lht', name: 'projects', isDir: true },
      {
          id: 'mcd',
          name: 'chonky-sphere-v2.png',
          thumbnailUrl: 'https://chonky.io/chonky-sphere-v2.png',
      },
  ];
  const folderChain = [{ id: 'xcv', name: 'projects', isDir: true }];
  return (
      <div style={{ height: 300 }}>
          {/* <FullFileBrowser files={files} folderChain={folderChain} /> */}
       
      

        <FullFileBrowser files={files} folderChain={folderChain} fileActions={fileActions}/>
      </div>
  );
};



async function process_file(filename){


  //let server_url = 'http://127.0.0.1:3002';
  return new Promise(function (resolve,reject){

    try{


                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    

                    fetch(server_url + "/process_file2?filename=" + filename, options)
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })



}


const SingleFileAutoSubmit = (props) => {
  const toast = (innerHTML) => {
    const el = document.getElementById('toast')
    el.innerHTML = innerHTML
    el.className = 'show'
    setTimeout(() => { el.className = el.className.replace('show', '') }, 3000)
  }

  //let server_url = 'http://127.0.0.1:3002';

  const getUploadParams = () => {
    return { url: server_url + '/upload-pdf' }
  }

  



  const handleChangeStatus = async ({ meta, remove }, status) => {
    let myWrapper = document.getElementById('filewrappers');
    if (status === 'headers_received') {
      toast(`${meta.name} uploaded!`)

      let filename = meta.name;
      myWrapper.style.display = "block";
      console.log('process file: ' + filename );
      let mydata = await process_file(filename);

      //return firsi item in the array
      console.log('SSS: ' + JSON.stringify(mydata[0]));
      if(mydata.docname === 'notfound'){
        toast('Document not matched - please train one first');
        myWrapper.style.display = "none";
      }
      else{
        props.parentCallback({mydata: mydata, filename: filename});
        console.log(mydata);
      
      }
 

      //setDocName(mydata.doccname);
      //setDistance(mydata.distance);


      remove()
    } else if (status === 'aborted') {
      toast(`${meta.name}, upload failed...`)
    }
  }

  return (
    <React.Fragment>
      <div id="toast">Upload</div>
      <Dropzone
        classNames="glass"
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent="Drop A File"
        inputContentColor="#2E8A7C"
        id="drop"
        styles={{
          dropzone: { 
            backgroundColor: '#FFFFFF',
            width: 600, 
            height: 200, 
            border:"dotted", 
            borderWidth:2, 
            borderColor:"#2E8A7C", 
            overflow: "hidden", 
            marginTop:20, 
            marginBottom:20, },
          dropzoneActive: { borderColor: 'green' },
          inputLabel:{color:'#2E8A7C', textTransform:"uppercase", letterSpacing:2, fontWeight:300,}
        }}
      />
    </React.Fragment>
  )
}



function PdfViewer(props){





  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [FileName, setFileName] = useState('');

  useEffect(()=>{


          console.log(props);
          //let server_url = 'http://127.0.0.1:3002';
          setFileName(server_url + "/uploads/" +  props.FileName);
          console.log("FNAME: " + FileName);
          

  },[props])

  function onDocumentLoadSuccess({ numPages }) {
    const spinner = document.getElementById('loading-spinner');
    var pages = document.getElementById('pageNumberP');
    
    spinner.style.display='none';
    pages.style.display= 'block';
    setNumPages(numPages);
  }

  function onDocumentLoadError(err){
    console.log(err);
  }

 


  return(


    <div>
            <Document
              file={FileName}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}

            >
              <Page pageNumber={pageNumber} />
            </Document>
            <p id="pageNumberP">Page {pageNumber} of {numPages}</p>
          </div>



  )



}



function Upload(){

      const notify = (mytext) => {
        toast(mytext);
      } 
  
  
        const [JsonData, setJsonData] = useState([]);
        const [SingleFieldData,setSingleFieldData] = useState([]);
        const [FileName, setFileName] = useState();

        const [DocName,setDocName] = useState();
        const [DocSender,setDocSender] = useState();
        const [DocTag,setDocTag] = useState();
        const [DocLink, setDocLink] = useState();
        const [mJsonData,setmJsonData] = useState([]);

        
        
        //function to get data from back upload component
        function callbackFunction(childData){
        
          //this.setState({message: childData})
          console.log('here');
          console.log(childData);
          console.log('here2');

          //Figure this out, got data from page1
          setJsonData(childData.mydata.pages[0].table_data); //table data
          setSingleFieldData(childData.mydata.pages[0].single_field_data); //single field data

          setFileName(childData.filename);
          setDocName(childData.mydata.doc_name);
          setDocTag(childData.mydata.doc_tag);
          setDocSender(childData.mydata.doc_sender);
          setDocLink(childData.mydata.doc_link);



          if(childData.mydata.doc_tag){

                console.log('Generating CSV FILE');
                console.log(childData);
                setmJsonData(childData);
          }
        
        }
        
  
          async function create_data(dataObject){
  
  
            return new Promise(function (resolve,reject){
  
              try{

                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                          headers: {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json'
                          },
                          insecure: true,
                          rejectUnauthorized: false
                        }

                        let myheaders = {
                          'Authorization': 'Bearer ' + token,
                          'Content-Type': 'application/json'
                        };
                          
                
                          fetch(server_url + "/create_data",{
                            method: 'POST',
                            //headers: { 'Content-Type': 'application/json' },
                            headers: myheaders,
                            body: JSON.stringify(dataObject)
                            })
                              .then(response => response.json())
                              .then(function(data){
                                      console.log('xxx');
                                      console.log(data);
                                      console.log('xxx2');
                                      //resolve(data);
                              
                              });
              }
              catch(err){
                      console.log(err);
                      reject(err);
              }
  
              })
  
  
  
  
          }
  
            async function DataTableCallback(childData){
  
              console.log('here dt callback');
              console.log(childData);
              let header_data = childData.header_data;
              console.log('HEADER');
              //console.log(header_data.headerData);



              let header = header_data.headerData;
              let docName = header.DocName;
              //let docName = DocName;
              let docTag = DocTag;
              let docSender = DocSender;
              let docLink = DocLink;


              let row_data = childData.mydata;
  
              let mobj = {
                docname: docName,
                docSender: docSender,
                docTag: docTag,
                docLink: docLink,
                single_data: header,
                row_data: row_data
              }
  
              console.log(mobj);
  
              //Post to create recon
              let resp = await create_data(mobj);
              console.log(resp);
  
            }
  


            async function create_csv(dataObject){
  


              console.log(dataObject);
  
              return new Promise(function (resolve,reject){
    
                try{
  
                          const token = localStorage.getItem('AccessToken'); 
                          let options = {
                            headers: {
                              'Authorization': 'Bearer ' + token,
                              'Content-Type': 'application/json'
                            },
                            insecure: true,
                            rejectUnauthorized: false
                          }
  
                          let myheaders = {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json'
                          };
                            
                  
                            fetch(server_url + "/generate_csv",{
                              method: 'POST',
                              //headers: { 'Content-Type': 'application/json' },
                              headers: myheaders,
                              body: JSON.stringify(dataObject)
                              })
                                .then(response => response.json())
                                .then(data=>{
                                         console.log('xxx');
                                        console.log(data);
                                        console.log('xxx2');
                                        resolve(data);
                                
                                });
                }
                catch(err){
                        console.log(err);
                        reject(err);
                }
    
                })
    
    
    
    
            }

            async function generateCSV(){


              console.log(mJsonData);
              let csv_data = await create_csv(mJsonData);
              console.log('here');
              console.log(csv_data.filename);


                              const token = localStorage.getItem('AccessToken'); 
                              let options = {
                              headers: {
                                  'Authorization': 'Bearer ' + token,
                                  Accept: 'application/pdf'
                              },
                              insecure: true,
                              rejectUnauthorized: false


                              }



                              const requestOptions = {
                                method: 'GET',
                                headers: { 'Content-Type': 'text/csv',
                                'Authorization': 'Bearer ' + token
                                 }
                                };
                                
                                fetch(server_url + "/get_pdf_file?file_name=" + csv_data.filename , requestOptions)
                                .then((res) => {
                                    return res.blob();
                                })
                                .then((blob) => {
                                    const href = window.URL.createObjectURL(blob);
                                    const link = document.createElement('a');
                                    link.href = href;
                                    link.setAttribute('download', csv_data.filename + '.csv'); //or any other extension
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                })
                                .catch((err) => {
                                    return Promise.reject({ Error: 'Something Went Wrong', err });
                                })

              



            }
  
            

             

           
  
  
  //FILE UPLOAD
  
    return (


      <div className="App" id='bodyBoxU'>
  
      
  
    
  
     
  
      {/*
      <Link to="/upload">Home</Link>
      <Link to="/signin">Signin</Link>
      */}
  
        
    
        {/* Test this component to step through the main doc upload / extract process */}
       {/* <HorizontalLinearStepper></HorizontalLinearStepper>*/}
      <div id="bodyBoxHome" classNames="glass_no_bg">
        <div><h3>Upload Trained Document</h3></div>
        </div>
      
  
  
        <div id="pdf-wrapper">
        <SingleFileAutoSubmit parentCallback = {callbackFunction}></SingleFileAutoSubmit>
        </div>


     

        {DocTag==='CSV' &&
       <div id='csv_wrapper'>

            File Detected as: {DocTag}

            <Button onClick={generateCSV}>Download CSV</Button>


        </div>

       
       }
  
       
    
  
        <div id="filewrappers">
        <div id="loading-spinner">
        <div class="spin-icon"></div>
      </div>
        <Splitter id="sppliter" direction={SplitDirection.Horizontal}>
      <div id="firstdoc" className="container"> 
      <div>

        WWWW
        <PdfViewer FileName={FileName}></PdfViewer>
        </div>
  
      </div>



       

        <div id="seconddoc">
     
    
        <div id="infoTable" className="container" style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: "100%"}}>
          <MyDataGrid  TableName="SupplierStatement" document_name={DocName} doc_tag={DocTag} doc_sender={DocSender} SingleFieldData={SingleFieldData} jsonData={JsonData} parentCallback = {DataTableCallback}></MyDataGrid>
        </div>
  
        </div>
        
        </Splitter>
   
  
  
     
  
        </div>
  
        </div>
  
  
  
  
  
    );
    //END OF FILE UPLOAD
  
  
  
}

export const getSession = () => {


    let token = localStorage.getItem('AccessToken');

    console.log(token);
    //return(token);
    
    let session;
    if(token.status === 'failed'){
        console.log('Login failed');
    
        return('failed');
    }
    else{
    let session = token;
    console.log('login exists')
    console.log(session);
     
    return(session);
    }
}






function App(props) {




          const history = useHistory();

          console.log(props);
          const [LoggedIn, setLoggedIn] = useState(0);
          const [Authenticated, setAuthenticated] = useState(0);
          const [UserName, setUserName] = useState();
          const [UserData, setUserData] = useState();



          //setup selector & dispatch for redux
          const authstate = useSelector((state) => state.authstate.value);
          const dispatch = useDispatch()


       

          //const myhistory = useHistory();
          

          async function redirect_signout(){

          
              console.log('signing out');
              localStorage.clear();
              //myhistory.push('/signin');
          
          }
        



          useEffect(()=>{



              checkAuthenticated();
              
        },[])


        /*
        const myHistory = useHistory();
        useEffect(()=>{

          if(checkTokenExpiry()){
            //history.push('/signin');
            //history.push('/signin');
            myHistory.push('/signin')
          }
        })
        */

        
        useEffect(()=>{

          console.log('ReduxAuthenticated');
          console.log("AUTH: " + JSON.stringify(authstate));
          let r_accesstoken = authstate.AccessToken;
          let r_isauthenticated = authstate.isAuthenticated;

          if(r_isauthenticated == true){
          setAuthenticated(1);
          console.log('Here1');
          }
          
          console.log("redux: " + r_accesstoken + " isAuth:" + r_isauthenticated );

        });



          //Watch user auth
        
          useEffect(()=>{

           // if(Authenticated == 0){
             if(authstate.isAuthenticated == false){
         
             // document.getElementsByClassName('MuiSvgIcon-root').style.display = "none";
            // var logoutbtn = document.getElementById('logoutBtn');
            // logoutbtn.classList.add("hidden");
             document.getElementById('logoutBtn').style.display="none";
            document.getElementById('iconMenu').style.display="none";
             document.getElementById('drawerBar').style.display="none";
             document.getElementById('mainCont').style.display="none";
              redirect_signout();

            }
            else{
              console.log('User is logged in');
             // var logoutbtn = document.getElementById('logoutBtn');
              //logoutbtn.classList.remove("hidden");
              document.getElementById('logoutBtn').style.display="block";
              document.getElementById('iconMenu').style.display="block";
            document.getElementById('drawerBar').style.display="block";
           document.getElementById('mainCont').style.display="block";
              
             // document.getElementsByClassName('MuiSvgIcon-root').style.display = "none";
              
            }
            
        },[Authenticated])


//FILE UPLOAD

console.log('llll:' + LoggedIn);
function handleLogoutCallback(data){


  
    setAuthenticated();
    setUserName();
   
   // document.getElementById('logoutBtn').style.display="none";
  //  document.getElementById('iconMenu').style.display="none";
   // document.getElementById('drawerBar').style.display="none";

   document.getElementById('logoutBtn').style.display="none";
   document.getElementById('iconMenu').style.display="none";
    document.getElementById('drawerBar').style.display="none";
    document.getElementById('mainCont').style.display="none";
  


}






function checkAuthenticated(){









 // const authstate = useSelector((state) => state.authstate.value);
 // const dispatch = useDispatch()
  let TokenVal = localStorage.getItem('AccessToken');
  let AuthVal = localStorage.getItem('isAuthenticated');

try{

  if(AuthVal){
    setAuthenticated(1);


    let decodedData = jwt_decode(TokenVal);
    console.log(decodedData);
    
    //document.getElementById('logoutBtn').style.display="block";
    //document.getElementById('iconMenu').style.display="block";
   // document.getElementById('drawerBar').style.display="block";
    setUserName(decodedData.username);
    document.getElementById('logoutBtn').style.display="block";
              document.getElementById('iconMenu').style.display="block";
            document.getElementById('drawerBar').style.display="block";
           document.getElementById('mainCont').style.display="block";
   
    
  }
  else{
    setAuthenticated(0);

    
  }
}
catch(err){
  console.log(err);
  setAuthenticated(0);
}

}

function handleSignCallback(data){


  try{
  console.log('Sign in calllback');
  console.log(data);
  if(data.isAuthenticated){
    setAuthenticated(1);


    let decodedData = jwt_decode(data.AccessToken);
    console.log(decodedData);
    
    //document.getElementById('logoutBtn').style.display="block";
    //document.getElementById('iconMenu').style.display="block";
   // document.getElementById('drawerBar').style.display="block";
    setUserName(decodedData.username);
    console.log(decodedData);
    setUserData(decodedData);
    
  }
  else{
    setAuthenticated(0);

    
  }
}catch(err){
  setAuthenticated(0);
}


}




  return (
    <div className="App">

    {/*
      <header className="App-header">
        
        <p>
          IsnRecon - Beta
   
        </p>
      </header>
    */}

    
               

    <Router>

    <MiniDrawer Authenticated={Authenticated} UserName={UserName} UserData={UserData}  parentCallback={handleLogoutCallback}> </MiniDrawer>


 

      <Switch>

     
         

          <Route path="/signin">
            <SignIn  parentCallback={handleSignCallback}/>
          </Route>
          <Route path="/signup">
            <SignUp></SignUp>
          </Route>
          <Route path="/forgotpassword">
            <ForgotPassword></ForgotPassword>
          </Route>
          
         
      
        



          {/* if(!LoggedIn){

          <Redirect to="/signin" />

          }*/}

          
          {/*
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/">
            <Home />
          </Route>
          */}


        </Switch>
        
         
      </Router>
     {/*  <div class="circle1"></div>
    <div class="circle2"></div>*/}
 
    </div>
  );
  //END OF FILE UPLOAD
        


}

export default App;


