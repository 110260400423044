import React from 'react'

function SearchBar({onChange, placeholder}) {
  return (
    <div>
    <input
        id="myInputSearch"
            type="text"
            placeholder={placeholder}
           
            onChange={onChange}
        />
</div>
  )
}

export default SearchBar