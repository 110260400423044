import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { PDFReader } from 'reactjs-pdf-reader';
import PreviewIcon from '@mui/icons-material/Preview';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button onClick={handleOpen}>Open Child Modal</Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: "100%", height: "100%" }}>
          <h2 id="child-modal-title">Text in a child modal</h2>
          <p id="child-modal-description">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          </p>
          <Button onClick={handleClose}>Close Child Modal</Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function MyModal(props) {
  const [open, setOpen] = React.useState(false);
  const [PDFData, setPDFData] = React.useState();


  React.useEffect(()=> {
  let pdfurl = props.pdf_url;
  setPDFData(pdfurl);
  

  });


  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

      <PreviewIcon onClick={handleOpen} className='iconButton'/>
      {/*<Button onClick={handleOpen}>Preview</Button>*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "80%",height: "fit-content" }}>
       <CloseOutlinedIcon className='closeBtn' onClick={handleClose} />

          <h2 id="parent-modal-title">Preview</h2>
          <p id="parent-modal-description">
            Preview of PDF Below
            <div style={{overflow:'auto',height:"fit-content",}}>

       


                    
                    {PDFData &&
                    <PDFReader   width="450" url={PDFData}></PDFReader> 
                        
                    }

                    </div>

          </p>
          {/*<ChildModal />*/}
        </Box>
      </Modal>
    </div>
  );
}

