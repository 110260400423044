import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import "./UserManagement.css";
//import Header from "./Header";
//import AddUser from "./AddUser";
import UserList from "./UserList";
//import EditUser from './EditUser';
import {CreateUserS, UpdateProfile} from '../templates/SuperAdmin';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import CompanyList from './CompanyList';

function CompanyManagement(props) {

  
  const LOCAL_STORAGE_KEY = "companies";
  const [searchTermC, setSearchTermC] = useState("");
  const [searchResults, setSearchResults]= useState([]);
  const [companies, setCompanies] = useState([]);

  const retrieveCompanies = async () => {
 
  }

  const AddCompanyHandler = (company) => {
    console.log(company);
    setCompanies([...companies, { id: uuidv4(), ...company }]);
  };

  
  const UpdateCompanyHandler = async (company) => {
    const request = {...company}
    const response= () => {

    }
}

  const RemoveCompanyHandler = (id) => {
    const newCompanyList = companies.filter((company) => {
      return company.id !== id;
    });

    setCompanies(newCompanyList);
  };


  useEffect(() => {
       const getAllCompanies = async () => {
      const allCompanies = await retrieveCompanies();
      if (allCompanies) setCompanies (allCompanies);
    };
    getAllCompanies();
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(companies));
  }, [companies]);

  const SearchHandlerC = (searchTermC)=>{
    setSearchTermC(searchTermC);
    if (searchTermC !== ""){
      const newCompanyList = companies.filter((company)=> {
       return Object.values(company).join("").toLowerCase().includes(searchTermC.toLowerCase());
      });
      setSearchResults(newCompanyList);
    }else{
      setSearchResults(companies);
    }
  };
  

  return (
    <div className="ui container">
     
      
      

      
      <CompanyList {...props}
            
            getCompanyId={RemoveCompanyHandler}  UpdateCompanyHandler={UpdateCompanyHandler} AddCompanyHandler={AddCompanyHandler}
            companies={searchTermC.length <1 ? companies : searchResults} 
            termC= {searchTermC} 
            companies={companies} 
            searchKeyword={SearchHandlerC}

      />
    </div>
  );
}


export default CompanyManagement;