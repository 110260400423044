import React, {useCallback, useState, useMemo, useEffect} from 'react';
import { Typography } from '@material-ui/core';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import CountUp from 'react-countup';
import './Dashboard.css';
import Moment from 'react-moment';
import 'moment-timezone';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactDOM from 'react-dom';
import {format} from 'date-fns';
import MUICard from '../components/MUICard';


import { useSelector, useDispatch } from 'react-redux';
import { loggedin,loggedout, AccessData } from '../redux/authSlice';

import jwt_decode from "jwt-decode";


let server_url = 'https://automate.isnet.co.za'







export default function Dashboard(props) {

  console.log("Dashboard props:  " + props.UserName);

  const history = useHistory();
 
  const [UserName, setUserName] = useState(props.UserName);

  const authstate = useSelector((state) => state.authstate.value);

  const [ActiveFiles, setActiveFiles] = useState();
  const [ArchivedFiles, setArchivedFiles] = useState();
  const [ProcessQueueFiles, setProcessQueueFiles] = useState();



  function get_billing(start_date, end_date){





    return new Promise(function (resolve,reject){
  
      try{
                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                        insecure: true,
                        rejectUnauthorized: false
                        }


                      fetch(server_url + "/transactions?start_date=" + start_date + "&end_date=" + end_date, options)
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              //resolve(data);
                              resolve(data);

                             
                              
                      });

                      
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
    


}

  function get_process_queue_files(){





      return new Promise(function (resolve,reject){
    
        try{
                          const token = localStorage.getItem('AccessToken'); 
                          let options = {
                          headers: {
                              'Authorization': 'Bearer ' + token
                          },
                          insecure: true,
                          rejectUnauthorized: false
                          }
  
  
                        fetch(server_url + "/processing_queue", options)
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                //resolve(data);
                                resolve(data);
  
                               
                                
                        });
  
                        
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
      


  }

  function get_active_queue_files(){

    return new Promise(function (resolve,reject){
  
      try{
                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                        insecure: true,
                        rejectUnauthorized: false
                        }


                      fetch(server_url + "/get_repo", options)
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);

                             
                              
                      });

                      
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
  }


  function get_archive_queue_files(){

    return new Promise(function (resolve,reject){
  
      try{
                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                        insecure: true,
                        rejectUnauthorized: false
                        }


                      fetch(server_url + "/get_repo_archived", options)
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);

                             
                              
                      });

                      
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
  }

  useEffect(()=>{



    try{
    let decodedData = jwt_decode(authstate.AccessToken);
    console.log(decodedData);
    
    setUserName(decodedData.username);
 
  
    console.log('zTOKEN: ' + JSON.stringify(decodedData));
    }
    catch(err){
      console.log('token error');
      console.log(err);
      history.push("/signin");
    }
    
   
    
    setActiveFiles(10);
    setArchivedFiles(10);
    async function get_process_files(){

      try{
      let process_files_count = await get_process_queue_files();
      console.log(process_files_count);
      console.log("ff: " + process_files_count[0].New.length);
      //return(process_files_count);
        setProcessQueueFiles(process_files_count[0].New.length);
      }
      catch(err){
        setProcessQueueFiles(0);
      }
    }
    get_process_files();
   // setProcesssFiles(process_files_count);



   async function get_active_files(){

    try{
    let active_files_count = await get_active_queue_files();
    console.log("MM");
    console.log(active_files_count);
    //console.log("ff: " + process_files_count[0].New.length);
    //return(process_files_count);
      //setProcessQueueFiles(process_files_count[0].New.length);
      setActiveFiles(active_files_count.length);
    }
    catch(err){
     // setProcessQueueFile(0);
     setActiveFiles(0);
    }
  }
  get_active_files();


  async function get_archive_files(){

    try{
    let archive_files_count = await get_archive_queue_files();
    console.log("HH");
    console.log(archive_files_count);
    //console.log("ff: " + process_files_count[0].New.length);
    //return(process_files_count);
      //setProcessQueueFiles(process_files_count[0].New.length);
      setArchivedFiles(archive_files_count.length);
    }
    catch(err){
     // setProcessQueueFile(0);
     setArchivedFiles(0);
    }
  }
  get_archive_files();







  },[])


 

        
    return (
        <div className="appBarShift">
        <Typography>
           Hi there, {UserName} <br/><br/>
            
        </Typography>

        <div>

        <div id="dateBox" className="glass">
        <Typography>
         Today is the {format(new Date(), 'do MMMM Y')}
         
        </Typography>
        <br/><br/>
        <p>
        
    
               {/* <Moment interval={30000}>
                    {dateToFormat}
                </Moment>*/}
              </p>  
            </div>   
          <div className="inline glass"><CountUp end={ProcessQueueFiles} duration={2}/><h5>Files In Processsing Queue</h5><Button  onClick={(text) => history.push('/upload')}>Upload New File</Button></div>
          <div className="inline glass"><CountUp end={ActiveFiles} duration={2}/><h5>Active Files</h5><Button onClick={(text) => history.push('/documentlisting')}>View Active Files</Button></div>
          <div className="inline glass"><CountUp end={ArchivedFiles} duration={2}/><h5>Archived Files</h5><Button onClick={(text) => history.push('/archivelisting')}>View Archived Files</Button></div>
         
         
         
          </div>


          <div id="dateBox" className="glass">
            <Button onClick={(text) => history.push('/transactions')}>View Billing</Button>
          </div>
          
            
         
        </div>
    )
}

ReactDOM.render(Dashboard,
  document.getElementById("root"))
