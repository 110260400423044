
import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ToastContainer, toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactDOM from 'react-dom';
import StorageIcon from '@mui/icons-material/Storage';
import SyncIcon from '@mui/icons-material/Sync';
import FileCopyIcon from '@mui/icons-material/FileCopy';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      justifycontent: "center",
      alignitems: "center",
    },
    container: {
        width: '80%',
        justifycontent: "center",
        alignitems: "center",
      },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      color: '#38AD9A',
      fontSize:'22',
      fontWeight:"bold",
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));


  let server_url = 'https://automate.isnet.co.za';

 /* let dev=false;


   if(dev){
     server_url = 'http://127.0.0.1:3002'
   }*/

 


function Xero() {

    const [ClientId, setClientId] = useState();
    const [ClientSecret, setClientSecret] = useState();
    const [Erp, setErp] = useState();

    const [Months, setMonths] = useState();
    const [Tenant, setTenant] = useState();
    const [RowKeys, setRowKeys] = useState([]);
    const [TenantIds, setTenantIds] = useState([]);
    const [TenantNames, setTenantNames] = useState([]);
    const [SelectedId, setSelectedId] = useState();
    const [SelectedMonths, setSelectedMonths] = useState();


    const classes = useStyles();


    useEffect(()=>{

   // setErp('XERO');

    },[Erp]);



  const history = useHistory();


  const ExternalRedirect = ({ to, ...routeProps }) => {
    return <Route {...routeProps} render={() => window.location = to} />;
 };


    /*
    async function save_xero_data(dataObject){
  
  
        return new Promise(function (resolve,reject){

          try{

                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    let myheaders = {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    };
                      

                      //let server_url = 'https://automate.isnet.co.za';
                      let server_url = 'http://127.0.0.1:3000';
            
                      fetch(server_url + "/save_xero_data",{
                        method: 'POST',
                        //headers: { 'Content-Type': 'application/json' },
                        headers: myheaders,
                        body: JSON.stringify(dataObject)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })
          */



          async function reauth_xero(dataObject){
  
  
            return new Promise(function (resolve,reject){
    
              try{
    
                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                          headers: {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json'
                          },
                          insecure: true,
                          rejectUnauthorized: false
                        }
    
                        let myheaders = {
                          'Authorization': 'Bearer ' + token,
                          'Content-Type': 'application/json'
                        };
                          
    
                          //let server_url = 'https://automate.isnet.co.za';
                          //let server_url = 'http://127.0.0.1:3002';
                
                          fetch(server_url + "/reauth_xero",{
                            method: 'POST',
                            //headers: { 'Content-Type': 'application/json' },
                            headers: myheaders,
                            body: JSON.stringify(dataObject)
                            })
                              .then(response => response.json())
                              .then(function(data){
                                      console.log('xxx');
                                      console.log(data);
                                      console.log('xxx2');
                                      resolve(data);
                              
                              });
              }
              catch(err){
                      console.log(err);
                      reject(err);
              }
    
              })




      }          


      async function sync_xero_payments(datestamp){
  
  
        return new Promise(function (resolve,reject){

          try{

                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    let myheaders = {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    };
                      

                      //let server_url = 'https://automate.isnet.co.za';
                      //let server_url = 'http://127.0.0.1:3002';
            
                      fetch(server_url + "/sync_xero_payments?datestamp=" + datestamp,{
                        method: 'GET',
                        //headers: { 'Content-Type': 'application/json' },
                        headers: myheaders,
                        body: JSON.stringify()
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })




  }          



  async function sync_xero_invoices(datestamp){
  
  
    return new Promise(function (resolve,reject){

      try{

                const token = localStorage.getItem('AccessToken'); 
                let options = {
                  headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  },
                  insecure: true,
                  rejectUnauthorized: false
                }

                let myheaders = {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                  

                  //let server_url = 'https://automate.isnet.co.za';
                  //let server_url = 'http://127.0.0.1:3002';
        
                  fetch(server_url + "/sync_xero_invoices?datestamp=" + datestamp,{
                    method: 'GET',
                    //headers: { 'Content-Type': 'application/json' },
                    headers: myheaders,
                    body: JSON.stringify()
                    })
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);
                      
                      });
      }
      catch(err){
              console.log(err);
              reject(err);
      }

      })




}       



async function create_company_db(){
  
  
  return new Promise(function (resolve,reject){

    try{

              const token = localStorage.getItem('AccessToken'); 
              let options = {
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                },
                insecure: true,
                rejectUnauthorized: false
              }

              let myheaders = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
              };
                

                //let server_url = 'https://automate.isnet.co.za';
                //let server_url = 'http://127.0.0.1:3002';
      
                fetch(server_url + "/create_company_db",{
                  method: 'POST',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}          




async function save_tenant(){
  
  
  return new Promise(function (resolve,reject){

    try{

              const token = localStorage.getItem('AccessToken'); 
              let options = {
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                },
                insecure: true,
                rejectUnauthorized: false
              }

              let myheaders = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
              };
                

                let dataJSON = {
                  tenantId: SelectedId
                }

                //let server_url = 'https://automate.isnet.co.za';
                //let server_url = 'http://127.0.0.1:3002';
      
                fetch(server_url + "/save_tenant",{
                  method: 'POST',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  body: JSON.stringify(dataJSON)
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}   

async function get_xero_tenants(){
  
  
  return new Promise(function (resolve,reject){

    try{

              const token = localStorage.getItem('AccessToken'); 
              let options = {
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                },
                insecure: true,
                rejectUnauthorized: false
              }

              let myheaders = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
              };
                

                //let server_url = 'https://automate.isnet.co.za';
                //let server_url = 'http://127.0.0.1:3002';
      
                fetch(server_url + "/get_xero_tenants",{
                  method: 'GET',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  body: JSON.stringify()
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}       

    return (
        <div>

<div className="centralized_box">
           <h2> Integrate with Xero</h2>



        {/*
            <TextField
            variant="outlined"
            margin="normal"
            value={ClientId}
            required
            fullWidth
            id="ClientId"
            label="ClientId"
            name="clientid"
            //autoComplete="text"
            autoFocus
            onChange={(e)=>{
   
            
              //FieldData[item] = e.target.value;
               setClientId(e.target.value);
               
          

         }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={ClientSecret}
            required
            fullWidth
            name="ClientSecret"
            label="ClientSecret"
            type="password"
            id="password"
            //autoComplete="current-password"
        
       
            onChange={(e)=>{
   
                 //console.log('testing:');
                 //console.log(e.target.value);
                 //FieldData[item] = e.target.value;
                  setClientSecret(e.target.value);
                  //console.log(e.target.value);
                 
             
   
            }}
          />
        */}


  
 
        <div className="xero_btn_box">
        <Typography className="number_order">
          1
        </Typography>

<Button
                  id="retrieveBtn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                   // toast('Setting up Xero');



                   /*
                   let doc_json = {
                       "client_id": ClientId,
                       "client_secret": ClientSecret
                   }
                   

                   console.log(doc_json);
                   let resp = await save_xero_data(doc_json);
                   console.log(resp);


                   
                   */
                  

                 try{

                  
                  let run_reauth_xero_url = await reauth_xero();
                  console.log(run_reauth_xero_url);
                  //history.push(run_reauth_xero_url.consentUrl);
                  //ExternalRedirect({'https://www.google.com'})
                  let consentUrl = run_reauth_xero_url.consentUrl;
                   window.open(consentUrl, '_blank');
                  


                 }
                 catch(err){
                     console.log(err);
                 }




                  }}
                  className={classes.button}

                  
                >
                  
                  Authenticate Xero
                </Button>

                 <Typography className="instructions">You will be redirected to Xero.com. Please allow popups to view the page.</Typography>
                  

       </div>
      <div className="xero_btn_box">
      <Typography className="number_order">
          2
        </Typography>
       <Button
                 // id="retrieveBtn"
                 id="tenants_btn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                   // toast('Setting up Xero');


                  //set this from a datebox somewhere
                   let datestamp = '2021-08-01';
            

                 try{
                  let get_xero_tenants_json= await get_xero_tenants();
                  console.log(get_xero_tenants_json);
                  setRowKeys(["Testing", "testint1234"]);




                  //Iterate Tenant and set row keys
                  console.log('xxxf');
                  console.log( get_xero_tenants_json.length);
                  let row = new Array();
                  let tenantIdArray = new Array();
                  let tenantNameArray = new Array();
                  let i=0; const iMax = get_xero_tenants_json.length - 1; for(; i<=iMax; i++){

                    console.log(get_xero_tenants_json[i]);
                    let item = get_xero_tenants_json[i];

                    let tenantId = item.tenantId;
                    let tenantName = item.tenantName;

                    let jsondoc = {
                      tenantId: tenantId,
                      tenantName: tenantName
                    }
                    row.push(jsondoc);
                    tenantIdArray.push(tenantId);
                    tenantNameArray.push(tenantName);


                    if(i == iMax){
                      //setRowKeys(jsondoc);
                      setTenantIds(tenantIdArray);
                      setTenantNames(tenantNameArray);
                    }


                  }
                  

                 }
                 catch(err){
                     console.log(err);
                 }

                  }}
                  className={classes.button}
                >
                  
                  Get Tenants
        </Button>




            <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Tenant}
                  label="Tenant"
                  onChange={(e)=>{
                        console.log(e.target.value);
                        setTenant(e.target.value);
                        //console.log(Fieldh);
                        //get index
                        let tindex = TenantNames.indexOf(e.target.value);
                        setSelectedId(TenantIds[tindex]);
                        console.log(tindex);

                  }
                  }
                >

              
                  
              {
              TenantNames &&
              TenantNames.map((item) => {

                return(

                  <MenuItem value={item}>{item}</MenuItem>
                  

                );

                })
              }
              

                </Select>



                <span>{SelectedId}</span>

              

             
                <Button
                 // id="retrieveBtn"
                 id="save_tenant_btn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                   // toast('Setting up Xero');


                  //set this from a datebox somewhere
                  // let datestamp = '2021-08-01';
            

                 try{
                  //let run_sync_xero_payments= await sync_xero_payments(datestamp);
                  let save_tenant_ret = await save_tenant();
                  console.log(save_tenant_ret);

                 }
                 catch(err){
                     console.log(err);
                 }

                  }}
                  className={classes.button}
                >
                  
                  Save Tenant Id
                </Button>

                </div>

                <div className="xero_btn_box">

                <Typography className="number_order">
                  3
                </Typography>

                <Typography>Select months to sync 

                  <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  
                  label="Months"
                  onChange={(e)=>{                     

                        console.log(e.target.value);
                        setMonths(e.target.value);
                                 
                        

                  }
                  }
                >

              

                  <MenuItem value={3}>3 months</MenuItem>
                  <MenuItem value={6}>6 months</MenuItem>
                  <MenuItem value={12}>12 months</MenuItem>
                  

              

              
             
              

                </Select>

                

                <span>{SelectedMonths}</span>
                </Typography>
                  <br/>
                <Button
                //  id="retrieveBtn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                  // toast('Setting up Xero');


                  //set this from a datebox somewhere
                  let datestamp = '2021-08-01';
            

                try{
                  let run_sync_xero_payments= await sync_xero_payments(datestamp);

                }
                catch(err){
                    console.log(err);
                }

                  }}
                  className="icon_btn"
                
                >
                  <Typography><SyncIcon/>&nbsp;&nbsp;
                  Sync Payments
                  </Typography>
                </Button>

                <Button
                // id="retrieveBtn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                  // toast('Setting up Xero');


                  //set this from a datebox somewhere
                  let datestamp = '2021-08-01';
            

                try{
                  let run_sync_xero_invoices= await sync_xero_invoices(datestamp);

                }
                catch(err){
                    console.log(err);
                }

                  }}

                  className="icon_btn"
                
                >
                  <Typography><FileCopyIcon/>&nbsp;&nbsp;
                  Sync Invoices
                  </Typography>
                </Button>
          </div>

               

               {/*   <div className="card_box">
              <Button
                 // id="retrieveBtn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                   // toast('Setting up Xero');


                  //set this from a datebox somewhere
                   let datestamp = '2021-08-01';
            

                 try{
                  let run_create_db = await create_company_db();

                 }
                 catch(err){
                     console.log(err);
                 }

                  }}
                  className='card_btns'
                >
                <Typography> 
                  Create Company  Database
                  </Typography>
                </Button>


               
      
                </div>*/}


        </div>
            
        </div>
    )
}

export default Xero
