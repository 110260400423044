import React, { useEffect, useState, useRef, Component } from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';






export default function TableRows({rowsData, deleteTableRows, handleChange, handleChangeCode, AccountCodeData, AccountCodeLookup, handlegetCode,handleChangeID}) {


  const [FoundCode, setFoundCode] = React.useState();
  const [LookupIndex, setLookupIndex] = React.useState();

  let lookupObject = new Object();


  function myAccountCodeLookup(code){

    let accounts = AccountCodeLookup;
    //console.log('Accountz');
    let i=0; const iMax = accounts.length - 1; for(; i<=iMax; i++){

      //console.log(accounts[i].code);
      if(accounts[i].code == code){

        //console.log('Account found');
        return(accounts[i].name);
      }


      if(i==iMax){
        //console.log('Accountz Done');

      }
    }

  }
 
  


    return(
        
        rowsData.map((data, index)=>{
            let {id, Description, Quantity, UnitAmount, TaxAmount,LineAmount,AccountAmount, AccountName, AccountCode}= data;

            

            console.log('data---->');
            console.log(data);

            //console.log("index: " + index);
           // console.log("checking index:" + lookupObject[index]);
           // console.log("Lookup:" + LookupIndex);
            //process.exit(0);
            if(!AccountCode && Description){
                //try lookup account code

                try{
                  console.log('Looking up code for Description: ' + Description)
                  //lookup_acc_code(Description, index);
                 // data.AccountCode = FoundCode;
                 

                }
                catch(err){
                  console.log(err);
                }

            }

            if(TaxAmount === "NaN"){
              console.log('NNANANANANNANANANA');
              TaxAmount =  "";
              
            }


            return(
  
                <tr key={index}>
                <td>
               <input required type="text" value={id} id={`myID_${index}`} onChange={(e)=>(handleChangeID(e.target.value,index, e))} name="id" className="form-control"/>
                </td>
                <td><input required  type="text" value={Description}  onChange={(evnt)=>(handleChange(index, evnt))} name="Description" className="form-control"/> </td>
                <td><input required  type="text" value={Quantity}  onChange={(evnt)=>(handleChange(index, evnt))} name="Quantity" className="form-control" /> </td>
                <td><input required  type="text" value={UnitAmount}  onChange={(evnt)=>(handleChange(index, evnt))} name="UnitAmount" className="form-control" /> </td>
                
                <td><input required  type="text" value={LineAmount}  onChange={(evnt)=>(handleChange(index, evnt))} name="LineAmount" className="form-control" /> </td>

                <td><input required  type="text" value={TaxAmount}  onChange={(evnt)=>(handleChange(index, evnt))} name="TaxAmount" className="form-control" /> </td>
               
                <td><select required  type="text" value={AccountName}  onChange={(e)=>handleChangeCode(e.target.value,index)} name="AccountName" className="form-control" placeholder='Select an option' >

                <option value="disabled" id="lightGrey">Select an option</option>
                 
                {AccountCodeData.map((item)=>{
  
                 
                if(AccountCode){
                  //console.log("Codez: " + item + " " + AccountCode );

                  let mylookup = myAccountCodeLookup(AccountCode);
                  //console.log('Lookup returned: ' + mylookup);
                  
                  //console.log('AccountCode:' + AccountCode + " __ " + mylookup);
                  if(item === mylookup){

                    console.log('Matched: ' + mylookup);
                  return(
                    <>
              
                    <option selected>{item}</option>
                    </>
                  )
                 }
                 else{

                  return(
                    <>
              
                    <option>{item}</option>
                    </>
                  )

                 }
                }


                })}
                  
                  
                </select></td>
               
                {/*<td><input type="text"  id={`code_${id}`} onChange={(e)=>handleChange(e.target.value,index)} name="AccountCode" className="form-control" /> </td>*/}

                <td><p type="text"  id={`code_${id}`} name="AccountCode" className="form-control" >{AccountCode}{FoundCode}</p> </td>
  
                <td><button className="btnDelete" onClick={()=>(deleteTableRows(index))}><DeleteOutlinedIcon className='DeleteIcon'/></button></td>
            </tr>
  
  
            )
      }))}