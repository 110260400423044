import React, {useState} from 'react'
import AdminUserManagement from '../components/AdminUserManagement';

function AdminScreen(props) {
  //console.log(props.usersCompany);
    return (
        <div id="managementBox">
        <div ><h3>ADMIN CENTRE</h3></div>
            <div>
            <AdminUserManagement {...props}></AdminUserManagement>
            </div>
           
            
        </div>
    )
}

export default AdminScreen;