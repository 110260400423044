import React, { useEffect,useState } from 'react';
import Dropdown from 'react-dropdown';
import {BrowserRouter as Router, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

let server_url = process.env.REACT_APP_SERVER_URL;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const MailModal= ({mail}) => {

   // console.log(mail);
    const [open, setOpen] = React.useState(false);
    
  
    //const handleClose = () => setOpen(false);
   
    const [EmailAddressUp, setEmailAddressUp]= React.useState();
    const [MailBoxStatusUp, setMailBoxStatusUp]= React.useState();
    const [MailBoxTypeUp, setMailBoxTypeUp]= React.useState();
    const [MailServerUp, setMailServerUp]= React.useState();
    const [PasswordUp, setPasswordUp]= React.useState();
    const [UsernameUp, setUsernameUp]= React.useState();
    const [IdUp, setIdUp]= React.useState();
    const [MailBox, setMailBox]= useState(mail);
   
    //const [Result, setResult] = useState();
    const history = useHistory();
    
  React.useEffect(()=> {
  
        //setMailBox(props);
        console.log("start");
        console.log(MailBox);
        console.log("end");
        let emailData = MailBox.email_address;
        let status = MailBox.mailbox_status;
        let type = MailBox.mailbox_type;
        let mserver = MailBox.mailserver;
        let usern= MailBox.username;
        let pass = MailBox.password;
        let id = MailBox._id;
    
        console.log(emailData);
      
        setMailBoxStatusUp(status);
        setMailBoxTypeUp(type);
        setMailServerUp(mserver);
        setPasswordUp(pass);
        setEmailAddressUp(emailData);
        setUsernameUp(usern);
        setIdUp(id);
    
    
    
    },[]);


    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };


    return (
  
   
        <div className="mailBox" key={IdUp}>
                    {/*<CloseOutlinedIcon className='closeBtn' onClick={()=> setOpen(false)} />*/}
        
                  <label>Email</label><br/>  <TextField  variant="outlined" type="text"  defaultValue={EmailAddressUp}
                  value={EmailAddressUp}
                    onChange={(e)=>{
                      //console.log('change')

                      setEmailAddressUp(e.target.value);
                      
                      }}
                  /><br/>
                  <label>Status</label><br/> <TextField  variant="outlined" type="text" value={MailBoxStatusUp}
                    onChange={(e)=>{
                      //console.log('change')
                      setMailBoxStatusUp(e.target.value)
                      }}
                  /><br/>
                  <label>Mail Box Type</label><br/>  <TextField  type="text" variant="outlined" value={MailBoxTypeUp}
                    onChange={(e)=>{
                      //console.log('change')
                      setMailBoxTypeUp(e.target.value)
                      }}
                  /><br/>
                  <label>Password</label><br/><TextField  type="password" variant="outlined" value={PasswordUp}
                    onChange={(e)=>{
                      //console.log('change')
                      setPasswordUp(e.target.value)
                      }}
                  /><br/>
                 <label> Mail Server</label><br/> <TextField  type="text" variant="outlined" value={MailServerUp}
                    onChange={(e)=>{
                      //console.log('change')
                      setMailServerUp(e.target.value)
                      }}
                  /> <br/>
                  <label>Username</label><br/> <TextField  type="text" variant="outlined" value={UsernameUp}
                    onChange={(e)=>{
                      //console.log('change')
                      setUsernameUp(e.target.value)
                      }}
                  />
                 
                  

                      <br/>
                  <Button variant="contained"
           className="buttons"
             
             onClick={()=>{

              


               let document = {
              
                 "email_address": EmailAddressUp, 
                 "mail_box_status": MailBoxStatusUp, 
                 "mail_box_type": MailBoxTypeUp, 
                 "mail_server": MailServerUp, 
                 "password": PasswordUp, 
                 "username": UsernameUp
               }



               console.log('Mailbox update');
               console.log(document);


               try{


                const token = localStorage.getItem('AccessToken'); 

                let headers =  { 
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + token
                 };
    
     
                
           
                 fetch(server_url + "/update_mailbox",{
                   method: 'POST',
                   headers: headers,
                   body: JSON.stringify(document)
                   })
                     .then(response => response.json())
                     .then(function(data){
                             console.log('xxxx mailbox update');
                             console.log(data);
                             console.log('xxx2 mailbox update');
                             //resolve(data);
                             history.push('/superadmin'); 
                     
                     });
              }
             catch(err){
                     console.log(err);
                    // reject(err);
             }

             }}>Update Mail Box</Button>
       </div>

    

      
  )
}

export default MailModal;