


import React, { useEffect,useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
//import Link from '@material-ui/core/Link';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {BrowserRouter as Router, useHistory } from 'react-router-dom';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
//import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ReactDOM from 'react-dom';
import './admincentre.css';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import UserManagement from '../components/UserManagement';
import CompanyManagement from '../components/CompanyManagement';
import { Label } from '@material-ui/icons';




//const server_url = 'https://automate.isnet.co.za';

let server_url = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



class Tabsadmin extends React.Component {
  render(){

 
    return(

   
      
      <div>
        <div>
        <UserManagement></UserManagement>
        </div>
        <div>
        <CompanyManagement></CompanyManagement>
        </div>

        {/*<div className='tabs'>
        <Tabs>
                <Tab label="Users Tab">
                  <div className="displayTab" style={{flexDirection:'row'}}>
                                  
                  <UserManagement></UserManagement>
                  
                  </div>
                  
                </Tab>
            
          
                <Tab label="New User">
                  <div className="displayTab">
                  <h1>Create New User</h1>
                  <br/>
                  <CreateUserS></CreateUserS>
                  </div>
                </Tab>
                <Tab label="Update Profile">
                  <div className="displayTab">
                  
                  <UpdateProfile></UpdateProfile>
                  </div>
                  </Tab>

                  <Tab label="Create New Company">
                  <div className="displayTab">
                  <h1 >Create New Company</h1>
                  <div>
                  <CreateCompany></CreateCompany>
                  </div>
                  </div>
                </Tab>
                 
            </Tabs>
                
                  
                
              
        </div>*/}
    
        
         
         

         
      
      </div>
   
    )
  }
}

class Tabs extends React.Component{
  state ={
    activeTab: this.props.children[0].props.label
  }
  changeTab = (tab) => {

    this.setState({ activeTab: tab });
  };
  render(){
    
    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, child =>{
          buttons.push(child.props.label)
          if (child.props.label === this.state.activeTab) content = child.props.children
        })}
         
        <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab}/>
        <div className="tab-content">{content}</div>
        
      </div>
    );
  }
}

const TabButtons = ({buttons, changeTab, activeTab}) =>{
   
  return(
    <div className="tab-buttons-super">
    {buttons.map(button =>{
       return <button className={button === activeTab? 'active': ''} onClick={()=>changeTab(button)}>{button}</button>
    })}
    </div>
  )
}

const Tab = props =>{
  return(
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}
 

ReactDOM.render(<Tabsadmin />, document.getElementById('root'));



export function CreateCompany(){



    const [CompanyStatus, setCompanyStatus] = useState();
    const [CompanyName, setCompanyName] = useState();
    const [CompanyDatabase, setCompanyDatabase] = useState();
    const [CompanyAPIUrl, setCompanyAPIUrl] = useState();
    const [CompanyAdmin, setCompanyAdmin] = useState();

    const history = useHistory();


    return(

        <div id="addCompanyForm">
       
            <label>Company Name</label> <br/>
               <TextField id="CompanyName"  variant="outlined" value={CompanyName}
               onChange={(e)=>{
                //console.log('change')
                setCompanyName(e.target.value)
                }}
               
               /> <br/>
               <label>Company Database</label><br/>
               <TextField id="CompanyDatabase" variant="outlined" value={CompanyDatabase}
                  onChange={(e)=>{
                    //console.log('change')
                    setCompanyDatabase(e.target.value)
                    }} 
              /><br/>
         
                   
         <label>Company API URL</label><br/>
               <TextField id="CompanyAPIUrl"  variant="outlined" value={CompanyAPIUrl}
                onChange={(e)=>{
                  //console.log('change')
                  setCompanyAPIUrl(e.target.value)
                  }}
               />  <br/>
               <label>Company Admin</label><br/>
               <TextField id="CompanyAdmin" variant="outlined" value={CompanyAdmin}
                 onChange={(e)=>{
                  //console.log('change')
                  setCompanyAdmin(e.target.value)
                  }}
               /><br/>
              
               
            <div id="inputBoxCompany">
              {/* <TextField id="CompanyStatus" label="Company Status" variant="outlined" /> */}
              <label>Company Status</label><br/>
               
                    <Select
                        style={{minWidth: 250, height: 35}}
                       
                        value={CompanyStatus}
                        label="Status"
                        onChange={(e)=>{
                            console.log('change')
                            setCompanyStatus(e.target.value)
                        }
                        
                        }
                    >
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"Disabled"}>Disabled</MenuItem>
                    </Select>

            </div><br/>

          
        
        
        <div>
        <Button variant="contained"
                className="buttons"
                  //color="primary"
                  onClick={()=>{

                    ///setCurrentView('CreateCompany');
                    setCompanyName(CompanyName.replace(/ /g, ""));

                    let document = {
                      "company_name": CompanyName,
                      "company_database": CompanyDatabase,
                      "company_api_url": CompanyAPIUrl,
                      "company_admin": CompanyAdmin,
                      "company_status": CompanyStatus
                    }



                    console.log('Setup Company');
                    console.log(document);


                    try{
          
                     
                
                      fetch(server_url + "/create_company",{
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(document)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxxx company status');
                                  console.log(data);
                                  console.log('xxx2 company status');
                                  //resolve(data);
                                  history.push('/superadmin'); 
                          
                          });
                   }
                  catch(err){
                          console.log(err);
                         // reject(err);
                  }

                  }}>Setup Company</Button>
                  <Link to="/superadmin">
            <Button className="center MuiButton-root detailBtn">Back to Companies List</Button>
        </Link>
        </div>
        </div>
    )

}


export function CreateUser(){



  const [user_status, setuser_status] = useState();
  const [username, setusername] = useState();
  const [company_name, setcompany_name] = useState();
  const [password, setpassword] = useState();
  const [user_role, setuser_role] = useState();
  const [user_email, setuser_email] = useState();

  const history = useHistory();

  useEffect(()=>{
    //setAuthenticated(props.Authenticated);
    //setUserName(props.UserName);
   // console.log('setting: ' + props.Authenticated); 

    async function gusers(){

      let userData = await get_users();

      let company_name= userData[0].CompanyName;
     
    
      setcompany_name(company_name);
   
    }

    gusers();

  },[]);
  

      async function get_users(){

        return new Promise(function (resolve,reject){
        
        try{
        
        const token = localStorage.getItem('AccessToken');
        
        let options = {
        
        headers: {
        
        'Authorization': 'Bearer ' + token
        
        },
        
        insecure: true,
        
        rejectUnauthorized: false
        
        }
        
        
        fetch(server_url + "/company_users", options)
        
        .then(response => response.json())
        
        .then(function(data){
        
        console.log('xxx');
        
        console.log(data[0].UserName);
        
        console.log('xxx2');
     const username = data[0].UserName;
     
        console.log(data);
        resolve(data);
     
        
        });
        
        }
        
        catch(err){
        
        console.log(err);
        
        reject(err);
        
        }
        
        })
        
        }


  return(

      <div>
      <div>
          <div>

      
          <TextField className="Mui-disabled" id="company_name" value={company_name}
               // onChange={(e)=>{
                  //console.log('change')
                //  setcompany_name(e.target.value)
                //  }} 
            /> 
             <TextField id="username" label="Name" variant="outlined" value={username}
             onChange={(e)=>{
              //console.log('change')
              setusername(e.target.value)
              }}
             
             /> 
            
          </div>
         
          <div>
             <TextField id="password" label="User Password" variant="outlined" value={password}
              onChange={(e)=>{
                //console.log('change')
                setpassword(e.target.value)
                }}
             /> 
             <TextField id="user_role" label="User Role" variant="outlined" value={user_role}
               onChange={(e)=>{
                //console.log('change')
                setuser_role(e.target.value)
                }}
             />
             </div>
          
          <div>
            {/* <TextField id="CompanyStatus" label="Company Status" variant="outlined" /> */}  
            <TextField id="user_email" label="User Email" variant="outlined" value={user_email}
              onChange={(e)=>{
                //console.log('change')
                setuser_email(e.target.value)
                }}
             /> 
   <TextField id="user_status" label="User Status" variant="outlined" value={user_status}
              onChange={(e)=>{
                //console.log('change')
                setuser_status(e.target.value)
                }}
             /> 
            
                      
          </div>

        
      </div>
      
      <div>

     
      <Button variant="contained"
                //color="primary"
                className="buttons"
                onClick={()=>{
                   
                  let document = {
                    "CompanyName": company_name,
                    "UserName": username,
                    "UserPassword": password,
                    "UserRole": user_role,
                    "UserEmail": user_email,
                    "UserStatus": user_status,
                  }



                  console.log('Setup New User');
                  console.log(document);


                  try{
        
                   
              
                    fetch(server_url + "/create_user",{
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(document)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxxx user status');
                                console.log(data);
                                console.log('xxx2 user status');
                                //resolve(data);
                                history.push('/superadmin'); 
                        
                        });
                 }
                catch(err){
                        console.log(err);
                       // reject(err);
                }

                }}>Create New User</Button> 
      </div>
      </div>
  )

}


export function CreateUserS(){



  const [UserStatus, setUserStatus] = useState();

  const [UserRole, setUserRole] = useState();
  const [UserEmail, setUserEmail] = useState();


  const [user_status, setuser_status] = useState();
  const [username, setusername] = useState();
  const [company_name, setcompany_name] = useState();
  const [password, setPassword] = useState();
  const [user_role, setuser_role] = useState();
  const [user_email, setuser_email] = useState();
  const [RowKeys, setRowKeys] = useState([]);
  const [companiesId, setcompaniesId]= useState();
  const [companiesName, setcompaniesName]= useState();
  
  

  const history = useHistory();

  const [Authenticated, setAuthenticated] = useState();
  const [UserName, setUserName] = useState();
  const [SelectedStatus, setSelectedStatus] = useState();
  const [SelectedRole, setSelectedRole] = useState();
  const [SelectedCompany, setSelectedCompany] = useState();
  const [company_names, setcompany_names] = useState();
  const [CompanyName, setCompanyName] = useState([]);
  
  
 
 
 

  useEffect(()=>{
    //setAuthenticated(props.Authenticated);
    //setUserName(props.UserName);
   // console.log('setting: ' + props.Authenticated); 

    async function gcompanies(){

      let companyData = await get_companies();
     
      let company_name= companyData;
    

     
      
      console.log(company_name);
    }

    gcompanies();

  },[]);



  

      async function get_companies(){

        return new Promise(function (resolve,reject){
        
        try{
        
        const token = localStorage.getItem('AccessToken');
        
        let options = {
        
        headers: {
        
        'Authorization': 'Bearer ' + token
        
        },
        
        insecure: true,
        
        rejectUnauthorized: false
        
        }
        
        
        fetch(server_url + "/get_companies", options)
        
        .then(response => response.json())
        
        .then(function(data){
        
        console.log('xxx');
        
       // console.log(data.CompanyName);
       setCompanyName(data);
        
        console.log('xxx2');
        const company_name = data.CompanyName;
        const company_id = data._id;

        setcompany_name(company_name);
        
        console.log(data);
        for (let i = 0; i < data.length; i++) {
          var element = data[i].CompanyName;
          console.log(element);
          
        resolve(element);
     
        }
        });
        
        }
        
        catch(err){
        
        console.log(err);
        
        reject(err);
        
        }
        
        })
        
        }

  

        useEffect(()=>{
         
      
          async function all_companies(){
      
            let companyNames = await get_companies();
           
            let company_names= companyNames;
          
      
           
            setcompany_names(company_names);
            console.log(company_names);
          }
      
          all_companies();
      
        },[]);

      

  return(

    <div className="ui main">
    <h2>Add New User</h2>
      
          
    <form className="ui form">
      {/*<div className="field" >
       
            <label>Company Name</label>
                

              
                    <select
                        style={{minWidth: 200}}
                        labelId="demo-simple-select-label"
                        value={company_name}
                        id="demo-simple-select"
                        //value={company_name}
                        
           

                                                

                        onChange={(e)=>{
                            console.log('change')
                          
                            
                            setcompany_name(e.target.value)
                        }
                        
                        }
                        
                    >
                        <option>{company_names}</option>
                    
                    </select>
                    <span>{SelectedCompany}</span>
            </div>*/}

         {/* <TextField id="company_name" label="Company Name" variant="outlined" value={company_name}
                onChange={(e)=>{
                  //console.log('change')
                  setcompany_name(e.target.value)
                  }} 
            /> */}


            <div className="field">
            <label>Company Name</label>
             {/*<input id="company_name"  variant="outlined" value={company_name}
             onChange={(e)=>{
              //console.log('change')
              setcompany_name(e.target.value)
              }}
             
             />*/}



              <Select
                   style={{width: '50%', height: 35}}
                  
                   value={company_name} 
                   //label="Company Name"
                   onChange={(e)=>{
                       console.log('change')
                       setcompany_name(e.target.value)
                   }
                   
                   }
               >
              
              {CompanyName.map((item)=>{
                return(
               
                  <MenuItem value={item.CompanyName}>{item.CompanyName}</MenuItem>
                
                  
                )   
              })}
              

               </Select>
            </div>
            <div className="field">
            <label>Name</label>
             <input id="username"  variant="outlined" value={username}
             onChange={(e)=>{
              //console.log('change')
              setusername(e.target.value)
              }}
             
             /> 
            </div>
       
          
            <div className="field">
         
          <label>Password</label>
             <input id="password" variant="outlined" value={password}
              onChange={(e)=>{
                //console.log('change')
                setPassword(e.target.value)
                }}
             /> 
             </div>
             <div className="field" >
             <label>User Role</label>
             {/*<input value={user_role} onChange={(e)=>{
                //console.log('change')
                setuser_role(e.target.value)}}/>*/}

                <select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={user_role}
                  placeholder='Select an option'
                  onChange={(e)=>{
                //console.log('change')
                setuser_role(e.target.value)
                }}
                >

              

                  <option value={"guest"}>Guest</option>
                  <option value={"admin"}>Admin</option>
                  <option value={"superadmin"}>Super Admin</option>
                 
                  

                </select>

                

                <span>{SelectedRole}</span>
            
          </div>
        
          <div className="field" >
       
            {/* <TextField id="CompanyStatus" label="Company Status" variant="outlined" /> */}  
            <label>User Email</label>
            <input id="user_email"  variant="outlined"  value={user_email}
              onChange={(e)=>{
                //console.log('change')
                setuser_email(e.target.value)
                }}
             /> 
           </div>

        
            <div className="field">
            <label>Status</label>
            


                  <Select
                   style={{width: '50%', height: 35}}
                  
                   value={UserRole} 
                   //label="Status"
                   onChange={(e)=>{
                       console.log('change')
                       setuser_status(e.target.value)
                   }
                   
                   }
               >
                   <MenuItem value={"active"}>active</MenuItem>
                   <MenuItem value={"disabled"}>disabled</MenuItem>
                  
               </Select>

            </div>  
        
                
        
   
      
      <div>

     
      <Button variant="contained"
                //color="primary"
                className="buttons"
                onClick={()=>{
                   
                  let document = {
                    "company_name": company_name,
                    "username": username,
                    "password": password,
                    "user_role": user_role,
                    "user_email": user_email,
                    "user_status": user_status,
                  }
                 



                  console.log('Setup New User');
                  console.log(document);


                  try{
        
                   
              
                    fetch(server_url + "/create_user",{
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(document)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxxx added user');
                                console.log(data);
                                console.log('xxx2 added user');
                                //resolve(data);
                                //history.push('/superadmin'); 
                        
                        });
                 }
                catch(err){
                        console.log(err);
                       // reject(err);
                }

                }}>Create New User</Button> 
      </div>
      </form>
      <div >
        <Link to="/superadmin">
            <Button className="buttons">Back to Users List</Button>
        </Link>
        

        </div>
      </div>
      
  )

}



export function CreateMailbox(){



  const [CompanyName, setCompanyName] = useState();
  const [MailboxType, setMailboxType] = useState();
  const [MailServer, setMailServer] = useState();
  const [EmailAddress, setEmailAddress] = useState();
  const [UserName, setUserName] = useState();
  const [Password, setPassword] = useState();
  const [MailboxStatus, setMailboxStatus] = useState();

  const history = useHistory();





  return(

      <div id="addMailboxForm">
     
          <label>Company Name</label> <br/>
             <TextField id="CompanyName"  variant="outlined" value={CompanyName}
               onChange={(e)=>{
                //console.log('change')
                setCompanyName(e.target.value)
                }} 
             
             /> <br/>
              <label>EmailAddress</label><br/>
             <TextField id="EmailAddress" variant="outlined" value={EmailAddress}
                onChange={(e)=>{
                  //console.log('change')
                  setEmailAddress(e.target.value)
                  }} 
            /><br/>
                <label>MailServer</label><br/>
             <TextField id="MailServer" variant="outlined" value={MailServer}
                onChange={(e)=>{
                  //console.log('change')
                  setMailServer(e.target.value)
                  }} 
            /><br/>
             <label>Mailbox Type</label><br/>
             <TextField id="MailboxType" variant="outlined" value={MailboxType}
                onChange={(e)=>{
                  //console.log('change')
                  setMailboxType(e.target.value)
                  }} 
            /><br/>
       
                 
       <label>UserName</label><br/>
             <TextField id="UserName"  variant="outlined" value={UserName}
              onChange={(e)=>{
                //console.log('change')
                setUserName(e.target.value)
                }}
             />  <br/>
             <label>Password</label><br/>
             <TextField id="Password" variant="outlined" value={Password}
               onChange={(e)=>{
                //console.log('change')
                setPassword(e.target.value)
                }}
             /><br/>
            
             
          <div id="MailboxStatus">
            {/* <TextField id="CompanyStatus" label="Company Status" variant="outlined" /> */}
            <label>Mailbox Status</label><br/>
             
                  <Select
                      style={{minWidth: 250, height: 35}}
                     
                      value={MailboxStatus}
                      label="Status"
                      onChange={(e)=>{
                          console.log('change')
                          setMailboxStatus(e.target.value)
                      }
                      
                      }
                  >
                      <MenuItem value={"Active"}>Active</MenuItem>
                      <MenuItem value={"Disabled"}>Disabled</MenuItem>
                  </Select>

          </div><br/>

        
      
      
      <div>
      <Button variant="contained"
              className="buttons"
                //color="primary"
                onClick={()=>{

                  ///setCurrentView('CreateCompany');


                  let document = {
                    "email_address": EmailAddress,
                    "company_name": CompanyName,
                    "mailserver": MailServer,
                    "mailbox_type": MailboxType,
                    "username": UserName,
                    "password": Password,
                    "mailbox_status": MailboxStatus
                  }



                  console.log('Setup Mailbox');
                  console.log(document);


                  try{
        
                   
              
                    fetch(server_url + "/create_mailbox",{
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(document)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxxx company status');
                                console.log(data);
                                console.log('xxx2 company status');
                                //resolve(data);
                                history.push('/superadmin'); 
                        
                        });
                 }
                catch(err){
                        console.log(err);
                       // reject(err);
                }

                }}>Setup Mailbox</Button>
                <Link to="/superadmin">
          <Button className="center MuiButton-root detailBtn">Back to Companies List</Button>
      </Link>
      </div>
      </div>
  )

}

export function UpdateProfile(){

 const history = useHistory();
  const [Authenticated, setAuthenticated] = useState();
  const [UserName, setUserName] = useState();
  const [user_status, setuser_status] = useState();
  const [company_name, setcompany_name] = useState();
  const [password, setpassword] = useState();
  const [user_role, setuser_role] = useState();
  const [user_email, setuser_email] = useState();

  useEffect(()=>{
    //setAuthenticated(props.Authenticated);
    //setUserName(props.UserName);
   // console.log('setting: ' + props.Authenticated); 

    async function gusers(){

      let userData = await get_users();
      let UserName = userData[0].UserName;
      let user_status= userData[0].UserStatus;
      let company_name= userData[0].CompanyName;
      let password= userData[0].password;
      let user_role= userData[0].UserRole;
      let user_email= userData[0].user_email;

      setUserName(UserName);
      setuser_status(user_status);
      setcompany_name(company_name);
      setpassword(password);
      setuser_role(user_role);
      setuser_email(user_email);
     
    }

    gusers();

  },[]);
  

      async function get_users(){

        return new Promise(function (resolve,reject){
        
        try{
        
        const token = localStorage.getItem('AccessToken');
        
        let options = {
        
        headers: {
        
        'Authorization': 'Bearer ' + token
        
        },
        
        insecure: true,
        
        rejectUnauthorized: false
        
        }
        
        
        fetch(server_url + "/company_users", options)
        
        .then(response => response.json())
        
        .then(function(data){
        
        console.log('xxx');
        
        console.log(data[0].UserName);
        
        console.log('xxx2');
     const username = data[0].UserName;
     
        console.log(data);
        resolve(data);
     
        
        });
        
        }
        
        catch(err){
        
        console.log(err);
        
        reject(err);
        
        }
        
        })
        
        }

    return(
        <div>
        <div >
          <h1>Update User Profile</h1>
         
         <div>
      <div>
          <div authenticated={Authenticated} UserName={UserName}>
          <div className="labelBox Mui-disabled" m={2} p={2} padding={3} >
          <p style={{fontSize:10, paddingTop: 3, margin:0, textAlign:'left', color:'#2E8A7C',}}>Company Name</p>
          <TextField inputProps={{ readOnly: true }}  className="inputSpace Mui-disabled" id="company_name" variant="outlined" value={company_name}
                
            /> 
            </div>
            <div className="labelBox Mui-disabled" m={2} p={2} padding={3} >
            <p style={{fontSize:10, paddingTop: 3, margin:0, textAlign:'left', color:'#2E8A7C',}}>Name</p>
           <TextField inputProps={{ readOnly: true }}   className="inputSpace Mui-disabled"  id="username"  variant="outlined" value={UserName}
             
             />
            </div>
          </div>
         
          <div>
          <div className="labelBox" m={2} p={2} padding={3} >
          <p style={{fontSize:10, paddingTop: 3, margin:0, textAlign:'left', color:'#2E8A7C',}}>User Password</p>
             <TextField type="password" className="inputSpace"  id="password"  variant="outlined" value={password}
              onChange={(e)=>{
                //console.log('change')
                setpassword(e.target.value)
                }}
             /> 
             </div>
             <div className="labelBox Mui-disabled" m={2} p={2} padding={3} >
             <p style={{fontSize:10, paddingTop: 3, margin:0, textAlign:'left', color:'#2E8A7C',}}>User Role</p>
             <TextField inputProps={{ readOnly: true }} className="inputSpace Mui-disabled"  id="user_role"  variant="outlined" value={user_role}
               onChange={(e)=>{
                //console.log('change')
                setuser_role(e.target.value)
                }}
             />
             </div>
             </div>
          
          <div>
            {/* <TextField id="CompanyStatus" label="Company Status" variant="outlined" /> */}  
            <div className="labelBox" m={2} p={2} padding={3} >
            <p style={{fontSize:10, paddingTop: 3, margin:0, textAlign:'left', color:'#2E8A7C',}}>User Email</p>
            <TextField id="user_email" variant="outlined" value={user_email}
              onChange={(e)=>{
                //console.log('change')
                setuser_email(e.target.value)
                }}
             /> 
             </div>
             <div className="labelBox Mui-disabled" m={2} p={2} padding={3} >
             <p style={{fontSize:10, paddingTop: 3, margin:0, textAlign:'left', color:'#2E8A7C',}}>User Status</p>
           <TextField inputProps={{ readOnly: true }}  id="user_status Mui-disabled"  variant="outlined" value={user_status}
              
             /> 
            </div>
                      
          </div>

        
      </div>
      <br />
      <div>

     
      <Button variant="contained"
                //color="primary"
                onClick={()=>{

                  let document = {
                    "CompanyName": company_name,
                    "UserName": UserName,
                    "UserPassword": password,
                    "UserRole": user_role,
                    "UserEmail": user_email,
                    "UserStatus": user_status,
                  }



                  console.log('Setup New User');
                  console.log(document);


                  try{
        
                   
              
                    fetch(server_url + "/update_user",{
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(document)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxxx user status');
                                console.log(data);
                                console.log('xxx2 user status');
                                //resolve(data);
                                history.push('/superadmin'); 
                        
                        });
                 }
                catch(err){
                        console.log(err);
                       // reject(err);
                }

                }}>Update User</Button> 
            </div>
            </div>
            </div>
           
        </div>
     
    )
}

export default function SuperAdmin() {


    const [CurrentView,setCurrentView] = useState();

    useEffect(() => {




    },[])

    


    return(

        <div>
        <div id="bodyBoxAdmin">
        <div ><h3><ListItemIcon><AddModeratorIcon/></ListItemIcon>SUPER ADMIN CENTRE</h3></div>
        <div >
          <div><Tabsadmin></Tabsadmin></div>  

        </div>
            
        </div>
        <div>

          
               




        
          </div>



        </div>

        
    );


 



}