

import React, {useCallback, useState, useMemo, useEffect} from 'react'
import dotenv from  'dotenv'
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';



//import { Document, Page } from 'react-pdf';

// using CommonJS modules
// import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';




import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
//import { FullFileBrowser } from 'chonky';
import { ChonkyActions, FileBrowser, FullFileBrowser, FileNavbar, FileToolbar, FileList } from 'chonky';
import OpenInNewIcon from '@material-ui/icons/OpenInNew'



//import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { DataGrid } from '@material-ui/data-grid';
import MuiDrawer from '@mui/material/Drawer';


// Somewhere in your `index.ts`:


import clsx from 'clsx';
import { styled, makeStyles, useTheme } from '@material-ui/core/styles';
//import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import LogOutIcon from '@mui/icons-material/Logout';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Splitter, { SplitDirection } from '@devbookhq/splitter'
 


//Nav Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@material-ui/icons/Description';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SyncIcon from '@mui/icons-material/Sync';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import ArchiveIcon from '@mui/icons-material/Archive';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';


import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';


import InvoiceModal from './invoicemodal';
import InvoiceWorkflow from './InvoiceWorkflow';

//import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';


//import './App.css';
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';



//import MainAdmin from './templates/MainAdmin';
import { v4 as uuidv4 } from 'uuid';





//redux
import { useSelector, useDispatch } from 'react-redux';
import { loggedin,loggedout, AccessData } from '../redux/authSlice';



import { Domain, SingleBedOutlined } from '@material-ui/icons';

import jwt_decode from "jwt-decode";
import Tooltip from '@mui/material/Tooltip';
import { create } from '@mui/material/styles/createTransitions';

import jwt from "jwt-decode";






let server_url = process.env.REACT_APP_SERVER_URL;


const useStyles = makeStyles((theme) => ({

    button:{
  
      background:'#2E8A7C',
      color: 'white',
      padding: 5,
      paddingRight:12,
      paddingLeft: 12, 
      margin: 5,
      minWidth: 90,
  
    },
  
    
    
    table: {
      minWidth: 400,
      maxWidth: 400,
      
      
    },
  
    
  
    root: {
      
      display: 'flex',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },'& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    appBar: {
     
      background:'#535353',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    menuButton: {
      marginRight: 36,
      
    },
    hide: {
      display: 'none',
    },


    toolbar: {
      
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      
      flexGrow: 1,
      padding: theme.spacing(3),
      
    },
    instructions: {
      color: 'red',
      fontFamily: 'Arial',
      fontSize:20,
      textTransform:'uppercase',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  
    
  }));



  ///delete_from_processing_queue_uuid
  async function delete_from_processing_queue_uuid(dataObject){
  
  
    return new Promise(function (resolve,reject){
  
      try{
  
                const token = localStorage.getItem('AccessToken'); 
                let options = {
                  headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  },
                  insecure: true,
                  rejectUnauthorized: false
                }
  
                let myheaders = {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                  
        
                  fetch(server_url + "/delete_from_processing_queue_uuid",{
                    method: 'POST',
                    //headers: { 'Content-Type': 'application/json' },
                    headers: myheaders,
                    body: JSON.stringify(dataObject)
                    })
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);
                      
                      });
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
  
  
  
  
  }



export function MyTableHeader(props){


  //Loop through single elements
  let singleFieldData = props.jsonData;


  const [DocTag,setDocTag] = useState(props.DocTag);

  const[FieldData,setFieldData] = useState([props.jsonData]);
  let keys = Object.keys(props.jsonData);
  const[Fields,setFields] = useState(keys);

  const [TestField,setTestField] = useState();
  const [RunOnce, setRunOnce] = useState(true);

  const [DocName,setDocName] = useState();
  //const [DocSender,setDocSender] = useState();
  //const [DocTag,setDocTag] = useState();

  const [UUID, setUUID] = useState();


  
  useEffect(() => {

    console.log('Field Data');
    let fieldData = props.jsonData;
    let keys = Object.keys(props.jsonData);


    let docTag = props.DocTag;
    setDocTag(docTag);

    setUUID(props.UUID);

    //setDocName(props.document_name);
    //setDocTag(props.doc_tag);
    //setDocSender(props.doc_sender);

    

    if(keys.indexOf('DocName') < 0 ){
        keys.push('DocName');
    }

    

    console.log(props.jsonData);
    //console.log(keys);
    setFields(keys);
    setFieldData(fieldData);
     // let jdata = props.jsonData;
     console.log(FieldData);

      //f(RunOnce){
      //  keys.push('DocName');
       // setRunOnce(false);
      //}

      
      
    },[props])

  //},[props])





  return(

  <div >

      {/*
      <div m={2} p={2} padding={3}>

              <p style={{fontSize:10, paddingTop: 10, margin:0, textAlign:'left', color:'#2E8A7C',}}>TemplateName</p>
              <TextField style={{marginTop: 0, width:'100%'}} m={2} p={2} id="DocName"  variant="outlined"
              id='DocName'
              //value={FieldData[item]}
              value={DocName}

              onChange={(e)=>{

                  console.log(e.target.value);
                  setDocName(e.target.vallue);
                  //Send Header Data back to parent
                  //props.parentCallback({headerData: FieldData});
                  props.parentCallback({DocName: DocName});

              }}
              ></TextField>
              
              <p style={{fontSize:10, paddingTop: 10, margin:0, textAlign:'left', color:'#2E8A7C',}}>DocTag</p>
              <TextField style={{marginTop: 0, width:'100%'}} m={2} p={2} id="DocTag"  variant="outlined"
              id='DocTag'
              //value={FieldData[item]}
              value={DocTag}

              onChange={(e)=>{

                  console.log(e.target.value);
                  setDocTag(e.target.vallue);
                  //Send Header Data back to parent
                  //props.parentCallback({headerData: FieldData});
                  props.parentCallback({DocTag: DocTag});

              }}
              ></TextField>

<p style={{fontSize:10, paddingTop: 10, margin:0, textAlign:'left', color:'#2E8A7C',}}>DocTag</p>
              <TextField style={{marginTop: 0, width:'100%'}} m={2} p={2} id="DocSender"  variant="outlined"
              id='DocSender'
              //value={FieldData[item]}
              value={DocSender}

              onChange={(e)=>{

                  console.log(e.target.value);
                  setDocSender(e.target.vallue);
                  //Send Header Data back to parent
                  //props.parentCallback({headerData: FieldData});
                  props.parentCallback({DocSender: e.target.value});

              }}
              ></TextField>

      </div>
            */}

  
        

  {Fields.map((item,i) => (
 
          <div m={2} p={2} padding={3} >



          <p style={{fontSize:10, paddingTop: 10, margin:0, textAlign:'left', color:'#2E8A7C',}}>{item}</p>
         <TextField style={{marginTop: 0, width:'100%'}} m={2} p={2} id={item}  variant="outlined"
         id={'field_id_' + item}
         
         //value={FieldData[item]}
         value={FieldData[item]}
         

        
       
         onChange={(e)=>{

              console.log('testing:');
              console.log(e.target.value);
              //FieldData[item] = e.target.value;

              console.log(FieldData);
              let newData = FieldData;
              newData[item] = e.target.value;
              //setFieldData({...FieldData, newData});
              setFieldData({...FieldData});
              FieldData[item] = e.target.value;
              console.log(newData);
              console.log(FieldData);
              setTestField(e.target.value);
              //Send Header Data back to parent
              props.parentCallback({headerData: FieldData});
          

         }}
          ></TextField>
         
          </div>
     ))
   
  }
  </div>

  );
}

//}



export function MyDataGrid(props) {


  const [RowHeader, setRowHeader] = useState([]);
  const [RowData, setRowData] = useState([]);
  const [TableName, setTableName] = useState();
  const [ColumnData, setColumnData] = useState([])

  const [deletedRows, setDeletedRows] = useState([]);

  const [HeaderData, setHeaderData] = useState([]);


  const [SingleFieldData, setSingleFieldData] = useState([]);

  const [SingleRowData, setSingleRowData] = useState([]);
  const [SingleColumnData, setSingleColumnData] = useState([]);

  const [DocName,setDocName] = useState();
  const [DocSender,setDocSender] = useState();
  const [DocTag,setDocTag] = useState();
  const [UUID,setUUID] = useState();

  const classes = useStyles();



  async function HeaderCallback(data, DocName,DocTag,DocSender,UUID){


    console.log(data);
    setDocName(DocName);
    setDocTag(DocTag);
    setDocSender(DocSender);
    console.log("xx:" + DocSender);
    setHeaderData(data);

    console.log(UUID);
  }
  

  useEffect(() => {
    console.log(props)

    let jdata = props.jsonData;


    setUUID(props.UUID);


    setTableName(props.TableName);
    setSingleFieldData(props.SingleFieldData); 

    setDocName(props.document_name);
    setDocSender(props.doc_sender);
    setDocTag(props.DocTag);




    //Setup Single Line Data
    console.log('setting:' + JSON.stringify(SingleFieldData));



    setSingleRowData(props.SingleFieldData);
    let mySingleObject = new Object; 
    let singleData = props.SingleFieldData;

    let y=0; const yMax = singleData.length -1; for(; y<=yMax; y++){

      singleData[y]['id'] = y; //Add id key to each field


      let myskeys = Object.keys(singleData[x]);

     
     
      //Add Headers
     myskeys.forEach((item) => {
     let singleheaderdata = {
        field: item,
        headerName: item,
        //rowHeight:30,
        width: 150,
        editable: true,
      };

      mySingleObject[item] = singleheaderdata;

      //Add Rows
      //{ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
      //console.log(jdata[x]);
      




    })

    if(y == yMax){
      
      
   

       //add custom columns
       mySingleObject["ReconName"] = {
        field: "ReconName",
        headerName: "ReconName",
        width: 150,
        editable: true,
      };

      
        


      let singleitems = Object.keys(mySingleObject);
      let singlecolArray = new Array();
      let a=0; const aMax = singleitems.length - 1; for(; a <= aMax; a++){
          singlecolArray.push(mySingleObject[singleitems[a]]);

          if(a===aMax){

            console.log(singlecolArray);
            setSingleColumnData(singlecolArray);
          }

        }
     

      }


    }



    //set Row Data

    setRowData(jdata);

    //Setup Headers
    let toplen = 0;

    let myObject = new Object;
    let rowArray = new Array;
    console.log("L: " + jdata.length);
    let x=0; const xMax = jdata.length -1; for(; x<=xMax; x++){

          jdata[x]['id'] = x; //Add id key to each field


          let mykeys = Object.keys(jdata[x]);

         
         
          //Add Headers
         mykeys.forEach((item) => {
         let headerdata = {
            field: item,
            headerName: item,
            width: 150,
            editable: true,
          };

          myObject[item] = headerdata;

          //Add Rows
          //{ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
          //console.log(jdata[x]);
          




        })

        if(x == xMax){
          
          
       

           //add custom columns
           myObject["note"] = {
            field: "Note",
            headerName: "Note",
            width: 150,
            editable: true,
          };

          
            


          let items = Object.keys(myObject);
          let colArray = new Array();
          let z=0; const zMax = items.length - 1; for(; z <= zMax; z++){
              colArray.push(myObject[items[z]]);

              if(z===zMax){

                console.log(colArray);
                setColumnData(colArray);
              }

            }
          
           



          }


        }

         
    



  },[props]);



  const handleRemove = (id) => {
    const newRowData = RowData.filter((row => row.id !== id));
 
    setRowData(newRowData);

    console.log(RowData);


  };


  const handleCellEditCommit = (updateitem) => {

    console.log(updateitem);

    let id = updateitem.id;

    let newRowData = new Array();

    let i=0; const iMax = RowData.length -1; for(; i <= iMax; i++){

      let item = RowData[i];
      let cid = item.id;
      if(id == cid){

        console.log('Match');
        //console.log(row);
        console.log(item);
        let field = updateitem.field;
        let value = updateitem.value;
        item[field] = value;
        //console.log(updateitem);
        
        //update Row witn new Row
        newRowData.push(item);

      } 
      else{

        newRowData.push(item);
      }

      if(i === iMax){
        setRowData(newRowData);
      }

    }

  }

  return (

    <>
  <div style={{ height: 900, width: '100%', display: 'block',  alignItems:'center'}}  >

     

      <div>
         <MyTableHeader jsonData={SingleFieldData} document_name={DocName} DocTag={DocTag} UUID={UUID} parentCallback = {HeaderCallback}></MyTableHeader>
      </div>

      <div>

  </div>

      
      
   
      <br></br>
  <div style={{ height: 600, width: '100%', display: 'flex',  justifyContent:'center', alignItems:'center', }}>
      <DataGrid
        rows={RowData}
        columns={ColumnData}
        pageSize={50}
        rowHeight={30}
        
        rowsPerPageOptions={[50]}
        //checkboxSelection
        //checkboxSelection={false}
        onCellEditCommit={handleCellEditCommit}
        //disableSelectionOnClick
        //onSelectionModelChange={({selectionModel}) => {
        //  const rowIds = selectionModel.map(rowId => parseInt(String(rowId), 10));
        //  const rowsToDelete = RowData.filter(row => rowIds.includes(RowData.id));
        //  setDeletedRows(rowsToDelete);
        //
        onSelectionModelChange={(ids) => {
          console.log(ids);
          setDeletedRows(ids);
       
        }}

      />
  </div>

      <Divider />
</div>
    <div id="my_wrapper">
      <div id="looksgood">
          <Button 
            variant="contained" 
            className={classes.button}
            //color="primary"
            onClick={async ()=> {

              var docName = document.getElementById('field_id_DocName').value.length;
              
              console.log(docName);
              if(docName < 1 || docName == undefined){
                alert('Please enter Document Name');
                return
              }else{


                

                console.log('saving');
                  props.parentCallback({header_data: HeaderData, mydata: RowData});

                  console.log('HHHH');
                  console.log(HeaderData);
                  console.log(RowData);
                  console.log('END HHHH');
                  alert("Recon Successfully completed!");
                  //Remove document from processing queue
                  let jobj = {
                    uuid: UUID
                  }
                  console.log('UUUUUUU');
                  console.log(jobj);
                  //let resdata = 'tmp disabled delete';
                    let resdata = await delete_from_processing_queue_uuid(jobj);
                  console.log(resdata)
                  if(resdata.ok == 1){
                    console.log('reloading')
                    window.location.href = "/processing_queue";
                  }
                  
                  

              }
                  

            }}
            >
          Looks Good - Lets Start a Recon1
        </Button>
      
      </div>

      <div id="deleteBtn" style={{display: 'block',  justifyContent:'center', alignItems:'center'}}>
          <Button
              variant="contained" 
              //color="primary" 
              className={classes.button}
              onClick={() => { 
                  

                  console.log("deleting:" + JSON.stringify(deletedRows));
                  //handleRowSelection();



                  deletedRows.forEach((item) => {

                    console.log('removing: ' + item);
                    handleRemove(item);

                })

                
                }}>
                  Delete Row
          </Button>
       </div>
    </div>
    
 </>
  );
}







function BasicTable(props) {

  const classes = useStyles();


  const [RowHeader, setRowHeader] = useState([]);
  const [RowData, setRowData] = useState([]);
  const [TableName, setTableName] = useState();
  

  useEffect(() => {
  console.log(props)

    let jdata = props.jsonData;
    setTableName(props.TableName);
    setRowData(jdata);
    console.log('Updating Table Data');

    //Get Headers
    let toplen = 0;
    let x=0; const xMax = jdata.length -1; for(; x<=xMax; x++){

          let mykeys = Object.keys(jdata[x]);
          console.log(mykeys.length)
          if(parseInt(mykeys.length) > parseInt(toplen)){
            setRowHeader(mykeys);
            toplen = parseInt(mykeys.length);
          }
          console.log(mykeys);

          if(x === xMax){
            console.log('RowHeader');
            console.log(RowHeader);
          }
    }



  },[props]);




  return (

 
    <TableContainer component={Paper}>
      


         <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
              <TableCell>{TableName}</TableCell>
          </TableRow>
          <TableRow>
                <TableCell>Date</TableCell>
          </TableRow>

          <TableRow>
                <TableCell>Status</TableCell>
          </TableRow>
          
        </TableHead>
        </Table>


      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>

          

          {RowHeader.map((row,i) => (
                       
                        <TableCell><b>{row}</b></TableCell>
                        

          ))}

       
          </TableRow>
        </TableHead>
        <TableBody>

          {RowData.map((row,i) => (

              <TableRow key={row}>

                {Object.keys(row).map((key) => {

                      return(

                    
                        <TableCell component="th" scope="row">
                          {JSON.stringify(row[key])}

                          </TableCell>
                     

                          )
                  })}

                  </TableRow>
                 

                

              
          ))}
    
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export const MyFileBrowser = () => {

  

  const fileActions = React.useMemo(
    () => [
        ChonkyActions.CreateFolder, // Adds a button to the toolbar
        ChonkyActions.UploadFiles, // Adds a button
        ChonkyActions.DownloadFiles, // Adds a button
        ChonkyActions.CopyFiles, // Adds a button and a shortcut: Ctrl+C
        ChonkyActions.DeleteFiles, // Adds a button and a shortcut: Delete
    ],
    []
);


  const files = [
      { id: 'lht', name: 'projects', isDir: true },
      {
          id: 'mcd',
          name: 'chonky-sphere-v2.png',
          thumbnailUrl: 'https://chonky.io/chonky-sphere-v2.png',
      },
  ];
  const folderChain = [{ id: 'xcv', name: 'projects', isDir: true }];
  return (
      <div style={{ height: 300 }}>
          {/* <FullFileBrowser files={files} folderChain={folderChain} /> */}
       
      

        <FullFileBrowser files={files} folderChain={folderChain} fileActions={fileActions}/>
      </div>
  );
};



async function process_file(filename){


  //let server_url = 'http://127.0.0.1:3002';
  return new Promise(function (resolve,reject){

    try{


                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    

                    fetch(server_url + "/process_file2?filename=" + filename, options)
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })



}




function PdfViewer(props){





  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [FileName, setFileName] = useState('');

  let token = localStorage.getItem('AccessToken');
  let decoded_token = jwt(token);
  console.log('decoded:');
  console.log(decoded_token);
  let mycompany = decoded_token.company;


  useEffect(()=>{


            console.log('Loading....');

          console.log(props);
          //let server_url = 'http://127.0.0.1:3002';

          if(props.Stored){
            setFileName(server_url + "/companies/" + mycompany + "/" +  props.FileName);
          }
          else{
              setFileName(server_url + "/uploads/" +  props.FileName);
          }
          console.log("FNAME: " + FileName);
          

  },[props])

  function onDocumentLoadSuccess({ numPages }) {



    const spinner = document.getElementById('loading-spinner');
    var pages = document.getElementById('pageNumberP');
    
    spinner.style.display='none';
    pages.style.display= 'block';
    setNumPages(numPages);


    
    

  }

  function onDocumentLoadError(err){
    console.log(err);
  }

 


  return(


    <div>
            <Document
              file={FileName}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}

            >
              <Page pageNumber={pageNumber} />
            </Document>
            <p id="pageNumberP">Page {pageNumber} of {numPages}</p>
          </div>



  )



}







async function process_queue_file(filename){

    //https://automate.isnet.co.za/process_file2?filename=mustek&trained=true

    console.log('processing queue4 file....');

  
        return new Promise(function (resolve,reject){
      
          try{
      
      
            const token = localStorage.getItem('AccessToken'); 
      
          

                      
      
                      
                      fetch(server_url + "/process_file2?filename=" + filename + "&queue=true",{
                        method: 'GET',
                        headers: { 
                                    'Content-Type': 'application/json',
                                    Authorization: 'Bearer ' + token
                        }
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
      
      
      
      
      
      


}




function ProcessData(props) {




const [FileName, setFileName] = React.useState();
const [SingleFieldData, setSingleFieldData] = useState([]);

const [SingleRowData, setSingleRowData] = useState([]);
const [SingleColumnData, setSingleColumnData] = useState([]);

const [DocName,setDocName] = useState();
const [DocSender,setDocSender] = useState();
const [DocTag,setDocTag] = useState();
const [JsonData,setJsonData] = useState();
const [DocLink, setDocLink] = useState();

const [Company,setCompany] = useState();

const [Data, setData] = useState([]);


const [UUID,setUUID] = useState();
const [UUIDNotFound,setUUIDNotFound] = useState(false);

const [Stored, setStored] = useState();




const [ProcessRouting, setProcessRouting] = useState();

/*
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
*/
function useQuery(){
    return new URLSearchParams(useLocation().search);

}




//Post Recon Data to API
async function create_data(dataObject){
  
  
  return new Promise(function (resolve,reject){

    try{

              const token = localStorage.getItem('AccessToken'); 
              let options = {
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                },
                insecure: true,
                rejectUnauthorized: false
              }

              let myheaders = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
              };
                
      
                fetch(server_url + "/create_data",{
                  method: 'POST',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  body: JSON.stringify(dataObject)
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            //resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}

//Post Recon Data to API
async function get_doc_uuid(uuid){
  
  
  return new Promise(function (resolve,reject){

    try{

              const token = localStorage.getItem('AccessToken'); 
              let options = {
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                },
                insecure: true,
                rejectUnauthorized: false
              }

              let myheaders = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
              };
                
      
                fetch(server_url + "/get_repo_doc_uuid?uuid=" + uuid,{
                  method: 'GET',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  //body: JSON.stringify(dataObject)
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}




useEffect(()=>{



  let uuid = query.get("uuid");
  let stored = query.get("stored");

  setUUID(uuid);
  console.log(uuid + " set");


  if(stored){

    setStored(true);

  }


  

  //Search for document data ini doc_repo
  async function get_uuid_doc(uuid){
     let result = await get_doc_uuid(uuid);
     console.log("Doc data found by uuid --->");

     console.log(result[0]);


     try{

     if(result[0].doc_tag == "Invoice"){


      console.log("zzz: Saved INVOICE FLOW DETECTED")
      //Show Modal

      console.log("UUID value: " + uuid);
      setUUID(uuid);
      setUUIDNotFound(false);

      result[0].pages = new Array();
      result[0].pages[0] = new Object();
  
      result[0].pages[0].single_field_data = JSON.parse(result[0].single_data);

      let myrows = JSON.parse(result[0].row_data)
      result[0].pages[0].table_data = myrows.rowsData;

      setProcessRouting("InvoiceProcess")



      setData(result[0]);

      //fixup singlefield_data = 
      let single_field_data =  result[0].pages[0].single_field_data;
      console.log("SSSSSSSSSingle");
      console.log(single_field_data);
      result[0].pages[0].single_field_data.NetTotal = single_field_data.INVSubTotal;
      result[0].pages[0].single_field_data.Total = single_field_data.INVTotal;
      result[0].pages[0].single_field_data.Tax = single_field_data.INVTax;

      result[0].pages[0].single_field_data.SubTotal = single_field_data.INVSubTotal;
      result[0].pages[0].single_field_data.Total = single_field_data.INVTotal;
      result[0].pages[0].single_field_data.TaxTotal = single_field_data.INVTax;

      console.log('SSSingl2:');
      console.log(result[0].pages[0].single_field_data);

      //setFileName("VLAHH");
      setDocName(result[0].doc_name);
      setDocTag(result[0].doc_tag);
      setDocSender(result[0].doc_sender);
      setSingleFieldData(result[0].pages[0].single_field_data);
      setJsonData(result[0].pages[0].table_data);


    }

   
    
  }
  catch(err){


    console.log(err);
    //process.exit();

    console.log('Document not found saved');

    get_file_data(filename);
    setUUIDNotFound(true);
  }


    /*
    setData(result[0]);

    //setFileName("VLAHH");
    setDocName(result[0].doc_name);
    setDocTag(result[0].doc_tag);
    setDocSender(result[0].doc_sender);
    setSingleFieldData(result[0].single_data);
    setJsonData(result[0].row_data);

    */


  }
  get_uuid_doc(uuid);





    async function get_file_data(filename){

       
      let token = localStorage.getItem('AccessToken');
      let decoded_token = jwt(token);
      console.log('decoded:');
      console.log(decoded_token);
      setCompany(decoded_token.company);


        console.log('trying: ' + filename);
        

        let result = await process_queue_file(filename);


    
        console.log(result);

        let doc_name = result.docname;


        if(doc_name === 'notfound'){

          console.log("ERROR Document not found"); 
  
          //pages.style.display= 'block';
          setDocName('notfound');

          document.getElementById('doc_status').innerHTML = `Error Trained Document not found: 
          <br><br> <a href="${server_url}/get_queue_pdf_file?filename=${filename}&company=${decoded_token.company}">Download File</a>
          
          And Train it in the trainer
          `;
         

        
                      
        }
        else{
         

          console.log("RESULT RESULT");
          console.log("X: " + result.doc_name);
          console.log("X: " + result.doc_tag);
          console.log("X: " + result.doc_link);
          console.log("Y: " + result.status);

          let status = result.status;
          let doc_name = result.doc_name;

          if(!doc_name){
            console.log('doc_name not set');
          }

          try{
          if(status.includes('error no data')){

              console.log('No no no data');

              const spinner = document.getElementById('loading-spinner');

              const errorPage = document.getElementById('errorPage');
              //var pages = document.getElementById('pageNumberP');
              
              spinner.style.display='none';
              errorPage.style.display='block';



              return;

          }
          }
          catch(err){
            console.log(err);
          }


          /*
          
          if(result.status == "error no data"){

            console.log('No data detected');
            setProcessRouting('ERROR');
            console.log('Set Error');


            const spinner = document.getElementById('loading-spinner');

            const errorPage = document.getElementById('errorPage');
            //var pages = document.getElementById('pageNumberP');
            
            spinner.style.display='none';
            errorPage.style.display='block';
            //pages.style.display= 'block';
            //setNumPages(0);
        
            //return;
          }
          */
          
          

          setDocLink(result.doc_link);
      


          if(result.doc_tag == "Invoice"){


            console.log("X: INVOICE FLOW DETECTED")
            //Show Modal

            console.log("UUID value: " + uuid);
            setUUID(uuid);
    
            setProcessRouting("InvoiceProcess")


          }

          if(result.doc_tag == "Workflow"){


            console.log('Workflow Process Initiated');
            setProcessRouting("InvoiceProcess");
      
      
          }

          setData(result);



          //setFileName("VLAHH");
          try{
          setDocName(result.doc_name);
          setDocTag(result.doc_tag);
          setDocSender(result.doc_sender);
   
          setSingleFieldData(result.pages[0].single_field_data);
          setJsonData(result.pages[0].table_data);
          }
          catch(err){
            console.log(err);
          }
          
        }
       
    
    
    }

  
    let filename = query.get("filename");
   
   // let filename = DocLink;
   if(DocLink){
    filename = DocLink;
   }

    console.log("FF: " + filename);
    setFileName(filename);

    if(UUIDNotFound){

   
      console.log('uuid not found')
      //saved UUID not found  continue processing
      get_file_data(filename);

    }

   // 

    


},[]);


async function DataTableCallback(childData){
  
    console.log('here dt callback');
    console.log(childData);
    let header_data = childData.header_data;
    console.log('HEADER');
    let filename = query.get("filename");
    console.log("FF: " + filename);
    setFileName(filename);
    //console.log(header_data.headerData);





    let header = header_data.headerData;
    let docName = header.DocName;
    //let docName = DocName;
    let docTag = DocTag;
    let docSender = DocSender;
    let docLink = DocLink;


    let row_data = childData.mydata;

    let mobj = {
      docname: docName,
      docSender: docSender,
      docTag: docTag,
      docLink: docLink,
      single_data: header,
      row_data: row_data
    }

    console.log(mobj);

    //Post to create recon
    //let resp = await create_data(mobj);
    //console.log(resp);
     //Post to create recon
     let resp = await create_data(mobj);
     console.log(resp);

     console.log("TO DO - Delete document from processing queue if its ok");
     

     

  }

  let query = useQuery();


  if(JsonData){


  return (
<div>
    <div className='tagRow'>
        {/* ProcessData */}
        <div className='tagContainer'>

          <p>Document Name</p>
          <h4>{DocName}</h4>
        </div>
        <div className='tagContainer'>
          <p>Document Sender</p>
          <h4>{DocSender}</h4>

        </div>
        <div className='tagContainer'>
          <p>Document Tag</p>
          <h4>{DocTag}</h4>

        </div>
</div>
        {/*<TextField
          required
          id="outlined-required"
          label="Document Name"
          defaultValue={DocName}
          disabled
        />
         

        <TextField
          required
          id="outlined-required"
          label="Document Sender"
          defaultValue={DocSender}
          disabled
        />

        <TextField
          required
          id="outlined-required"
          label="Document Tag"
          defaultValue={DocTag}
          disabled
        />*/}
   
    <div>

        {/* <div id="filewrappers"> */}



       

        <div id="loading-spinner">
        <div className="spin-icon"></div>

      </div>

      {/*
      <div id="errorPage">
        <div>An Error has occured with the document</div>

      </div>
      */}
        

        
      {ProcessRouting === 'InvoiceProcess' ?



      <Splitter id="sppliter my_wrapper" direction={SplitDirection.Vertical}>
      <div  className="container"> 
  <div id="infoTable" className="container" style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: "100%"}}>
  {/*<InvoiceModal po_number={SingleFieldData.PurchaseOrder} pdf_url={FileName} invoice_data={Data}></InvoiceModal>*/}
  <InvoiceWorkflow doc_name={DocName} doc_tag={DocTag} doc_sender={DocSender} po_number={SingleFieldData.PurchaseOrder} PurchaseOrder={SingleFieldData.PurchaseOrder} InvoiceNumber={SingleFieldData.InvoiceNumber} UUID={UUID} pdf_url={FileName} invoice_data={Data}/>

  
 

</div>
 </div>



       

<div style={{width: "100%", justifyContent:"center", textAlign:"center"}}>

<div id="canvasContainer">


<PdfViewer FileName={FileName} Stored={Stored}></PdfViewer>
</div>




</div>

</Splitter>
  
  
   :
   <Splitter id="sppliter my_wrapper" direction={SplitDirection.Horizontal}>
   <div style={{width: "100%", justifyContent:"center", textAlign:"center"}}>

<div id="canvasContainer">


<PdfViewer FileName={FileName}></PdfViewer>
</div>




</div>
      <div  className="container"> 

      

  <div id="infoTable" className="container" style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: "100%"}}>
<MyDataGrid  TableName="SupplierStatement" document_name={DocName} doc_tag={DocTag} doc_sender={DocSender} SingleFieldData={SingleFieldData} UUID={UUID} jsonData={JsonData} parentCallback = {DataTableCallback}></MyDataGrid>
</div>
 </div>



       



</Splitter>

}


{ProcessRouting === 'ERROR' &&



<Splitter id="sppliter my_wrapper" direction={SplitDirection.Vertical}>
<div  className="container"> 
<div id="infoTable" className="container" style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: "100%"}}>
{/*<InvoiceModal po_number={SingleFieldData.PurchaseOrder} pdf_url={FileName} invoice_data={Data}></InvoiceModal>*/}
<InvoiceWorkflow doc_name={DocName} doc_tag={DocTag} doc_sender={DocSender} po_number={SingleFieldData.PurchaseOrder} UUID={UUID} pdf_url={FileName} invoice_data={Data}/>




</div>
</div>



 

<div style={{width: "100%", justifyContent:"center", textAlign:"center"}}>

<div id="canvasContainer">


<PdfViewer FileName={FileName} Stored={Stored}></PdfViewer>
</div>




</div>

</Splitter>



}

     
  
     
   
  
  
     
  
        </div>


      
  
       {/* </div> */}



    
    </div>
  

  
  );
  }
  else{

    return(
    <div id="doc_status">
    <div id="errorPage" style={{ display: "none" }}>An Error has occured - No Data Returned</div>
    <div id="loading-spinner">
        <div class="spin-icon"></div>
      <div id="filewrappers">
        
        </div>
        </div>
     </div>
    );
  }


}

export default ProcessData;


