import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';




let server_url = 'https://automate.isnet.co.za';
//let server_url = 'http://127.0.0.1:3404';



/*

    let invoice_json = {
    "invoices": [
    {
        "type": "ACCREC",
        "contact": {
        "name": customer_name
        },
        "dateString": "2021-03-05",
        "dueDate": "2021-04-30",
        "status": "SUBMITTED",
        //"status": "AUTHORISED",
        "reference": ref,
        "currencyCode": "ZAR",
        "lineItems": line_items
    }
    ]
}



      let line_time = {
                        "lineItemID": itemID,
                        "description": description,
                        "quantity": hours,
                        "unitAmount": unitPrice,
                        "lineAmount": unitPrice * hours,
                        "accountCode": accountCode,
                        "tracking": [
                        {
                            "name": "Sales Reps Once-off",
                            "option": salesRepName
                        }
                        ]
                    }

                    line_items.push(line_item);


*/


function ProcessInvoice (){


    const [Status,setStatus] = React.useState();



    useEffect(()=>{
 
        console.log('Signup');
        
    
      });


      async function post_xero_invoice(dataObject){
  
  
        return new Promise(function (resolve,reject){

          try{

                  

                    const token = localStorage.getItem('AccessToken'); 


                    let myheaders = {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      };
                        

                      let line_items = new Array();

                      let line_item = {
                        "lineItemID": "",
                        "description": "Test Description",
                        "quantity": 1,
                        "unitAmount": 100,
                        "lineAmount": 100 * 1,
                        "accountCode": "2031011",
                        //"tracking": [
                       // {
                       //     "name": "Sales Reps Once-off",
                       //     "option": salesRepName
                       // }
                        //]
                    }

                    line_items.push(line_item);

                


                    let invoice_json = {
                        "invoices": [
                        {
                            "type": "ACCPAY",
                            "contact": {
                            "name": 'Richard Moore (Staff)'
                            },
                            "dateString": "2022-03-03",
                            "dueDate": "2022-02-03",
                            "status": "SUBMITTED",
                            //"status": "AUTHORISED",
                            //"reference": ref,
                            "reference": 'RMTesting',
                            "currencyCode": "ZAR",
                            //"lineItems": line_items
                            "lineItems": line_items,
                        }
                        ]
                    }
                    dataObject = invoice_json;

                      
            
                      fetch(server_url + "/post_xero_invoice",{
                        method: 'POST',
                        //headers: { 'Content-Type': 'application/json' },
                        headers: myheaders,
                        body: JSON.stringify(dataObject)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                               
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })




      }
    


    return(


      <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <FormControl variant="standard">


      <div>
        <TextField
          label="Supplier"
          id="username"
          defaultValue=""
          size="small"
          margin="dense"
        />
      </div>
          

      <div>
        <TextField
          label="Blah"
          id="password"
          type="password"
          defaultValue=""
          size="small"
          margin="dense"
        />
       
      </div>
       
      <div>
        <TextField
          label="Email"
          id="email"
          defaultValue=""
          size="small"
          margin="dense"
        />
       
      </div>


       
   


      <div>
      <Button variant="text" onClick={async ()=>{

           /* let username = document.getElementById('username').value;
            let password = document.getElementById('password').value;
            let email = document.getElementById('email').value;
       
            let hashed_pass = sha512(password);


            let jsonData = {
              username: username,
              password: hashed_pass,
              email: email
            }

            console.log(jsonData);
            let res = await signup_user(jsonData);
            if(res.status === 'OK'){
                setStatus('User Created');
            }
            else{
                setStatus(res.status);
            }
            */
            console.log('POSTING');
            post_xero_invoice();


      }}>Upload Invoice</Button>

       
      </div>
      <div>{Status}</div>



          
          
        </FormControl>    
 

    </Box>



    );
   }
    
   export default ProcessInvoice;