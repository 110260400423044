import React, { useRef, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ReactDOM from "react-dom";

import Editor from "@monaco-editor/react";

import Grid from '@material-ui/core/Grid';
import { Button, Divider } from '@material-ui/core';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




import LoadData from './loaddata';




const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
});








export default function MyEditor() {
  const classes = useStyles();


  const [value, setValue] = React.useState(0);

  const [NLoadData,setLoadData] = React.useState();
  const [NSaveCode,setSaveCode] = React.useState();

  const [TestCode, setTestCode] = React.useState();



  useEffect(()=>{


    let testcode = localStorage.getItem("testcode");
    setTestCode(testcode);

},[]);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };




  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor; 
  }
  
  function showValue() {
    alert(editorRef.current.getValue());



  }


  var runIframe = null, runIframeHeight = 0;


	function doRun(runContainer) {
		if (runIframe) {
			// Unload old iframe
			runContainer.removeChild(runIframe);
		}

		// Load new iframe
		runIframe = document.createElement('iframe');
		runIframe.id = 'runner';
		//runIframe.src = 'playground/playground-runner.html';
		runIframe.className = 'run-iframe';
		runIframe.style.boxSizing = 'border-box';
		runIframe.style.height = runIframeHeight + 'px';
		runIframe.style.width = '100%';
		runIframe.style.border = '1px solid lightgrey';
		runIframe.frameborder = '0';

        


		runContainer.appendChild(runIframe);



	

		runIframe.addEventListener('load', function (e) {
	
            console.log('Running Script');
            let myscript = runIframe.createElement("script");
            myscript.append("alert('testing');")
            
		});
	}

    function LoadPDF(){

        console.log('loading pdf');

        if(NLoadData){
            setLoadData(false);
        }
        else{
            setLoadData(true);
        }
        console.log(NLoadData);
    }

    

    function SaveCode(){

        console.log('Saving Code');

        localStorage.setItem('testcode', editorRef.current.getValue());

        if(NSaveCode){
            setSaveCode(false);
        }
        else{
            setSaveCode(true);
        }
        console.log(NSaveCode);
    }

    function RunPlayground() {
		
    
        
        var myIframe = document.getElementById("runnerIframe");
        document.getElementById('runnerIframe').src = "";
        
        let element = myIframe.contentWindow.document.getElementById("myplayroundscript");

        if(typeof(element) != 'undefined' && element != null){
            console.log("script exists");
            //myIframe.contentWindow.document.removeChild("myplaygroundscript");
            element.remove();

        }
        else{
            console.log('script doesnt exist');

        }
        
     
        var script = myIframe.contentWindow.document.createElement("script");
        script.setAttribute('id', "myplayroundscript");
     

        script.type = "text/javascript";
        //script.src = editorRef.current.getValue();

        script.innerHTML = editorRef.current.getValue();
        myIframe.contentWindow.document.body.appendChild(script);
       
        console.log('done');

	}



  return (

   
  

    <div width="400px" height="100%">
        
        <div>

        <div id="bodyBoxHome">
        <div><h3>Upload Statement File</h3></div>
        </div>


        <Button    
                variant="contained"
                color="primary"
                onClick={()=>{

                   
                    LoadPDF();
                  

                  }}
                  className={classes.button}>Load Data</Button>

                  &nbsp;

            <Button    
                variant="contained"
                color="primary"
                onClick={()=>{

                    //toast('Running Code');
                    let editor = document.getElementById("EditorContainer");
                    //doRun(editor);
                    RunPlayground();
                    //showValue();

                  }}
                  className={classes.button}>Run Code</Button>

                    &nbsp;

<Button    
                variant="contained"
                color="primary"
                onClick={()=>{

                    //toast('Running Code');
                    let editor = document.getElementById("EditorContainer");
                    //doRun(editor);
                    SaveCode();
                    //showValue();

                  }}
                  className={classes.button}>Save Code</Button>


                  

                    <ToastContainer />

       </div>
     

       <br />
       <br />


       { NSaveCode &&
            

                <div>
                        Display Save Code
                </div>
                
        } 
        { NLoadData &&
            

            <div>
                    Display LoadData

                    <LoadData></LoadData>

            </div>
        
        }
    
        <Grid container spacing={2} justify="center">
        <div>
        <div id="EditorContainer">
        <Editor
     height="200vh"
     width="800px"
     defaultLanguage="javascript"
     id="myid"
     defaultValue={TestCode}
     onMount={handleEditorDidMount}
   />
   </div>
   <div style={{ display: "none" }}>

   <iframe id="runnerIframe"></iframe>
                  </div>
    </div>
    </Grid>
    </div>

  )  
}