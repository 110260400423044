


import React, {useCallback, useState, useRef, useMemo, useEffect} from 'react';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';


import moment from 'moment';
import './documentlisting.css';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import Button from '@material-ui/core/Button';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ModeEditIcon from '@mui/icons-material/ModeEdit';


import jwt from 'jwt-decode'
import { PDFReader } from 'reactjs-pdf-reader';


import MyModal from './modal.js';


import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';





//let server_url = 'https://automate.isnet.co.za';
//let server_url = 'http://127.0.0.1:3002';
let server_url = process.env.REACT_APP_SERVER_URL;

var date;



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};





async function load_pdf(doc_link){


    return new Promise(function (resolve,reject){
  
        try{
                          const token = localStorage.getItem('AccessToken'); 



                          let options = {
                          headers: {
                              'Authorization': 'Bearer ' + token
                          },
                          insecure: true
                          //rejectUnauthorized: false
                          }


  
  
                        fetch(server_url + "/", options)
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
  
                               
                                
                        });
  
                        
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })



}

async function get_documents(){





    return new Promise(function (resolve,reject){
  
      try{
                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                        insecure: true,
                        rejectUnauthorized: false
                        }


                      fetch(server_url + "/training_queue", options)
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);

                             
                              
                      });

                      
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
    }
  
  
    async function get_pdf_data(filename, company){
  
  
        return new Promise(function (resolve,reject){
      
          try{
      
      
            const token = localStorage.getItem('AccessToken'); 
      
          
          
      
      
                      
                      fetch(server_url + "/get_queue_pdf_file?company=" + company + "&filename=" + filename,{
                        method: 'GET',
                        headers: { 
                                    'Content-Type': 'application/pdf'
                        },
                        })
                        .then((res) => {
                           // return res.blob();
                           //resolve(res.blob());]
                           const file = new Blob([res.data], { type: 'application/pdf' });
                           const fileURL = URL.createObjectURL(file);
                           resolve( fileURL);
                           //resolve(res.data);

                        })
                    
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
      
      
      
      
      }
  

      /*
function DocumentList(props){

   
    let listItems = props.RepoData;

    console.log(listItems.length);
    console.log(listItems);
   
    

    if(listItems.length > 0){
    return(
        <div>
       {
            listItems.map((item)=> {

               

                return(
                    
                    <div>
                     <li key={item.id}>
                          {item.doc_name}
                    </li> 
                  </div>
                )

            }  )  
        }
        </div>
    )
    }
    else{
        return(null);
    }

}
*/


async function delete_from_training_queue(dataObject){

  
    return new Promise(function (resolve,reject){

      try{

                const token = localStorage.getItem('AccessToken'); 
                let options = {
                  headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  },
                  insecure: true,
                  rejectUnauthorized: false
                }

                let myheaders = {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                  
        
                  fetch(server_url + "/delete_from_training_queue",{
                    method: 'POST',
                    //headers: { 'Content-Type': 'application/json' },
                    headers: myheaders,
                    body: JSON.stringify(dataObject)
                    })
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);
                      
                      });
      }
      catch(err){
              console.log(err);
              reject(err);
      }

      })




  }



async function process_queue_file(filename){

    //https://automate.isnet.co.za/process_file2?filename=mustek&trained=true

  
        return new Promise(function (resolve,reject){
      
          try{
      
      
            const token = localStorage.getItem('AccessToken'); 
      
          

      
      
                      
                      fetch(server_url + "/process_file2?filename=" + filename + "&queue=true",{
                        method: 'GET',
                        headers: { 
                                    'Content-Type': 'application/json',
                                    Authorization: 'Bearer ' + token
                        }
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
      
      
      
      
      
      


}


async function get_invoice_data(filename){

  //https://automate.isnet.co.za/process_file2?filename=mustek&trained=true


      return new Promise(function (resolve,reject){
    
        try{
    
    
          const token = localStorage.getItem('AccessToken'); 
    
        

    
    
                    
                    fetch(server_url + "/get_invoice_data?filename=" + filename + "&queue=true",{
                      method: 'GET',
                      headers: { 
                                  'Content-Type': 'application/json',
                                  Authorization: 'Bearer ' + token
                      }
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    
    


}




const open_file = (doc_link) => {

        console.log('opening file: ' + doc_link);

        return new Promise(function (resolve,reject){

          try{
                            const token = localStorage.getItem('AccessToken'); 
                            let options = {
                            headers: {
                                'Authorization': 'Bearer ' + token,
                                Accept: 'application/pdf'
                            },
                            insecure: true,
                            rejectUnauthorized: false


                            }



                            const requestOptions = {
                              method: 'GET',
                              headers: { 'Content-Type': 'application/pdf',
                              'Authorization': 'Bearer ' + token
                               }
                              };
                              
                              //fetch(server_url + "/get_pdf_file?file_name=" + doc_link , requestOptions)
                              fetch(doc_link, requestOptions)
                              .then((res) => {
                                  return res.blob();
                              })
                              .then((blob) => {
                                  const href = window.URL.createObjectURL(blob);
                                  const link = document.createElement('a');
                                  link.href = href;
                                  link.setAttribute('download', 'file.pdf'); //or any other extension
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                              })
                              .catch((err) => {
                                  return Promise.reject({ Error: 'Something Went Wrong', err });
                              })
                              
    
                          /*
                          fetch(server_url + "/get_pdf_file?file_name=" + doc_link , options)
                          .then(response => response.text())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);

                                  //const file = new Blob(
                                  //  [data], 
                                  //  {type: 'application/pdf'})

                                    //Build a URL from the file
                                    //const fileURL = URL.createObjectURL(file);
                                    //console.log(fileURL);

                                  //Build a URL from the file
                                  //window.open(fileURL);

                                  console.log('xxx2');
                                  //resolve(data.toString('base64'));

                                  resolve(data);
                                  //resolve(fileURL);
                                  //resolve(file);
    
                                 
                                  
                          });
                          */

    
                          
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })




  }





function DocumentList2(props){


    const forceUpdate = useForceUpdate();

   
    let listItems = props.RepoData;

    console.log(listItems.length);
    console.log(listItems);
  
   
    const [Company,setCompany] = useState();
    const [UserName, setUserName] = useState();
    const [PDFData,setPDFData] = useState();
    const [ModalShow,setModalShow] = useState();

    const [selectedFile, setSelectedFile] = useState(null);
    const [UploadName, setUploadName] = useState();
    const [TemplateUUID, setTemplateUUID] = useState();


    const [PdfFileName,setPdfFileName] = useState();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [openEdit, setOpenEdit] = React.useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const [openUpload, setOpenUpload] = React.useState(false);
    const handleOpenUpload = () => setOpenUpload(true);
    const handleCloseUpload = () => setOpenUpload(false);


    const [EditTemplateFile, setEditTemplateFile] = useState();
    const [EditStatus, setEditStatus] = useState();

    

    //this.inputOpenFileRef = React.createRef()
    const inputOpenFileRef = useRef();

    const history = useHistory();



    useEffect(()=>{

    let token = localStorage.getItem('AccessToken');
    let decoded_token = jwt(token);
    console.log('decoded:');
    console.log(decoded_token);
    setCompany(decoded_token.company);
    setUserName(decoded_token.username);

    },[]);

    



    //call this once file is uploaded
    async function create_document_for_training(dataObject){

  
      return new Promise(function (resolve,reject){
    
        try{
    
                  const token = localStorage.getItem('AccessToken'); 
                  let options = {
                    headers: {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    },
                    insecure: true,
                    rejectUnauthorized: false
                  }
    
                  let myheaders = {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  };
                    
          
                    fetch(server_url + "/create_document_for_training",{
                      method: 'POST',
                      //headers: { 'Content-Type': 'application/json' },
                      headers: myheaders,
                      body: JSON.stringify(dataObject)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }
    
        })
    
    
    
    
    }
   


    const FileUpload = () => {


      //var input = document.querySelector('input[type="file"]');
     // var input = document.getElementById('FiletoUpload');

      console.log('XXXX');
      console.log(selectedFile);

      var data = new FormData()
      //data.append('file', input.files[0])
      data.append('file', selectedFile);
      data.append('user', UserName);
      data.append('company', Company);
      data.append('template_name', UploadName);
      data.append('uuid', TemplateUUID);





      console.log(data);
      
      fetch(server_url + '/upload_training_file', {
        method: 'POST',
        body: data
      }).then(response => response.json())
      .then(function(data){
              console.log('xxx');
              console.log(data);
              console.log('xxx2');
              //resolve(data);

             
              
      });
      

    }




    const PDFFileUpload = () => {


      //var input = document.querySelector('input[type="file"]');
     // var input = document.getElementById('FiletoUpload');

      console.log('XXXX');
      console.log(PdfFileName);

      var data = new FormData()
      //data.append('file', input.files[0])
      data.append('file', PdfFileName);
      data.append('user', UserName);
      data.append('company', Company);
      data.append('template_name', UploadName);
      data.append('uuid', TemplateUUID);





      console.log(data);
      
      fetch(server_url + '/upload_training_pdf', {
        method: 'POST',
        body: data
      }).then(response => response.json())
      .then(function(data){
              console.log('xxx');
              console.log(data);
              console.log('xxx2');
              return(data);
              //resolve(data);

             
              
      });
      

    }





    const TrainingUpdate = () => {


      //var input = document.querySelector('input[type="file"]');
     // var input = document.getElementById('FiletoUpload');

      console.log('XXXX');
      //console.log(selectedFile);

      var data = new FormData()
      //data.append('file', input.files[0])
      //data.append('file', selectedFile);
      data.append('user', UserName);
      data.append('company', Company);
      data.append('template_name', UploadName);
      data.append('uuid', TemplateUUID);




      const jobj = {
          user: UserName,
          company: Company,
          template_name: UploadName,
          uuid: TemplateUUID,
          status: EditStatus
      }

      const token = localStorage.getItem('AccessToken'); 

      console.log(jobj);

      let myheaders = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      };
      
      fetch(server_url + '/update_training_file', {
        headers: myheaders,
        method: 'POST',
        body: JSON.stringify(jobj)
      }).then(response => response.json())
      .then(function(data){
              console.log('xxx');
              console.log(data);
              console.log('xxx2');
              window.location.reload();
              //resolve(data);

             
              
      });
      

    }
  
    



    

    if(listItems.length > 0){
    return(
        


  
        <div>


        {/*
        <MyModal show={ModalShow} pdf_url={PDFData}>
          <p>Modal</p>
        </MyModal>
        */}


          <div>

          <Button 
                    
                    //disabled={!PONumber}
                    id="uploadBtn"
                    onClick={()=>{
                        
                            //Upload File
                            console.log('upload a file to the training queue');
                            handleOpenUpload();
                            
                          
                    }

                    }>Upload a file to the training queue</Button>
          </div>


      
        <table id="myTable">
        
        <thead>
                            <tr>
                            <th>ID</th>
                            <th>Company</th>
                            <th>Date</th>
                            <th>Assigned</th>
                            <th>FileName</th>
                           {/* <th>FullPath</th> */}
                            <th width='20px'>Status</th>
                            {/*<th>Detect</th>*/}
                            <th>TemplateFile</th>
                            <th>Train</th>
                            <th>Preview</th>
                            <th>Download</th>
                            <th>Upload</th>
                            <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                       

        
       {
            listItems.map((item, index)=> {
               

                let item_url = '';

         
           
                
                date = moment.unix(item.updated).format('YYYY-MM-DD HH:mm:ss');
                //var sender= item.doc_sender;
               // var docStatus= item.status;

               item_url = "/openfile?company=" + Company + "&filename=" + item.FileName + "&from='" + item.from+ "'&to='" + item.to + "&subject='" + item.subject + "'&date=" +item.date + "&doclink=" + item.filename;
               let myurl = server_url + "/get_training_pdf_file?filename=" + item.FileName + "&company=" + item.CompanyName;
                
                return(
                   
                            <tr key={index}>
                            <td>{item.uuid}</td>
                           {/* <td><Link to={item_url}>{item.doc_name}</Link></td> */}
                            <td>{item.CompanyName}</td>
                            <td>{item.Date}</td>
                            <td>{item.Assigned}</td>
                            <td>{item.FileName}</td>
                           {/* <td>{item.FullPath}</td> */}
                            <td width="20px">{item.Status}</td>
                            <th>{item.TemplateName} 
                            {item.TemplateName && <ModeEditIcon className='iconButton' onClick={async()=>{

                              console.log('update name:' + item.uuid);
                              setTemplateUUID(item.uuid);
                              setUploadName(item.TemplateName);
                              setEditTemplateFile(item.TemplateFile);
                              setEditStatus(item.Status);
                              
                              setOpenEdit(true);
                            }}></ModeEditIcon>}
                               
                               </th>
                            {/*<td width="20px">
                              <DocumentScannerIcon

                               className='iconButton'
                               onClick={async ()=>{
                                       //https://automate.isnet.co.za/process_file2?filename=' + item.filename + '&queue=true';

                                       console.log('Scanning Document');

                                       console.log(item.filename);
                                       let json_data = get_invoice_data(item.filename);

                                       ///get_invoice_data

                                      // history.push('/process_data?filename=' + item.filename);
                                                                               
                                       //let result = await process_queue_file(item.filename);
                                       //let url = "/process_data?filename=" + item.filename;
                                       

                                      // console.log(result);

                               }}
                               >


                              </DocumentScannerIcon>
                            </td>*/}
                            <td>

                            <RotateRightIcon 

                                className='iconButton'
                                onClick={async ()=>{
                                        //https://automate.isnet.co.za/process_file2?filename=' + item.filename + '&queue=true';

                                        console.log('Processing');

                                       // const history = useHistory();
                                        //const handleOnClick = useCallback(() => history.push('/sample'), [history]);

                                        history.push('/process_data?filename=' + item.filename);
                                                                                
                                        //let result = await process_queue_file(item.filename);
                                        //let url = "/process_data?filename=" + item.filename;
                                        

                                       // console.log(result);

                                }}

                            />

                           
                               

                            </td>

                            <td>
                               


                            <MyModal show={ModalShow} pdf_url={myurl}>
                                        <p>Preview</p>
                            </MyModal>

                            </td><td>
                               
                            <CloudDownloadIcon  onClick={()=>{

                                console.log('DDDownlloading');
                                open_file(myurl)}
                               // history.push(myurl);}
                                } 
                                className='iconButton'
                            />

                            </td>
                            <td>
                               
                           
                            <CloudUploadIcon  onClick={()=>{

                                console.log('uploading');
                                 //showOpenFileDlg.click();
                                 setOpen(true);
                                 setTemplateUUID(item.uuid);
                                 ///// inputOpenFileRef.current.click();
                                //open_file(myurl)}
                               // history.push(myurl);}
                              }}
                                className='iconButton'
                            />

                            </td>
                            
                            <td>

                                <DeleteOutlineOutlinedIcon className='trashBtn' onClick={async ()=>{

                                console.log("Deleting: " + item.FileName  + " " + Company );


                                ///delete_from_processing_queue
                                let doc = {
                                    filename: item.FileName,
                                    date: item.date
                                }
                                let res = await delete_from_training_queue(doc);


                                console.log("res:" + res);

                                if(res.deletedCount == 1){
                                    console.log('Item Deleted');
                                   // history.push('/training_queue');
                                   //forceUpdate();
                                   window.location.reload();

                                }

                                }}/>
                               {/*<Button onClick={async ()=>{

                                console.log("Deleting: " + item.filename  + " " + Company );


                                ///delete_from_processing_queue
                                let doc = {
                                    filename: item.filename,
                                    date: item.date
                                }
                                delete_from_processing_queue(doc);

                                }}>
                                Delete
                                </Button>*/}
                            </td>
                           
                            </tr>
                
                     
                )

            }  )  
        }
        </tbody>
        </table>



            <Modal
                //key={mail._id}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
              
                <div>
                                <form>
                                  
                                <label>TemplateName</label>
                                <input
                                  type="text"
                                  value={UploadName}
                                  placeholder="TemplateName - eg: supplier-Invoice"
                                  onChange={(e) => setUploadName(e.target.value)}
                                  />
                              
                                <br /><br />
                                <input type="hidden" id="myTemplateUUID" value={TemplateUUID}></input>

                                <input /*ref={inputOpenFileRef}*/ type="file" id="FiletoUpload"  onChange={(e) => {
                                  setSelectedFile(e.target.files[0]);

                                  console.log(e.target.files[0]);
                                 

                                  }}  
                                  /*style={{ display: "block" }}*/ />

                                  <input type='Button' id='upload' value='Upload Template' onClick={(e)=>{
                                        console.log('Uploading Template:' + UploadName  + " uuid: " + TemplateUUID);
                                        let fres =  FileUpload(); //Upload file
                                        handleClose();


                                  }} ></input>
                                </form>
                            </div>
            
                
                </Box>
              </Modal>




              <Modal
                //key={mail._id}
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
              
                <div>

                                Edit Training Data
                                <form>
                                  
                                <label>TemplateName</label>
                                <input
                                  type="text"
                                  value={UploadName}
                                  placeholder="TemplateName - eg: supplier-Invoice"
                                  onChange={(e) => setUploadName(e.target.value)}
                                  />


                                    <label>TemplateFile</label>
                                  <input
                                  type="text"
                                  value={EditTemplateFile}
                                  placeholder="TemplateName - eg: supplier-Invoice"
                                  onChange={(e) => setEditTemplateFile(e.target.value)}
                                  />

                                  <label>Status</label>
                                  <input
                                  type="text"
                                  value={EditStatus}
                                  placeholder="status"
                                  onChange={(e) => setEditStatus(e.target.value)}
                                  />

                              
                                <br /><br />
                                <input type="hidden" id="myTemplateUUID" value={TemplateUUID}></input>



                                  <input type='Button' id='upload' value='Update Template' onClick={(e)=>{
                                        console.log('Uploading Template:' + UploadName );
                                        let fres =  TrainingUpdate(); //Upload file
                                        handleCloseEdit();
                                        

                                  }} ></input>
                                </form>
                            </div>
            
                
                </Box>
              </Modal>



              <Modal
                //key={mail._id}
                open={openUpload}
                onClose={handleCloseUpload}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
              
                <div>

                               Upload PDF to Train Queue
                                <form>


                                <input /*ref={inputOpenFileRef}*/ type="file" id="FiletoUpload"  onChange={(e) => {
                                  setPdfFileName(e.target.files[0]);

                                  console.log(e.target.files[0]);

                               
                                 

                                  }} ></input>

                                {/*
                                  
                                <label>TemplateName</label>
                                <input
                                  type="text"
                                  value={UploadName}
                                  placeholder="TemplateName - eg: supplier-Invoice"
                                  onChange={(e) => setUploadName(e.target.value)}
                                  />


                                    <label>TemplateFile</label>
                                  <input
                                  type="text"
                                  value={EditTemplateFile}
                                  placeholder="TemplateName - eg: supplier-Invoice"
                                  onChange={(e) => setEditTemplateFile(e.target.value)}
                                  />

                                  <label>Status</label>
                                  <input
                                  type="text"
                                  value={EditStatus}
                                  placeholder="status"
                                  onChange={(e) => setEditStatus(e.target.value)}
                                  />

                                */}

                              
                                <br /><br />
                                <input type="hidden" id="myTemplateUUID" value={TemplateUUID}></input>



                                  <input type='Button' id='upload' value='Upload Pdf to train' onClick={(e)=>{
                                        console.log('Uploading PDF:' + PdfFileName );
                                        //let fres =  TrainingUpdate(); //Upload file
                                        let fres = PDFFileUpload();
                                        //console.log("FFF" + JSON.stringify(fres));
                                        handleCloseUpload();
                                        

                                  }} ></input>
                                </form>
                            </div>
            
                
                </Box>
              </Modal>


       

        <div >

       


   
        {PDFData &&
         <PDFReader  url={PDFData}></PDFReader> 
            
        }
        
        </div>

        </div>
      
    )
    }
    else{
        return(null);
    }

}



function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}



export default function TrainingQueue(){


    const [RepoData,setRepoData] = useState({});


   

    useEffect(()=>{


            //Get All Documents from database
            async function get_docs(){
                let doc_data = await get_documents();
                console.log('DD: ' + JSON.stringify(doc_data[0]));
                setRepoData(doc_data);
                console.log(RepoData.length)
            }
            get_docs();
            
            



    },[])


    function handleSearch() {
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[0];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }       
        }
      }


            return(

                <div id="bodyBox">
                    <div><h3><ListItemIcon><DescriptionIcon/></ListItemIcon> Reconify Training Queue</h3></div><br/>
                    <div>
                    <div>
                        <input
                            id="myInput"
                                type="text"
                                placeholder="Search sender"
                               
                                onChange={handleSearch}
                            />


                    </div>
                    
                         <DocumentList2 RepoData={RepoData} ></DocumentList2>
                       {/*  <pre>{JSON.stringify(RepoData)}</pre> */}
                    {/*
                    {RepoData.map((item) => {
                    
                    return(
                        <li>{item.doc_name}</li>
                    )
                    })
                    }
                    */}
                    </div>
                </div>
            )

    
}
