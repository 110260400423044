


import React, { useEffect,useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {BrowserRouter as Router, useHistory } from 'react-router-dom';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
//import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ReactDOM from 'react-dom';
import './admincentre.css';
import {UpdateProfile} from './SuperAdmin';



const server_url = 'https://automate.isnet.co.za';



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



class Tabsadmin extends React.Component {
  render(){
    return(

      
      <div className='tabs'>
    
    <Tabs>
         <Tab label="New User">
           <div className="displayTab">
           <h1>Create New User</h1>
           <br/>
           <CreateUser></CreateUser>
           </div>
        </Tab>
        <Tab label="Update Profile">
           <div className="displayTab">
           
           <UpdateProfile></UpdateProfile>
           </div>
           </Tab>
           </Tabs>
         
          
         
      
      </div>
   
    )
  }
}

class Tabs extends React.Component{
  state ={
    activeTab: this.props.children[0].props.label
  }
  changeTab = (tab) => {

    this.setState({ activeTab: tab });
  };
  render(){
    
    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, child =>{
          buttons.push(child.props.label)
          if (child.props.label === this.state.activeTab) content = child.props.children
        })}
         
        <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab}/>
        <div className="tab-content">{content}</div>
        
      </div>
    );
  }
}

const TabButtons = ({buttons, changeTab, activeTab}) =>{
   
  return(
    <div className="tab-buttons">
    {buttons.map(button =>{
       return <Button className={button === activeTab? 'active': ''} onClick={()=>changeTab(button)}>{button}</Button>
    })}
    </div>
  )
}

const Tab = props =>{
  return(
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}
 

ReactDOM.render(<Tabsadmin />, document.getElementById('root'));



function CreateCompany(){



    const [CompanyStatus, setCompanyStatus] = useState();
    const [CompanyName, setCompanyName] = useState();
    const [CompanyDatabase, setCompanyDatabase] = useState();
    const [CompanyAPIUrl, setCompanyAPIUrl] = useState();
    const [CompanyAdmin, setCompanyAdmin] = useState();

    const history = useHistory();


    return(

        <div id='myTab'>
       
            
               <TextField id="CompanyName" label="Company Name" variant="outlined" value={CompanyName}
               onChange={(e)=>{
                //console.log('change')
                setCompanyName(e.target.value)
                }}
               
               /> 
               <TextField id="CompanyDatabase" label="Company Database" variant="outlined" value={CompanyDatabase}
                  onChange={(e)=>{
                    //console.log('change')
                    setCompanyDatabase(e.target.value)
                    }} 
              />
         
           
           
               <TextField id="CompanyAPIUrl" label="Company API URL" variant="outlined" value={CompanyAPIUrl}
                onChange={(e)=>{
                  //console.log('change')
                  setCompanyAPIUrl(e.target.value)
                  }}
               />  
               <TextField id="CompanyAdmin" label="Company Admin" variant="outlined" value={CompanyAdmin}
                 onChange={(e)=>{
                  //console.log('change')
                  setCompanyAdmin(e.target.value)
                  }}
               />
              
               
            <div id="inputBox">
              {/* <TextField id="CompanyStatus" label="Company Status" variant="outlined" /> */}

               <InputLabel id="demo-simple-select-label">Company Status</InputLabel>
                    <Select
                        style={{minWidth: 234}}
                        labelId="demo-simple-select-label"
                        width="100px"
                        id="demo-simple-select"
                        value={CompanyStatus}
                        label="Status"
                        onChange={(e)=>{
                            console.log('change')
                            setCompanyStatus(e.target.value)
                        }
                        
                        }
                    >
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"Disabled"}>Disabled</MenuItem>
                    </Select>

            </div>

          
        
        
        <div>
        <Button variant="contained"
                  //color="primary"
                  onClick={()=>{

                    ///setCurrentView('CreateCompany');


                    let document = {
                      "company_name": CompanyName,
                      "company_database": CompanyDatabase,
                      "company_api_url": CompanyAPIUrl,
                      "company_admin": CompanyAdmin,
                      "company_status": CompanyStatus
                    }



                    console.log('Setup Company');
                    console.log(document);


                    try{
          
                     
                
                      fetch(server_url + "/create_company",{
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(document)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxxx company status');
                                  console.log(data);
                                  console.log('xxx2 company status');
                                  //resolve(data);
                                  history.push('/admincentre'); 
                          
                          });
                   }
                  catch(err){
                          console.log(err);
                         // reject(err);
                  }

                  }}>Setup Company</Button>
        </div>
        </div>
    )

}


export function CreateUser(){



  const [user_status, setuser_status] = useState();
  const [username, setusername] = useState();
  const [company_name, setcompany_name] = useState();
  const [password, setpassword] = useState();
  const [user_role, setuser_role] = useState();
  const [user_email, setuser_email] = useState();
  const [SelectedStatus, setSelectedStatus] = useState();
  const [SelectedRole, setSelectedRole] = useState();

  const history = useHistory();
  useEffect(()=>{
    //setAuthenticated(props.Authenticated);
    //setUserName(props.UserName);
   // console.log('setting: ' + props.Authenticated); 

    async function gusers(){

      let userData = await get_users();

      let company_name= userData[0].CompanyName;
     
    
      setcompany_name(company_name);
   
    }

    gusers();

  },[]);
  

      async function get_users(){

        return new Promise(function (resolve,reject){
        
        try{
        
        const token = localStorage.getItem('AccessToken');
        
        let options = {
        
        headers: {
        
        'Authorization': 'Bearer ' + token
        
        },
        
        insecure: true,
        
        rejectUnauthorized: false
        
        }
        
        
        fetch(server_url + "/company_users", options)
        
        .then(response => response.json())
        
        .then(function(data){
        
        console.log('xxx');
        
        console.log(data[0].UserName);
        
        console.log('xxx2');
     const username = data[0].UserName;
     
        console.log(data);
        resolve(data);
     
        
        });
        
        }
        
        catch(err){
        
        console.log(err);
        
        reject(err);
        
        }
        
        })
        
        }

  return(

      <div>
      <div>
          <div>

          <div className="labelBox Mui-disabled" m={2} p={2} padding={3} >
          <p style={{fontSize:10, paddingTop: 3, margin:0, textAlign:'left', color:'#2E8A7C',}}>Company Name</p>
          <TextField inputProps={{ readOnly: true }}  className="inputSpace Mui-disabled" id="company_name" variant="outlined" value={company_name}
                
            /> 
            </div>
             <TextField id="username" label="Name" variant="outlined" value={username}
             onChange={(e)=>{
              //console.log('change')
              setusername(e.target.value)
              }}
             
             /> 
            
          </div>
         
          <div>
             <TextField  id="password" label="User Password" variant="outlined" value={password}
              onChange={(e)=>{
                //console.log('change')
                setpassword(e.target.value)
                }}
             /> 
             {/*<TextField id="user_role" label="User Role" variant="outlined" value={user_role}
               onChange={(e)=>{
                //console.log('change')
                setuser_role(e.target.value)
                }}
             />*/}

             <TextField id="user_email" label="User Email" variant="outlined" value={user_email}
              onChange={(e)=>{
                //console.log('change')
                setuser_email(e.target.value)
                }}
             /> 
             
             </div>
          
          <div>
            {/* <TextField id="CompanyStatus" label="Company Status" variant="outlined" /> */}  
           
   {/*<TextField id="user_status" label="User Status" variant="outlined" value={user_status}
              onChange={(e)=>{
                //console.log('change')
                setuser_status(e.target.value)
                }}
             /> */}
             <div className="labelBox" m={2} p={2} padding={3} style={{marginRight:12}}>
             <p style={{fontSize:10, paddingTop: 3, margin:0, textAlign:'center', color:'#2E8A7C',}}>User Role</p>
             

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={user_role}
                 
                  onChange={(e)=>{
                //console.log('change')
                setuser_role(e.target.value)
                }}
                >

              

                  <MenuItem value={"editor"}>Editor</MenuItem>
                  <MenuItem value={"admin"}>Admin</MenuItem>
                  <MenuItem valu={"superadmin"}>Super Admin</MenuItem>
                 
                  

                </Select>

                

                <span>{SelectedRole}</span>
            
          </div>

             <div className="labelBox" m={2} p={2} padding={3} >
             <p style={{fontSize:10, paddingTop: 3, margin:0, textAlign:'center', color:'#2E8A7C',}}>Status</p>

                  <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  
                  
                  onChange={(e)=>{
                //console.log('change')
                setuser_status(e.target.value)
                }}
                >

              

                  <MenuItem value={"enabled"}>Active</MenuItem>
                  <MenuItem value={"disabled"}>Disabled</MenuItem>
                 
                  

                </Select>

                

                <span>{SelectedStatus}</span>
                
            
            </div>
            
                      
          </div>

        
      </div>
      
      <div>

     
      <Button variant="contained"
                //color="primary"
                onClick={()=>{

                  let document = {
                    "CompanyName": company_name,
                    "UserName": username,
                    "UserPassword": password,
                    "UserRole": user_role,
                    "UserEmail": user_email,
                    "UserStatus": user_status,
                  }



                  console.log('Setup New User');
                  console.log(document);


                  try{
        
                   
              
                    fetch(server_url + "/create_user",{
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(document)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxxx user status');
                                console.log(data);
                                console.log('xxx2 user status');
                                //resolve(data);
                                history.push('/admincentre'); 
                        
                        });
                 }
                catch(err){
                        console.log(err);
                       // reject(err);
                }

                }}>Create New User</Button> 
      </div>
      </div>
  )

}

export default function AdminCentre() {


    const [CurrentView,setCurrentView] = useState();

    useEffect(() => {




    },[])

    


    return(

        <div>
        <div id="bodyBoxAdmin">
        <div ><h3><ListItemIcon><AdminPanelSettingsIcon/></ListItemIcon> ADMIN CENTRE</h3></div>
        <div id="tabs" >
          <div><Tabsadmin></Tabsadmin></div>  

        </div>
            
        </div>
        <div>

          
               




        
          </div>



        </div>

        
    );


 



}