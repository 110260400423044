import React, { useEffect,useState, useRef } from 'react';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import UserCard from "./UserCard";
import user from "../images/user.png";
import { useSelector, useDispatch } from 'react-redux';
import { loggedin,loggedout, AccessData } from '../redux/authSlice';
import jwt_decode from "jwt-decode";


//const server_url = 'https://automate.isnet.co.za';
let server_url = process.env.REACT_APP_SERVER_URL;
const AdminList = (props) => {
  const history = useHistory();

  

  console.log("User list props: " + props.UserName);
  
  
  const userLogged= props.UserName;

  const [all_users, setall_users] = useState([]);
  const [id, setId]= useState();
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [LoggedUser, setLoggedUser] = useState(userLogged);
  const [LoggedCompany, setLoggedCompany] = useState(userLogged);
  
  useEffect(()=>{


   async function gusers(){

    let userData = await get_users();
     
      setall_users(userData);
     let user_id = userData._id;
     setId(user_id);
     console.log(LoggedUser);
     
     }

     

    gusers();

  },[]);



      async function get_users(){

        return new Promise(function (resolve,reject){
        
        try{
        
        const token = localStorage.getItem('AccessToken');        
        let options = {        
        headers: {        
        'Authorization': 'Bearer ' + token        
        },        
        insecure: true,        
        rejectUnauthorized: false        
        }
        
        
        fetch(server_url + "/get_all_users", options)        
        .then(response => response.json())        
        .then(function(data){        
        console.log('xxx');        
       // console.log(data.CompanyName);        
        console.log('xxx2');
        const user_name = data.UserName;
        const user_id = data._id;
        const dataArr= [];
       // console.log(data);
       
        for (let i = 0; i < data.length; i++) {
          var elementStatus = data[i].UserStatus;
          var elementUser = data[i].UserName;
          var elementCompany = data[i].CompanyName;
          
         console.log(elementCompany);
          if (LoggedUser == elementUser){
         
            var companyLogged = data[i].CompanyName;
          

            setLoggedCompany(companyLogged);
          }
        
          if(elementStatus != "deleted"){
           console.log(data);
            //dataArr.push(data[i]);
           

          }
          
         //console.log(dataArr);
        resolve(dataArr);
     
        }
        });
        
        }
        
        catch(err){
        
        console.log(err);
        
        reject(err);
        
        }
        
        })
        
        }

  


  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
    if (searchInput !== '') {
        const filteredData = all_users.filter((item) => {
            

            return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        })
        setFilteredResults(filteredData)
    }
    else{
        setFilteredResults(all_users)
    }
}

  
 
  return(
    <div className="main" id='userBoxAdmin'>
    <h2>User List</h2>
    <div className="ui search">
      <div className="ui icon input">
    <input 
       type="text"  
    placeholder="Search Users" 
    className="prompt" 
   
    onChange={(e) => searchItems(e.target.value)}
    
   
   
     />
    
    <i className="search icon"></i>
    
      </div>

      
    </div>
  
    <div>
    <button id="addBtn" onClick={(button) => history.push('/add')}>Add New User</button>
    </div>
     
    {searchInput.length > 1 ? (
                    filteredResults.map((item) => {
                        return (
                          <div className="item ui celled list"  id="list_users" 
      key={item._id}>
      
      
      <div className="content item" >
      <Link to={{pathname:`/user/${item._id}`, state: {users: {id: item._id, UserName: item.UserName, CompanyName: item.CompanyName, UserRole: item.UserRole, UserStatus: item.UserStatus}}}}>
      <img className="ui avatar image" src={user} alt="user" />
        <div className="header">{item.UserName}</div>
        <div>{item.CompanyName}</div>
        </Link>
       
        
      </div>
      <i
        className="trash alternate outline icon"
        style={{ color: "red", marginTop: "7px", marginRight: "40px" }}        
        
        onClick={()=>{

          
          let document = {"UserName": item.UserName}

                    
          console.log(document);

          try{
            const token = localStorage.getItem('AccessToken'); 

          let headers =  { 
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          };

                   
              
        fetch(server_url + "/delete_user",{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(document)
          })
            .then(response => response.json())
            .then(function(data){
                    console.log('xxxx deleting user');
                    console.log(data);
                    console.log('xxxx deleting user');
                    
                    
            
                  });
          }
          catch(err){
                  console.log(err);
                // reject(err);
          }

          }}
      ></i>
      
       <Link to={{pathname:`/edit/${item._id}`, state: {users: {id: item._id, UserName: item.UserName, CompanyName: item.CompanyName, UserRole: item.UserRole, UserStatus: item.UserStatus}}}} key={item._id} >
        <i
          className="edit alternate outline icon"
          style={{ color: "#2E8A7C", marginTop: "7px", marginRight: "10px" }}
         
        ></i>
      </Link>
     
    </div>
                        )
                    })
                ) : (
                    all_users.map((item) => {
                        return (
                          <div className="item ui celled list"  id="list_users" 
      key={item._id}>
      
      <div className="content item">
      <Link to={{pathname:`/user/${item._id}`, state: {users: {id: item._id, UserName: item.UserName, CompanyName: item.CompanyName, UserRole: item.UserRole, UserStatus: item.UserStatus}}}}>
      <img className="ui avatar image" src={user} alt="user" />
        <div className="header">{item.UserName}</div>
        <div>{item.CompanyName}</div>
        </Link>
       
        
      </div>
      <i
        className="trash alternate outline icon"
        style={{ color: "red", marginTop: "7px", marginRight: "40px" }}        
        
        onClick={()=>{

          
          let document = {"UserName": item.UserName}

                    
          console.log(document);

          try{
            const token = localStorage.getItem('AccessToken'); 

          let headers =  { 
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          };

                   
              
        fetch(server_url + "/delete_user",{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(document)
          })
            .then(response => response.json())
            .then(function(data){
                    console.log('xxxx deleting user');
                    console.log(data);
                    console.log('xxxx deleting user');
                    
                    
            
                  });
          }
          catch(err){
                  console.log(err);
                // reject(err);
          }

          }}
      ></i>
      
       <Link to={{pathname:`/edit/${item._id}`, state: {users: {id: item._id, UserName: item.UserName, CompanyName: item.CompanyName, UserRole: item.UserRole, UserStatus: item.UserStatus}}}} key={item._id} >
        <i
          className="edit alternate outline icon"
          style={{ color: "#2E8A7C", marginTop: "7px", marginRight: "10px" }}
         
        ></i>
      </Link>
     
    </div>
                        )
                    })
                )}

   </div>
  )
};

export default AdminList;