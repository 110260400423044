

export const accountCode = [
    {
      "AccountID": "618e048a-48ae-4b84-97b6-5ca88e18b500",
      "Code": "2041000",
      "Name": "Cos Dial Up",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203729853+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "c19d28e7-9c17-4c6b-9100-74b1100fd934",
      "Code": "2041001",
      "Name": "Cos Mailboxes",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203729883+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "607a9c0a-7e47-4d71-99a3-0f89ba162b53",
      "Code": "2041002",
      "Name": "Cos Hardware",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203729913+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "81f5c23f-d1e0-4be7-9c9e-cb5d578bd639",
      "Code": "2041005",
      "Name": "Cos Line Costs",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203729960+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "b026c877-ee9c-49e8-a9f4-58e82c572df6",
      "Code": "2041007",
      "Name": "Cos Corporate Access",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203729990+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "291501fe-4050-47a1-b224-a5f6de3b225f",
      "Code": "2041008",
      "Name": "Cos Annual Domain",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730023+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "18344921-5494-4665-9381-aa413b3bd59a",
      "Code": "2041015",
      "Name": "Cos Freight Disbursements",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730040+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "67cc7722-5140-469c-8bc0-db2eb85b1a6f",
      "Code": "2041017",
      "Name": "Cos Other Software",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730077+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "0d702f03-04e6-450e-ac54-564046ca81b4",
      "Code": "2041018",
      "Name": "Cos-M0365",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1597344515470+0000)/",
      "AddToWatchlist": true
    },
    {
      "AccountID": "dcb38829-43f0-42f9-a0dc-c38ebb785014",
      "Code": "2041019",
      "Name": "Cos-Azure",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1597344592833+0000)/",
      "AddToWatchlist": true
    },
    {
      "AccountID": "7171da6c-a4d6-412a-8f4c-c486c865f6ff",
      "Code": "2041020",
      "Name": "Cos Mimecaast ",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "Cos Mimecast",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1596789334987+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "460ad5e3-9ac3-4a49-a82e-1cda8cda6ff4",
      "Code": "2041021",
      "Name": "Cos Wireless Access",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730100+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "7ca9d53c-aa5e-467b-8c75-0b3796e2ecbf",
      "Code": "2041025",
      "Name": "Cos Anti Virus",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730133+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "a613eb7b-65d8-4f6a-960c-50d0c66a03da",
      "Code": "2041026",
      "Name": "Cos Adsl Services",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730163+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "fe445c24-8912-4bf3-bf89-7dbb83e46396",
      "Code": "2041045",
      "Name": "Cos Linespeed",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730210+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "76ca5880-21b4-4541-b42a-f4b31e78cb6f",
      "Code": "2041046",
      "Name": "Cos Remote Management",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730227+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "0e204033-b35a-4386-8608-c709814e3fa8",
      "Code": "2041048",
      "Name": "Cos Technical Outsourcing",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730257+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "74f4c01f-4ae2-4da5-8290-8e8f6509a09a",
      "Code": "2041049",
      "Name": "Cos Back up",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730303+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "9dfa23af-c809-41ea-b3e4-986c37eba473",
      "Code": "2042026",
      "Name": "Cos ADSL Karamba",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730397+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "fe3bc146-7176-46f5-bdca-e234238f37cd",
      "Code": "2043051",
      "Name": "Cos Travel Costs",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203730477+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "35b5b6cf-6f2e-40a0-9623-ef26f319222b",
      "Code": "2048000",
      "Name": "Cos Fibre",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203731633+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "439af536-c0e9-4e59-85d5-3fb0ccd2d609",
      "Code": "2048500",
      "Name": "Cos Sip",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203731663+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "392f1228-63a0-49ce-9ab5-0d9e790057e3",
      "Code": "2049500",
      "Name": "Cos ECM Annual License",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203731710+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "ca6e1951-ae5d-48d4-b1b0-a18391f96d0c",
      "Code": "2049515",
      "Name": "Cos ECM Hardware",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203731740+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "17289663-c306-47b1-90c8-7680befe3659",
      "Code": "2049525",
      "Name": "COS ECM Hosting",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203731773+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "6ec43dab-2537-48e5-9ea3-7c4820e4cdf7",
      "Code": "2049610",
      "Name": "Cos ECM Licenses and subscriptions",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203731850+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "0677fb00-bf04-479f-91e1-fabfcc612ef3",
      "Code": "2049615",
      "Name": "Cos ECM Hosting and Backup",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203731883+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "a067ed43-75da-4452-b5ea-23df674dbc7e",
      "Code": "2141001",
      "Name": "Cos Hosting",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "INPUT3",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203732240+0000)/",
      "AddToWatchlist": false
    },
    {
      "AccountID": "0eaed470-f8f2-4c6c-a309-f8f4c51eef15",
      "Code": "2345301",
      "Name": "Commisison Paid Karamba",
      "Status": "ACTIVE",
      "Type": "DIRECTCOSTS",
      "TaxType": "NONE",
      "Description": "",
      "Class": "EXPENSE",
      "EnablePaymentsToAccount": false,
      "ShowInExpenseClaims": false,
      "BankAccountType": "",
      "ReportingCode": "EXP",
      "ReportingCodeName": "Expense",
      "HasAttachments": false,
      "UpdatedDateUTC": "/Date(1571203732460+0000)/",
      "AddToWatchlist": false
    }
  ]
  

  