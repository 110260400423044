import React, { useEffect,useState } from 'react';
import Dropdown from 'react-dropdown';
import {CreateUserS, UpdateProfile} from '../templates/SuperAdmin';
import {BrowserRouter as Router, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MailModal from './MailModal';



import { sha256 } from 'js-sha256';
let server_url = process.env.REACT_APP_SERVER_URL;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function EditCompany(props) {

    console.log(props);
 
 const [CompanyName, setCompanyName]= useState(props.location.state.companies.CompanyName);
 const [CompanyStatus, setCompanyStatus] = useState(props.location.state.companies.CompanyStatus);
 const [CompanyDatabase, setCompanyDatabase] = useState(props.location.state.companies.CompanyDatabase);
 const [CompanyAPIUrl, setCompanyAPIUrl] = useState(props.location.state.companies.api_key);
 const [CompanyAdmin, setCompanyAdmin] = useState(props.location.state.companies.CompanyAdmin);
 const [CompanyMailBoxes, setCompanyMailBoxes]= useState(props.location.state.companies.CompanyMailBoxes);
 const [show,setShow]=useState(true);
 const [EmailAddress, setEmailAddress]= useState();
 const [MailBoxStatus, setMailBoxStatus]= useState();
 const [MailBoxType, setMailBoxType]= useState();
 const [MailServer, setMailServer]= useState();
 const [Password, setPassword]= useState();
 const [Username, setUsername]= useState();
 const [SelectedMailbox, setSelectedMailbox] = useState();
 const [Id, setId]= useState();
 const [open, setOpen] = React.useState(false);
 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const history = useHistory();


 async function create_company_db(company_name){
  
  
  return new Promise(function (resolve,reject){

    try{

              const token = localStorage.getItem('AccessToken'); 
              let options = {
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                },
                insecure: true,
                rejectUnauthorized: false
              }

              let myheaders = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
              };
                

                //let server_url = 'https://automate.isnet.co.za';
                //let server_url = 'http://127.0.0.1:3002';
                let dobj = {
                  company: company_name
                }
      
                fetch(server_url + "/create_company_db2",{
                  method: 'POST',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  body: JSON.stringify(dobj),
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}          

  

    return(
      <div className="ui main">
        <h2>Update Company</h2>

        
        <div id="addCompanyForm">
       
       <label>Company Name</label> <br/>
          <TextField id="CompanyName"  variant="outlined" value={CompanyName}
          onChange={(e)=>{
        
           setCompanyName(e.target.value)
           }}
          
          /> <br/><br/>
          <label>Company Database</label><br/>
          <TextField id="CompanyDatabase" variant="outlined" value={CompanyDatabase}
             onChange={(e)=>{
              
               setCompanyDatabase(e.target.value)
               }} 
         /><br/>
    <br/>
              
    <label>Company API URL</label><br/>
          <TextField id="CompanyAPIUrl"  variant="outlined" value={CompanyAPIUrl}
           onChange={(e)=>{
         
             setCompanyAPIUrl(e.target.value)
             }}
          />  <br/>
          <br/>
          <label>Company Admin</label><br/>
          <TextField id="CompanyAdmin" variant="outlined" value={CompanyAdmin}
            onChange={(e)=>{
            
             setCompanyAdmin(e.target.value)
             }}
          /><br/>
         <br/>
       
          
       <div id="inputBoxCompany">
   
         <label>Company Status</label><br/>
          
               <Select
                   style={{minWidth: 250, height: 35}}
                  
                   value={CompanyStatus}
                   label="Status"
                   onChange={(e)=>{
                       console.log('change')
                       setCompanyStatus(e.target.value)
                   }
                   
                   }
               >
                   <MenuItem value={"Active"}>Active</MenuItem>
                   <MenuItem value={"Disabled"}>Disabled</MenuItem>
               </Select>

       </div><br/>
                   <div>
       <button className="center MuiButton-root detailBtn addBox"  onClick={()=>setShow(!show)}>Add Mail Box</button>
                   <br/><br/>
       <button className="center MuiButton-root detailBtn addBox"  onClick={()=>create_company_db(CompanyName)}>Create Company DB</button>
       {
        show? null: <div className="emailForm">

        <label>Email Address</label><br/>
          <TextField  variant="outlined" disabled={false}
            onChange={(e)=>{
             //console.log('change')
             setEmailAddress(e.target.value)
             }}
          /><br/>
           <label>Mail Box Status</label><br/>
          <TextField id="CompanyAdmin" variant="outlined" 
            onChange={(e)=>{
             //console.log('change')
             setMailBoxStatus(e.target.value)
             }}
          /><br/>
           <label>Mail Box Type</label><br/>
          <TextField id="CompanyAdmin" variant="outlined" 
            onChange={(e)=>{
             //console.log('change')
             setMailBoxType(e.target.value)
             }}
          /><br/>
           <label>Mail Server</label><br/>
          <TextField id="CompanyAdmin" variant="outlined" 
            onChange={(e)=>{
             //console.log('change')
             setMailServer(e.target.value)
             }}
          /><br/>
           <label>Password</label><br/>
          <TextField id="CompanyAdmin" variant="outlined" 
            onChange={(e)=>{
             //console.log('change')
             setPassword(e.target.value)
             }}
          /><br/>
           <label>Username</label><br/>
          <TextField id="CompanyAdmin" variant="outlined" 
            onChange={(e)=>{
             //console.log('change')
             setUsername(e.target.value)
             }}
          /><br/>

        </div>
      }

       <br/>
       <div>
       
       {CompanyMailBoxes.map((mail)=>{
        
        
       
         return(
        <div className="mailBox" key={mail._id}>
          <p>Email: {mail.email_address}<br/>
          Status: {mail.mailbox_status}<br/>
          Mail Box Type: {mail.mailbox_type}<br/>
          Mail Server: {mail.mailserver}<br/>
          Username: {mail.username}</p>
         <div>
         <button onClick={()=>{

              setOpen(true);
              setSelectedMailbox(mail);
         }

         } className="editBtn" >
            <i
              className="edit alternate outline icon"
              style={{ color: "#2E8A7C", marginTop: "7px", marginRight: "10px", float: "none"}}
       
            
            ></i>
         </button>
     
        
            

              </div>
         </div>
         )
       })}


        <Modal
                //key={mail._id}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
              
                    <MailModal  mail={SelectedMailbox}/>
            
                
              </Box>
              </Modal>

      
       </div>
         </div>  
             

               

           
              
           
            
           <br/><br/>
          
   
   
   <div>
   <Button variant="contained"
           className="buttons"
             //color="primary"
             onClick={()=>{

               ///setCurrentView('CreateCompany');


               let document = {
                 "company_name": CompanyName,
                 "company_database": CompanyDatabase,
                 "company_api_url": CompanyAPIUrl,
                 "company_admin": CompanyAdmin,
                 "company_status": CompanyStatus,
                 "company_mail_boxes": [{"email_address": EmailAddress, "mail_box_status": MailBoxStatus, "mail_box_type": MailBoxType, "mail_server": MailServer, "password": Password, "username": Username}]
               }



               console.log('Company Update');
               console.log(document);


               try{


                const token = localStorage.getItem('AccessToken'); 

                let headers =  { 
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + token
                 };
    
     
                
           
                 fetch(server_url + "/update_company",{
                   method: 'POST',
                   headers: headers,
                   body: JSON.stringify(document)
                   })
                     .then(response => response.json())
                     .then(function(data){
                             console.log('xxxx company update');
                             console.log(data);
                             console.log('xxx2 company update');
                             //resolve(data);
                             history.push('/superadmin'); 
                     
                     });
              }
             catch(err){
                     console.log(err);
                    // reject(err);
             }

             }}>Update Company</Button>
             <Link to="/superadmin">
       <button className="center MuiButton-root detailBtn">Back to Companies List</button>
   </Link>
   </div>
   </div>
       
      </div>
   
  
  
  );
}

export default EditCompany;
