import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import "./UserManagement.css";
//import Header from "./Header";
//import AddUser from "./AddUser";
import AdminList from "./AdminList";
//import EditUser from './EditUser';
import {CreateUserS, UpdateProfile} from '../templates/SuperAdmin';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';


function AdminUserManagement(props) {

console.log("AdminUserManagement props: " + props.UserName)


 
  return (
    <div id="rowDiv" className="ui container">
     
      
      
      <AdminList {...props}
              />
      
     
    </div>
  );
}


export default AdminUserManagement;