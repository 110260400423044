import React, { useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ReactDOM from "react-dom";

import Editor from "@monaco-editor/react";

import Grid from '@material-ui/core/Grid';
import { Button, Divider } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';



import ReactJson from 'react-json-view';




const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
});






export default function LoadData() {
  const classes = useStyles();


  const [JSONView,setJSONView] = React.useState();
  const [TextView,setTextView] = React.useState();

  const [TxtBoxValue,setTxtBoxValue] = React.useState();


  useEffect(()=>{


            let testdata = localStorage.getItem("testObject");
            setTxtBoxValue(testdata);

  },[]);

  return (

   
  

    <div width="400px" height="100%">
        <h3>Load JSON Data</h3>
        <div>

        <Button    
                variant="contained"
                color="primary"
                onClick={()=>{

                  
                    if(JSONView){
                        setJSONView(false);
                        //setTextView(true);
                    }
                    else{
                        setJSONView(true);
                        setTextView(false);
                        console.log(TxtBoxValue);
                    }
                

                  }}
                  className={classes.button}>JSON</Button>

                    &nbsp;

        <Button    
                variant="contained"
                color="primary"
                onClick={()=>{

                    if(TextView){
                        setTextView(false);
                    }
                    else{
                        setTextView(true);
                        setJSONView(false);
                        
                    }
                  }}
                  className={classes.button}>Text</Button>


        &nbsp;

        <Button    
                variant="contained"
                color="primary"
                onClick={()=>{

                  
                    
                    //Get Data
                    localStorage.setItem('testObject', TxtBoxValue);

                

                  }}
                  className={classes.button}>Save Test Data</Button>



     

                  &nbsp;&nbsp;




       </div>
       <br />
       <Divider></Divider>
       <br />
       <br />


    
        <Grid container spacing={2} justify="center">

       
             
                <div id="TextContainer" height="100%" width='1600px'>
                {TextView &&
                            <TextField
                            id="standard-multiline-static"
                            onChange={(e)=> {

                                setTxtBoxValue(e.target.value);

                        
                            }}
                            label="Multiline"
                            multiline
                            rows={100}
                            defaultValue={TxtBoxValue}
                            style ={{width: '500px'}}
                            />
                }
                </div>
                <br />
                
       

                <div id="JSONContainer">
                {JSONView &&
                        <div>
                        <ReactJson src={JSON.parse(TxtBoxValue)} />

                        <br />
                        <br />

                        </div>
                }
                </div>

    </Grid>
    </div>

  )  
}