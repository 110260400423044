import React, { useEffect,useState, useRef } from 'react';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import UserCard from "./UserCard";
import company from "../images/company.png";


//const server_url = 'https://automate.isnet.co.za';
let server_url = process.env.REACT_APP_SERVER_URL;
const CompanyList = (props) => {
  const history = useHistory();

  

  //console.log(props);
  
  

  
  const [id, setId]= useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults]= useState([]);
  const [all_companies, setall_companies] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [CompanyMailBoxes, setCompanyMailBoxes]= useState([]);
  
  useEffect(()=>{
 

   async function gcompanies(){

    let companyData = await get_companies();

    setall_companies(companyData);
    let company_id = companyData._id;
    setId(company_id);
   
    
    }

    gcompanies();

  },[]);



  

      async function get_companies(){

        return new Promise(function (resolve,reject){
        
        try{
        
        const token = localStorage.getItem('AccessToken');
        
        let options = {
        
        headers: {
        
        'Authorization': 'Bearer ' + token
        
        },
        
        insecure: true,
        
        rejectUnauthorized: false
        
        }
        
        
        fetch(server_url + "/get_companies", options)
        
        .then(response => response.json())
        
        .then(function(data){
        
        console.log('xxx');
        
       // console.log(data.CompanyName);
        
        console.log('xxx2');
        
        const mailArr= [];
        const dataArr= [];
        console.log(data);
        for (let i = 0; i < data.length; i++) {
          var element = data[i].CompanyStatus;
        // var mailBoxes = data[i].CompanyMailBoxes[0];
        // console.log(data[0].CompanyMailBoxes[0].email_address);
        /* for (let m = 0; m < mailBoxes.length; m++) {
           const mailbox = mailBoxes[m];
           mailArr.push(mailbox);
           console.log(mailbox);
         }

        // setCompanyMailBoxes(mailArr)
         console.log(mailArr);*/
          if(element != "deleted"){

            dataArr.push(data[i]);


          }
          
        resolve(dataArr);
     
        }
        });
        
        }
        
        catch(err){
        
        console.log(err);
        
        reject(err);
        
        }
        
        })
        
        }

  


  
 
  

  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
    if (searchInput !== '') {
        const filteredData = all_companies.filter((item) => {
            

            return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        })
        setFilteredResults(filteredData)
    }
    else{
        setFilteredResults(all_companies)
    }
}


 
  return(
    <div className="main" id="companyBox">
    <h2>Company List</h2>
    <div className="ui search">
      <div className="ui icon input">
    <input 

    type="text"  
    placeholder="Search Companies" 
    className="prompt" 
    onChange={(e) => searchItems(e.target.value)}
    
   
   
     />
    
    <i className="search icon"></i>
    
      </div>

      
    </div>
  
    <div>
    <button className='buttons' id="addBtn" onClick={(button) => history.push('/addcompany')}>Add New Company</button>
    </div>
     
    {searchInput.length > 1 ? (
                    filteredResults.map((item) => {
                        return (

                          <div className="item ui celled list"  id="list_users" 
      key={item._id}>
      
      <div className="content item">
      <Link to={{pathname:`/company/${item._id}`, state: {companies: {id: item._id, CompanyName: item.CompanyName, CompanyAdmin:item.CompanyAdmin, CompanyDatabase: item.CompanyDatabase, CompanyStatus:item.CompanyStatus, api_key: item.api_key, CompanyMailBoxes: item.CompanyMailBoxes}}}}>
      <img className="ui avatar image" src={company} alt="company" />
        <div className="header">{item.CompanyName}</div>
        </Link>
        <div>Admin: {item.CompanyAdmin}</div>
        
       
        
      </div>
      <i
        className="trash alternate outline icon"
        style={{ color: "red", marginTop: "7px", marginRight: "40px" }}        
        
        onClick={()=>{

          
          let document = {"CompanyName": item.CompanyName}

                    
          console.log(document);

          try{
            const token = localStorage.getItem('AccessToken'); 

          let headers =  { 
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          };

                   
              
        fetch(server_url + "/delete_company",{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(document)
          })
            .then(response => response.json())
            .then(function(data){
                    console.log('xxxx deleting company');
                    console.log(data);
                    console.log('xxxx deleting company');
                    
                    
            
                  });
          }
          catch(err){
                  console.log(err);
                // reject(err);
          }

          }}
      ></i>
      
       <Link to={{pathname:`/editcompany/${item._id}`, state: {companies: {id: item._id, CompanyName: item.CompanyName, CompanyAdmin:item.CompanyAdmin, CompanyDatabase: item.CompanyDatabase, CompanyStatus:item.CompanyStatus, api_key: item.api_key, CompanyMailBoxes: item.CompanyMailBoxes}}}} key={item._id} >
        <i
          className="edit alternate outline icon"
          style={{ color: "#2E8A7C", marginTop: "7px", marginRight: "10px" }}
         
        ></i>
      </Link>
     
    </div>
     

                          )
                    })
                ) : (
                  all_companies.map((item) => {
                        return (

                            
                          <div className="item ui celled list"  id="list_users" 
      key={item._id}>
      
      <div className="content item">
      <Link to={{pathname:`/company/${item._id}`, state: {companies: {id: item._id, CompanyName: item.CompanyName, CompanyAdmin:item.CompanyAdmin, CompanyDatabase: item.CompanyDatabase, CompanyStatus:item.CompanyStatus, api_key: item.api_key, CompanyMailBoxes:item.CompanyMailboxes }}}}>
      <img className="ui avatar image" src={company} alt="company" />
        <div className="header">{item.CompanyName}</div>
        </Link>
        <div>Admin: {item.CompanyAdmin}</div>
       
        
       
        
      </div>
      <i
        className="trash alternate outline icon"
        style={{ color: "red", marginTop: "7px", marginRight: "40px" }}        
        
        onClick={()=>{

          
        let document = {"CompanyName": item.CompanyName}

                  
        console.log(document);

        try{
          const token = localStorage.getItem('AccessToken'); 

        let headers =  { 
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        };

                
            
        fetch(server_url + "/delete_company",{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(document)
        })
          .then(response => response.json())
          .then(function(data){
                  console.log('xxxx deleting company');
                  console.log(data);
                  console.log('xxxx deleting company');
                  
                  
          
                });
        }
        catch(err){
                console.log(err);
              // reject(err);
        }

        }}
      ></i>
      
       <Link to={{pathname:`/editcompany/${item._id}`, state: {companies: {id: item._id, CompanyName: item.CompanyName, CompanyAdmin:item.CompanyAdmin, CompanyDatabase: item.CompanyDatabase, CompanyStatus:item.CompanyStatus, api_key: item.api_key, CompanyMailBoxes:item.CompanyMailboxes}}}} key={item._id} >
        <i
          className="edit alternate outline icon"
          style={{ color: "#2E8A7C", marginTop: "7px", marginRight: "10px" }}
         
        ></i>
      </Link>
     
    </div>

                          )
                    })
                )}

   {/*<div id="list_users" className="ui celled list" >{renderCompanyList.length > 0 ?renderCompanyList : "No companies available"}</div>*/}
   </div>
  )
};

export default CompanyList;