
import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {BrowserRouter as Router, Link, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';




import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ToastContainer, toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactDOM from 'react-dom';
import Xero from './Xero';
import Sage from './Sage';
import Pastel from './Pastel';






const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      justifycontent: "center",
      alignitems: "center",
    },
    container: {
        width: '80%',
        justifycontent: "center",
        alignitems: "center",
      },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      color: '#38AD9A',
      fontSize:'22',
      fontWeight:"bold",
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
   
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  let server_url = 'https://automate.isnet.co.za';
 /*let dev=false;
  if(dev){
    server_url = 'http://127.0.0.1:3002'
  }*/


  class Tabsadmin extends React.Component {
    render(){
      return(
  
     
        
        <div id="Tabs_box">
  
  
          <div className='tabs'>
              
              <Tabs>
                  <Tab label="Xero">
                    <div className="displayTab">
                    <Xero></Xero>
                  
                   
                    </div>
                  </Tab>
                  <Tab label="Sage">
                    <div className="displayTab">
                   <Sage></Sage>
                    
                   
                    </div>
                    </Tab>
  
                    <Tab label="Pastel">
                    <div className="displayTab">
                    <Pastel></Pastel>
                    <div>
                    
                    </div>
                    </div>
                  </Tab>
                   
              </Tabs>
                  
                    
                  
                
          </div>
      
          
           
           
  
           
        
        </div>
     
      )
    }
  }
  
  class Tabs extends React.Component{
    state ={
      activeTab: this.props.children[0].props.label
    }
    changeTab = (tab) => {
  
      this.setState({ activeTab: tab });
    };
    render(){
      
      let content;
      let buttons = [];
      return (
        <div>
          {React.Children.map(this.props.children, child =>{
            buttons.push(child.props.label)
            if (child.props.label === this.state.activeTab) content = child.props.children
          })}
           
          <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab}/>
          <div className="tab-content">{content}</div>
          
        </div>
      );
    }
  }
  
  const TabButtons = ({buttons, changeTab, activeTab}) =>{
     
    return(
      <div className="tab-buttons-super">
      {buttons.map(button =>{
         return <button className={button === activeTab? 'active': ''} onClick={()=>changeTab(button)}>{button}</button>
      })}
      </div>
    )
  }
  
  const Tab = props =>{
    return(
      <React.Fragment>
        {props.children}
      </React.Fragment>
    )
  }
   
  
  ReactDOM.render(<Tabsadmin />, document.getElementById('root'));
  

  export function BasicModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
      <div>
        <Button onClick={handleOpen}>Open modal</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <iframe src="https://login.xero.com/identity/user/login"></iframe>
          </Box>
        </Modal>
      </div>
    );
  }


function ErpLink() {


    const [ClientId, setClientId] = useState();
    const [ClientSecret, setClientSecret] = useState();
    const [Erp, setErp] = useState();


    const [Tenant, setTenant] = useState();
    const [RowKeys, setRowKeys] = useState([]);
    const [TenantIds, setTenantIds] = useState([]);
    const [TenantNames, setTenantNames] = useState([]);
    const [SelectedId, setSelectedId] = useState();



    const classes = useStyles();


    useEffect(()=>{

   // setErp('XERO');

    },[Erp]);



  const history = useHistory();


  const ExternalRedirect = ({ to, ...routeProps }) => {
    return <Route {...routeProps} render={() => window.location = to} />;
 };


    /*
    async function save_xero_data(dataObject){
  
  
        return new Promise(function (resolve,reject){

          try{

                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    let myheaders = {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    };
                      

                      //let server_url = 'https://automate.isnet.co.za';
                      let server_url = 'http://127.0.0.1:3000';
            
                      fetch(server_url + "/save_xero_data",{
                        method: 'POST',
                        //headers: { 'Content-Type': 'application/json' },
                        headers: myheaders,
                        body: JSON.stringify(dataObject)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })
          */



          async function reauth_xero(dataObject){
  
  
            return new Promise(function (resolve,reject){
    
              try{
    
                        const token = localStorage.getItem('AccessToken'); 
                        let options = {
                          headers: {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json'
                          },
                          insecure: true,
                          rejectUnauthorized: false
                        }
    
                        let myheaders = {
                          'Authorization': 'Bearer ' + token,
                          'Content-Type': 'application/json'
                        };
                          
    
                          //let server_url = 'https://automate.isnet.co.za';
                          //let server_url = 'http://127.0.0.1:3002';
                
                          fetch(server_url + "/reauth_xero",{
                            method: 'POST',
                            //headers: { 'Content-Type': 'application/json' },
                            headers: myheaders,
                            body: JSON.stringify(dataObject)
                            })
                              .then(response => response.json())
                              .then(function(data){
                                      console.log('xxx');
                                      console.log(data);
                                      console.log('xxx2');
                                      resolve(data);
                              
                              });
              }
              catch(err){
                      console.log(err);
                      reject(err);
              }
    
              })




      }          


      async function sync_xero_payments(datestamp){
  
  
        return new Promise(function (resolve,reject){

          try{

                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    let myheaders = {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    };
                      

                      //let server_url = 'https://automate.isnet.co.za';
                      //let server_url = 'http://127.0.0.1:3002';
            
                      fetch(server_url + "/sync_xero_payments?datestamp=" + datestamp,{
                        method: 'GET',
                        //headers: { 'Content-Type': 'application/json' },
                        headers: myheaders,
                        body: JSON.stringify()
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })




  }          



  async function sync_xero_invoices(datestamp){
  
  
    return new Promise(function (resolve,reject){

      try{

                const token = localStorage.getItem('AccessToken'); 
                let options = {
                  headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  },
                  insecure: true,
                  rejectUnauthorized: false
                }

                let myheaders = {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                  

                  //let server_url = 'https://automate.isnet.co.za';
                  //let server_url = 'http://127.0.0.1:3002';
        
                  fetch(server_url + "/sync_xero_invoices?datestamp=" + datestamp,{
                    method: 'GET',
                    //headers: { 'Content-Type': 'application/json' },
                    headers: myheaders,
                    body: JSON.stringify()
                    })
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              resolve(data);
                      
                      });
      }
      catch(err){
              console.log(err);
              reject(err);
      }

      })




}       




async function save_tenant(){
  
  
  return new Promise(function (resolve,reject){

    try{

              const token = localStorage.getItem('AccessToken'); 
              let options = {
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                },
                insecure: true,
                rejectUnauthorized: false
              }

              let myheaders = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
              };
                

                let dataJSON = {
                  tenantId: SelectedId
                }

                //let server_url = 'https://automate.isnet.co.za';
                //let server_url = 'http://127.0.0.1:3002';
      
                fetch(server_url + "/save_tenant",{
                  method: 'POST',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  body: JSON.stringify(dataJSON)
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}   

async function get_xero_tenants(){
  
  
  return new Promise(function (resolve,reject){

    try{

              const token = localStorage.getItem('AccessToken'); 
              let options = {
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                },
                insecure: true,
                rejectUnauthorized: false
              }

              let myheaders = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
              };
                

                //let server_url = 'https://automate.isnet.co.za';
                //let server_url = 'http://127.0.0.1:3002';
      
                fetch(server_url + "/get_xero_tenants",{
                  method: 'GET',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  body: JSON.stringify()
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })




}       
  
      
      
      
      




    if(Erp === 'XERO'){


 

    return (
        <div>
            XERO Intergration Screen



        {/*
            <TextField
            variant="outlined"
            margin="normal"
            value={ClientId}
            required
            fullWidth
            id="ClientId"
            label="ClientId"
            name="clientid"
            //autoComplete="text"
            autoFocus
            onChange={(e)=>{
   
            
              //FieldData[item] = e.target.value;
               setClientId(e.target.value);
               
          

         }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={ClientSecret}
            required
            fullWidth
            name="ClientSecret"
            label="ClientSecret"
            type="password"
            id="password"
            //autoComplete="current-password"
        
       
            onChange={(e)=>{
   
                 //console.log('testing:');
                 //console.log(e.target.value);
                 //FieldData[item] = e.target.value;
                  setClientSecret(e.target.value);
                  //console.log(e.target.value);
                 
             
   
            }}
          />
        */}


       <Divider></Divider>


<Button
                  id="retrieveBtn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                   // toast('Setting up Xero');



                   /*
                   let doc_json = {
                       "client_id": ClientId,
                       "client_secret": ClientSecret
                   }
                   

                   console.log(doc_json);
                   let resp = await save_xero_data(doc_json);
                   console.log(resp);


                   
                   */
                  

                 try{

                  
                  let run_reauth_xero_url = await reauth_xero();
                  console.log(run_reauth_xero_url);
                  //history.push(run_reauth_xero_url.consentUrl);
                  //ExternalRedirect({'https://www.google.com'})
                  let consentUrl = run_reauth_xero_url.consentUrl;
                   window.open(consentUrl, '_blank');
                  


                 }
                 catch(err){
                     console.log(err);
                 }




                  }}
                  className={classes.button}

                  
                >
                  
                  Authenticate Xero
                </Button>

                  <BasicModal></BasicModal>
                  

       <Divider></Divider>


       <Button
                  id="retrieveBtn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                   // toast('Setting up Xero');


                  //set this from a datebox somewhere
                   let datestamp = '2021-08-01';
            

                 try{
                  let get_xero_tenants_json= await get_xero_tenants();
                  console.log(get_xero_tenants_json);
                  setRowKeys(["Testing", "testint1234"]);




                  //Iterate Tenant and set row keys
                  console.log('xxxf');
                  console.log( get_xero_tenants_json.length);
                  let row = new Array();
                  let tenantIdArray = new Array();
                  let tenantNameArray = new Array();
                  let i=0; const iMax = get_xero_tenants_json.length - 1; for(; i<=iMax; i++){

                    console.log(get_xero_tenants_json[i]);
                    let item = get_xero_tenants_json[i];

                    let tenantId = item.tenantId;
                    let tenantName = item.tenantName;

                    let jsondoc = {
                      tenantId: tenantId,
                      tenantName: tenantName
                    }
                    row.push(jsondoc);
                    tenantIdArray.push(tenantId);
                    tenantNameArray.push(tenantName);


                    if(i == iMax){
                      //setRowKeys(jsondoc);
                      setTenantIds(tenantIdArray);
                      setTenantNames(tenantNameArray);
                    }


                  }
                  

                 }
                 catch(err){
                     console.log(err);
                 }

                  }}
                  className={classes.button}
                >
                  
                  Get Tenants
        </Button>




            <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Tenant}
                  label="Tenant"
                  onChange={(e)=>{
                        console.log(e.target.value);
                        setTenant(e.target.value);
                        //console.log(Fieldh);
                        //get index
                        let tindex = TenantNames.indexOf(e.target.value);
                        setSelectedId(TenantIds[tindex]);
                        console.log(tindex);

                  }
                  }
                >

              
                  
              {
              TenantNames &&
              TenantNames.map((item) => {

                return(

                  <MenuItem value={item}>{item}</MenuItem>
                  

                );

                })
              }
              

                </Select>



                <span>{SelectedId}</span>



                <Button
                  id="retrieveBtn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                   // toast('Setting up Xero');


                  //set this from a datebox somewhere
                  // let datestamp = '2021-08-01';
            

                 try{
                  //let run_sync_xero_payments= await sync_xero_payments(datestamp);
                  let save_tenant_ret = await save_tenant();
                  console.log(save_tenant_ret);

                 }
                 catch(err){
                     console.log(err);
                 }

                  }}
                  className={classes.button}
                >
                  
                  Save Tenant Id
                </Button>

                <Divider></Divider>


          <Button
                  id="retrieveBtn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                   // toast('Setting up Xero');


                  //set this from a datebox somewhere
                   let datestamp = '2021-08-01';
            

                 try{
                  let run_sync_xero_payments= await sync_xero_payments(datestamp);

                 }
                 catch(err){
                     console.log(err);
                 }

                  }}
                  className={classes.button}
                >
                  
                  Sync Payments
                </Button>


                <Divider></Divider>


              <Button
                  id="retrieveBtn"
                  variant="contained"
                  //color="primary"
                  onClick={async ()=>{

                   // toast('Setting up Xero');


                  //set this from a datebox somewhere
                   let datestamp = '2021-08-01';
            

                 try{
                  let run_sync_xero_invoices= await sync_xero_invoices(datestamp);

                 }
                 catch(err){
                     console.log(err);
                 }

                  }}
                  className={classes.button}
                >
                  
                  Sync Invoices
                </Button>



        </div>
    )

    }
    else{

        return(
        <div>

          <h3>ERP Link Settings</h3>
{/* 
        <InputLabel id="demo-simple-select-label">ERP systems</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Erp}
          label="Select Erp System"
          onChange={(e)=>{
                console.log(e.target.value);
                setErp(e.target.value);
                //setFieldtoMatch(e.target.value);
                //console.log(Fieldh);
          }
          }
        >



          <MenuItem value='XERO'>Xero</MenuItem>
          <MenuItem value='PASTEL'>Pastel</MenuItem>
          <MenuItem value='PASTEL'>Sage</MenuItem>

    
      

        </Select>*/}

        <Tabsadmin></Tabsadmin>

          </div>

        );
    }
    


}

export default ErpLink;
