import React, { useEffect, useState, useRef, Component } from 'react';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';

import { DataGrid } from '@mui/x-data-grid';
import SearchBar from '../components/SearchBar';
import AutoCompleteInput from '../components/AutoCompleteInput';
import {accountCode} from '../components/AccountCodeData';
import { IconButton} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Select from 'react-select'

import TableRows from "../components/TableRows"
import { gridColumnsTotalWidthSelector } from '@material-ui/data-grid';
import { AccountBalanceWalletSharp } from '@material-ui/icons';








let server_url = 'https://automate.isnet.co.za';



function InvoiceTable (props){

  

    const [Sources,setSources] = useState([]);

    const [DocName, setDocName] = React.useState();
    const [DocSender, setDocSender] = React.useState();
    const [DocTag, setDocTag] = React.useState();
    const [DocLink,setDocLink] = React.useState();

    const [UUID, setUUID] = React.useState();

    const [RowData, setRowData] = useState([]);
    const [RowKeys, setRowKeys] = useState([]);
    const [ColumnData, setColumnData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [FilteredData, setFilteredData] = useState([]);
    const [AccountCodeData, setAccountCodeData] =useState([]);
    const [AccountCode, setAccountCode]= useState([]);
    const [Code, setCode]= useState();
    const [SelectedData, setSelectedData]= useState();
    const [rows, setRows] = React.useState(props.Sources);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [deletedRows, setDeletedRows] = useState([]);
    const [SuppliersData, setSuppliersData]=useState([]);
    const [selectedValue, setSelectedValue] = React.useState("");


    const [openVerification, setOpenVerification] = React.useState(false);
    const handleOpenVerification = () => setOpenVerification(true);
    const handleCloseVerification = () => setOpenVerification(false);


    const [VerifyStatus, setVerifyStatus] = useState();
    
    
    const myRef = useRef('');

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    


    const jsonData = accountCode.map((item)=>{
      return(
        item.Name

      )
    });


    const jsonCode = accountCode.map((item)=>{
      return(

        item.Code

      )
    });
   

    useEffect(()=>{
      

      
      //setCode(jsonCode);

      async function getAcccounts(){
        console.log('AAA');
 
      
       
        let Accounts = await getXeroAccounts();
        console.log(Accounts.accounts);

        const zjsonCode = Accounts.accounts.map((item)=>{
          return(
    
            item.code
    
          )
        });

        setCode(zjsonCode);

        const zjsonData = Accounts.accounts.map((item)=>{
          return(
            item.name
            

          )
        });

        setAccountCodeData(zjsonData);
        setCode(Accounts.accounts);

     

        



        console.log('END - AAA');
      }

      getAcccounts();
 
    },[]);

    useEffect(()=>{
      
      //setAccountCodeData(jsonData);
     
    },[]);



  async function getXeroAccounts () {
   
  return new Promise(function (resolve,reject){
    
      try{
  
  
        const token = localStorage.getItem('AccessToken'); 
  
      
  

          fetch("https://automate.isnet.co.za/get_xero_accounts",{
              method: 'GET',
              headers: { 
                          'Content-Type': 'application/json',
                          Authorization: 'Bearer ' + token
              }
              })
              .then(response => response.json())
              .then(function(data){
                      console.log('ppp');
                      console.log(data.body);
                      console.log('ppp2');
                      resolve(data.body);
                      //setAccounts(data.body);
              
              });
             
              //console.log(SuppliersData);
          }
          catch(err){
          console.log(err);
          reject(err);
          }

          })



}






    async function XeroSuppliers () {
   
      return new Promise(function (resolve,reject){
        
          try{
      
      
            const token = localStorage.getItem('AccessToken'); 
      
          
      
    
              fetch("https://automate.isnet.co.za/get_xero_suppliers",{
                  method: 'GET',
                  headers: { 
                              'Content-Type': 'application/json',
                              Authorization: 'Bearer ' + token
                  }
                  })
                  .then(response => response.json())
                  .then(function(data){
                          console.log('fff');
                          console.log(data.body.contacts);
                          console.log('xxx2');
                          resolve(data.body.contacts);
                          setSuppliersData(data.body.contats);
                  
                  });
                 
                  console.log(SuppliersData);
              }
              catch(err){
              console.log(err);
              reject(err);
              }
  
              })
  
  
  
  }

 // XeroSuppliers();

  useEffect( ()=>{

    XeroSuppliers();
    
    
   
  },[]);
    
 
 
    const autoCompleteCallback=(data)=>{

      console.log('callback: ' + data);  
      setSelectedData(data);    
      return data;

    }




      useEffect(()=>{


        /*
        accountCode.map((item)=>{      


          
          if(SelectedData !== '' && SelectedData === item.name){
            
            setCode(item.code)
           return code; 
            
          }else{
          
           
           return
          }
       })
        */
      });

 


  

 function AddDeleteTableRows(){

 // console.log("props Adddelete: " +data.Description)
  const [rowsData, setRowsData] = useState([]);
  const[CodeNameValue,setCodeNameValue]= useState('');
  const [subTotal, setSubTotal]=useState();
  const [totalTaxCheck, setTotalTaxCheck]=useState();
  const [totalCheck, setTotalCheck]=useState();
  const [addId, setAddId]= useState();
  
  
  const[TaxRemoved, setTaxRemoved] = useState(false);

 

  useEffect(()=>{

    let obj = RowData;

    for (let i = 0; i < obj.length; i++) {
      var element = obj[i];
      // tmp hold element.AccountCode='';
      setRowData(obj);
     // handleInitialSum();
      console.log(obj);
      
    }

    console.log('YEAY YEAh');
  
   setRowsData(RowData);
 
 }, [])



 

 //Verify Data matches
  
  const handleSum=()=>{

    let NewArr=[];
    let sumVal = 0;
    let TaxArr = [];
    let totalTaxAm = 0;
  
    let myTotal = 0;

    console.log("RowData: "+ RowData);
    console.log("rowsData: "+ rowsData);
    
  for (let i = 0; i < rowsData.length; i++) {
  
    const element = rowsData[i].LineAmount;
    const taxEl= rowsData[i].TaxAmount;

    //console.log('XXX: ' + taxEl);
    
    TaxArr.push(Number(taxEl)); 
    NewArr.push(Number(element));  
   
    sumVal = NewArr.reduce((a, b) => a + b);
    totalTaxAm = TaxArr.reduce((a, b) => a + b);
  
    myTotal= sumVal+totalTaxAm;
   
    
  }
  
      setSubTotal(sumVal.toFixed(2));
      setTotalTaxCheck(totalTaxAm.toFixed(2));
      setTotalCheck(myTotal.toFixed(2));


      //Check Totals

  
   
  }



  const handleVerification=()=>{




    let NewArr=[];
    let sumVal = 0;
    let TaxArr = [];
    let totalTaxAm = 0;
  
    let myTotal = 0;

    console.log("RowData: "+ RowData);
    console.log("rowsData: "+ rowsData);


    let RowsTotal = 0;

    
    

  //Check totals for Rows
  let i=0; const iMax = rowsData.length - 1; for (; i <= iMax; i++) {
  
    //const element = rowsData[i].LineAmount;
    //const taxEl= rowsData[i].TaxAmount;

    let quantity = rowsData[i].Quantity;
    let unitAmount = rowsData[i].UnitAmount;
    let taxAmount = rowsData[i].TaxAmount;
    let LineAmount = rowsData[i].LineAmount;

   

    console.log(quantity + " " + unitAmount + " " + taxAmount + " " + LineAmount );
    RowsTotal = RowsTotal + LineAmount

   if(i == iMax){

      //Validata SubTotal Tax and Total
      console.log("SubTotal:" + RowsTotal);
      let Tax = (Math.round(((RowsTotal*0.15) * 100))/100).toFixed(2);
      //let Tax = 0;
      console.log("Tax: " + Tax);
      let Total = ((parseFloat(RowsTotal) * 100) + (parseFloat(Tax) * 100))/100;
      console.log("Total: " + Total);


      //Calculate Row Totals
      setSubTotal(RowsTotal);
      setTotalTaxCheck(Tax);
      setTotalCheck(Total);

      //setOpenVerification(true);
      console.log('Totals');

      let vData = {
        SubTotal: RowsTotal,
        TaxTotal: Tax,
        Total: Total
      }
      props.verifyCallback(vData);

   }
   

    
  }
  
     // setSubTotal(sumVal.toFixed(2));
     // setTotalTaxCheck(totalTaxAm.toFixed(2));
     // setTotalCheck(myTotal.toFixed(2));


      //Check Totals

  
   
  }
  


  const handleUpdate=()=>{




    let NewArr=[];
    let sumVal = 0;
    let TaxArr = [];
    let totalTaxAm = 0;
  
    let myTotal = 0;

    console.log("RowData: "+ RowData);
    console.log("rowsData: "+ rowsData);


    let RowsTotal = 0;

    
    

  //Check totals for Rows
  let i=0; const iMax = rowsData.length - 1; for (; i <= iMax; i++) {
  
    //const element = rowsData[i].LineAmount;
    //const taxEl= rowsData[i].TaxAmount;

    let quantity = rowsData[i].Quantity;
    let unitAmount = rowsData[i].UnitAmount;
    let taxAmount = rowsData[i].TaxAmount;

    let LineAmount;
    let NewTaxTotal;
    
    if(rowsData[i].LineAmount){
      LineAmount = rowsData[i].LineAmount;
    }
    else{
      var number = Number(unitAmount.replace(/[^0-9.-]+/g,""));
      LineAmount = number * Number(quantity); //2022-06-27
      console.log('setting line: ' + LineAmount)
      rowsData[i].LineAmount = LineAmount;
    
    }
    console.log(quantity + " " + unitAmount + " " + taxAmount + " " + LineAmount );
    RowsTotal = RowsTotal + LineAmount
    NewTaxTotal = NewTaxTotal + rowsData[i].TaxAmount;



   if(i == iMax){

      //Validata SubTotal Tax and Total

      let sSubTotal = (parseFloat(RowsTotal)).toFixed(2);
      RowsTotal = sSubTotal;

      console.log("SubTotal:" + RowsTotal);
      let Tax;

      if(TaxRemoved){

          Tax = NewTaxTotal;

      }
      else{
          Tax = (Math.round(((RowsTotal*0.15) * 100))/100).toFixed(2);
          console.log("Tax: " + Tax);
          }

      let Total = (((parseFloat(RowsTotal) * 100) + (parseFloat(Tax) * 100))/100).toFixed(2);
      console.log("Total: " + Total);


      //Calculate Row Totals
      setSubTotal(RowsTotal);
      setTotalTaxCheck(Tax);
      setTotalCheck(Total);

      //setOpenVerification(true);
      console.log('Totals');

      let vData = {
        SubTotal: RowsTotal,
        TaxTotal: Tax,
        Total: Total
      }
      //props.verifyCallback(vData);

   }
   

    
  }
  
     // setSubTotal(sumVal.toFixed(2));
     // setTotalTaxCheck(totalTaxAm.toFixed(2));
     // setTotalCheck(myTotal.toFixed(2));


      //Check Totals

  
   
  }



  const handleTaxRemove=()=>{



    if(TaxRemoved == false){
    console.log('removing tax from line amount');

    let NewArr=[];
    let sumVal = 0;
    let TaxArr = [];
    let totalTaxAm = 0;
  
    let myTotal = 0;

    console.log("RowData: "+ RowData);
    console.log("rowsData: "+ rowsData);


    let RowsTotal = 0;

    
    

  //Check totals for Rows
  let i=0; const iMax = rowsData.length - 1; for (; i <= iMax; i++) {
  
    //const element = rowsData[i].LineAmount;
    //const taxEl= rowsData[i].TaxAmount;

    let quantity = rowsData[i].Quantity;
    let unitAmount = rowsData[i].UnitAmount;
    let taxAmount = rowsData[i].TaxAmount;

    let LineAmount;
    let NewTaxTotal;
    if(rowsData[i].LineAmount){

      
     // let lAmount = rowsData[i].LineAmount;
     // console.log('fmt:' + lAmount);
     // let cleanline = Number(lAmount.replace(/[^0-9.-]+/g,""))
     // console.log('Amt; ' + cleanline);
     // let tAmount = ((Number(rowsData[i].LineAmount) * 100) / 0.15) / 100;
     // console.log('Tax Amount: ' + tAmount);
      let tpLineAmount = rowsData[i].LineAmount;
      if(tpLineAmount){
          console.log("ZZttzZ; " + tpLineAmount);
          let lll = Number(tpLineAmount.toString().replace(/[^0-9.-]+/g,""));
          console.log('lll:' + lll);
         // let ltax =  ((lll * 100) * 0.15)/100 

          let ltax = (Math.round(((lll*0.15) * 100))/100).toFixed(2);
          console.log('TCODE: ' + ltax);
          let newTotal = (Math.round((tpLineAmount - ltax)*100)/100).toFixed(2);
          console.log("new: " + newTotal);
          LineAmount = newTotal;
          rowsData[i].LineAmount = LineAmount;
          rowsData[i].TaxAmount = ltax;
          setTaxRemoved(true);

      }
    }
    else{
      var number = Number(unitAmount.replace(/[^0-9.-]+/g,""));

      let tnumber = ((number * 100) * 0.15)/100 
      console.log('taxtax: ' + tnumber);
      LineAmount = number * Number(quantity); //2022-06-27
      console.log('setting line: ' + LineAmount)
      rowsData[i].LineAmount = LineAmount;
      rowsData[i].TaxAmount = tnumber;
      

      
    }
   
    console.log(quantity + " " + unitAmount + " " + taxAmount + " " + LineAmount );
    RowsTotal = RowsTotal + LineAmount
    NewTaxTotal = NewTaxTotal + rowsData[i].TaxAmount;



   if(i == iMax){

      //Validata SubTotal Tax and Total

      let sSubTotal = (parseFloat(RowsTotal)).toFixed(2);
      RowsTotal = sSubTotal;

      console.log("SubTotal:" + RowsTotal);

      let Tax = 0;
      if(TaxRemoved){

        Tax = NewTaxTotal;
        console.log('NewTax: ' + Tax);
          
      }
      else{
          //Tax = (Math.round(((RowsTotal*0.15) * 100))/100).toFixed(2);
          Tax = NewTaxTotal;
          console.log("Tax: " + Tax);
      }
      let Total = (((parseFloat(RowsTotal) * 100) + (parseFloat(Tax) * 100))/100).toFixed(2);
      console.log("Total: " + Total);


      //Calculate Row Totals
      setSubTotal(RowsTotal);
      setTotalTaxCheck(Tax);
      setTotalCheck(Total);

      //setOpenVerification(true);
      console.log('Totals');

      let vData = {
        SubTotal: RowsTotal,
        TaxTotal: Tax,
        Total: Total
      }
      //props.verifyCallback(vData);

   }
   

    
  }
  
     // setSubTotal(sumVal.toFixed(2));
     // setTotalTaxCheck(totalTaxAm.toFixed(2));
     // setTotalCheck(myTotal.toFixed(2));


      //Check Totals
    }
    else{

      console.log('Tax Already Removed');
    } 

  
   
  }
  

  const setAllCodesSame = ()=>{

    console.log('Setting All codes the same');


    let obj = RowData;

    console.log(obj.length);

    let firstCode = obj[0].AccountCode;
    console.log(firstCode);
    
    for (let i = 0; i < obj.length; i++) {
      var element = obj[i];
      // tmp hold element.AccountCode='';
      obj[i].AccountCode = firstCode;
     
      setRowData(obj);
     // handleInitialSum();
      console.log(obj[i]);
      
    }

    console.log('YEAY YEAh');
  
    setRowsData(RowData);
    console.log(RowData);
    //
    handleUpdate(); //Update Totals


  }
  
 
  const addTableRows = ()=>{

   

    let addIdRow = rowsData.length;

  
    console.log(rowsData.length);
      const rowsInput={
          id:addIdRow, 
          Description:'', 
          Quantity: '', 
          UnitAmount:'', 
          TaxAmount:'', 
          LineAmount:'', 
          AccountCode:''  
      } 
      setRowsData([...rowsData, rowsInput])
      
    
  }

 const deleteTableRows = (index)=>{
      const rows = [...rowsData];
      rows.splice(index, 1);
      setRowsData(rows);
  
   
      
 }

 const handleChange = (index, evnt)=>{
  
  const { name, value } = evnt.target;
  const rowsInput = [...rowsData];
  rowsInput[index][name] = value;
  setRowsData(rowsInput);
  



}




const handleChangeID = (e, id, index) =>{
  console.log(e);


 let myRowId = 'myID_' + e;
 document.getElementById(myRowId).setAttribute('value',e);
 
  RowData[e].id= e;
  const rowsInput = [...rowsData];
  setRowsData(rowsInput);
 
 


}

let accCode;
const handleChangeCode = (e, id, index)=>{
  

  console.log('Changing Code: ' + e);

  console.log(Code);

  for (let i = 0; i < Code.length; i++) {
    let accName = Code[i].name.trim();
    accCode = Code[i].code;

    
    console.log('checking: ' + accName + " ___ " + e);
    if (e===accName) {
    
      let code_id = 'code_' + id;

      if(id==undefined){
        console.log("undefined")
      }else{

     
      document.getElementById(code_id).setAttribute('value',accCode);
      document.getElementById(code_id).innerHTML = accCode;

      console.log('yyy setting: ' + code_id + " " + accCode);
     
      //Set SelectBox Value 626

      rowsData[id].AccountCode= accCode;
    }
  }

  }



}





const handlegetCode = (index, evnt) =>{

  const { name, value } = evnt.target;
  const rowsInput = [...rowsData];
  rowsInput[index][name] = value;



  setRowsData(rowsInput);

}







  return(
      <div className="container">
          <div className="row">
              <div>
              <p id="Totals">{VerifyStatus}</p>
              <p id="p1"></p>
              <table className="table DynamicTable" id="invoiceDataTbl">
                  <thead>
                    <tr key="header">
                        <th>id</th>
                        <th>Description</th>
                        <th>QTY</th>
                        <th>UnitAmount</th>
              
                        <th>LineAmount</th>
                        <th>TaxAmount</th>
                        <th>AccountName</th>
                        <th>Code <button className='setall' onClick={setAllCodesSame} title="This button sets the account code on all items the same as the first row">SetAll</button></th>
                        <th><button className="btnAdd" onClick={addTableRows} >+</button></th>
                    </tr>

                  </thead>
                 <tbody>

                 <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} handleChangeCode={handleChangeCode} AccountCodeData={AccountCodeData} AccountCodeLookup={Code} handlegetCode={handlegetCode} handleChangeID={handleChangeID}/>

              
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Subtotal: {subTotal}</td>
                    <td>Total Tax: {totalTaxCheck}</td>
                    <td></td>
                    <td></td>
                    <td>Total: {totalCheck}</td>
                  </tr>
                 </tbody> 
              </table>

              <Button id="removeTaxBtn" onClick={handleTaxRemove}  style={{ height: 'auto', width: '140px'}}>
                Remove Tax
              </Button>

              <Button id="verifyBtn" onClick={handleUpdate}  style={{ height: 'auto', width: '140px'}}>
                Update Totals
              </Button>


              {/*
              <Button id="verifyBtn2" onClick={handleVerification}>
                Verify Invoice
              </Button>
            */}


            {/*
              <Button id="updateBtn" onClick={handleSum}>
                Update
              </Button>
            */}

            <Button
            id="saveBtn"
            type="submit"
            style={{ height: 'auto', width: '140px'}}
             onClick={()=>{

            const invoiceTabl= document.getElementById('invoice_data_table');
            const invoiceDataTabl= document.getElementById('invoiceDataTbl');






            

            if(invoiceTabl && invoiceDataTabl){




              //Verify Totals
              var PONumber = document.getElementsByClassName('invValue')[0].value;;
              var POSupplierName = document.getElementsByClassName('invValue')[1].value;
              var POCurrencyCode = document.getElementsByClassName('invValue')[2].value;
              var POReference = document.getElementsByClassName('invValue')[3].value;
              var INVStatus = document.getElementsByClassName('invValue')[4].value;
              var INVSubTotal = document.getElementsByClassName('invValue')[5].value;
              var INVTax = document.getElementsByClassName('invValue')[6].value;
              var INVTotal = document.getElementsByClassName('invValue')[7].value;
            

              let RowsTotal = 0;

    
    

              //Check totals for Rows
              let i=0; const iMax = rowsData.length - 1; for (; i <= iMax; i++) {
              
                //const element = rowsData[i].LineAmount;
                //const taxEl= rowsData[i].TaxAmount;
            
                let quantity = rowsData[i].Quantity;
                let unitAmount = rowsData[i].UnitAmount;
                let taxAmount = rowsData[i].TaxAmount;
               // let LineAmount = rowsData[i].LineAmount;


                let LineAmount;
                if(rowsData[i].LineAmount){
                  LineAmount = rowsData[i].LineAmount;
                }
                else{
                  var number = Number(unitAmount.replace(/[^0-9.-]+/g,""));
                  LineAmount = number;
                  console.log('setting line: ' + LineAmount);
                }
                
                console.log(quantity + " " + unitAmount + " " + taxAmount + " " + LineAmount );
                RowsTotal = RowsTotal + LineAmount
            
               if(i == iMax){
            
                  //Validata SubTotal Tax and Total
                  console.log("SubTotal:" + RowsTotal);
                  let sSubTotal = (parseFloat(RowsTotal)).toFixed(2);
                  RowsTotal = sSubTotal;


                  let Tax = (Math.round(((RowsTotal*0.15) * 100))/100).toFixed(2);
                  console.log("Tax: " + Tax);
                  let Total = ((parseFloat(RowsTotal) * 100) + (parseFloat(Tax) * 100))/100;
                  console.log("Total: " + Total);
            
            
                  //Calculate Row Totals
                  setSubTotal(RowsTotal);
                  setTotalTaxCheck(Tax);
                  setTotalCheck(Total);
            
                  //setOpenVerification(true);
                  console.log('Totals');
            
                  let data = {
                    SubTotal: RowsTotal,
                    TaxTotal: Tax,
                    Total: Total
                  }
                  //.verifyCallback(vData);


                  let mismatch_detected = false;


              try{


              console.log('Checking Total: ' + INVTotal + " against column total: " + data.Total);
              if(INVTotal === undefined || INVTax === undefined || INVSubTotal === undefined){
                mismatch_detected = true;
                console.log("Mismatch Detected Undefined Values");
                setVerifyStatus("Mismatch Detected Undefined Values");
              
              }


              if(parseFloat(data.SubTotal) !== parseFloat(INVSubTotal)){
                  console.log('SubTotal Mismatch:' + INVSubTotal + " " + data.SubTotal);
                  if(!mismatch_detected){
                    setVerifyStatus('SubTotal Mismatch:' + INVSubTotal + " " + data.SubTotal);
                    mismatch_detected = true;
                  }
              }
              else{
                if(!mismatch_detected){
                setVerifyStatus('Amounts Verified');
                }
              }

              if(parseFloat(data.TaxTotal) !== parseFloat(INVTax)){
                console.log('Tax Mismatch:' + INVTax + " " + data.TaxTotal);
                if(!mismatch_detected){
                setVerifyStatus('Tax Mismatch:' + INVTax + " " + data.TaxTotal);
                mismatch_detected = true;
                }
              }
              else{
                if(!mismatch_detected){
                setVerifyStatus('Amounts Verified');
                }
              }

              if(parseFloat(data.Total) !== parseFloat(INVTotal)){
                console.log('Total Mismatch: ' + INVTotal + " " + data.Total);
                if(!mismatch_detected){
                setVerifyStatus('Total Mismatch: ' + INVTotal + " " + data.Total);
                mismatch_detected = true;
                }
              }
              else{
                if(!mismatch_detected){
                setVerifyStatus('Amounts Verified');
                }
              }

              }
              catch(err){
                setVerifyStatus('Cant Verify: ' + JSON.stringify(err));
              }
                        

               if(mismatch_detected){
                  document.getElementById("p1").innerHTML = "Totals Verification Error";
                  document.getElementById("p1").style.color="red";
           
                  return
               }
               else{

                  document.getElementById("p1").innerHTML = "All fields successfully Verified";
                  document.getElementById("p1").style.color="green";
               }

            
               }
               
            
                
              }
              
              
              




              /*** End Verify Totals */


            var rowLength = invoiceTabl.rows.length;

              
            

             
                for (let i = 0; i < rowLength; i++){                  

              
                  var oCells = invoiceTabl.rows.item(i).cells;

                  
                  var cellLength = oCells.length;

                 
                  for(var j = 0; j < cellLength; j++){

                         
                          var cellVal = oCells.item(j).value;
                            
                         
                          var PONumber = document.getElementsByClassName('invValue')[0].value;;
                          var POSupplierName = document.getElementsByClassName('invValue')[1].value;
                          var POCurrencyCode = document.getElementsByClassName('invValue')[2].value;
                          var POReference = document.getElementsByClassName('invValue')[3].value;
                         var INVStatus = document.getElementsByClassName('invValue')[4].value;
                         var INVSubTotal = document.getElementsByClassName('invValue')[5].value;
                         var INVTax = document.getElementsByClassName('invValue')[6].value;
                         var INVTotal = document.getElementsByClassName('invValue')[7].value;
                         

                         var invoiceData={
                           
                              'PONumber':PONumber,
                              'POSupplierName': POSupplierName,
                              'POCurrencyCode': POCurrencyCode,
                              'INVCurrencyCode': 'ZAR',
                              'POReference': POReference,
                              'INVStatus': INVStatus,
                              'INVSubTotal': INVSubTotal,
                             'INVTax': INVTax,
                              'INVTotal': INVTotal

                            
                            }

                          var invoiceTable = {
                          
                              rowsData
                            
                          }

                          var allData = {
                            invoiceData, invoiceTable
                          }

                          for(var p = 0; p < rowsData.length; p++){
                            var dataItems= rowsData[p];
                            console.log(dataItems);

                            if(dataItems.id ==="" || dataItems.LineAmount ==="" || dataItems.AccountCode ==="" || dataItems.Description ==="" || dataItems.Quantity ==="" || dataItems.TaxAmount ==="" || dataItems.UnitAmount ===""){

                              document.getElementById("p1").innerHTML = "All fields are mandatory";
                              document.getElementById("p1").style.color="red";
                              console.log('Enter Line Amount');
                              return

                            }else{

                              document.getElementById("p1").innerHTML = "All fields successfully saved";
                              document.getElementById("p1").style.color="green";
                              //console.log(allData);


                              //console.log('--- Save this invoice to table ---');
                              //console.log(allData);

                          
                          
                              //Rather call a callback here
                             // let resp = create_data(mobj);
                              //Post to create recon
                              //let resp = await create_data(mobj);
                              //console.log(resp);
                               //Post to create recon
                               //let resp = await create_data(mobj);
                               //console.log(resp);
                          
                           
                            }

                            if(p == (rowsData.length - 1)){

                                  //Post to automate.isnet.co.za/create_data
                                
                                //create_data(mobj);
                                
                            }
                         
                          }
                         

                          
                          
                      

                      /*
                      if(j == (cellLength-1 )){

                            console.log('--- Save this invoice to table ---');
                                  
                            let mobj = {
                              docname: DocName,
                              docSender: DocSender,
                              docTag: DocTag,
                              docLink: DocLink,
                              single_data: allData.invoiceData,
                              row_data: allData.invoiceTable
                          }
                      
                          console.log(mobj);
                          props.saveCallback(mobj);

                      }
                      */
                    }




                    if(i == (rowLength -1 )){

                      console.log('--- Save this invoice to table ---');
                      
                            
                      let mobj = {
                        uuid: UUID,
                        docname: DocName,
                        docSender: DocSender,
                        docTag: DocTag,
                        docLink: DocLink,
                        single_data: allData.invoiceData,
                        row_data: allData.invoiceTable
                    }
                
                    console.log(mobj);
                    props.saveCallback(mobj);

                    }
                }
            
            

              
            }
              
            
          }
            
              
              }>Save</Button>

             
              </div>
          </div>
      </div>
  )

}
      

    useEffect(()=>{

     
      setSources(props.Sources);

      setDocName(props.DocName);
      setDocSender(props.DocSender);
      setDocTag(props.DocTag);
      setDocLink(props.DocLink);

      setUUID(props.UUID);
      
     
    })



   
    useEffect(()=>{


      
      setRowData(Sources);
      setRowKeys(Object.keys(Sources));

      let toplen = 0;
  
      let myObject = new Object;
      let rowArray = new Array;
      //console.log("L: " + Sources.length);
      let x=0; const xMax = Sources.length -1; for(; x<=xMax; x++){
  
            Sources[x]['id'] = x; //Add id key to each field
  
  
            let mykeys = Object.keys(Sources[x]);
  
           
        
  
          if(x == xMax){
            
            
         
           
  
  
            let items = Object.keys(myObject);
            let colArray = new Array();
            let z=0; const zMax = items.length - 1; for(; z <= zMax; z++){
                colArray.push(myObject[items[z]]);
               
               
              
  
                if(z===zMax){
                  
                  //console.log(colArray);
                  setColumnData(colArray);
                }
  
              }
            
             
  
  
  
            }
  
  
          }
  
           
      
  
  
  



    },[Sources]);



    
    async function update_sources(sourcesJson){
  
  
      return new Promise(function (resolve,reject){

        try{

               

                  let myheaders = {
                    'Content-Type': 'application/json'
                  };

                    
                 
                    fetch(server_url + "/update_sources",{
                      method: 'POST',
                      
                      headers: myheaders,
                       body: JSON.stringify(sourcesJson)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                setSources(data);
                                resolve(data)
                              
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }

        })

      }


      async function get_data(){
  
  
        return new Promise(function (resolve,reject){

          try{

                  

                    let myheaders = {
                      'Content-Type': 'application/json'
                    };

                      
            
                      fetch(server_url + "/get_data",{
                        method: 'GET',
                     
                        headers: myheaders,
                        
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  setSources(data);
                                  resolve(data)
                                
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })




      }


  


      const columns = [
        { field: 'sourceid', headerName: 'ID', width: 90 },
        {
          field: 'cnt',
          headerName: 'Country',
          width: 150,
          editable: true,
        },
        {
          field: 'code',
          headerName: 'Code',
          width: 150,
          editable: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: true,
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 150,
            editable: true,
          },
          {
            field: 'url',
            headerName: 'URL',
            width: 150,
            editable: true,
          },
          {
            field: 'web',
            headerName: 'WEb',
            width: 150,
            editable: true,
          }
        ]
    


        const handleCellEditCommit = (updateitem) => {

          console.log('hERE');
  
          console.log(updateitem);
      
          let id = updateitem.id;
      
          let newRowData = new Array();
      
          let i=0; const iMax = RowData.length -1; for(; i <= iMax; i++){
      
            let item = RowData[i];
            let cid = item.id;
            if(id == cid){
      
              console.log('Match');
              //console.log(row);
              console.log(item);
              let field = updateitem.field;
              let value = updateitem.value;
              item[field] = value;
              console.log(updateitem);
              
              //update Row witn new Row
              newRowData.push(item);
      
            } 
            else{
      
              newRowData.push(item);
            }
    
           
      
            if(i === iMax){
              console.log('Setting New RowData');
              setRowData(newRowData);
              console.log(newRowData);
            }

          }
      
      }
        

      const [editRowsModel, setEditRowsModel] = React.useState({});

      const handleEditRowsModelChange = React.useCallback((model) => {
        console.log('yes');
        setEditRowsModel(model);
        console.log(model);
      }, []);


      const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = Sources.filter((item) => {
                
    
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setRowData(filteredData)
        }
        else{
            setRowData(Sources)
        }
    }

  

    

   

    return(

    <div>

      <div className='editableTable' style={{ height: 'auto', width: "100%", marginBottom:30,}}>
      <SearchBar  id="searchTable" placeholder="Search..."
        onChange={(e) => searchItems(e.target.value)}
       />

   

      <div>

        <AddDeleteTableRows/>
      </div>
      
     


      
    </div>


    <div>
    {/*<Button
                  variant="contained"
                  onClick={handleDeleteRow}
                 
                 onClick={(id) => {

                   // handleDeleteRow();

                      const newRowData = RowData.filter((row => row.id !== id));
                  
                      //setRowData(newRowData);
                  
                      console.log(RowData);
                  
                  
                    }}
                  
                >
                  Delete Row
                </Button>*/}


  {/*<Button
                  variant="contained"
                 
                  onClick={(id) => {
                    handleAddRow();
                  
                  
                    }}
                  
                >
                  Add Row
                </Button>*/}





  {/*  <Button variant="text" onClick={()=>{

        

        console.log(RowData);

        
        update_sources(RowData);


        props.parentCallback({line_item_data: RowData});
        


        let code = document.getElementById('code').value;
        let feed = document.getElementById('feedname').value;
        let url = document.getElementById('url').value;
        let status = document.getElementById('status').value;
        console.log(code + " " + feed + " " + url);

        let jsonData = {
        code: code,
        feed: feed,
        url: url,
        status: status
        }
        

       // update_data(jsonData);


    }}>save</Button>  */}     

        {/* Verification Modall */}

       
            
    </div>
    </div>








    );
   }
    
   export default InvoiceTable;