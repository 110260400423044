import React from 'react'

function Pastel() {
    return (
        <div>
            <h1>Pastel Content</h1>
        </div>
    )
}

export default Pastel
