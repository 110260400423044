import React from 'react'
import UserManagement from '../components/UserManagement';
import CompanyManagement from '../components/CompanyManagement';
function AdminScreensuper(props) {
    return (
        <div id="managementBox">
        <div ><h3>SUPER ADMIN CENTRE</h3></div>
            <div>
            <UserManagement></UserManagement>
            </div>
            <div>
            <CompanyManagement></CompanyManagement>
            </div>
            
        </div>
    )
}

export default AdminScreensuper;
