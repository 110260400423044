import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: "70%",
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function MyCards(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;


  const [Stories,setStories] = useState([]);
  const [RunOnce,setRunOnce] = useState(true);

  useEffect(()=>{

   // if(RunOnce){
        //let stories = props.stories;
        console.log('Zhere');
        console.log(props.stories);
        setStories(props.stories);
       // setRunOnce(false);
   // }

  },[props])




  return (

    <div>
  
        Test
    </div>
  )

}