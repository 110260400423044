import React from "react";
import {Link} from 'react-router-dom';
import user from "../images/user.png";

const UserDetail = (props) => {
    console.log(props);
    //const {_id, UserName, email, CompanyName, user_role, password, status} =props.location.state.user;
    const { UserName,  CompanyName, UserRole, password, UserStatus} =props.location.state.users;
  return (
   <div className="main">
    <div className="ui card centered">
        <div className="image">
            <img src={user} alt="user"/>
        </div>
        <div className="content">
            <div className="header">{UserName}</div>
            {/*<div className="description">{email}</div>*/}
            <div className="description">{CompanyName}</div>
            <div className="description">{UserRole}</div>
            <div className="description">{UserStatus}</div>
           
           
            
        </div>
    </div>
        <div className="center-div">
        <Link to="/superadmin">
            <button className="center MuiButton-root detailBtn">Back to Users List</button>
        </Link>
        

        </div>

   </div>
  );
};

export default UserDetail;